import $ from 'jquery';

import {
  InlineChoiceGetter,
  getInlineChoiceMenu,
  DragDropGetter,
  HotSpotGetter,
  FractionShadeGetter,
} from '../getters';
import { makeProblemContext } from '../makeProblemContext';
import {
  isText,
  hasText,
  isQuantity,
  hasQuantity,
  getBox,
} from '../mathObjects';
import {
  axesModalState,
  setAxesModalGridGetter,
} from '../modals/axesModal/axesModalStore';
import {
  problemModalState,
  setFocusedNativeInput,
} from '../modals/problemModalStore';
import {
  initSpeech,
  injectReadAloudButton,
  buildTTSDTO,
  getReadAloudWrapper,
  readAloud,
  cancelReadAloud,
  shiftIndexes,
} from '../readAloud';
import {
  arrow,
  arrowDown,
  arrowLeft,
  arrowRight,
  arrowUp,
  circle,
  dot,
  dottedLine,
  filledArrow,
  line,
  lineA,
  numLineDrawing,
  roundRect2,
  xyz,
} from '../renderers';
import { getReadAloudButtonWidth, READ_ALOUD_HIGHLIGHT_COLOR } from '../state';
import { lineFont, lineFontSize } from '../state/font';
import {
  getBasicObject,
  getProblemImageSource,
  MathImage,
  getFillerBasicObject,
  ScribbleObject,
  getWordMenu,
  getMultiMenu,
  floater,
  getSubmitButton,
} from '../uiObjects';
import {
  decodeText,
  drawImage,
  encodeText,
  getIntersectingPolygon,
  isTouchDeviceOnly,
  isEmptyOrSpace,
  getImage,
  onlyLetters,
  processFloat,
  scaleFont,
  parseFont,
  getFullTopLeftLocation,
  getFullTopLeftLocation2,
  applyPixelRatioToCanvas,
} from '../utils';
import { baseBugPatch } from '../value';

import {
  NARROW_PROBLEM_WIDTH,
  PROBLEM_WIDTH,
  MAX_MULTI_LINE_WIDTH,
  STUDENT_PROBLEM_BACKCOLOR,
} from './problemCanvasConstants';
import { getApiUrl } from './problemCanvasSetup';
import { callSubmit } from './submit';

const axisColor = 'black';
const axisWidth = 3;

// also used for default user-created ray/segment/line color
const defaultPointColor = 'rgb(50,135,244)';

const emptyTextFill = '#FF6666';
const optionalEmptyTextFill = '#e6e8ef';
const unfocusedBoxBorderColor = 'gray';

const correctBlue = '#5bc0de';

const inverse = '\u207B\u00B9';
const theta = '\u03B8';

const EXTRA_PIXELS_UNDER_PROBLEM = 50;
const lineAfterH = 20;
const defaultSoloTextWidth = 5;
const defaultSpacerTextWidth = 3;
const rowVertSpacer = 10;
const rowHorSpacer = 5;
const statusDiameter = 15;

const middleSymbols = ['=', '<', '>', '\u2264', '\u2265'];
const otherChars = '0123456789 ><+=-\u03C0\u2212.*\u00F7,\u2264\u2265%$:\'"';

export function buildProblemCanvasOwner({
  isStudent,
  isTeacherViewingExamProblem,
  problemContext,
}) {
  // The 'text' that will receive key input (may be undefined)
  let focused;
  let imageNum;
  let base64Imgs = {};

  let canvasW = PROBLEM_WIDTH;
  let screenMaxTextW = MAX_MULTI_LINE_WIDTH;
  let narrow;
  let builtCurrentFrom;
  var keyboard;
  var keyboardRow;
  var keyboardScalar = 1;
  var DEFAULT_KEYBOARD_WIDTH = NARROW_PROBLEM_WIDTH;
  var keyboardW = DEFAULT_KEYBOARD_WIDTH;
  var submitLock = false;
  var lastSubmitTime = -1;
  var lettersButton;
  var clearAllB;
  var submitCount = 0;

  var $canvas;

  var problemPanel;
  var attemptGetters = new Map();
  var onlyNormalGetters = true;
  var currentAttemptGetter;
  var buttons = {};
  var buttonKeys = [];
  var currentProblemId;
  var imageFromRestoreTest;

  var imageLoadedListener;
  var selfCheckListener;

  var world;
  var gridGuide;

  let pointCount = 0;

  // DragDropGetter | undefined
  var dragController;

  // fills out inbound problemContext with defaults for
  // any missing (inbound problemContext can be undefined
  // or partial)
  problemContext = makeProblemContext(problemContext);

  var getProblemContext = function () {
    return problemContext;
  };

  /**
   * We can remove this when retroencabulator stops using it.
   */
  function setBase64EncodedImgs(imgDict) {
    base64Imgs = imgDict;
  }

  const setToBlank = () => {
    if (!$canvas) return;

    if (attemptGetters) attemptGetters.clear();
    buttons = {};
    currentAttemptGetter = undefined;
    builtCurrentFrom = undefined;

    if (problemPanel) {
      problemPanel.children = [];
      problemPanel.viewportSlideX = 0;
      problemPanel.viewportSlideY = 0;
    }

    keyboardRow = undefined;

    if (keyboard) {
      keyboard.getter = undefined;
      keyboard.agId = undefined;
    }

    window.scrollTo(0, 0);
    repositionProblemRows();

    paintCanvas();
  };

  var getThemeColor = function () {
    return problemContext.getThemeColor();
  };

  // Very similar for InlineChoiceGetter, MatchTableGridGetter, MultiSelectGetter,
  // and traditional multiple choice getter. For now, pass into those
  // classes as we make them, but...
  // Target for cleansing.
  var grabFocus = function (getter) {
    if (currentAttemptGetter && currentAttemptGetter.gmmName == 'gridGetter') {
      currentAttemptGetter.g.setSelected(false);
    }

    if (focused) {
      focused.setFocused(false);
    }

    removeKeyboard();
    currentAttemptGetter = getter;
  };

  var errorToServer = function (error) {
    getProblemContext().errorToServer(error);
  };

  var getProblemJSDependencies = function () {
    return {
      grabFocus,
      isTesting,
      getStaticPanel,
      getStaticPanelFromText,
      getText,
      getProbImage,
      submitAttempt,
      getThemeColor,
      paintCanvas,
      resizeCanvas,
      $canvas,
      isNarrow,
      NARROW_PROBLEM_WIDTH,
      MAX_MULTI_LINE_WIDTH,
      EXTRA_PIXELS_UNDER_PROBLEM,
    };
  };

  var setLoading = function (b) {
    getProblemContext().setLoading(b);
  };

  var paintCanvas = function () {
    if (!$canvas) return;

    var ctx = $canvas[0].getContext('2d');

    ctx.save();

    world.paint(ctx);

    floater.paint(ctx);

    ctx.restore();

    ctx.save();

    if (gridGuide && gridGuide.on) {
      gridGuide.paintMe(ctx);
    }

    ctx.restore();

    floater.paint(ctx);
  };

  var preCircle = function (ctx, gridDot, r, color, open, lt) {
    if (open && !lt) lt = 2;
    r = r || 5;

    if (open && lt) {
      r -= lt / 2.0;
      r += 0.5;
    }

    var col =
      gridGuide && gridGuide.on && gridGuide.dot === gridDot
        ? 'magenta'
        : gridDot.color;

    circle(ctx, gridDot.cx, gridDot.cy, r, col, open, false, lt);
  };

  var canvasMouseMove = function (e) {
    if (ignoreTeacherAfterTotallyCorrect()) return;

    var x = e.pageX - $(e.target).offset().left;
    var y = e.pageY - $(e.target).offset().top;

    canvasMouseMoveHelper(x, y);
  };

  var canvasMouseMoveHelper = function (x, y) {
    if (dragController) {
      dragController.moveDraggingImage(x, y);
    }

    let results = {
      kill: false,
      repaint: false,
      mousePointerChangeTo: undefined,
    };

    world.mouseMove(x, y, results);

    if (results.repaint) {
      paintCanvas();
    }

    if (!isDragging() && results.mousePointerChangeTo) {
      $canvas.css('cursor', results.mousePointerChangeTo);
    }

    return results.kill;
  };

  var isDragging = function () {
    return dragController && dragController.isDragging();
  };

  var canvasMouseUp = function (e) {
    if (ignoreTeacherAfterTotallyCorrect()) return;

    var x = e.pageX - $(e.target).offset().left;
    var y = e.pageY - $(e.target).offset().top;

    canvasMouseUpHelper(x, y);
  };

  var canvasMouseUpHelper = function (x, y) {
    world.mouseUp(x, y);
    paintCanvas();
  };

  var canvasMouseDown = function (mouseEvent) {
    if (ignoreTeacherAfterTotallyCorrect()) return;

    const canvasRect = $canvas[0].getBoundingClientRect();
    const x = mouseEvent.clientX - canvasRect.left;
    const y = mouseEvent.clientY - canvasRect.top;

    const causedSomethingToHappen = canvasMouseDownHelper(x, y, mouseEvent);

    if (causedSomethingToHappen) {
      mouseEvent.preventDefault();
      mouseEvent.stopPropagation();
    }

    return causedSomethingToHappen;
  };

  var canvasMouseDownHelper = function (x, y, evt) {
    if (!getProblemContext().activity()) return;

    if (floater.mouseDown(x, y, paintCanvas)) {
      paintCanvas();

      return true;
    }

    if (gridGuide && gridGuide.on) {
      if (gridGuide.mouseDownResponse(x, y)) {
        paintCanvas();

        return true;
      }
    }

    if (dragController) {
      dragController.mostRecentMouseDown = { x: x, y: y };
      dragController.sourceCanvas = evt.target;
    }

    var b = world.mouseDown(x, y, paintCanvas, evt);

    paintCanvas();

    return !!b;
  };

  var pressedKey = function (char) {
    if (char == '/') {
      char = '\u00F7';
    }

    // only allow alpha numeric
    var allow = otherChars.indexOf(char) > -1;

    if (!allow) {
      // taking out limitation of keystrokes to only relevant letters
      // cuz kids thought keyboards were broken
      allow = /^[a-z]+$/i.test(char);
    }

    if (allow) {
      // Remove any extra button selections
      if (
        currentAttemptGetter != null &&
        currentAttemptGetter.selectedButton != null
      )
        currentAttemptGetter.selectedButton = null;

      focused.insertString(char);

      storeCurrentLineXML();
    }
  };

  var storeCurrentLineXML = function () {
    // if currentAttemptGetter is a 'normal getter' used by a number line getter,
    // then this storage logic should acquire the parent number line getter
    var found = attemptGetters.get(currentAttemptGetter.agId);

    if (!found) {
      console.error('ag #' + currentAttemptGetter.agId + ' not found');

      return;
    }

    if (focused.line) {
      var xml = focused.line.toXML();
      var cell;

      if (found.gmmName === 'tableGetter') {
        var col = found.table.args.cols[focused.line.tableCol];

        cell = col[focused.line.tableRow];
        cell.xml = xml;
      } else if (found.gmmName === 'valueTableGetter') {
        var row = found.valueTable.args.rows[focused.line.tableRow];

        cell = row[focused.line.tableCol];
        cell.xml = xml;
      } else if (found.gmmName === 'numberLineGetter') {
        var zone = focused.line.nlZone;

        found.nl.args[zone] = xml;
      }
      // Assume 'normalGetter'
      else {
        found.setXML(focused.line.lineNumber, xml);
      }

      focused.line.notifySizeChanged();
    } else {
      console.error('Not coded for non-line focused (such as Grid?)');
    }
  };

  var moveCursor = function (direction) {
    if (!focused) {
      return false;
    }

    var moved = false;

    if (direction === 'left') {
      moved = focused.slideCursor(-1);
    } else if (direction == 'right') {
      moved = focused.slideCursor(1);
    } else {
      moved = focused.parent.moveCursor(focused, direction);
    }

    if (!moved && currentAttemptGetter.gmmName === 'tableGetter') {
      currentAttemptGetter.tab(direction);

      return true;
    }

    if (!moved && currentAttemptGetter.gmmName === 'valueTableGetter') {
      currentAttemptGetter.tab(direction);

      return true;
    }

    return moved;
  };

  var toLine = function (xml, x, y, w, h, multiline, lockViewportDim, font) {
    var line = getLine(x || 0, y || 0, font);

    var multi = getTilesHelper(line, xml, 0, xml.length - 1, multiline);

    line.children.pop();
    multi.parent = line;
    line.children.push(multi);

    if (font) line.setFont(font);

    line.balanceEmptyTexts();
    line.buildSizeRecursive();

    line.viewportW = line.width;
    line.viewportH = line.height;
    line.lockViewportDim = lockViewportDim || true;

    return line;
  };

  // return populated multi from recursive dive
  var getTilesHelper = function (
    line,
    val,
    first,
    last,
    multiline,
    gettingBase
  ) {
    var mtList = [];

    while (first < last) {
      var r = val.indexOf('>', first);
      var tag = val.substring(first + 1, r);
      var closeTag = findClose(tag, r + 1, val);

      var s = r + 1;
      var e = closeTag - 1;

      if (tag === 't') {
        if (s < e + 1) {
          var text = val.substring(s, e + 1);

          if (text.length > 0) {
            var decoded = decodeText(text);

            if (decoded != null) text = decoded;
            var bestW = Math.min(MAX_MULTI_LINE_WIDTH, window.innerWidth - 10);

            // Leave room for a ReadAloudButton
            if (isReadAloud()) bestW -= getReadAloudButtonWidth();

            var at = getText(0, 0, text, lineFontSize, multiline, bestW);

            mtList.push(at);
          }
        }
      } else if (tag === 'q' || tag === 'a') {
        var ab = tag === 'a';
        var q;

        if (s == e) {
          q = getQuantity(line, undefined, ab);
        } else {
          // Check for new format
          if (val.indexOf('contents', first) != -1) {
            // Pull out left character
            var startLChar = val.indexOf('lChar', first) + 6;
            var endLChar = findClose('lChar', startLChar, val) - 1;
            var lchar = getTilesHelper(line, val, startLChar, endLChar)
              .children[0];

            // Pull out contents
            var startContents = val.indexOf('contents', endLChar) + 9;
            var endContents = findClose('contents', startContents, val) - 1;
            var mt = getTilesHelper(line, val, startContents, endContents);

            // Pull out right character
            var startRChar = val.indexOf('rChar', endContents) + 6;
            var endRChar = findClose('rChar', startRChar, val) - 1;
            var rchar = getTilesHelper(line, val, startRChar, endRChar)
              .children[0];

            // Build quantity
            q = getQuantity(line, mt, ab, lchar, rchar);
          } else {
            var mt = getTilesHelper(line, val, s, e);

            q = getQuantity(line, mt, ab);
          }
        }

        mtList.push(q);
      } else if (tag === 'r') {
        var rr;

        if (s == e) {
          rr = getRoot(line, undefined);
        } else {
          if (val.indexOf('rad', first) != -1) {
            s += 5; // for <rad>
            var endRad = findClose('rad', s, val);

            e = endRad - 1; // for <
            var rad = getTilesHelper(line, val, s, e);

            s = endRad + 12; // for </rad><root>
            var endRoot = findClose('root', s, val);

            e = endRoot - 1;
            var root = getTilesHelper(line, val, s, e);

            rr = getRoot(line, rad, root);
          } else {
            var rad = getTilesHelper(line, val, s, e);

            rr = getRoot(line, rad);
          }
        }

        mtList.push(rr);
      } else if (tag === 'f') {
        var f;

        if (s == e) {
          f = getFraction(line);
        } else {
          s += 5; // for <num>
          var endNum = findClose('num', s, val);

          e = endNum - 1; // for <
          var num = getTilesHelper(line, val, s, e);

          s = endNum + 13; // for </num><denom>
          var endDenom = findClose('denom', s, val);

          e = endDenom - 1;
          var denom = getTilesHelper(line, val, s, e);

          f = getFraction(line);
          f.setNumeratorAndDenominator(num, denom);
        }

        mtList.push(f);
      } else if (tag === 'b') {
        var b;

        if (s == e) {
          b = getBarred(line);
        } else {
          var endB = findClose('b', s, val);

          e = endB - 1;

          if (val.indexOf('linetype', first) > -1) {
            // point to char after <linetype> tag
            s += 10;
            var endLineType = findClose('linetype', s, val);
            var lineTypeLength = endLineType - s;
            var lineType = val.substring(s, endLineType);

            // point to char after </linetype> tag
            s += lineTypeLength + 11;
          } else {
            lineType = 'LINE';
          }

          var stuff = getTilesHelper(line, val, s, e);

          b = getBarred(line);
          b.setLineType(lineType);
          b.setStuff(stuff);
        }

        mtList.push(b);
      } else if (tag === 'p') {
        var p;

        if (s == e) {
          p = getPower(line);
        } else {
          // assumes tags b /b e /e
          s += 3; // for <b>
          var endNum = findClose('b', s, val);

          e = endNum - 1; // for <
          var base = getTilesHelper(line, val, s, e, undefined, true);

          s = endNum + 7; // for </b><e>
          var endDenom = findClose('e', s, val);

          e = endDenom - 1;
          var exp = getTilesHelper(line, val, s, e);

          p = getPower(line, base, exp);
        }

        mtList.push(p);
      } else if (tag === 's') {
        var subscript;

        if (s == e) {
          subscript = getSubscript(line);
        } else {
          // assumes tags b /b sub /sub
          s += 3; // for <b>
          var endBase = findClose('b', s, val);

          e = endBase - 1; // for <
          var base = getTilesHelper(line, val, s, e);

          s = endBase + 9; // for </b><sub>
          var endSub = findClose('sub', s, val);

          e = endSub - 1;
          var sub = getTilesHelper(line, val, s, e);

          subscript = getSubscript(line);
          subscript.setBaseAndSub(base, sub);
        }

        mtList.push(subscript);
      } else if (tag === 'tf') {
        var tFunction;

        if (s == e) {
          tFunction = getFunction(line);
        } else {
          // assumes tags ft /ft fb /fb v /v
          s += 4; // for <ft>
          var endType = findClose('ft', s, val);

          e = endType - 1; // for <
          var type = getTilesHelper(line, val, s, e);

          s = endType + 9; // for </ft><fb>
          var endBase = findClose('fb', s, val);

          e = endBase - 1; // for <
          var base = getTilesHelper(line, val, s, e);

          s = endBase + 8; // for </fb><v>
          var endValue = findClose('v', s, val);

          e = endValue - 1;
          var value = getTilesHelper(line, val, s, e);

          tFunction = getFunction(line);
          tFunction.setAll(type, base, value);
        }

        mtList.push(tFunction);
      }
      // Pull-down menu embedded into a paragraph
      // for InlineChoiceGetter
      else if (tag === 'inlineChoice') {
        mtList.push(
          getInlineChoiceMenu(
            getProblemJSDependencies(),
            val.substring(s, e + 1),
            attemptGetters
          )
        );
      } else if (tag === 'box') {
        mtList.push(getBox(line));
      } else {
        console.error('not coded for ' + tag);
      }

      first = val.indexOf('>', closeTag) + 1;
    }

    if (mtList.length === 0) {
      var empty = getMulti(line);

      return empty;
    } else {
      var ret = getMulti(line);

      ret.locationParent = line;

      if (gettingBase && mtList.length == 1 && mtList[0].t === 'quantity') {
        // remove default empty text element
        ret.children.splice(0, 1);
        // now the ONLY element of the multi will be a Quantity
        ret.add(mtList[0]);
        // DON'T balanceEmptyTexts here to avoid adding empty text elements on either side of base's Quantity
      } else {
        for (var i = 0; i < mtList.length; i++) {
          ret.add(mtList[i]);
          mtList[i].locationParent = ret;
        }

        ret.balanceEmptyTexts();
      }

      return ret;
    }
  };

  var getLine = function (x, y) {
    var ret = getBasicObject(x, y);

    ret.t = 'line';
    ret.gmmName = 'line';
    ret.e = true;
    ret.lockViewportDim = false;

    var multi = getMulti(ret);

    ret.children.push(multi);
    multi.parent = ret;

    ret.balanceEmptyTexts = function () {
      ret.children[0].balanceEmptyTextsRecursive();
    };

    ret.setLineOfChildren = function () {
      ret.children[0].setLine(ret);
    };

    ret.hasMultiLinesText = function () {
      return ret.children[0].hasMultiLinesText();
    };

    ret.pixelsToMiddle = function (align) {
      var x = 0;
      var mult = ret.children[0];
      var multC = mult.children;

      for (var a = 0; a < multC.length; a++) {
        var child = multC[a];

        if (align && child.t === 'text' && child.hasMiddleSymbol()) {
          return x + child.pixelsToMiddle(align);
        } else {
          x += child.viewportW;
        }
      }
    };

    ret.notifySizeChanged = function () {
      // Allow process event if dynamic sizing is enabled
      if (ret.isDynamicSize) {
        var row = ret.getRow();

        if (row !== undefined) row.buildSizeRecursive();
      }
    };

    ret.buildSize = function () {
      if (!ret.lockViewportDim) {
        if (ret.isDynamicSize || !ret.maxVW) {
          ret.setAllDim(ret.children[0].viewportW, ret.children[0].viewportH);
        } else {
          ret.setAllDim(
            Math.min(ret.children[0].viewportW, ret.maxVW),
            ret.children[0].viewportH
          );
        }
      } else {
        ret.setAllDim(ret.children[0].viewportW, ret.children[0].viewportH);

        // I'll assume that locking the dim means you want
        // vertical centering.
        var dy = (ret.viewportH - 2 * ret.viewportMargin) / 2 - ret.height / 2;

        ret.children[0].viewportY = dy;
      }
    };

    ret.setFont = function (f) {
      ret.children[0].setFont(f);
    };

    ret.buildSizeRecursive = function () {
      ret.children[0].buildSizeRecursive();
      ret.buildSize();
    };

    ret.childSizeChanged = function () {
      ret.buildSize();

      if (ret.lineHolder) {
        ret.lineHolder.lineSizeChanged();
      } else if (ret.normalHolder) {
        ret.normalHolder.repositionLines();
      }
    };

    // Overrides BasicObject.add to ensure indexed addition
    // gets passed down to the multi
    ret.add = function (addMe, index) {
      ret.children[0].add(addMe, index);
      addMe.parent = ret.children[0];
    };

    ret.grabFocus = function (dir) {
      ret.children[0].grabFocus(dir);

      if (!ret.e) {
        console.error('focus error 4');
      }
    };

    // tell holder so it can resize to show submit and icons
    ret.gainedFocus = function () {
      if (
        currentAttemptGetter &&
        currentAttemptGetter.gmmName == 'gridGetter'
      ) {
        currentAttemptGetter.g.setSelected(false);
      }

      if (ret.normalHolder) {
        ret.normalHolder.childGainedFocus();
      } else if (ret.tableGetter) {
        ret.tableGetter.childGainedFocus();
      }

      if (!ret.e) {
        console.error('focus error 5');
      }
    };

    // tell holder so it can resize to hide submit and icons
    ret.lostFocus = function () {};

    ret.toXML = function () {
      return ret.children[0].toXML();
    };

    ret.swapString = function (old, replacer) {
      ret.children[0].swapString(old, replacer);
    };

    ret.clear = function () {
      var multi = getMulti(ret);

      ret.children = [];
      ret.children.push(multi);
      multi.parent = ret;
      ret.balanceEmptyTexts();
      ret.buildSizeRecursive();

      storeCurrentLineXML();
    };

    ret.setEditable = function (b) {
      ret.children[0].setEditable(b);
      ret.e = b;
    };

    ret.isFocused = function () {
      return focused && focused.line === ret;
    };

    return ret;
  };

  var toXML = function (line) {
    return line.toXML();
  };

  var getMulti = function (line, string) {
    var ret = getBasicObject();

    ret.line = line;
    ret.t = 'm';

    /**
     * @memberOf ret
     */
    ret.setEditable = function (b) {
      for (var a = 0; a < ret.children.length; a++) {
        ret.children[a].setEditable(b);
      }
    };

    ret.getLineX = function () {
      if (ret.parent.t === 'line') return 0;

      return ret.parent.viewportX + ret.parent.parent.getLineX();
    };

    // RELIES ON top level line parent to recursively work resizing
    ret.setFont = function (f) {
      for (var a = 0; a < ret.children.length; a++) {
        ret.children[a].setFont(f);
      }
    };

    ret.setFontSize = function (fontSize) {
      for (var a = 0; a < ret.children.length; a++) {
        if (ret.children[a].setFontSize) ret.children[a].setFontSize(fontSize);
        else ret.children[a].setFont(fontSize + 'px ' + lineFont);
      }
    };

    /**
     * @memberOf ret
     */
    ret.balanceEmptyTextsRecursive = function () {
      // skip down through intervening Fraction/Power/Quantity...
      for (var i = 0; i < ret.children.length; i++) {
        var child = ret.children[i];

        if (child.t !== 'text') {
          for (var j = 0; j < child.children.length; j++) {
            var grandchild = child.children[j];

            if (grandchild.t === 'm') {
              grandchild.balanceEmptyTextsRecursive();
            }
          }
        }
      }

      ret.balanceEmptyTexts();
    };

    ret.hasMultiLinesText = function () {
      for (var i = 0; i < ret.children.length; i++) {
        var child = ret.children[i];

        if (child.t !== 'text') {
          for (var j = 0; j < child.children.length; j++) {
            var grandchild = child.children[j];

            if (grandchild.t === 'm') {
              var has = grandchild.hasMultiLinesText();

              if (has) return true;
            }
          }
        } else {
          if (child.lines && child.lines.length > 1) return true;
        }
      }

      return false;
    };

    ret.isEmptyOrSpace = function () {
      for (var i = 0; i < ret.children.length; i++) {
        var child = ret.children[i];

        if (child.t !== 'text') {
          for (var j = 0; j < child.children.length; j++) {
            var grandchild = child.children[j];

            if (grandchild.t === 'm') {
              if (!grandchild.isEmptyOrSpace()) return false;
            }
          }
        } else {
          if (!isEmptyOrSpace(child.text)) return false;
        }
      }

      return true;
    };

    ret.setLine = function (line) {
      ret.line = line;

      for (var x = 0; x < ret.children.length; x++) {
        ret.children[x].setLine(line);
      }
    };

    ret.toXML = function () {
      var sb = '';

      for (var i = 0; i < ret.children.length; i++) {
        sb += ret.children[i].toXML();
      }

      return sb;
    };

    ret.swapString = function (old, replacer) {
      for (var i = 0; i < ret.children.length; i++) {
        ret.children[i].swapString(old, replacer);
      }
    };

    /**
     * @memberOf getMulti
     */
    ret.balanceEmptyTexts = function () {
      // don't pad base of power with empty text elements on left and right
      if (
        ret.children.length == 1 &&
        ret.gmmName &&
        ret.gmmName === 'base multi'
      ) {
        return;
      }

      // first, merge any adjoining
      var lastWasText = false;

      // var splicedMultiline = false;
      for (var i = 0; i < ret.children.length; i++) {
        var child = ret.children[i];

        if (lastWasText) {
          if (child.t === 'text') {
            var first = ret.children[i - 1];

            first.text += child.text;

            if (child.multiline) {
              first.multiline = true;
              first.maxW = child.maxW;
            }

            //* *code to remove child here**
            ret.children.splice(i, 1);

            // decrement so that this slot is reconsidered after later children slide down
            i--;
          }
        }

        lastWasText = child.t === 'text';
      }

      // second, ensure c sequencing of blank texts
      lastWasText = false;

      for (var i = 0; i < ret.children.length; i++) {
        var child = ret.children[i];

        if (child.t !== 'text') {
          if (!lastWasText) {
            var insert = getText();

            insert.e = true;
            // code to insert at i
            ret.add(insert, i);
            // decrement so that this slot (now with text) is processed again
            i--;
          } else {
            lastWasText = false;
          }
        } else {
          lastWasText = true;
        }
      }

      if (ret.children[ret.children.length - 1].t !== 'text') {
        var last = getText();

        last.e = true;
        ret.add(last);
      }

      // last, set all minW to default OR 'empty'
      if (ret.children.length === 1) {
        ret.children[0].minWidth = defaultSoloTextWidth;
      } else {
        for (var i = 0; i < ret.children.length; i++) {
          var child = ret.children[i];

          if (child.t === 'text') {
            child.minWidth = defaultSpacerTextWidth;
          }
        }
      }

      for (var i = 0; i < ret.children.length; i++) {
        var child = ret.children[i];

        if (child.t === 'text') {
          child.sizeMe(true);
        }
      }

      ret.childSizeChanged();
    };

    ret.getElementToLeft = function (me) {
      var index = -1;

      for (var i = 0; i < ret.children.length; i++) {
        if (me === ret.children[i]) {
          index = i;
          break;
        }
      }

      return index < 1 ? undefined : ret.children[index - 1];
    };

    ret.getElementToRight = function (me) {
      var index = -1;

      for (var i = 0; i < ret.children.length; i++) {
        if (me === ret.children[i]) {
          index = i;
          break;
        }
      }

      return index == -1 || index == ret.children.length - 1
        ? undefined
        : ret.children[index + 1];
    };

    ret.pushTextOutOfBaseToLeft = function (power, text) {
      var n = ret.getElementToLeft(power);

      if (n.gmmName === 'text') {
        n.text += text;
        n.sizeMe();
        n.setCursorIndentChars(text.length);
      } else {
        getProblemContext().addStackableDialog({
          msg: 'GMM entry error, please restart',
          reload: true,
          top: 'Error',
        });
      }
    };

    ret.pushTextOutToRight = function (parent, text) {
      var n = ret.getElementToRight(parent);

      if (n.gmmName === 'text') {
        n.text += text;
        n.sizeMe();

        ret.moveCursor(parent, 'right');
        n.setCursorIndentChars(text.length, true);
      } else {
        getProblemContext().addStackableDialog({
          msg: 'GMM entry error, please restart',
          reload: true,
          top: 'Error',
        });
      }
    };

    /**
     * @memberOf getMulti
     */
    ret.deleteLeft = function (srcText) {
      // get index of src
      var index = -1;

      for (var i = 0; i < ret.children.length; i++) {
        if (srcText === ret.children[i]) {
          index = i;
          break;
        }
      }

      if (index < 2 || index == -1) {
        return;
      }

      //* *remove child at index - 1
      //* *slide everyone else down
      ret.children.splice(index - 1, 1);
      ret.children[i - 2].grabFocus('left');
      ret.balanceEmptyTexts();
      ret.line.notifySizeChanged();
    };

    /**
     * @memberOf getMulti
     */
    ret.moveCursor = function (child, direction) {
      if (direction === 'down' || direction === 'up') {
        var m = false;

        if (ret.parent.t === 'line') {
          m = false;
        } else if (
          ret.parent.t !== 'fraction' &&
          !(
            ret.parent.t === 'power' &&
            ret === ret.parent.base &&
            direction === 'up'
          ) &&
          !(
            ret.parent.t === 'power' &&
            ret === ret.parent.exp &&
            direction === 'down'
          ) &&
          !(
            ret.parent.t === 'root' &&
            ret === ret.parent.rad &&
            direction === 'up'
          ) &&
          !(
            ret.parent.t === 'root' &&
            ret === ret.parent.root &&
            direction === 'down'
          ) &&
          !(
            ret.parent.t === 'subscript' &&
            ret === ret.parent.base &&
            direction === 'down'
          ) &&
          !(
            ret.parent.t === 'subscript' &&
            ret === ret.parent.sub &&
            direction === 'up'
          ) &&
          !(
            ret.parent.t === 'function' &&
            (ret === ret.parent.type || ret === ret.parent.value) &&
            direction === 'down'
          ) &&
          !(
            ret.parent.t === 'function' &&
            ret === ret.parent.base &&
            direction === 'up'
          )
        ) {
          m = ret.parent.parent.moveCursor(ret.parent, direction);
        } else if (ret.parent.t === 'fraction') {
          if (direction === 'down' && ret === ret.parent.numerator) {
            ret.parent.denominator.grabFocus();
            m = true;
          }

          if (direction === 'up' && ret === ret.parent.denominator) {
            ret.parent.numerator.grabFocus();
            m = true;
          }
        } else if (ret.parent.t === 'power') {
          if (direction === 'down') {
            ret.parent.base.grabFocus('left');
            m = true;
          } else {
            ret.parent.exp.grabFocus('right');
            m = true;
          }
        } else if (ret.parent.t === 'root') {
          if (
            ret.parent.showRoot() &&
            (direction === 'up' || direction === 'left')
          ) {
            ret.parent.root.grabFocus('left');
            m = true;
          } else {
            ret.parent.rad.grabFocus('right');
            m = true;
          }
        } else if (ret.parent.t === 'subscript') {
          if (direction === 'down') {
            ret.parent.sub.grabFocus('right');
            m = true;
          } else {
            ret.parent.base.grabFocus('left');
            m = true;
          }
        } else if (ret.parent.t === 'function') {
          if (direction === 'down') {
            if (ret.parent.showBase) {
              if (ret === ret.parent.type) ret.parent.base.grabFocus('left');
              else ret.parent.base.grabFocus('right');
            }

            m = true;
          } else {
            if (child.cursorIndentChars == 0) ret.parent.type.grabFocus('left');
            else ret.parent.value.grabFocus('right');
            m = true;
          }
        }

        if (m) return m;

        if (
          currentAttemptGetter &&
          currentAttemptGetter.gmmName === 'normalGetter' &&
          focused
        ) {
          var ls = currentAttemptGetter.lines;

          if (ls.length > 0) {
            var i = -1;

            for (var a = 0; a < ls.length; a++) {
              if (focused.line === ls[a]) {
                i = a;
                break;
              }
            }

            if (i > -1) {
              var d;

              if (direction === 'up') {
                if (i === 0) return false;
                d = -1;
              } else {
                if (i == ls.length - 1) {
                  ls[i].normalGetter.nextLine();

                  return false;
                }

                d = 1;
              }

              i += d;
              var tl = ls[i];
              var y = tl.viewportY + (d > 0 ? 3 : tl.viewportH - 3);
              var x = Math.max(5, focused.getLineX());

              tl.normalGetter.mouseDown(x, y, paintCanvas);
            }
          }
        }

        return false;
      }

      // get index of src
      var index = -1;

      for (var i = 0; i < ret.children.length; i++) {
        if (child === ret.children[i]) {
          index = i;
        }
      }

      if (index == -1) {
        return false;
      }

      if (direction === 'left') {
        if (index < 1) {
          if (ret.parent.t === 'line') {
            return false;
          } else {
            var moved = false;

            if (ret.parent.t === 'power') {
              if (ret === ret.parent.exp) {
                ret.parent.base.grabFocus('left');
                moved = true;
              }
            } else if (ret.parent.t === 'root') {
              if (ret === ret.parent.rad && ret.parent.showRoot()) {
                ret.parent.root.grabFocus('left');
                moved = true;
              }
            } else if (ret.parent.t === 'subscript') {
              if (ret === ret.parent.sub) {
                ret.parent.base.grabFocus('left');
                moved = true;
              }
            } else if (ret.parent.t === 'function') {
              if (ret === ret.parent.base) {
                ret.parent.type.grabFocus('left');
                moved = true;
              } else if (ret === ret.parent.value) {
                if (ret.parent.showBase) {
                  ret.parent.base.grabFocus('left');
                  moved = true;
                } else {
                  ret.parent.type.grabFocus('left');
                  moved = true;
                }
              }
            }

            if (!moved) return ret.parent.parent.moveCursor(ret.parent, 'left');

            return true;
          }
        } else {
          ret.children[index - 1].grabFocus('left');

          return true;
        }
      } else {
        if (index >= ret.children.length - 1) {
          if (ret.parent.t === 'line') {
            return false;
          } else {
            var moved = false;

            if (ret.parent.t === 'power') {
              if (ret === ret.parent.base) {
                ret.parent.exp.grabFocus('right');
                moved = true;
              }
            } else if (ret.parent.t === 'root') {
              if (ret === ret.parent.root) {
                ret.parent.rad.grabFocus('right');
                moved = true;
              }
            } else if (ret.parent.t === 'subscript') {
              if (ret === ret.parent.base) {
                ret.parent.sub.grabFocus('right');
                moved = true;
              }
            } else if (ret.parent.t === 'function') {
              if (ret === ret.parent.type) {
                if (ret.parent.showBase) {
                  ret.parent.base.grabFocus('right');
                  moved = true;
                } else {
                  ret.parent.value.grabFocus('right');
                  moved = true;
                }
              } else if (ret === ret.parent.base) {
                ret.parent.value.grabFocus('right');
                moved = true;
              }
            }

            if (!moved)
              return ret.parent.parent.moveCursor(ret.parent, 'right');

            return true;
          }
        } else {
          ret.children[index + 1].grabFocus('right');

          return true;
        }
      }
    };

    /**
     * @memberOf getMulti
     */
    ret.deleteRight = function (srcText) {
      var index = -1;

      for (var i = 0; i < ret.children.length; i++) {
        if (srcText === ret.children[i]) {
          index = i;
          break;
        }
      }

      if (index == ret.children.length - 1 || index == -1) {
        return;
      }

      //* *remove child at index
      //* slide everyone else down
      //* *sizeMe, not recursive
      ret.children.splice(index + 1, 1);
      ret.balanceEmptyTexts();
      ret.line.notifySizeChanged();
    };

    /**
     * @memberOf getMulti
     */
    ret.insertRequested = function (t, srcText) {
      var count = 0;
      var next = ret;

      while (next.parent && next.parent.parent) {
        count++;
        next = next.parent.parent;
      }

      if (count > 3) {
        return;
      }

      var intoBase = ret.parent.t === 'power' && ret.parent.base === ret;

      if (intoBase && t === 'power') return;

      var index = -1;

      for (var i = 0; i < ret.children.length; i++) {
        if (srcText === ret.children[i]) {
          index = i;
          break;
        }
      }

      var text = srcText.text;
      var preString =
        text.length === 0
          ? ''
          : srcText.cursorIndentChars === 0
          ? ''
          : text.substring(0, srcText.cursorIndentChars);
      var postString =
        text.length === 0
          ? ''
          : srcText.cursorIndentChars == text.length
          ? ''
          : text.substring(srcText.cursorIndentChars);

      srcText.text = preString;

      var insert;

      if (t === 'fraction') {
        insert = getFraction(ret.line);
      } else if (t == 'barred') {
        insert = getBarred(ret.line);
      } else if (t === 'quantity') {
        insert = getQuantity(ret.line);
      } else if (t === 'absoluteValue') {
        insert = getAbsoluteValue(ret.line);
      } else if (t === 'power') {
        insert = getPower(ret.line);

        if (preString.length > 0) {
          var ll = preString.substring(preString.length - 1);
          var possibleAbbreviation = false;

          if (preString.length > 1 && ll === '.') {
            var previousCharacter = preString.substring(
              preString.length - 2,
              preString.length - 1
            );

            possibleAbbreviation = /^[a-zA-Z]*$/.test(previousCharacter);
          }

          if (/^[a-zA-Z\u03C0]*$/.test(ll) || possibleAbbreviation) {
            srcText.text = preString.substring(0, preString.length - 1);
            insert.base.children[0].text = ll;
            insert.base.children[0].sizeMe();
          } else if ($.isNumeric(ll)) {
            var built = ll;
            var stop = preString.length - 1;

            for (var myI = preString.length - 2; myI > -1; myI--) {
              var sn = preString.substring(myI, myI + 1);

              if ($.isNumeric(sn) || sn == '.') {
                built = sn + built;
                stop--;
              } else {
                break;
              }
            }

            srcText.text = preString.substring(0, stop);
            insert.base.children[0].text = built;
            insert.base.children[0].sizeMe();
          }
        } else if (index > 0) {
          var left = ret.children[index - 1];

          // don't let powered values be raised to powers...
          // ...student should use parens for wrapping if power-to-a-power is desired
          if (left.t === 'power') {
            return;
          } else if (left.t !== 'text') {
            insert.base.children[0] = left;
            left.parent = insert.base;
            ret.children.splice(index - 1, 1);
            index--;
          }
        }
      } else if (t === 'subscript') {
        insert = getSubscript(ret.line);

        if (preString.length > 0) {
          // Find characters left of the new subscript
          var lastChar = preString.length - 1;

          while (onlyLetters(preString[lastChar]) && lastChar >= 0) {
            lastChar--;
          }

          // Last character is always one greater
          lastChar++;

          // Try to find a base for the subscript
          var subscriptBase =
            preString.length - lastChar > 0
              ? preString.substring(lastChar, preString.length)
              : '';
          var previous = preString.substring(0, lastChar);

          // Split text and resize
          srcText.text = previous;
          srcText.sizeMe();
          insert.base.children[0].text = subscriptBase;
          insert.base.children[0].sizeMe();
        } else if (index > 0) {
          var left = ret.children[index - 1];

          if (left.t !== 'text') {
            insert.base.children[0] = left;
            left.parent = insert.base;
            ret.children.splice(index - 1, 1);
            index--;
          }
        }
      } else if (t === 'function') {
        insert = getFunction(ret.line);

        if (preString.length > 0) {
          // Find characters left of the new function
          var lastChar = preString.length - 1;

          while (onlyLetters(preString[lastChar]) && lastChar >= 0) {
            lastChar--;
          }

          // Last character is always one greater
          lastChar++;

          // Try to find a type for the function
          var type =
            preString.length - lastChar > 0
              ? preString.substring(lastChar, preString.length)
              : '';
          var previous = preString.substring(0, lastChar);

          // Split text and resize
          srcText.text = previous;
          srcText.sizeMe();
          insert.type.children[0].text = type;
          insert.type.children[0].sizeMe();
        } else if (index > 0) {
          var left = ret.children[index - 1];

          if (left.t !== 'text') {
            insert.type.children[0] = left;
            left.parent = insert.type;
            ret.children.splice(index - 1, 1);
            index--;
          }
        }
      } else if (t === 'root') {
        insert = getRoot(ret.line);
      } else {
        console.error(t + ' not supported');
      }

      if (intoBase) {
        if (preString.length !== 0)
          ret.parent.parent.pushTextOutOfBaseToLeft(ret.parent, preString);

        // ret is the base
        if (postString.length === 0) {
          ret.children = [];
          ret.add(insert);
        } else {
          var pow = ret.parent;
          var powHolder = pow.parent;

          index = -1;

          for (var i = 0; i < powHolder.children.length; i++) {
            if (pow === powHolder.children[i]) {
              index = i;
              break;
            }
          }

          powHolder.add(insert, index);
          powHolder.balanceEmptyTexts();

          srcText.setText(postString);
        }

        ret.line.buildSizeRecursive();
      } else {
        ret.add(insert, index + 1);

        if (postString.length > 0) {
          var secondText = getText(postString);

          secondText.e = true;
          ret.add(secondText, index + 2);
        }
      }

      insert.buildSizeRecursive();

      if (!intoBase) ret.balanceEmptyTexts();

      maybeUndoSelectedLineEntryButton();

      ret.line.notifySizeChanged();

      return insert;
    };

    /**
     * @memberOf getMulti
     */
    ret.buildSizeRecursive = function () {
      for (var i = 0; i < ret.children.length; i++) {
        var child = ret.children[i];

        // texts automatically maintain size
        if (child.t !== 'text') {
          child.buildSizeRecursive();
        }
      }

      ret.buildSize();
    };

    /**
     * @memberOf getMulti
     */
    ret.buildSize = function () {
      var w = 0;
      var h = 0;
      var upperHeight = 0;
      var lowerHeight = 0;

      for (var i = 0; i < ret.children.length; i++) {
        w += ret.children[i].viewportW;

        h = ret.children[i].viewportH;
        var childUpperHeight = h / 2;
        var childLowerHeight = h / 2;

        // Check for non vertically centered
        var vertBaseLine = this.children[i].getVertToBaseline?.();

        if (vertBaseLine) {
          var diff = vertBaseLine - childUpperHeight;

          childUpperHeight += diff;
          childLowerHeight -= diff;
        }

        upperHeight = Math.max(upperHeight, childUpperHeight);
        lowerHeight = Math.max(lowerHeight, childLowerHeight);
      }

      ret.setAllDim(w, upperHeight + lowerHeight);
      ret.setPositionsOfChildren();
    };

    ret.getFirst = function (s) {
      for (var a = 0; a < ret.children.length; a++) {
        if (ret.children[a].t === s) {
          return ret.children[a];
        }
      }
    };

    ret.getVertToBaseline = function () {
      var toB = 0;

      for (var i = 0; i < ret.children.length; i++) {
        var c = ret.children[i];
        var cB = c.getVertToBaseline();

        toB = Math.max(toB, cB);
      }

      return toB;
    };

    /**
     * @memberOf getMulti
     */
    ret.setPositionsOfChildren = function () {
      var toB = ret.getVertToBaseline();
      var x = 0;

      for (var i = 0; i < ret.children.length; i++) {
        var c = ret.children[i];

        c.viewportY = toB - c.getVertToBaseline();
        c.viewportX = x;
        x += c.viewportW;
      }
    };

    /**
     * @memberOf getMulti
     */
    ret.childSizeChanged = function () {
      ret.buildSize();

      ret.parent?.childSizeChanged();
    };

    /**
     * @memberOf getMulti
     */
    ret.add = function (tile, index) {
      if (index === undefined) index = ret.children.length; // index = index || ret.children.length;
      ret.children.splice(index, 0, tile);
      tile.parent = ret;
      tile.setLine(ret.line);
    };

    /**
     * @memberOf getMulti
     */
    ret.grabFocus = function (direction) {
      // var index = direction === "left" ? ret.children.length - 1 : 0;
      var index = direction === 'right' ? 0 : ret.children.length - 1;

      ret.children[index].grabFocus(direction);
    };

    var text = getText(0, 0, string ? string : '');

    text.e = true;
    text.parent = ret;

    ret.add(text);

    return ret;
  };

  var maybeUndoSelectedLineEntryButton = function () {
    if (!currentAttemptGetter.selectedButton) return false;

    for (var a = 0; a < buttonKeys.length; a++) {
      if (buttonKeys[a].text === currentAttemptGetter.selectedButton) {
        buttonKeys[a].sel = false;
        currentAttemptGetter.selectedButton = undefined;
        submitAttempt({
          blank: true,
          eventSource: 'undoSelectedLineEntryButton',
        });
      }
    }

    return true;
  };

  var getQuantity = function (line, contents, abVal, lChar, rChar) {
    var ret = getBasicObject();

    // Ratio for how closely to squash nested grouping symbols (0-1, smaller is closer)
    ret.nestedWidthRatio = 0.5;

    // Ratio for how closely to squash nested objects (0-1, smaller is closer)
    ret.nestedContentRatio = 0.75;

    ret.t = 'quantity';
    ret.groupRatio = 1.25;

    // Set grouping characters if not provided
    if (!lChar)
      lChar = getText(0, 0, abVal ? '|' : '(', lineFontSize * ret.groupRatio);

    if (!rChar)
      rChar = getText(0, 0, abVal ? '|' : ')', lineFontSize + ret.groupRatio);

    // Always mid align the grouping characters
    lChar.forceMid = true;
    rChar.forceMid = true;

    ret.t = 'quantity';

    ret.leftParen = lChar;
    ret.rightParen = rChar;
    ret.multi = contents ? contents : getMulti(line);

    ret.getPaintedCharCount = function () {
      // '+2' to consider enclosing graphic at each side of the quantity as one char
      return ret.multi.getPaintedCharCount() + 2;
    };

    ret.toXML = function () {
      return '<q>' + ret.multi.toXML() + '</q>';
    };

    ret.swapString = function (old, replacer) {
      ret.multi.swapString(old, replacer);
    };

    ret.setFont = function (f) {
      ret.multi.setFont(f);
    };

    ret.setEditable = function (b) {
      ret.multi.setEditable(b);
    };

    ret.buildSizeRecursive = function () {
      ret.multi.buildSizeRecursive();
      ret.buildSize();
    };

    ret.hasLeftBoundQuantity = function () {
      var child = ret.children[1];
      var first = child.children[0];

      return (
        isText(first) && first.text === '' && isQuantity(child.children[1])
      );
    };

    ret.hasRightBoundQuantity = function () {
      var child = ret.children[ret.children.length - 2];
      var last = child.children[child.children.length - 1];

      return (
        isText(last) &&
        last.text === '' &&
        isQuantity(child.children[child.children.length - 2])
      );
    };

    ret.setLine = function (line) {
      ret.line = line;
      ret.multi.setLine(line);
    };

    ret.grabFocus = function (direction) {
      ret.multi.grabFocus(direction);
    };

    ret.buildSize = function () {
      ret.leftParen.setFontSize(ret.multi.viewportH * ret.groupRatio);
      ret.rightParen.setFontSize(ret.multi.viewportH * ret.groupRatio);

      var w =
        ret.leftParen.viewportW * ret.nestedContentRatio +
        ret.multi.viewportW +
        ret.rightParen.viewportW * ret.nestedContentRatio;
      var h = Math.max(
        Math.max(ret.multi.viewportH, ret.leftParen.viewportH),
        ret.rightParen.viewportH
      );

      // Check for nested quantities
      if (hasQuantity(ret)) {
        if (ret.hasLeftBoundQuantity(ret))
          w -=
            ret.leftParen.viewportW -
            ret.leftParen.viewportW * ret.nestedWidthRatio;
        if (ret.hasRightBoundQuantity(ret))
          w -=
            ret.rightParen.viewportW -
            ret.rightParen.viewportW * ret.nestedWidthRatio;
      }

      ret.setAllDim(w, h);
      ret.setPositionsOfChildren();
    };

    ret.getVertToBaseline = function () {
      return Math.max(
        Math.max(
          ret.leftParen.getVertToBaseline(),
          ret.rightParen.getVertToBaseline()
        ),
        ret.multi.getVertToBaseline()
      );
    };

    ret.setPositionsOfChildren = function () {
      var nested = hasQuantity(ret);
      var leftBoundQuantity = nested && ret.hasLeftBoundQuantity();
      var rightBoundQuantity = nested && ret.hasRightBoundQuantity();

      ret.multi.viewportX = leftBoundQuantity
        ? ret.leftParen.viewportW *
          ret.nestedContentRatio *
          ret.nestedWidthRatio
        : ret.leftParen.viewportW * ret.nestedContentRatio;
      ret.multi.viewportY =
        ret.getVertToBaseline() - ret.multi.getVertToBaseline();

      ret.rightParen.viewportX =
        ret.multi.viewportX +
        ret.multi.viewportW -
        (rightBoundQuantity
          ? ret.rightParen.viewportW -
            ret.rightParen.viewportW *
              ret.nestedContentRatio *
              ret.nestedWidthRatio
          : ret.rightParen.viewportW -
            ret.rightParen.viewportW * ret.nestedContentRatio);
    };

    ret.childSizeChanged = function () {
      ret.buildSize();
      ret.parent?.childSizeChanged();
    };

    ret.add = function (addMe) {
      ret.multi.add(addMe);
    };

    ret.children.push(ret.leftParen);
    ret.children.push(ret.multi);
    ret.multi.parent = ret;
    ret.children.push(ret.rightParen);

    return ret;
  };

  var getAbsoluteValue = function (line, contents) {
    var ret = getBasicObject();

    ret.t = 'absoluteValue';

    ret.xtraForParen = 10;
    ret.leftParen = getText(0, 0, '|', lineFontSize + ret.xtraForParen);
    ret.rightParen = getText(0, 0, '|', lineFontSize + ret.xtraForParen);
    ret.multi = contents ? contents : getMulti(line);

    ret.toXML = function () {
      return '<a>' + ret.multi.toXML() + '</a>';
    };

    ret.swapString = function (old, replacer) {
      ret.multi.swapString(old, replacer);
    };

    ret.setFont = function (f) {
      ret.multi.setFont(f);
    };

    ret.setEditable = function (b) {
      ret.multi.setEditable(b);
    };

    ret.buildSizeRecursive = function () {
      ret.multi.buildSizeRecursive();
      ret.buildSize();
    };

    ret.setLine = function (line) {
      ret.line = line;
      ret.multi.setLine(line);
    };

    ret.grabFocus = function (direction) {
      ret.multi.grabFocus(direction);
    };

    ret.buildSize = function () {
      ret.leftParen.setFontSize(ret.multi.viewportH + ret.xtraForParen);
      ret.rightParen.setFontSize(ret.multi.viewportH + ret.xtraForParen);

      var w =
        ret.leftParen.viewportW +
        ret.multi.viewportW +
        ret.rightParen.viewportW;
      var h = ret.multi.viewportH + ret.xtraForParen;

      ret.setAllDim(w, h);
      ret.setPositionsOfChildren();
    };

    ret.getVertToBaseline = function () {
      return ret.xtraForParen / 2 + ret.multi.getVertToBaseline();
    };

    ret.setPositionsOfChildren = function () {
      ret.multi.viewportX = ret.leftParen.viewportW;
      var slop = ret.xtraForParen / 2;

      ret.multi.viewportY = slop;

      ret.rightParen.viewportX = ret.leftParen.viewportW + ret.multi.viewportW;
    };

    ret.childSizeChanged = function () {
      ret.buildSize();
      ret.parent?.childSizeChanged();
    };

    ret.add = function (addMe) {
      ret.multi.add(addMe);
    };

    ret.children.push(ret.leftParen);
    ret.children.push(ret.multi);
    ret.multi.parent = ret;
    ret.children.push(ret.rightParen);

    return ret;
  };

  var getPower = function (line, base, exp) {
    const powerScale = 0.7;
    const offsetRatio = 0.75;
    var ret = getBasicObject();

    ret.base = base || getMulti(line);
    ret.base.gmmName = 'base multi';
    ret.exp = exp || getMulti(line);
    ret.exp.gmmName = 'exp multi';
    ret.line = line;
    ret.t = 'power';
    ret.gmmName = 'power';

    ret.getPaintedCharCount = function () {
      return ret.base.getPaintedCharCount() + ret.exp.getPaintedCharCount();
    };

    ret.setEditable = function (b) {
      ret.base.setEditable(b);
      ret.exp.setEditable(b);
    };

    ret.getPowerScale = function () {
      return (
        powerScale -
        (ret.exp.children.length == 3 && ret.exp.children[1].t == 'fraction'
          ? 0.1
          : 0)
      );
    };

    ret.setFont = function (f) {
      ret.base.setFont(f);
      scaleFont(ret.exp, f, ret.getPowerScale());
    };

    ret.swapString = function (old, replacer) {
      ret.base.swapString(old, replacer);
      ret.exp.swapString(old, replacer);
    };

    ret.hasDigitBase = function () {
      if (ret.base.children.length > 1) return false;
      var t = ret.base.children[0];

      if (t.gmmName !== 'text') return false;

      return $.isNumeric(t.text);
    };

    ret.setBaseAndExp = function (base, exp) {
      ret.children = [];
      ret.children.push(base);
      ret.children.push(exp);
      ret.base = base;
      ret.exp = exp;
      base.parent = ret;
      exp.parent = ret;
      scaleFont(ret.exp, ret.exp.children[0].font, ret.getPowerScale());
    };

    ret.setLine = function (line) {
      ret.line = line;
      ret.base.setLine(line);
      ret.exp.setLine(line);
    };

    ret.toXML = function () {
      return (
        '<p><b>' + ret.base.toXML() + '</b><e>' + ret.exp.toXML() + '</e></p>'
      );
    };

    ret.grabFocus = function (d) {
      if (d === 'left') {
        ret.exp.grabFocus(d);
      } else {
        ret.base.grabFocus(d);
      }
    };

    ret.unsureWhichToFocus = function () {
      var b = ret.base.children[0];

      if ((b.t === 'text' && b.text.length > 0) || b.t != 'text') {
        ret.exp.grabFocus();
      } else {
        ret.base.grabFocus();
      }
    };

    ret.buildSizeRecursive = function () {
      ret.base.buildSizeRecursive();
      ret.exp.buildSizeRecursive();
      ret.buildSize();
    };

    ret.buildSize = function () {
      var w = ret.base.viewportW + ret.exp.viewportW;
      var h = ret.base.viewportH + offsetRatio * ret.exp.viewportH;

      ret.setAllDim(w, h);
      ret.setPositionsOfChildren();
    };

    ret.getVertToBaseline = function () {
      return ret.base.getVertToBaseline() + offsetRatio * ret.exp.viewportH;
    };

    ret.setPositionsOfChildren = function () {
      ret.base.viewportY = offsetRatio * ret.exp.viewportH;
      ret.exp.viewportX = ret.base.viewportW;
    };

    ret.childSizeChanged = function () {
      ret.buildSize();
      ret.parent?.childSizeChanged();
    };

    ret.add = function (tile, exp) {
      if (!exp) {
        ret.base.add(tile);
      } else {
        ret.exp.add(tile);
      }
    };

    ret.children.push(ret.base);
    ret.base.parent = ret;
    ret.children.push(ret.exp);
    ret.exp.parent = ret;

    scaleFont(ret.exp, ret.exp.children[0].font, ret.getPowerScale());

    return ret;
  };

  var getSubscript = function (line, base, sub) {
    const subscriptScale = 0.7;
    var ret = getBasicObject();

    ret.base = base || getMulti(line);
    ret.base.gmmName = 'base multi';
    ret.sub = sub || getMulti(line);
    ret.sub.gmmName = 'sub multi';
    ret.line = line;
    ret.t = 'subscript';
    ret.gmmName = 'subscript';

    ret.getPaintedCharCount = function () {
      return ret.base.getPaintedCharCount() + ret.sub.getPaintedCharCount();
    };

    ret.setEditable = function (b) {
      ret.base.setEditable(b);
      ret.sub.setEditable(b);
    };

    ret.setFont = function (f) {
      ret.base.setFont(f);
      scaleFont(ret.sub, f, subscriptScale);
    };

    ret.swapString = function (old, replacer) {
      ret.base.swapString(old, replacer);
      ret.sub.swapString(old, replacer);
    };

    ret.hasDigitBase = function () {
      if (ret.base.children.length > 1) return false;
      var t = ret.base.children[0];

      if (t.gmmName !== 'text') return false;

      return $.isNumeric(t.text);
    };

    ret.setBaseAndSub = function (base, sub) {
      ret.children = [];
      ret.children.push(base);
      ret.children.push(sub);
      ret.base = base;
      ret.sub = sub;
      base.parent = ret;
      sub.parent = ret;
      scaleFont(ret.sub, ret.sub.children[0].font, subscriptScale);
    };

    ret.setLine = function (line) {
      ret.line = line;
      ret.base.setLine(line);
      ret.sub.setLine(line);
    };

    ret.toXML = function () {
      return (
        '<s><b>' +
        ret.base.toXML() +
        '</b><sub>' +
        ret.sub.toXML() +
        '</sub></s>'
      );
    };

    ret.grabFocus = function (d) {
      if (d === 'left') {
        ret.sub.grabFocus(d);
      } else {
        ret.base.grabFocus(d);
      }
    };

    ret.unsureWhichToFocus = function () {
      var b = ret.base.children[0];

      if ((b.t === 'text' && b.text.length > 0) || b.t != 'text') {
        ret.sub.grabFocus();
      } else {
        ret.base.grabFocus();
      }
    };

    ret.buildSizeRecursive = function () {
      ret.base.buildSizeRecursive();
      ret.sub.buildSizeRecursive();
      ret.buildSize();
    };

    ret.buildSize = function () {
      var w = ret.base.viewportW + ret.sub.viewportW;
      var h = ret.base.viewportH + 0.5 * ret.sub.viewportH;

      ret.setAllDim(w, h);
      ret.setPositionsOfChildren();
    };

    ret.getVertToBaseline = function () {
      return ret.base.viewportY + ret.base.getVertToBaseline();
    };

    ret.setPositionsOfChildren = function () {
      ret.base.viewportY = 0.25 * ret.sub.viewportH;
      ret.sub.viewportY = 0.75 * ret.base.viewportH;
      ret.sub.viewportX = ret.base.viewportW;
    };

    ret.childSizeChanged = function () {
      ret.buildSize();
      ret.parent?.childSizeChanged();
    };

    ret.add = function (tile, sub) {
      if (!sub) {
        ret.base.add(tile);
      } else {
        ret.sub.add(tile);
      }
    };

    ret.children.push(ret.base);
    ret.base.parent = ret;
    ret.children.push(ret.sub);
    ret.sub.parent = ret;

    scaleFont(ret.sub, ret.sub.children[0].font, subscriptScale);

    return ret;
  };

  var getFunction = function (line, type, base, value) {
    const functionScale = 0.7;
    const baseOffset = 0.75;
    const baseExtra = 5;
    var ret = getBasicObject();

    ret.type = type || getMulti(line);
    ret.type.gmmName = 'base multi';
    ret.base = base || getMulti(line);
    ret.base.gmmName = 'log base multi';
    ret.value = value || getMulti(line);
    ret.value.gmmName = 'value multi';
    ret.line = line;
    ret.t = 'function';
    ret.gmmName = 'function';

    ret.getPaintedCharCount = function () {
      const a = ret.type.getPaintedCharCount();
      const b = ret.showBase ? ret.base.getPaintedCharCount() : 0;
      const c = ret.value.getPaintedCharCount();

      return a + b + c;
    };

    ret.setEditable = function (b) {
      ret.type.setEditable(b);
      ret.base.setEditable(b);
      ret.value.setEditable(b);
    };

    ret.setFont = function (f) {
      ret.type.setFont(f);
      ret.value.setFont(f);
      scaleFont(ret.base, f, functionScale);
    };

    ret.swapString = function (old, replacer) {
      ret.type.swapString(old, replacer);
      ret.base.swapString(old, replacer);
      ret.value.swapString(old, replacer);
    };

    ret.hasDigitBase = function () {
      if (ret.base.children.length > 1) return false;
      var t = ret.base.children[0];

      if (t.gmmName !== 'text') return false;

      return $.isNumeric(t.text);
    };

    ret.setShowBase = function (showBase) {
      ret.showBase = showBase;
      ret.children = [];
      ret.children.push(ret.type);
      if (showBase) ret.children.push(ret.base);
      ret.children.push(ret.value);
      ret.buildSizeRecursive();
    };

    ret.setAll = function (type, base, value) {
      ret.children = [];
      ret.children.push(type);
      ret.children.push(base);
      ret.children.push(value);
      ret.type = type;
      ret.base = base;
      ret.value = value;
      type.parent = ret;
      base.parent = ret;
      value.parent = ret;
      scaleFont(ret.base, ret.base.children[0].font, functionScale);

      var typeText = type.children[0].text;
      var baseText = base.children[0].text;

      var supportsBase = typeText === 'log';

      ret.setShowBase(
        supportsBase &&
          !((typeText === 'log' && baseText === '10') || base.isEmptyOrSpace())
      );
    };

    ret.setTypeAndBase = function (type, base, showBase) {
      ret.type.children[0].text = type;
      ret.type.children[0].sizeMe();
      ret.type.children[0].setCursorIndentChars(type.length);
      ret.setShowBase(showBase);

      if (base) {
        ret.base.children[0].text = base;
        ret.base.children[0].sizeMe();
        ret.base.children[0].setCursorIndentChars(base.length);
      }

      ret.unsureWhichToFocus();
      ret.buildSizeRecursive();
    };

    ret.setLine = function (line) {
      ret.line = line;
      ret.type.setLine(line);
      ret.base.setLine(line);
      ret.value.setLine(line);
    };

    ret.toXML = function () {
      return (
        '<tf><ft>' +
        ret.type.toXML() +
        '</ft><fb>' +
        ret.base.toXML() +
        '</fb><v>' +
        ret.value.toXML() +
        '</v></tf>'
      );
    };

    ret.grabFocus = function (d) {
      if (d === 'left') {
        ret.value.grabFocus(d);
      } else {
        ret.type.grabFocus(d);
      }
    };

    ret.unsureWhichToFocus = function () {
      var type = ret.type.children[0];
      var base = ret.base.children[0];

      if ((type.t === 'text' && type.text.length > 0) || type.t != 'text') {
        if (
          !ret.showBase ||
          (base.t === 'text' && base.text.length > 0) ||
          base.t != 'text'
        )
          ret.value.grabFocus();
        else ret.base.grabFocus();
      } else {
        ret.type.grabFocus();
      }
    };

    ret.buildSizeRecursive = function () {
      ret.type.buildSizeRecursive();
      ret.base.buildSizeRecursive();
      ret.value.buildSizeRecursive();
      ret.buildSize();
    };

    ret.setPositionsOfChildren = function () {
      ret.type.viewportX = baseExtra;
      ret.base.viewportX = ret.type.viewportX + ret.type.viewportW;
      ret.value.viewportX = ret.showBase
        ? ret.base.viewportX + ret.base.viewportW
        : ret.type.viewportW + baseExtra;
    };

    ret.buildSize = function () {
      var w =
        ret.type.viewportW +
        baseExtra +
        (ret.showBase ? ret.base.viewportW : 0) +
        ret.value.viewportW;
      var h = Math.max(
        ret.value.viewportH,
        ret.showBase
          ? ret.type.viewportH + ret.base.viewportH + baseExtra
          : ret.type.viewportH
      );

      ret.setAllDim(w, h);
      ret.setPositionsOfChildren();
    };

    ret.getVertToBaseline = function () {
      return ret.value.getVertToBaseline();
    };

    ret.setPositionsOfChildren = function () {
      ret.type.viewportX = 5;
      ret.base.viewportX = ret.type.viewportX + ret.type.viewportW;
      ret.value.viewportX = ret.showBase
        ? ret.base.viewportX + ret.base.viewportW
        : ret.type.viewportW + 5;

      ret.type.viewportY = 0;
      ret.value.viewportY = 0;

      var typeVertToBaseline = ret.type.getVertToBaseline();
      var valueVertToBaseline = ret.value.getVertToBaseline();

      // Position based off the larger baseline of the two
      var shift = Math.abs(
        ret.value.getVertToBaseline() - ret.type.getVertToBaseline()
      );

      if (typeVertToBaseline > valueVertToBaseline) ret.value.viewportY = shift;
      else if (typeVertToBaseline < valueVertToBaseline)
        ret.type.viewportY = shift;

      ret.base.viewportY = ret.type.viewportY + ret.type.viewportH * baseOffset;
    };

    ret.childSizeChanged = function () {
      ret.buildSize();
      ret.parent?.childSizeChanged();
    };

    ret.add = function (tile, value) {
      if (!value) {
        ret.base.add(tile);
      } else {
        ret.value.add(tile);
      }
    };

    ret.type.parent = ret;
    ret.base.parent = ret;
    ret.value.parent = ret;
    ret.setShowBase(true);

    scaleFont(ret.base, ret.base.children[0].font, functionScale);

    return ret;
  };

  var getRoot = function (line, radicand, root) {
    const nthRootScale = 0.7;
    const padding = 2;
    var ret = getBasicObject();

    ret.rad = radicand || getMulti(line);
    ret.rad.parent = ret;

    ret.root = root || getMulti(line);
    ret.root.parent = ret;
    ret.root.isOptional = true;

    ret.line = line;
    ret.t = 'root';

    ret.getPaintedCharCount = function () {
      // not currently counting root as a char
      return ret.rad.getPaintedCharCount();
    };

    ret.showRoot = function () {
      var rootText = ret.root.children[0];
      var rootValue = rootText.text;

      return rootValue !== '2';
    };

    ret.setEditable = function (b) {
      ret.rad.setEditable(b);
      ret.root.setEditable(b);
    };

    ret.setFont = function (f) {
      ret.rad.setFont(f);
      scaleFont(ret.root, f, nthRootScale);
    };

    ret.setRadicandAndRoot = function (radicand, root) {
      ret.children = [];
      ret.children.push(radicand);
      if (ret.showRoot()) ret.children.push(root);
      ret.rad = radicand;
      ret.root = root;
      radicand.parent = ret;
      root.parent = ret;
    };

    ret.setLine = function (line) {
      ret.line = line;
      ret.rad.setLine(line);
      ret.root.setLine(line);
    };

    ret.toXML = function () {
      return (
        '<r><rad>' +
        ret.rad.toXML() +
        '</rad><root>' +
        ret.root.toXML() +
        '</root></r>'
      );
    };

    ret.swapString = function (old, replacer) {
      ret.rad.swapString(old, replacer);
      ret.root.swapString(old, replacer);
    };

    ret.grabFocus = function (direction) {
      ret.rad.grabFocus(direction);
    };

    ret.buildSizeRecursive = function () {
      ret.rad.buildSizeRecursive();
      ret.root.buildSizeRecursive();
      ret.buildSize();
    };

    ret.slantWidth = function () {
      return ret.rad.viewportH * Math.tan(Math.PI / 6);
    };

    ret.buildSize = function () {
      var h = ret.rad.viewportH + ret.extraHeight();
      var w = ret.rad.viewportW + ret.root.viewportW + ret.slantWidth();

      ret.setAllDim(w, h);
      ret.setPositionsOfChildren();
    };

    ret.getVertToBaseline = function () {
      return ret.extraHeight() + ret.rad.getVertToBaseline();
    };

    ret.extraHeight = function () {
      var heightDiff = ret.rad.viewportH / 2 - ret.root.viewportH;

      return ret.showRoot && heightDiff < 0
        ? Math.abs(heightDiff)
        : ret.getLineWidth() + padding;
    };

    ret.setPositionsOfChildren = function () {
      ret.rad.viewportX = ret.root.viewportW + ret.slantWidth();
      ret.rad.viewportY = ret.extraHeight();

      ret.root.viewportX = padding;
      ret.root.viewportY =
        ret.viewportH / 2 - ret.root.viewportH + ret.extraHeight();
    };

    ret.childSizeChanged = function () {
      ret.buildSize();
      ret.parent?.childSizeChanged();
    };

    ret.paintMe = function (ctx) {
      ctx.save();
      ctx.strokeStyle = ret.fillStyle || 'black';
      ctx.lineWidth = ret.getLineWidth();
      ctx.beginPath();

      var h = ret.viewportH;
      var w = ret.root.viewportW;

      // over two from original design to give clarity to preceding negative symbol
      ctx.moveTo(padding, h / 2 + ret.extraHeight());
      ctx.lineTo(w, h / 2 + ret.extraHeight());
      ctx.lineTo(w + ret.slantWidth() / 4 - 1, h - ret.getLineWidth() / 2);
      ctx.lineTo(w + ret.slantWidth() - 1, ret.getLineWidth() / 2);
      ctx.lineTo(ret.viewportW + padding, ret.getLineWidth() / 2);

      ctx.stroke();
      ctx.restore();
    };

    ret.getLineWidth = function () {
      return ret.fillStyle ? 2 : 1;
    };

    ret.add = function (tile, rad) {
      if (rad) ret.rad.add(tile);
      else ret.root.add(tile);
    };

    ret.children.push(ret.rad);
    ret.rad.parent = ret;

    if (ret.showRoot()) ret.children.push(ret.root);
    ret.root.parent = ret;

    scaleFont(ret.root, ret.root.children[0].font, nthRootScale);

    return ret;
  };

  var getBarred = function (line, stuff) {
    var ret = getBasicObject();

    ret.multi = stuff || getMulti(line);
    ret.line = line;
    ret.t = 'barred';
    ret.topH = 7;
    ret.topThick = 2;
    ret.extraWForBar = 2;

    ret.getPaintedCharCount = function () {
      return ret.multi.getPaintedCharCount();
    };

    ret.setEditable = function (b) {
      ret.multi.setEditable(b);
    };

    ret.setFont = function (f) {
      ret.multi.setFont(f);
    };

    ret.setStuff = function (stuff) {
      ret.children = [];
      ret.children.push(stuff);
      ret.multi = stuff;
      stuff.parent = ret;
    };

    ret.setLineType = function (lineType) {
      ret.lineType = lineType;

      // Add height for the arrows
      if (lineType.toLowerCase() != 'line') {
        ret.topH += 5;
      }
    };

    ret.setLine = function (line) {
      ret.line = line;
      ret.multi.setLine(line);
    };

    ret.toXML = function () {
      return '<b>' + ret.multi.toXML() + '</b>';
    };

    ret.swapString = function (old, replacer) {
      ret.multi.swapString(old, replacer);
    };

    ret.grabFocus = function (direction) {
      ret.multi.grabFocus(direction);
    };

    ret.buildSizeRecursive = function () {
      ret.multi.buildSizeRecursive();
      ret.buildSize();
    };

    ret.buildSize = function () {
      var w = ret.multi.viewportW;

      w += ret.extraWForBar * 2;
      var h = ret.multi.viewportH;

      h += ret.topH;
      ret.setAllDim(w, h);
      ret.setPositionsOfChildren();
    };

    ret.getVertToBaseline = function () {
      return ret.multi.getVertToBaseline() + ret.topH;
    };

    ret.setPositionsOfChildren = function () {
      ret.multi.viewportX = ret.extraWForBar;
      ret.multi.viewportY = ret.topH;
    };

    ret.childSizeChanged = function () {
      ret.buildSize();
      ret.parent.childSizeChanged();
    };

    ret.paintMe = function (ctx) {
      ctx.save();
      ctx.strokeStyle = ret.fillStyle || 'black';
      ctx.fillStyle = ret.fillStyle || 'black';
      var rightArrow = false;
      var leftArrow = false;

      if (ret.lineType != null) {
        rightArrow =
          ret.lineType.toLowerCase() === 'arrow_right' ||
          ret.lineType.toLowerCase() === 'arrow_both';
        leftArrow =
          ret.lineType.toLowerCase() === 'arrow_left' ||
          ret.lineType.toLowerCase() === 'arrow_both';
      }

      var xLineStart = 0;
      var xLineEnd = ret.viewportW;
      var yValue = ret.topH / 2;
      var arrow_size = 4;

      if (ret.lineType == 'ARC') {
        ctx.beginPath();
        ctx.lineWidth = ret.topThick;
        ctx.ellipse(
          (xLineEnd + xLineStart) / 2,
          yValue + arrow_size,
          (xLineEnd - xLineStart) / 2,
          arrow_size,
          0,
          0,
          Math.PI,
          true
        );
        ctx.stroke();
        ctx.restore();
      } else if (ret.lineType == 'HAT') {
        var vertHatShift = 4;

        ctx.beginPath();
        ctx.lineWidth = ret.topThick;
        // using vertHatShift to lower "hat"... else "hat" is too high above variable
        ctx.moveTo(xLineStart, yValue + vertHatShift);
        ctx.lineTo((xLineEnd - xLineStart) / 2, yValue);
        ctx.lineTo(xLineEnd, yValue + vertHatShift);
        ctx.stroke();
        ctx.restore();
      } else {
        var arrow_size = 4;

        if (rightArrow) {
          xLineEnd = xLineEnd - arrow_size;
          // Draw Arrow
          filledArrow(ctx, 0, yValue, xLineEnd, yValue, arrow_size);
        }

        if (leftArrow) {
          xLineStart = arrow_size;
          // Draw Arrow
          filledArrow(ctx, xLineEnd, yValue, arrow_size, yValue, arrow_size);
        }

        // Draw the Line
        ctx.beginPath();
        ctx.lineWidth = ret.topThick;
        ctx.moveTo(xLineStart, yValue);
        ctx.lineTo(xLineEnd, yValue);
        ctx.stroke();
        ctx.restore();
      }
    };

    ret.add = function (tile) {
      ret.multi.add(tile);
    };

    ret.children.push(ret.multi);
    ret.multi.parent = ret;

    return ret;
  };

  var getFraction = function (line, num, denom) {
    var ret = getBasicObject();

    ret.numerator = num || getMulti(line);
    ret.denominator = denom || getMulti(line);
    ret.line = line;
    ret.t = 'fraction';

    ret.middleH = 7;
    ret.middleThick = 2;
    ret.extraWForFractionBar = 4;
    ret.blankPixelPadding = 1;

    ret.getPaintedCharCount = function () {
      return (
        ret.numerator.getPaintedCharCount() +
        ret.denominator.getPaintedCharCount()
      );
    };

    ret.setEditable = function (b) {
      ret.numerator.setEditable(b);
      ret.denominator.setEditable(b);
    };

    ret.setFont = function (f) {
      ret.numerator.setFont(f);
      ret.denominator.setFont(f);
    };

    ret.setNumeratorAndDenominator = function (num, denom) {
      ret.children = [];
      ret.children.push(num);
      ret.children.push(denom);
      ret.numerator = num;
      ret.denominator = denom;
      num.parent = ret;
      denom.parent = ret;
    };

    ret.mouseDownResponse = function (x, y) {
      // Check the attempt getter to see if it is editable
      var parentIsLocked = false;

      if (
        ret.line &&
        ret.line.locationParent &&
        ret.line.locationParent.e != undefined
      ) {
        parentIsLocked = !ret.line.locationParent.e;
      }

      if (!ret.line || !ret.line.e || parentIsLocked) return false;
      var dir = x < ret.viewportW / 2 ? 'right' : 'left';

      if (y < ret.numerator.viewportH + ret.middleH / 2) {
        ret.numerator.grabFocus(dir);
      } else ret.denominator.grabFocus(dir);

      return true;
    };

    ret.setLine = function (line) {
      ret.line = line;
      ret.numerator.setLine(line);
      ret.denominator.setLine(line);
    };

    ret.toXML = function () {
      return (
        '<f><num>' +
        ret.numerator.toXML() +
        '</num><denom>' +
        ret.denominator.toXML() +
        '</denom></f>'
      );
    };

    ret.swapString = function (old, replacer) {
      ret.numerator.swapString(old, replacer);
      ret.denominator.swapString(old, replacer);
    };

    ret.grabFocus = function (direction) {
      ret.numerator.grabFocus(direction);
    };

    ret.buildSizeRecursive = function () {
      ret.numerator.buildSizeRecursive();
      ret.denominator.buildSizeRecursive();
      ret.buildSize();
    };

    ret.buildSize = function () {
      var w = Math.max(ret.numerator.viewportW, ret.denominator.viewportW);

      w += ret.extraWForFractionBar * 2 + 2 * ret.blankPixelPadding;
      var h = ret.numerator.viewportH + ret.denominator.viewportH;

      h += ret.middleH;
      ret.setAllDim(w, h);
      ret.setPositionsOfChildren();
    };

    ret.getVertToBaseline = function () {
      return ret.numerator.viewportH + ret.middleH / 2;
    };

    ret.setPositionsOfChildren = function () {
      var slop =
        ret.viewportW - ret.extraWForFractionBar * 2 - ret.numerator.viewportW;

      ret.numerator.viewportX = ret.extraWForFractionBar + slop / 2;
      ret.numerator.viewportY = 0;

      slop =
        ret.viewportW -
        ret.extraWForFractionBar * 2 -
        ret.denominator.viewportW;
      ret.denominator.viewportX = ret.extraWForFractionBar + slop / 2;
      ret.denominator.viewportY = ret.numerator.viewportH + ret.middleH;
    };

    ret.childSizeChanged = function () {
      ret.buildSize();
      ret.parent.childSizeChanged();
    };

    ret.paintMe = function (ctx) {
      ctx.save();
      ctx.strokeStyle = ret.fillStyle || 'black';
      ctx.beginPath();
      ctx.lineWidth = ret.middleThick;
      ctx.moveTo(ret.blankPixelPadding, ret.getVertToBaseline());
      ctx.lineTo(
        ret.viewportW - ret.blankPixelPadding,
        ret.getVertToBaseline()
      );
      ctx.stroke();
      ctx.restore();
    };

    ret.add = function (tile, denom) {
      if (!denom) {
        ret.numerator.add(tile);
      } else {
        ret.denominator.add(tile);
      }
    };

    ret.children.push(ret.numerator);
    ret.numerator.parent = ret;
    ret.children.push(ret.denominator);
    ret.denominator.parent = ret;

    return ret;
  };

  var getText = function (x, y, t, fontSize, multiline, maxW) {
    // MAX_CHARS_IN_LINE is used for the max number of characters allowed for student input
    // Note: this value was previously set at 34 characters due to typing on iPad...
    // ...we've semi-arbitrarily increased it to 60 to handle longer labels
    // Last note: this value now matches the value used in ProblemQuestionBetter.java
    const MAX_CHARS_IN_LINE = 60;
    var ret = getBasicObject();

    ret.minWidth = defaultSoloTextWidth;
    ret.multiline = multiline;
    ret.maxW = maxW;
    if (!maxW || maxW > screenMaxTextW) ret.maxW = screenMaxTextW;
    ret.t = 'text';
    ret.gmmName = 'text';
    ret.text = t ? t : typeof x === 'string' ? x : '';

    if (!fontSize) {
      fontSize = lineFontSize;
    }

    ret.fontSize = fontSize;
    ret.font = fontSize + 'px ' + lineFont;

    ret.setHighlightReadAloud = function (start) {
      ret.firstHighlightedChar = start;
    };

    ret.getPaintedCharCount = function () {
      return ret.text.length;
    };

    ret.clearHighlightReadAloud = function () {
      ret.firstHighlightedChar = undefined;
    };

    ret.setEditable = function (b) {
      ret.e = b;
    };

    ret.getLineX = function () {
      return ret.parent.getLineX() + ret.viewportX + ret.getPixelsToCursor();
    };

    ret.hasMiddleSymbol = function () {
      for (var a = 0; a < middleSymbols.length; a++) {
        if (ret.text.indexOf(middleSymbols[a]) > -1) {
          return true;
        }
      }

      return false;
    };

    ret.setText = function (t) {
      ret.text = t;
      ret.setCursorIndentChars(t.length);
      ret.sizeMe();
    };

    ret.nextLine = function () {
      if (ret.line && ret.line.normalGetter) {
        ret.line.normalGetter.nextLine();
      }
    };

    ret.pixelsToMiddle = function () {
      for (var a = 0; a < middleSymbols.length; a++) {
        if (ret.text.indexOf(middleSymbols[a]) > -1) {
          var symbol = middleSymbols[a];
          var i = ret.text.indexOf(symbol);
          var pre = ret.text.substring(0, i);
          var ctx = $canvas[0].getContext('2d');
          var w = ctx.measureText(pre).width;

          w += ctx.measureText(middleSymbols[a]).width / 2;

          return w;
        }
      }
    };

    /**
     *  @memberOf getText
     */
    ret.setFontSize = function (fontSize) {
      ret.setFont(fontSize + 'px ' + lineFont);
    };

    /**
     *  @memberOf getText
     */
    ret.setFont = function (font) {
      const { fontSize } = parseFont(font);

      ret.fontSize = parseInt(fontSize, 10);
      ret.font = font;
      ret.sizeMe();
    };

    ret.setToBold = function () {
      ret.font = 'bold ' + ret.fontSize + 'px ' + lineFont;
      ret.sizeMe();
    };

    /**
     *  @memberOf getText
     */
    ret.toXML = function () {
      if (ret.text.length === 0) {
        return '';
      } else {
        return '<t>' + ret.text + '</t>';
      }
    };

    ret.swapString = function (old, replacer) {
      var i = ret.text.indexOf(old);

      if (i > -1) {
        var swap =
          ret.text.substring(0, i) +
          replacer +
          ret.text.substring(i + old.length);

        ret.setText(swap);
      }
    };

    ret.setLine = function (line) {
      ret.line = line;
    };

    /**
     *  @memberOf getText
     */
    ret.grabFocus = function (direction) {
      ret.setFocused(true);

      if (direction === 'left') {
        ret.setCursorIndentChars(ret.text.length);
      } else {
        ret.setCursorIndentChars(0, true);
      }
    };

    /**
     *  @memberOf getText
     */
    ret.setFocused = function (b) {
      if (!b) {
        if (focused === ret && ret.line) {
          focused = null;
          ret.line.lostFocus();
        }
      } else {
        var lw = ret.line && ret.line.isFocused();

        if (focused && focused !== ret) {
          focused.setFocused(false);
        }

        focused = ret;

        if (!lw) {
          ret.line.gainedFocus();
        }
      }
    };

    ret.rebuildFromNativeInput = function (txt) {
      ret.setText('');

      for (let x = 0; x < txt.length; x++) {
        keyDown(
          { key: txt[x], preventDefault: () => {}, stopPropagation: () => {} },
          true
        );
      }

      paintCanvas();
    };

    /**
     *  @memberOf getText
     */
    ret.isFocused = function () {
      return ret === focused;
    };

    ret.xtraForLowHang = 5;
    ret.lineSpacer = 4;

    ret.getVertToBaseline = function () {
      return ret.fontSize / 2;
    };

    /**
     *  @memberOf getText
     */
    ret.sizeMe = function (dontTellParent) {
      ret.lines = [];
      var ctx = $canvas[0].getContext('2d');

      ctx.font = ret.font;

      if (ret.multiline) {
        ret.lines = getMultipleLines(ret.text, ret.maxW, ctx);
      } else {
        var singW = ctx.measureText(ret.text).width;

        if (singW <= ret.maxW) {
          ret.lines.push([ret.text, singW]);
          ret.xtraForLowHang = 0;
        } else {
          ret.lines = getMultipleLines(ret.text, ret.maxW, ctx);
        }
      }

      var h =
        ret.lines.length * (ret.fontSize + ret.xtraForLowHang) +
        (ret.lines.length - 1) * ret.lineSpacer;
      var w = 0;

      for (var i = 0; i < ret.lines.length; i++) {
        var lineW = ret.lines[i][1];

        if (lineW > w) {
          w = lineW;
        }
      }

      if (w < ret.minWidth) {
        w = ret.minWidth;
      }

      ret.viewportFill = undefined;

      // don't use parameters of construction later in life
      x = ret.viewportX || x;
      y = ret.viewportY || y;

      ret.viewportX = x && typeof x === 'number' ? x : 0;
      ret.viewportY = y && typeof y === 'number' ? y : 0;

      ret.viewportW = w;
      ret.viewportH = h;
      ret.width = w;
      ret.height = h;

      if (ret.parent && ret.parent.childSizeChanged && !dontTellParent) {
        ret.parent.childSizeChanged();
      }
    };

    ret.paintHighlightForReadAloud = function (ctx) {
      if (!isReadAloud()) return;

      let firstHighlightedChar = ret.firstHighlightedChar;

      if (firstHighlightedChar === undefined) return;

      const lineHeight = ret.fontSize + ret.xtraForLowHang + ret.lineSpacer;

      for (let i = 0; i < ret.lines.length; i++) {
        const currentLine = ret.lines[i][0];

        if (firstHighlightedChar < currentLine.length) {
          const top = i * lineHeight;
          const startHighlight = ctx.measureText(
            currentLine.substring(0, firstHighlightedChar)
          ).width;

          const remainderOfLine = currentLine.substring(firstHighlightedChar);
          let indexOfEnd = remainderOfLine.indexOf(' ');

          if (indexOfEnd < 0) indexOfEnd = remainderOfLine.length;

          const word = remainderOfLine.substring(0, indexOfEnd);
          const highlightWidth = ctx.measureText(word).width;

          const store = ctx.fillStyle;

          ctx.fillStyle = READ_ALOUD_HIGHLIGHT_COLOR;
          ctx.fillRect(
            startHighlight,
            top,
            highlightWidth,
            ret.fontSize + ret.xtraForLowHang
          );

          ctx.fillStyle = store;

          break;
        }

        // Logic seems to NOT need this '+1', but reality and testing
        // show that it does. Haven't figured out why.
        firstHighlightedChar -= currentLine.length + 1;
      }
    };

    /**
     *  @memberOf getText
     */
    ret.paintMe = function (ctx) {
      ctx.save();

      var col;

      if (!col && ret.text.trim().length === 0) {
        if (ret.isFocused()) col = getThemeColor();
        else if (
          !ret.parent ||
          ret.parent.parent.gmmName !== 'line' ||
          ret.parent.parent.lineNumber !== undefined
        ) {
          if (ret.e && ret.viewportW > 4) {
            if (
              !(
                !isTesting() &&
                ret.line &&
                ret.line.normalGetter &&
                ret.line.normalGetter.st === 'c'
              )
            ) {
              if (ret.parent.isOptional) col = optionalEmptyTextFill;
              else col = emptyTextFill;
            }
          }
        }
      }

      if (col) {
        ctx.fillStyle = col;

        ctx.fillRect(0, 0, ret.viewportW, ret.viewportH);

        ctx.restore();
        ctx.save();
      }

      ctx.beginPath();
      ctx.textBaseline = 'middle';

      ctx.fillStyle =
        ret.fillStyle || (this.line && this.line.fillStyle) || 'black';
      ctx.font = ret.font;

      ret.paintHighlightForReadAloud(ctx);

      for (var i = 0; i < ret.lines.length; i++) {
        var h = i * (ret.fontSize + ret.xtraForLowHang + ret.lineSpacer);
        var currentLine = ret.lines[i][0];
        // \u00F7 is the division operator (obelus)
        var divI = currentLine.indexOf('\u00F7');

        if (divI < 0) {
          ctx.fillText(currentLine, 0, h + ret.fontSize / 2);
        }
        // let's paint the division operator (obelus) darker to help students who are having trouble
        // distinguishing between obelus and plus on grainy Chromebooks
        else {
          // second index: boolean for bold
          var pieces = [];

          while (divI > -1) {
            if (divI == 0) {
              var next = ['\u00F7', true];

              pieces.push(next);
            } else {
              var next = [currentLine.substring(0, divI), false];

              pieces.push(next);
              var nextD = ['\u00F7', true];

              pieces.push(nextD);
            }

            currentLine = currentLine.substring(divI + 1);
            divI = currentLine.indexOf('\u00F7');
          }

          if (currentLine.length > 0) {
            var last = [currentLine, false];

            pieces.push(last);
          }

          var xPos = 0;
          var restoreFont = ctx.font;

          for (var loopy = 0; loopy < pieces.length; loopy++) {
            var str = pieces[loopy][0];
            var bold = pieces[loopy][1];

            ctx.font = !bold
              ? ret.font
              : 'bold ' + ret.fontSize + 'px ' + lineFont;
            ctx.fillText(str, xPos, h + ret.fontSize / 2);
            xPos += ctx.measureText(str).width;
          }

          ctx.font = restoreFont;
        }
      }

      //* *Assumes single line**
      if (ret.isFocused()) {
        if (
          !(
            typeof ret.cursorIndentChars === 'number' &&
            ret.cursorIndentChars >= 0
          )
        ) {
          ret.setCursorIndentChars(ret.text.length);
        }

        ctx.beginPath();
        ctx.strokeStyle = 'green';
        ctx.lineWidth = 2;
        var i = ret.cursorIndentChars;
        var x = ctx.measureText(ret.text.substring(0, i)).width;

        x += i === 0 ? 1 : i < ret.text.length ? -1 : 1;
        ctx.moveTo(x, -1);
        ctx.lineTo(x, ret.fontSize + 1);
        ctx.stroke();
      }

      ctx.restore();
    };

    /**
     *  @memberOf getText
     */
    ret.setCursorIndentChars = function (i, right) {
      ret.cursorIndentChars = i;

      if (i === 0 && ret.parent) {
        var pp = ret.parent.parent;

        if (pp && pp.gmmName === 'power' && pp.base === ret.parent) {
          if (!right && pp.parent && ret.text.length > 0) {
            pp.parent.moveCursor(pp, 'left');
          } else if (right && ret.text.length > 0) {
            ret.cursorIndentChars = 1;
          }
        }
      }
    };

    ret.getPixelsToCursor = function () {
      var ctx = $canvas[0].getContext('2d');

      ctx.font = ret.font;

      if (ret.text.length === 0 || ret.cursorIndentChars === 0) {
        return 0;
      } else {
        var t = ret.text.substring(0, ret.cursorIndentChars);

        return ctx.measureText(t).width;
      }
    };

    /**
     *  @memberOf getText
     */
    ret.mouseDownResponse = function (x, y) {
      var notok =
        !ret.e ||
        (ret.line &&
          (!ret.line.e || (ret.line.normalHolder && !ret.line.normalHolder.e)));

      if (notok) {
        return false;
      }

      var ctx = $canvas[0].getContext('2d');

      ctx.font = ret.font;
      var allText = ret.text;

      if (allText.length === 0) {
        ret.setCursorIndentChars(0);
      } else {
        var text = '';
        var index = 0;
        var done = false;

        while (!done) {
          var letter = allText.substring(index, index + 1);

          text += letter;
          var tw = ctx.measureText(text).width;

          if (x <= tw) {
            var lw = ctx.measureText(letter).width;
            var middle = tw - lw / 2;

            if (x > middle) {
              index++;
            }

            ret.setCursorIndentChars(index);
            done = true;
          } else {
            index++;

            // maybe we just did last letter
            if (index >= allText.length) {
              ret.setCursorIndentChars(index);
              done = true;
            }
          }
        }
      }

      ret.setFocused(true);

      paintCanvas();

      // ie, consume event, no mouse reaction by parents!
      return true;
    };

    ret.checkForMergeWithPowerToRight = function () {
      if (!ret.isBase()) {
        if (ret.parent.t === 'm') {
          var r = ret.parent.getElementToRight(ret);

          if (r && r.t === 'power') {
            if (!r.hasDigitBase()) return;
            var chop = '';
            var chopI = -1;
            var foundDot = false;

            for (var ii = ret.text.length - 1; ii > -1; ii--) {
              var snip = ret.text.substring(ii, ii + 1);

              if (
                $.isNumeric(snip) ||
                (!foundDot && snip == '.') ||
                snip == ','
              ) {
                chop = snip + chop;
                chopI = ii;
                if (snip == '.') foundDot = true;
              } else {
                break;
              }
            }

            if (chop.length > 0) {
              var ot = r.base.children[0];

              // ret.deleteString(chopI, chop.length);
              ret.text = chopI < 1 ? '' : ret.text.substring(0, chopI);
              ot.text = chop + ot.text;
              ot.sizeMe();
              ot.grabFocus();
              ot.setCursorIndentChars(chop.length);
            }
          }
        }
      }
    };

    /**
     *  @memberOf getText
     */
    ret.insertString = function (string, last) {
      if ((string + ret.text).length > MAX_CHARS_IN_LINE) {
        return;
      }

      if (string === '-') {
        string = '\u2212';
      }

      if (
        last ||
        !(
          typeof ret.cursorIndentChars === 'number' &&
          ret.cursorIndentChars >= 0
        )
      ) {
        ret.setCursorIndentChars(ret.text.length);
      }

      var pre = ret.text.substring(0, ret.cursorIndentChars);
      var post = ret.text.substring(ret.cursorIndentChars);

      if (string === ' ') {
        if (pre.length > 0 && pre.substring(pre.length - 1) === ' ') return;
        if (post.length > 0 && post.substring(0, 1) === ' ') return;
      }

      if (
        ret.parent.parent.t === 'subscript' ||
        ret.parent.parent.t === 'function'
      ) {
        // Check for equals and push out to the right
        if (!ret.isAlphaNumeric(string)) {
          ret.parent.parent.parent.pushTextOutToRight(
            ret.parent.parent,
            string
          );
        } else if (ret.cursorIndentChars == 0 && ret.text.length > 0) {
          ret.parent.parent.parent.pushTextOutOfBaseToLeft(
            ret.parent.parent,
            string
          );
        } else {
          ret.text = pre + string + post;
          ret.setCursorIndentChars(ret.cursorIndentChars + 1);
        }

        ret.sizeMe();
        paintCanvas();

        return;
      }

      ret.text = pre + string + post;
      ret.checkForMergeWithPowerToRight();

      // normal situation: advance cursor to immediately following insert
      ret.setCursorIndentChars(ret.cursorIndentChars + (string + '').length);

      ret.sizeMe();

      changedMaybe();

      //* *plz find ways to limit the repaint calls!**
      paintCanvas();
    };

    /**
     *  @memberOf getText
     */
    ret.deleteString = function (start, len) {
      ret.text = ret.text.substring(0, start) + ret.text.substring(start + len);

      ret.checkForMergeWithPowerToRight();

      changedMaybe();
    };

    /**
     *  @memberOf getText
     */
    ret.deletePressed = function () {
      if (
        !(
          typeof ret.cursorIndentChars === 'number' &&
          ret.cursorIndentChars >= 0
        )
      ) {
        ret.setCursorIndentChars(ret.text.length);
      }

      if (ret.cursorIndentChars == ret.text.length) {
        if (ret.parent) {
          ret.parent.deleteRight(ret);
        }
      } else {
        ret.deleteString(ret.cursorIndentChars, 1);
        ret.sizeMe();
      }

      storeCurrentLineXML();

      changedMaybe();
    };

    /**
     *  @memberOf getText
     */
    ret.backspace = function () {
      if (
        !(
          typeof ret.cursorIndentChars === 'number' &&
          ret.cursorIndentChars >= 0
        )
      ) {
        ret.setCursorIndentChars(ret.text.length);
      }

      if (ret.cursorIndentChars === 0) {
        if (ret.isBase()) {
          moveCursor('left');
          focused.backspace();
          moveCursor('right');
        } else if (ret.parent) {
          ret.parent.deleteLeft(ret);
        }
      } else {
        ret.deleteString(ret.cursorIndentChars - 1, 1);
        ret.setCursorIndentChars(ret.cursorIndentChars - 1);
        ret.sizeMe();
      }

      storeCurrentLineXML();
      changedMaybe();
    };

    /**
     *  @memberOf getText
     */
    ret.clearMyLine = function () {
      ret.line.clear();
      changedMaybe();
    };

    /**
     *  @memberOf getText
     */
    ret.slideCursor = function (dx) {
      if (
        !(
          typeof ret.cursorIndentChars === 'number' &&
          ret.cursorIndentChars >= 0
        )
      ) {
        ret.setCursorIndentChars(0);
      }

      if (ret.cursorIndentChars === 0 && dx < 0) {
        return ret.parent.moveCursor(ret, 'left');
      }

      if (ret.cursorIndentChars >= ret.text.length && dx > 0) {
        return ret.parent.moveCursor(ret, 'right');
      }

      ret.setCursorIndentChars(ret.cursorIndentChars + dx);

      return true;
    };

    ret.isBase = function () {
      return ret.parent && ret.parent.gmmName === 'base multi';
    };

    ret.isAlphaNumeric = function (str) {
      var code, i, len;

      for (i = 0, len = str.length; i < len; i++) {
        code = str.charCodeAt(i);

        if (
          !(code > 47 && code < 58) && // numeric (0-9)
          !(code > 64 && code < 91) && // upper alpha (A-Z)
          !(code > 96 && code < 123) && // lower alpha (a-z)
          !(code == 960) // Pi
        ) {
          return false;
        }
      }

      return true;
    };

    ret.sizeMe();

    ret.viewportFill = ret.text.length === 0 ? 'red' : undefined;

    return ret;
  };

  // Getters call this so that a 'square' for an exam problem will be
  // updated to uncertainty regarding readiness for turning in a test
  // (whether to put a check mark on square)
  var changedMaybe = function (useAgId) {
    // building getter, probably, so no need to save changes to it
    if (!currentAttemptGetter) return;

    if (!isTesting()) return;
    var di = maybeUndoSelectedLineEntryButton();

    if (isTesting() && !di) {
      getProblemContext().setCurrentUncertain();
      attemptGetters.get(useAgId || currentAttemptGetter.agId).uncertain = true;
    }
  };

  var getDrawing = function (d) {
    var ret = getBasicObject();
    var xtraW = 50;
    var xtraH = 20;
    var mw = d.w + xtraW;
    var mh = d.h + xtraH;
    var scale =
      !narrow || mw <= NARROW_PROBLEM_WIDTH ? 1 : NARROW_PROBLEM_WIDTH / mw;

    ret.setAllDim(mw * scale, mh * scale);
    ret.d = d;

    ret.paintMe = function (ctx) {
      ctx.save();

      ctx.scale(scale, scale);

      ctx.save();

      var d = ret.d;

      ctx.translate(d.cx + 0.5 * xtraW, d.cy + 0.5 * xtraH);
      ctx.translate(0, -d.slideUp);
      ctx.rotate(d.rotate);

      // circle has to be first or it fills over other items
      for (var a = 0; a < d.geoMarks.length; a++) {
        var gm = d.geoMarks[a];

        if (gm.t === 'geoCircle') {
          circle(ctx, gm.cx, gm.cy, gm.r, 'black', true);
        }
      }

      for (var a = 0; a < d.geoMarks.length; a++) {
        var gm = d.geoMarks[a];

        if (gm.t === 'Segment') {
          if (!gm.dotted) lineA(ctx, gm.pts, gm.thickness, gm.color);
          else
            dottedLine(
              ctx,
              gm.pts[0],
              gm.pts[1],
              gm.pts[2],
              gm.pts[3],
              gm.color
            );

          if (gm.arrows) {
            for (var aa = 0; aa < gm.arrows.length; aa++) {
              var arrow = gm.arrows[aa];
              var arPts = [];

              arPts.push(arrow[0]);
              arPts.push(arrow[1]);
              arPts.push(arrow[2]);
              arPts.push(arrow[3]);
              lineA(ctx, arPts, null, gm.markupColor);
              arPts = [];
              arPts.push(arrow[4]);
              arPts.push(arrow[5]);
              arPts.push(arrow[2]);
              arPts.push(arrow[3]);
              lineA(ctx, arPts, null, gm.markupColor);
            }
          }

          if (gm.congruencyMarks) {
            for (var aa = 0; aa < gm.congruencyMarks.length; aa++) {
              var pts = gm.congruencyMarks[aa];

              lineA(ctx, pts, null, gm.markupColor);
            }
          }
        } else if (gm.t === 'Rectangle') {
          ctx.beginPath();
          ctx.moveTo(gm.pts[0], gm.pts[1]);

          for (var z = 2; z < 8; z += 2) {
            ctx.lineTo(gm.pts[z], gm.pts[z + 1]);
          }

          if (gm.shaded) {
            ctx.fillStyle = 'gray';
            ctx.globalAlpha = 0.4;
            ctx.fill();
            ctx.globalAlpha = 1;
          }

          ctx.stroke();
          ctx.beginPath();

          if (gm.pts.length > 7) {
            ctx.moveTo(gm.pts[8], gm.pts[9]);

            for (var z = 10; z < 16; z += 2) {
              ctx.lineTo(gm.pts[z], gm.pts[z + 1]);
            }
          }

          if (gm.shaded) {
            ctx.fillStyle = 'white';
            ctx.fill();
          }

          ctx.stroke();
        } else if (gm.t === 'geoPoint') {
          circle(ctx, gm.cx, gm.cy, 3, 'black', false);
        } else if (gm.t == 'geoAngle') {
          var pts = [];

          pts.push(gm.pts[0]);
          pts.push(gm.pts[1]);
          pts.push(gm.pts[2]);
          pts.push(gm.pts[3]);
          lineA(ctx, pts);
          pts = [];
          pts.push(gm.pts[4]);
          pts.push(gm.pts[5]);
          pts.push(gm.pts[2]);
          pts.push(gm.pts[3]);
          lineA(ctx, pts);
        } else if (gm.t === 'geoCongruencyArc') {
          for (var z = 0; z < gm.arcs.length; z++) {
            var arc = gm.arcs[z];

            for (var zz = 0; zz < arc.length; zz += 2) {
              var pts = [];

              pts.push(arc[zz]);
              pts.push(arc[zz + 1]);
              pts.push(arc[zz + 2]);
              pts.push(arc[zz + 3]);
              lineA(ctx, pts);
            }
          }
        } else if (gm.t === 'geoArc') {
          if (gm.arcType === 'open') {
            ctx.beginPath();
            ctx.arc(gm.cx, gm.cy, gm.r, gm.as, gm.ae, false);
            ctx.stroke();
          } else {
            ctx.stroke();
            ctx.beginPath();

            // need to fill arc here
            if (gm.arcType === 'pie') {
              ctx.moveTo(gm.cx, gm.cy);
            }

            ctx.arc(gm.cx, gm.cy, gm.r, gm.as, gm.ae, false);
            ctx.fillStyle = '#3370d4';
            ctx.fill();

            if (gm.arcType === 'chord') {
              lineA(ctx, gm.pts);
            } else if (gm.arcType === 'pie') {
              lineA(ctx, gm.pts1);
              lineA(ctx, gm.pts2);
            }
          }
        }
      }

      ctx.restore();
      ctx.save();
      ctx.translate(0, -d.slideUp);
      ctx.translate(0.5 * xtraW, 0.5 * xtraH);
      // ctx.scale(scale, scale);

      var adjX = 0;
      var adjY = 0;

      for (var a = 0; a < d.geoMarks.length; a++) {
        var gm = d.geoMarks[a];

        if (gm.t === 'geoLabel') {
          if (gm.line === undefined) {
            gm.line = toLine(gm.xml);
            gm.line.viewportX = adjX + (gm.cx - gm.line.viewportW / 2);
            gm.line.viewportY = adjY + (gm.cy - gm.line.viewportH / 2);
          }

          gm.line.fillStyle = gm.color;
          gm.line.paint(ctx);
        }
      }

      ctx.restore();
      ctx.restore();
    };

    return ret;
  };

  var getStaticPanelFromText = function (text, font, tts, targetForReadAloud) {
    return getStaticPanel(
      {
        xml: '<t>' + text + '</t>',
        font: font,
        tts: tts,
      },
      0,
      0,
      undefined,
      targetForReadAloud
    );
  };

  // If there is a targetForReadAloud, it means we already have a BasicObject
  // that will show the ReadAloudButton and the static panel we are about to build
  // should NOT have a ReadAloudButton but instead should have its tts data
  // appended to the TTS that is already associated with targetForReadAloud
  var getStaticPanel = function (args, x, y, leftJ, targetForReadAloud) {
    x = x || 0;
    y = y || 0;

    if (args.panels) {
      var obj = getBasicObject(x, 0);
      var maxH = 0;

      for (var a = 0; a < args.panels.length; a++) {
        var panel = getStaticPanel(args.panels[a], 0, 0, null);

        obj.add(panel);
        maxH = Math.max(panel.viewportH, maxH);
      }

      if (args.vertPSS) {
        obj.layoutChildrenUD(5);
        if (!leftJ && args.horizontalAlignment !== 'LEFT')
          obj.centerHorizontally();
      } else {
        obj.layoutChildrenLR(10);

        for (var a = 0; a < obj.children.length; a++) {
          var child = obj.children[a];
          var surplus = (maxH - child.viewportH) / 2;

          child.viewportY += surplus;
        }
      }

      obj.sizeMeToFitChildren();

      return obj;
    } else if (args.drawing) {
      return getDrawing(args.drawing);
    } else if (args.cards) {
      return getCards(args);
    } else if (args.imageLibrary) {
      var obj = getBasicObject(x, 0);

      obj.image = getImage(args.imageLibrary, paintCanvas);
      obj.setAllDim(obj.image.width, obj.image.height);

      obj.paintMe = function (ctx) {
        drawImage(ctx, obj.image, 0, 0);
      };

      return obj;
    } else if (args.stemAndLeaf) {
      return getStemAndLeaf(args.stemAndLeaf);
    }
    // java side: Value (such as Equation, Expression, Fraction, etc.)
    else if (args.xml) {
      var line = toLine(
        args.xml,
        x,
        0,
        undefined,
        undefined,
        true,
        undefined,
        args.font
      );

      line.e = false;

      if (isReadAloud() && args.tts) {
        injectReadAloudButton(
          targetForReadAloud || line,
          args.tts,
          { paintCanvas, errorToServer },
          line
        );

        // Resize line to account for inject ReadAloudButton
        if (!targetForReadAloud) {
          line.lockViewportDim = false;
          line.buildSizeRecursive();
        }
      }

      return line;
    } else if (args.g) {
      var grid = getGrid(args.g);

      return grid;
    } else if (args.table || args.valueTable) {
      if (!isReadAloud())
        return args.table
          ? getTable(x, 0, args.table)
          : getValueTable(x, 0, args.valueTable);

      // ReadAloudButton and table are wrapped in a BasicObject.
      // Clicking on button reads all non-editable cells of table.
      var readAloudTableWrapper = getBasicObject();
      var table = args.table
        ? getTable(x, 0, args.table, readAloudTableWrapper)
        : getValueTable(x, 0, args.valueTable, readAloudTableWrapper);

      readAloudTableWrapper.add(table);
      readAloudTableWrapper.layoutChildrenLR();
      readAloudTableWrapper.sizeMeToFitChildren();

      // We found cases where tables barely fit in narrow view prior to read
      // aloud, so audio icon added to top left caused table's row to exceed
      // narrow max width. Here, we detect this issue and resolve it by
      // bumping the audio icon to above the table
      if (
        isNarrow() &&
        readAloudTableWrapper.viewportW > NARROW_PROBLEM_WIDTH
      ) {
        readAloudTableWrapper.layoutChildrenUD();
        readAloudTableWrapper.children.forEach(child => {
          child.viewportX = 0;
        });
        readAloudTableWrapper.sizeMeToFitChildren();
      }

      return readAloudTableWrapper;
    } else if (args.nl) {
      var ret = getNumberLine(0, 0, args.nl);

      return ret;
    }
    // java side: StringsAndValuesParagraph
    else if (args.xmlGraph) {
      var lines = [];
      var align = args.xmlGraph.align;
      var arr = args.xmlGraph.lines.slice();
      var maxW = screenMaxTextW;

      // Leave room for a ReadAloudButton
      if (isReadAloud()) maxW -= getReadAloudButtonWidth();

      var process = function (usey) {
        var xml = '';

        for (var b = 0; b < usey.length; b++) {
          var nex = usey[b];

          // Trim spaces from new lines
          if (b == 0) nex = nex.trimStart();

          if (nex.indexOf('<') !== 0) {
            nex = '<t>' + nex + '</t>';
          }

          xml += nex;
        }

        var myL = toLine(xml, x, 0, undefined, undefined, true);

        myL.setEditable(false);

        return myL;
      };
      var use = [];

      for (var z = 0; z < arr.length; z++) {
        var val = arr[z];

        if (val.indexOf('<') !== 0) {
          var words = [];
          var cutter = val;

          while (cutter.length > 0) {
            var myI = cutter.indexOf(' ');
            var nexter = myI > -1 ? cutter.substring(0, myI + 1) : cutter;

            cutter = myI > -1 ? cutter.substring(myI + 1) : '';
            words.push(nexter);
          }

          var ki = 0;

          while (ki < words.length) {
            var nx = words[ki];

            if (nx !== '') {
              ki++;
            } else {
              words.splice(ki, 1);
            }
          }

          if (words.length > 0) {
            var last = '';
            var sum = '';
            var first = true;
            var broke = false;

            for (var wordIndex = 0; wordIndex < words.length; wordIndex++) {
              var word = words[wordIndex];

              last = sum;
              sum += word;
              use.push(sum);
              var line = process(use);

              if (line.viewportW > maxW || line.hasMultiLinesText()) {
                if (first) {
                  if (use.length === 1) {
                    var i = word.length / 2;
                    var rep = word.substring(0, i) + ' ' + word.substring(i);

                    arr.splice(z, 1, rep);

                    if (wordIndex < words.length - 1) {
                      var blder = '';

                      for (var za = wordIndex + 1; za < words.length; za++) {
                        blder += words[za];

                        if (za != words.length - 1) {
                          blder += ' ';
                        }
                      }

                      arr.splice(z + 1, 0, blder);
                    }

                    z--;
                    use = [];
                  } else {
                    use.splice(use.length - 1, 1);
                    lines.push(process(use));
                    use = [];
                    z--;
                  }
                } else {
                  use.splice(use.length - 1, 1);
                  use.push(last);
                  lines.push(process(use));
                  use = [];
                  var rem = '';

                  for (var re = wordIndex; re < words.length; re++) {
                    rem += words[re];
                  }

                  arr[z] = rem;
                  z--;
                }

                broke = true;
                break;
              } else {
                use.splice(use.length - 1, 1);

                // if beginning of a line is a space, we end up removing it while laying out the line,
                // so we need to shift the displayIndexes array else our highlighting will be off
                if (
                  isReadAloud() &&
                  args.tts &&
                  use.length === 0 &&
                  wordIndex == 0 &&
                  word === ' '
                ) {
                  // get paintedCharCount from all previous lines to know
                  // which elements in the displayIndexes array to shift
                  let paintedCharCount = 0;

                  for (let i = 0; i < lines.length; i++) {
                    paintedCharCount += lines[i].getPaintedCharCount();
                  }

                  // get displayIndexes array from tts object and update it
                  // -1 shifts left 1, but wrote function to be able to shift by any amount
                  shiftIndexes(args.tts.displayIndexes, paintedCharCount, -1);
                }
              }

              first = false;
            }

            if (!broke) {
              use.push(val);
            }
          }
        } else {
          use.push(val);
          var myL = process(use);

          if (myL.viewportW > maxW || myL.hasMultiLinesText()) {
            if (use.length === 1) {
              lines.push(myL);
              use = [];
            } else {
              use.splice(use.length - 1, 1);
              z--;
              myL = process(use);
              lines.push(myL);
              use = [];
            }
          }
        }
      }

      if (use.length > 0) {
        lines.push(process(use));
      }

      var ret = getBasicObject(x, y);
      var y = 0;
      var maxMid = 0;

      for (var a = 0; a < lines.length; a++) {
        var line = lines[a];

        line.viewportY = y;
        y += line.viewportH;
        ret.add(line);

        if (a != lines.length - 1) {
          var spacer = getBasicObject();

          spacer.setAllDim(1, 5);
          spacer.viewportY = y;
          y += 5;
          ret.add(spacer);
        }

        maxMid = Math.max(maxMid, line.pixelsToMiddle(align));
      }

      for (var a = 0; a < lines.length; a++) {
        var m = lines[a].pixelsToMiddle(align);
        var delta = maxMid - m;

        if (delta > 0) {
          lines[a].viewportX = delta;
        }
      }

      if (isReadAloud() && args.tts) {
        let readAloudButton;

        for (let x = 0; x < lines.length; x++) {
          const nextLine = lines[x];

          // First line starts with a ReadAloudButton.
          // Note to selves: we decided not to use a 'wrapper'
          // strategy because then the button was not vertically centered with
          // the rest of the elements in the first line.
          if (x === 0)
            readAloudButton = injectReadAloudButton(
              targetForReadAloud || nextLine,
              args.tts,
              { paintCanvas },
              nextLine
            );
          else {
            // Lines in an xmlGraph do not have their own
            // tts data from the server, since a single tts object
            // models the entire paragraph.
            readAloudButton.tts.addDisplayedElement(nextLine);

            if (!targetForReadAloud) {
              // Force a matching horizontal space to left of each non-first line
              // to match indent from ReadAloudButton on first line
              const filler = getFillerBasicObject(getReadAloudButtonWidth(), 0);

              nextLine.add(filler, 0);
            }
          }

          nextLine.lockViewportDim = false;
          nextLine.buildSizeRecursive();
        }
      }

      ret.sizeMeToFitChildren();

      return ret;
      // 3 possibilities form java side:
      // SystemOfEquations,
      // SystemOfInequalities,
      // SystemOfIneqs
    } else if (args.linesXML) {
      var lines = [];
      var ret = getBasicObject(x, y);

      var y = 0;
      var maxMid = 0;

      for (var a = 0; a < args.linesXML.length; a++) {
        const line = getStaticPanel(args.linesXML[a], 0, 0);

        line.viewportY = y;
        y += line.viewportH;
        lines.push(line);
        ret.add(line);

        if (a != args.linesXML.length - 1) {
          var spacer = getBasicObject();

          spacer.setAllDim(1, 5);
          spacer.viewportY = y;
          y += 5;
          ret.add(spacer);
        }

        maxMid = Math.max(maxMid, line.pixelsToMiddle(align));
      }

      for (var a = 0; a < lines.length; a++) {
        var m = lines[a].pixelsToMiddle(align);
        var delta = maxMid - m;

        if (delta > 0) {
          lines[a].viewportX = delta;
        }
      }

      ret.sizeMeToFitChildren();

      if (isReadAloud() && args.tts) {
        const wrapper = getBasicObject();

        wrapper.add(ret);
        injectReadAloudButton(
          targetForReadAloud || wrapper,
          args.tts,
          { paintCanvas },
          wrapper
        );
        wrapper.layoutChildrenLR();
        wrapper.sizeMeToLowestAndRightmostChildren();

        ret = wrapper;
      }

      return ret;
    } else if (args.image) {
      return getProbImage(args, x, y, targetForReadAloud);
    }
  };

  var getProbImage = function (args, x, y, targetForReadAloud) {
    const imageData = args.image;

    // build our image holder prior to loading the image in order
    // to be ready handle 'onload' event
    const ret = new MathImage(imageData, getProblemJSDependencies(), x, y);

    if (!isSkipLoadingImages()) {
      if (imageData.base64) {
        ret.loadBase64Image(imageData.base64, imageLoadedListener);

        // Don't save raw base64 image data on student app because we save the Image created from it.
        // If we keep the base64, we're doubling (plus some) memory usage.
        // Avoid any race conditions by only setting base64 to undefined after the image has loaded.
        if (isStudent && ret.loaded) imageData.base64 = undefined;
      } else {
        // Generally, the server expects a client-side-incremented number. The server
        // methodically iterates through the ProblemQuestionBetter until it gets to
        // this '#th' image.
        // Sometimes, the server sends a specific image number instead as part of the
        // json describing the image. For example, -1 has been used for answer images in several
        // getters.
        const imageNumber =
          imageData.imageNumber !== undefined
            ? imageData.imageNumber
            : imageNum;

        // Increment for the next call to getProbImage (occurs during setProblem, which resets imageNum to 0 at start of execution)
        if (imageData.imageNumber === undefined) imageNum++;

        const urlBuilder = {
          urlBase: getApiUrl() + '/',
          restoreId: imageData.restoreId,
          test: imageData.test || imageFromRestoreTest,
          teacher: !isStudent,
        };

        const src = getProblemImageSource(
          imageNumber,
          imageData,
          urlBuilder,
          base64Imgs
        );

        const loadedSrc = ret.loadImage(
          src,
          !!imageData.svg,
          imageLoadedListener
        );

        const sendErrorMessage = `getProbImage: image data did not come with base64 data.\n
          imageData: ${JSON.stringify(imageData)}\n
          src: ${src}\n
          restoreId: ${imageData.restoreId}\n
          skillId: ${getProblemContext().getCurrentSkillId()}\n`;

        // Only send 1 error message per problem
        // Only log the error when we actually reach out to the server for the image
        if (imageNumber === 1 && loadedSrc.includes('GeneralPurpose')) {
          getProblemContext().errorToServer(sendErrorMessage);
        }
      }
    }

    ret.setMaxWidth(narrow ? NARROW_PROBLEM_WIDTH : PROBLEM_WIDTH);
    if (!isReadAloud() || !args.tts) return ret;

    ret.setTTS(args.tts);

    // If the caller supplied a targetForReadAloud, it means we already have a BasicObject
    // that should show the audio icon. Per pattern in getStaticPanel, we inject the tts
    // from the new panel into the tts of the targetForReadAloud and then return the new
    // panel. It is the caller's responsibility to shrink the returned panel if needed.
    if (targetForReadAloud) {
      injectReadAloudButton(targetForReadAloud, args.tts, { paintCanvas }, ret);

      return ret;
    }

    // ReadAloudWrapper protects the size of the audio icon when
    // the image needs to be scaled down.
    var readAloudWrapper = getReadAloudWrapper();

    readAloudWrapper.add(ret);
    injectReadAloudButton(readAloudWrapper, args.tts, { paintCanvas }, ret);
    readAloudWrapper.layoutChildrenLR();
    // Shrink to fit available space, audio icon sizing is protected by ReadAloudWrapper
    readAloudWrapper.setMaxWidth(narrow ? NARROW_PROBLEM_WIDTH : PROBLEM_WIDTH);

    return readAloudWrapper;
  };

  // Allow internal tooling to skip loading images
  var isSkipLoadingImages = function () {
    return selfCheckListener && selfCheckListener.skipLoadingImages();
  };

  var getCards = function (data) {
    var ret = getBasicObject();

    if (data.dir) {
      var directions = getStaticPanelFromText(data.dir, undefined, data.tts);

      ret.add(directions);
    }

    var cards = getBasicObject();

    var s = lineFontSize + 10;
    var row;

    for (var a = 0; a < data.cards.length; a++) {
      if (row === undefined) {
        row = getBasicObject();
      }

      var letter = getBasicObject();

      letter.viewportMargin = 1;
      var t = getText(0, 0, data.cards[a]);

      letter.add(t);
      letter.setAllDim(s);
      letter.centerHorizontally();
      letter.centerVertically();
      row.add(letter);

      if (a == data.cards.length - 1 || (a > 0 && (a + 1) % 7 === 0)) {
        row.layoutChildrenLR(5);
        row.sizeMeToFitChildren();
        cards.add(row);
        row = undefined;
      }
    }

    cards.layoutChildrenUD(5);
    cards.sizeMeToFitChildren();

    ret.add(cards);
    ret.layoutChildrenUD(10);
    ret.sizeMeToFitChildren();
    ret.centerHorizontally();

    return ret;
  };

  var getStemAndLeaf = function (vals) {
    var vSpaceToTitle = 10;
    var vSpaceAfterTitle = 10;
    var vSpaceAfterRow = 8;
    var vSpaceToKey = 10;
    var vSpaceInKey = 5;
    var vSpaceAfterKey = 10;

    var horizSpace = 13;
    var horizOuterMargin = 10;

    var horizKeyMargin = 7;

    var ret = getBasicObject();

    var data = vals.data;

    var keyStem = 0;
    var keyLeaf = 0;
    var width = 0;
    var height = 0;
    var text = getText(0, 0, '8');

    var stemToLeaves = {};

    for (var x = 0; x < data.length; x++) {
      var i = data[x];
      var stem = 0;
      var leaf = 0;

      if (i < 10) {
        leaf = i;
      } else if (i < 100) {
        stem = parseInt(i / 10);
        leaf = i % 10;
      } else if (i < 1000) {
        stem = parseInt(i / 100);
        leaf = parseInt((i % 100) / 10);
      } else if (i < 10000) {
        stem = parseInt(i / 1000);
        leaf = parseInt((i % 1000) / 100);
      }

      if (stemToLeaves[stem + ''] === undefined) {
        stemToLeaves[stem + ''] = {};
      }

      if (stem === 0) {
        ret.keyStem = 0;
        ret.keyLeaf = leaf;
      } else if (keyStem === 0) {
        ret.keyStem = stem;
        ret.keyLeaf = leaf;
      }

      var leafer = stemToLeaves[stem + ''];

      if (leafer[leaf + ''] === undefined) {
        leafer[leaf + ''] = 0;
      }

      leafer[leaf + ''] += 1;
    }

    var r = Object.keys(stemToLeaves).length;

    if (keyStem === 0 && r > 1) {
      for (var i in stemToLeaves) {
        if (stemToLeaves.hasOwnProperty(i)) {
          if (parseInt(i) !== 0) {
            keyStem = i;
            var leaves = stemToLeaves[i];

            for (var l in leaves) {
              if (leaves.hasOwnProperty(l)) {
                keyLeaf = l;
                break;
              }
            }

            break;
          }
        }
      }
    }

    height += vSpaceToTitle + vSpaceAfterTitle + vSpaceToKey + vSpaceAfterKey;
    height += 4 * vSpaceInKey + text.viewportH * 2;

    height += (r - 1) * vSpaceAfterRow;

    height += (r + 1) * text.viewportH;

    width += horizOuterMargin;
    width += text.viewportW;
    // tween stem and first leaf
    width += horizSpace;
    var longest = 0;

    for (var prop in stemToLeaves) {
      if (stemToLeaves.hasOwnProperty(prop)) {
        var len = 0;
        var leavers = stemToLeaves[prop];

        for (var lprop in leavers) {
          if (leavers.hasOwnProperty(lprop)) {
            len += leavers[lprop];
          }
        }

        longest = Math.max(longest, len);
      }
    }

    width += (longest - 1) * horizSpace;
    width += longest * text.viewportW;
    width += horizOuterMargin;

    ret.setAllDim(width, height);

    ret.paintMe = function (gr) {
      gr.save();

      gr.translate(horizOuterMargin, vSpaceToTitle);
      var innerW = width - horizOuterMargin * 2;

      var text = getText(0, 0, 'Data');

      gr.save();
      gr.translate(innerW / 2 - text.viewportW / 2, 0);
      text.paintMe(gr);
      gr.restore();

      text.setText('8');
      var tw = text.viewportW;

      gr.translate(0, text.viewportH + vSpaceAfterTitle);

      var poke = 5;
      var lineLen = r * text.viewportH + (r - 1) * vSpaceAfterRow;
      var lineX = tw + horizSpace / 2;

      line(gr, lineX, -poke, lineX, lineLen + poke);

      for (var stem in stemToLeaves) {
        if (stemToLeaves.hasOwnProperty(stem)) {
          text.setText(stem);
          text.paintMe(gr);

          var leaf = stemToLeaves[stem];
          var horDelta = 0;

          for (var i in leaf) {
            if (leaf.hasOwnProperty(i)) {
              text.setText(i);

              for (var x = 0; x < leaf[i]; x++) {
                gr.translate(horizSpace + tw, 0);
                horDelta += horizSpace + tw;
                text.paintMe(gr);
              }
            }
          }

          gr.translate(-horDelta, 0);
          gr.translate(0, vSpaceAfterRow + text.viewportH);
        }
      }

      gr.translate(0, -vSpaceAfterRow);
      gr.translate(0, vSpaceToKey);

      var keyString =
        keyStem + ' | ' + keyLeaf + ' = ' + keyStem + '' + keyLeaf;

      text.setText(keyString);
      var keyW = horizKeyMargin * 2 + text.viewportW;
      var keyH = vSpaceInKey * 4 + text.viewportH * 2;
      var keyRectX = innerW / 2 - keyW / 2;

      gr.beginPath();
      gr.rect(keyRectX + 2, 0, keyW - 4, keyH);
      gr.lineWidth = 1;
      gr.strokeStyle = 'black';
      gr.stroke();

      gr.translate(0, vSpaceInKey);
      text.setText('Key');
      gr.save();
      gr.translate(innerW / 2 - text.viewportW / 2, 0);
      text.paintMe(gr);
      gr.restore();

      text.setText(keyString);
      gr.translate(
        innerW / 2 - text.viewportW / 2,
        text.viewportH + 2 * vSpaceInKey
      );
      text.paintMe(gr);

      gr.restore();
    };

    return ret;
  };

  var isNarrow = function () {
    return canvasW < PROBLEM_WIDTH;
  };

  var setProblem = function (p, id) {
    cancelReadAloud();

    if (gridGuide) {
      gridGuide.on = false;
      gridGuide.grid = undefined;
      gridGuide.dot = undefined;
    }

    // reset pointer in case it was 'grab'/'grabbing' from DragDrop
    $canvas.css('cursor', 'default');

    var nar = isNarrow();

    if (nar !== narrow) {
      narrow = nar;
      getGridGuide();
    }

    builtCurrentFrom = p;

    currentProblemId = id;
    attemptGetters.clear();
    onlyNormalGetters = true;
    buttons = {};
    currentAttemptGetter = undefined;
    problemPanel.children = [];
    keyboardRow = undefined;
    keyboard.getter = undefined;
    keyboard.agId = undefined;
    problemPanel.viewportSlideX = 0;
    problemPanel.viewportSlideY = 0;
    floater.clear();
    dragController = undefined;
    var y = rowVertSpacer;
    var r = p.r;

    imageFromRestoreTest = p.imageFromRestoreTest;

    if (narrow) {
      var rn = [];

      for (var row = 0; row < r.length; row++) {
        rn.push([]);

        // var agged = false;
        for (var psi = 0; psi < r[row].length; psi++) {
          var sup = r[row][psi];

          rn[rn.length - 1].push(sup);

          if (psi + 1 < r[row].length) {
            if (
              sup.ag ||
              sup.answerPlusSupplier ||
              r[row][psi + 1].ag ||
              r[row][psi + 1]
            ) {
              rn.push([]);
            }
          }
        }
      }

      r = rn;
    }

    // inject horizontal blue dividers ("lineAfters") after getters
    var tempRowsJson = [];

    r.forEach((rowJson, rowIndex) => {
      tempRowsJson.push(rowJson);

      let hasGetter = rowJson.some(
        panelSupplier => panelSupplier.ag || panelSupplier.answerPlusSupplier
      );

      if (hasGetter && rowIndex < r.length - 1) {
        const nextRowFirstPanelSupplier = r[rowIndex + 1][0];

        // Only inject if the server didn't already supply a lineAfter
        if (!nextRowFirstPanelSupplier.lineAfter) {
          tempRowsJson.push([{ lineAfter: true, injectedLineAfter: true }]);
        }
      }
    });

    r = tempRowsJson;

    imageNum = 1;
    var maxRowWidth = 0;

    let lineAfters = 0;

    // Loop through each row of panel suppliers sent from server
    r.forEach((row, rowIndex) => {
      const rowIndexWithoutLineAfters = rowIndex - lineAfters;
      var scribbleLevel = false;

      if (p.scribbleLevels) {
        for (var ab = 0; ab < p.scribbleLevels.length; ab++) {
          if (p.scribbleLevels[ab] == rowIndex) {
            scribbleLevel = true;
          }
        }
      }

      var rowObj = scribbleLevel
        ? new ScribbleObject(paintCanvas)
        : getBasicObject();

      rowObj.gmmName = 'row ' + rowIndexWithoutLineAfters;

      if (narrow && isStudent) {
        rowObj.paintPost = function (ctx) {
          if (this.getter && !isTesting()) {
            var st = this.getter.st;

            if (st === 'wr' || st === 'c') {
              ctx.save();
              ctx.globalAlpha = 0.5;
              ctx.beginPath();
              var w = 8;

              ctx.lineWidth = w;
              ctx.strokeStyle = st === 'wr' ? 'red' : 'green';
              ctx.rect(
                -problemPanel.viewportX + -this.viewportX + w / 2,
                -(w / 2 + 2),
                canvasW - w,
                this.viewportH + (w + 4)
              );
              ctx.stroke();

              ctx.restore();
            }
          }
        };
      }

      var specialRowHor = undefined;

      // If there is more than one panel supplier in a row,
      // the first one will own the ReadAloudButton. Subsequent
      // panel suppliers will automatically get 'read' when
      // read aloud for the first one is triggered.
      let firstPanelSupplierPerRow = undefined;

      for (var psi = 0; psi < row.length; psi++) {
        var sup = row[psi];
        var height = 0;
        var width = 0;

        if (sup.lineAfter) {
          lineAfters++;
          var lineAfter = getBasicObject();

          lineAfter.gmmName = 'lineAfter';
          lineAfter.row = rowIndex;
          lineAfter.setAllDim(100, lineAfterH);

          lineAfter.paintMe = function (ctx) {
            ctx.save();
            ctx.moveTo(-world.viewportW, lineAfterH / 2);
            ctx.lineTo(world.viewportW, lineAfterH / 2);
            ctx.lineWidth = 1;
            ctx.strokeStyle = 'blue';
            ctx.stroke();
            ctx.restore();
          };

          height = lineAfterH;
          width = 100;
          rowObj.add(lineAfter);
          rowObj.gmmName = 'lineAfter';

          y += lineAfterH;
        } else if (sup.answerPlusSupplier) {
          var obj = getBasicObject();

          obj.gmmName = 'answerPlusSupplier';
          var above = getStaticPanel(sup.answerPlusSupplier.above, 0, 0);
          var ag = sup.answerPlusSupplier.ag.ag;
          var gr = buildGetter(ag, rowIndex);
          var getter = gr.ag;

          rowObj.getter = getter;
          getter.viewportY = above.viewportH + 5;
          getter.parentForKeyboardX = obj;
          obj.add(above);
          obj.add(getter);
          obj.centerHorizontally();
          obj.setAllDim(
            Math.max(above.viewportW, getter.viewportW),
            above.viewportH + getter.viewportH + 5
          );

          // Setup for size changes
          getter.parent = obj;
          obj.getter = getter;
          obj.above = above;

          obj.buildSizeRecursive = function () {
            if (this.getter) {
              this.getter.buildSizeRecursive();
              this.centerHorizontally();
              this.setAllDim(
                this.getMaxChildViewportWidth(),
                this.above.viewportH + this.getter.viewportH + 5
              );
              height = this.viewportH;
            }
          };

          height = obj.viewportH;
          width = obj.viewportW;
          obj.parent = rowObj;
          rowObj.add(obj);
          specialRowHor = 100;
        } else if (sup.ag) {
          var ag = sup.ag;
          var ro = buildGetter(ag, rowIndex);

          width = ro.w;
          height = ro.h;

          // Need parent chain all the way up to row to handle size changes
          // for dynamic lines
          ro.ag.parent = rowObj;
          rowObj.add(ro.ag);
          rowObj.getter = ro.ag;
        } else {
          var obj = getStaticPanel(
            sup,
            0,
            0,
            undefined,
            // When there is more than one static panel supplier in a row,
            // we only want one ReadAloudButton at the beginning, so we use
            // the rowObj as the targetForReadaloud
            row.length > 1 ? rowObj : undefined
          );

          height = obj.viewportH;
          width = obj.viewportW;

          rowObj.add(obj);

          if (!firstPanelSupplierPerRow) {
            firstPanelSupplierPerRow = obj;
          }
        }

        // hor spacer
        if (!sup.lineAfter && psi != row.length - 1) {
          var fillerW = specialRowHor || rowHorSpacer;
          var filler = getFillerBasicObject(fillerW, 0);

          rowObj.add(filler);
        }
      }

      /**
       * Rows may need to resize themselves when they have
       * a descendant line that 'isDynamic'. This occurs
       * when lines are used to support editable cells
       * in tables. As the user alters the cell, the table
       * can widen or narrow, which means parent panels also
       * need to resize so that hitboxes are still accurate.
       */
      rowObj.buildSizeRecursive = function () {
        var priorW = rowObj.viewportW;

        for (var i = 0; i < rowObj.children.length; i++) {
          if (rowObj.children[i].buildSizeRecursive) {
            rowObj.children[i].buildSizeRecursive();
          }
        }

        rowObj.layoutChildrenLR();
        rowObj.sizeMeToLowestAndRightmostChildren();
        rowObj.centerVertically();

        var newW = rowObj.viewportW;

        if (newW !== priorW) {
          rowObj.viewportX += (priorW - newW) / 2;
        }
      };

      rowObj.layoutChildrenLR();
      rowObj.sizeMeToLowestAndRightmostChildren();
      rowObj.centerVertically();

      rowObj.viewportX = rowVertSpacer;
      rowObj.viewportY = y;

      problemPanel.add(rowObj);
      y += rowObj.viewportH + rowVertSpacer;

      maxRowWidth = Math.max(rowObj.viewportW, maxRowWidth);

      var extraWidth = 0;
      var agFound = false;
      var kids = rowObj.children;

      for (var j = 0; j < kids.length; j++) {
        var kid = kids[j];

        if (
          kid.gmmName === 'normalGetter' ||
          kid.gmmName === 'tableGetter' ||
          kid.gmmName === 'valueTableGetter' ||
          kid.gmmName === 'answerPlusSupplier'
        ) {
          // Calculate the difference in midpoint offsets to determine extra width
          var agMidpoint =
            kid.viewportX + Math.max(kid.viewportW, keyboardW) / 2;
          var midpoint = rowObj.viewportW / 2;

          extraWidth = Math.max(
            extraWidth,
            Math.abs(agMidpoint - midpoint) / 2
          );

          agFound = true;
        }
      }

      // Add any extra width that was calculated based on an offset ag/keyboard
      maxRowWidth += extraWidth;

      // Always ensure keyboard width if attempt getter found
      if (agFound && maxRowWidth < keyboardW) maxRowWidth = keyboardW;
    });

    advanceFocus();
    maxRowWidth = Math.min(maxRowWidth, canvasW);
    problemPanel.width = maxRowWidth;
    problemPanel.viewportW = maxRowWidth;

    // to force inner width/height to be accurate
    repositionProblemRows();
  };

  var applySelectedButton = function (g) {
    var getter = g || currentAttemptGetter;

    // saves button to json/dto that is held by 'owner' (problemContext).
    // this way, if you click off then click on, owner will hand back button state
    getter.getJsonFromServer().selectedButton = getter.selectedButton;

    for (var a = 0; a < buttonKeys.length; a++) {
      var s = buttonKeys[a].text === getter.selectedButton;

      buttonKeys[a].sel = s;
    }
  };

  var buildGetter = function (ag, row, x) {
    var ret = buildGetterH(ag, row, x);

    ret.ag.selectedButton = ag.selectedButton;

    return ret;
  };

  var buildGetterH = function (attemptGetterJson, row, x) {
    // internal tooling CanvasCapture tracks how many getters exist per Problem
    if (selfCheckListener) {
      selfCheckListener.addTarget();
    }

    // 'c' (non-exam, correct)
    // 'wr' (non-exam, wrong)
    // 'u' (non-exam, untried)
    // 'ready' (exam, has valid entry)
    // 'invalid' (exam, does not have valid entry)

    let status;

    // starting with MultiSelectGetter, created fall, 2022,
    // server signals AttemptGetter status with human-readable status fields 'correct' or 'validAttempt'
    // instead of prior overloaded 's' (status)
    if (
      attemptGetterJson.correct !== undefined ||
      attemptGetterJson.validAttempt !== undefined
    ) {
      // not a test, so we'll display whether this getter has been marked correct or wrong ('u' means never validly submitted)
      if (attemptGetterJson.correct !== undefined) {
        status =
          attemptGetterJson.correct === 'correct'
            ? 'c'
            : attemptGetterJson.correct === 'wrong'
            ? 'wr'
            : 'u';
      }
      // it's an exam: just mark whether the problem is ready to be handed in (has valid attempt)
      // ('u' means not known to be ready for grading, possibly invalid or possibly not submitted in current form)
      else {
        status = attemptGetterJson.validAttempt === true ? 'ready' : 'invalid';
      }
    } else {
      if (attemptGetterJson.s == 'c') status = 'c';
      if (attemptGetterJson.s == 'wr') status = 'wr';
      if (!isTesting() && attemptGetterJson.s == 'u') status = 'u';
      if (attemptGetterJson.s == 'ready') status = 'ready';
      if (attemptGetterJson.s == 'invalid') status = 'invalid';
      if (isTesting() && !status) status = 'invalid';
    }

    if (attemptGetterJson.buttons) {
      buttons[attemptGetterJson.agId + ''] = attemptGetterJson.buttons;
    }

    var getterType = attemptGetterJson.t;
    var getter;

    if (getterType === 'normal' || getterType === 'n') {
      var lines = [];

      if (attemptGetterJson.lines) {
        for (var a = 0; a < attemptGetterJson.lines.length; a++) {
          lines.push(toLine(attemptGetterJson.lines[a].xml, 3, 0));
        }
      } else {
        lines.push(getLine(x + 3, 0));
      }

      for (var a = 0; a < lines.length; a++) {
        lines[a].balanceEmptyTexts();
        lines[a].buildSizeRecursive();
        lines[a].setLineOfChildren();
        lines[a].lockViewportDim = false;
      }

      getter = getNormalGetter({
        x: x,
        y: 0,
        jsonFromServer: attemptGetterJson,
        lines: lines,
        row: row,
        agId: attemptGetterJson.agId,
      });

      keyboard.setShowBarred(attemptGetterJson.allowBarred);
      keyboard.setShowDivision(
        attemptGetterJson.allowMultiplicationAndDivisionSymbols
      );
      keyboard.setShowDollarSymbol(attemptGetterJson.showDollarSymbol);
    } else {
      onlyNormalGetters = false;

      if (getterType === 'valueTable') {
        getter = getValueTableGetter(x, 0, attemptGetterJson, row);
      } else if (getterType === 'numberLine' || getterType === 'nag') {
        getter = getNumberLineGetter(x, 0, attemptGetterJson, row);
      } else if (getterType === 'grid' || getterType === 'g') {
        getter = getGridGetter(x, 0, attemptGetterJson);
      } else if (getterType === 'multipleChoice' || getterType === 'm') {
        getter = getMultiChoiceGetter(x, 0, attemptGetterJson);
      } else if (getterType === 'multiSelect') {
        getter = getMultiSelectGetter(x, 0, attemptGetterJson);
      } else if (getterType === 'matchTableGrid') {
        getter = getMatchTableGrid(x, 0, attemptGetterJson);
      } else if (getterType === 'dragDrop') {
        getter = new DragDropGetter(
          getProblemJSDependencies(),
          attemptGetterJson,
          status,
          changedMaybe,
          x
        );
        dragController = getter;
      } else if (getterType === 'hotSpot') {
        getter = new HotSpotGetter(
          getProblemJSDependencies(),
          attemptGetterJson,
          status,
          changedMaybe,
          x
        );
      } else if (getterType === 'fractionShade') {
        getter = new FractionShadeGetter(
          getProblemJSDependencies(),
          attemptGetterJson,
          status,
          changedMaybe,
          x
        );
      }
    }
    // Paused as of March, 2023
    // else if (getterType === 'fractionModel') {
    //   getter = new FractionModelGetter(
    //     getProblemJSDependencies(),
    //     attemptGetterJson,
    //     status,
    //     changedMaybe,
    //     x
    //   );
    // }

    // set the letters to display on the touch keyboard if the getter's toJSON method has sent them
    if (attemptGetterJson.letters) {
      getter.letters = attemptGetterJson.letters;
    }

    attemptGetters.set(attemptGetterJson.agId, getter);

    // inlineChoice needs special handling because it must be added to
    // the attemptGetters map before it can trigger building its UI
    if (getterType === 'inlineChoice') {
      getter = new InlineChoiceGetter(
        getProblemJSDependencies(),
        attemptGetterJson,
        status,
        changedMaybe,
        x
      );

      attemptGetters.set(attemptGetterJson.agId, getter);

      getter.buildUI(
        getSubmitButton(
          getter,
          paintCanvas,
          submitAttempt,
          getThemeColor,
          'inlineChoice'
        )
      );
    }

    getter.setStatus(status);

    return {
      ag: getter,
      w: getter.viewportW,
      h: getter.viewportH,
    };
  };

  /* JSON DTO
   * {
   *   "correct": "correct" | "wrong" | "untried" // result of prior submission, 'untried' if not test and never had valid attempt
   *   "validAttempt": true | false // whether current state is 'turn-in-able' for test, only provided if test
   *   "t": "matchTableGrid"
   *   "prompt": string with directions for student
   *   "missingChoiceInvalid": string with message for invalid if student has not made a choice in one or more rows
   *   "agId": #
   *   "headers" : [strings] // first row after prompt
   *   "rows" : [ // not including header row
   *     {
   *       "display" : first column display json here
   *       "boxes" : [
   *         {
   *           "state" : "checked" | "unchecked",
   *           "correct": true | false, // result of prior submission, not provided if test
   *         }
   *       ]
   *     }
   *   ]
   * }
   */
  var getMatchTableGrid = function (x, y, jsonFromServer) {
    // Separate borders and text by at least margin
    const MARGIN = 5;
    const VERTICAL_ROW_MARGIN = MARGIN * 2;
    const CHECKBOX_HEIGHT = 25;
    const MARGIN_UNDER_LAST_OPTION_PANEL = 15;

    // horizontal
    const MARGIN_BETWEEN_GETTER_AND_CHOICE_ZONE = 5;
    // vertical
    const MARGIN_BETWEEN_CHOICE_ZONES = 10;
    const CHOICE_ZONE_WIDTH =
      NARROW_PROBLEM_WIDTH - 2 * MARGIN_BETWEEN_GETTER_AND_CHOICE_ZONE;
    const CHOICE_ZONE_BORDER_COLOR = 'lightgray';
    const GETTER_BACKGROUND = '#707070';

    const MIN_ROW_HEIGHT = CHECKBOX_HEIGHT + 2 * VERTICAL_ROW_MARGIN;
    const MIN_OPTION_WIDTH = CHECKBOX_HEIGHT + 2 * MARGIN;

    const WIDE_HEADER_FONT = 'bold ' + lineFontSize + 'px ' + lineFont;
    const narrowHeaderFontSize = 1.125 * lineFontSize;
    const NARROW_HEADER_FONT =
      'bold ' + narrowHeaderFontSize + 'px ' + lineFont;

    var matchTableGrid = getBasicObject(x, y);

    matchTableGrid.args = jsonFromServer;
    matchTableGrid.agId = jsonFromServer.agId;
    // gmmName is used for convenience when debugging
    matchTableGrid.gmmName = 'matchTableGrid';
    matchTableGrid.t = jsonFromServer.t;
    matchTableGrid.headers = jsonFromServer.headers;
    matchTableGrid.rows = jsonFromServer.rows;

    matchTableGrid.getJsonFromServer = function () {
      return matchTableGrid.args;
    };

    // track minimum cell dimensions as we create ui elements
    var columnWidths = [];
    var rowHeights = [];

    var prompt = getStaticPanelFromText(
      jsonFromServer.prompt,
      undefined,
      buildTTSDTO(jsonFromServer.prompt)
    );

    // vertical spacer between bottom of prompt and top border of header row
    prompt.viewportH += MARGIN;

    matchTableGrid.add(prompt);

    // ************** FULL layout ******************

    if (!isNarrow()) {
      rowHeights[0] = MIN_ROW_HEIGHT;

      var table = getBasicObject();
      var tableWrapperForReadAloud = getBasicObject();
      var headerRow = getBasicObject();

      // Build one row of cells containing column headers.
      // Header horizontal positioning occurs later, after column widths are determined.
      matchTableGrid.headers.forEach((headerJson, headerIndex) => {
        // first column header is bold and bigger (matching appearance in narrow layout)
        var header = getStaticPanelFromText(
          headerJson,
          WIDE_HEADER_FONT,
          buildTTSDTO(headerJson),
          tableWrapperForReadAloud
        );

        // set tentative values for column widths
        if (headerIndex == 0) {
          columnWidths[headerIndex] = header.viewportW + 2 * MARGIN;
        } else {
          columnWidths[headerIndex] = Math.max(
            header.viewportW + 2 * MARGIN,
            MIN_OPTION_WIDTH
          );
        }

        // probe to see if this header forces row's height to increase
        rowHeights[0] = Math.max(
          rowHeights[0],
          header.viewportH + 2 * VERTICAL_ROW_MARGIN
        );

        headerRow.add(header);
      });

      headerRow.viewportH = rowHeights[0];
      headerRow.centerVertically();

      table.add(headerRow);

      var optionRowY = rowHeights[0];

      // Build series of rows, each containing a display and checkboxes
      matchTableGrid.rows.forEach((rowJson, rowIndex) => {
        var row = getMatchTableGridOptionGroup();

        var display = getStaticPanel(
          rowJson.display,
          0,
          0,
          undefined,
          tableWrapperForReadAloud
        );

        // probe to see if this display forces first column's width to increase
        columnWidths[0] = Math.max(
          columnWidths[0],
          display.viewportW + 2 * MARGIN
        );
        // store height for row
        rowHeights[rowIndex + 1] = Math.max(
          display.viewportH + 2 * VERTICAL_ROW_MARGIN,
          MIN_ROW_HEIGHT
        );

        row.add(display);

        // Build checkboxes on 'holders' for each option
        rowJson.boxes.forEach((boxJson, boxIndex) => {
          var boxHolder = getMatchTableGridBoxHolder({
            matchTableGridGetter: matchTableGrid,
            optionGroup: row,
            rowJson,
            boxIndex,
            // boxHolder sized to fill entire cell -- so clicking anywhere in cell checks box
            width: columnWidths[boxIndex + 1],
            height: rowHeights[rowIndex + 1],
          });

          row.add(boxHolder);
        });

        row.sizeMeToLowestAndRightmostChildren();
        row.centerVertically();
        row.viewportY = optionRowY;
        optionRowY += rowHeights[rowIndex + 1];

        table.add(row);
      });

      // set horizontal positioning now that columnWidths are determined
      table.children.forEach((row, rowIndex) => {
        var x = 0;

        row.children.forEach((cell, columnIndex) => {
          cell.viewportX = x;

          // display column and header row cells need margin
          // box cell contents have margin built in
          if (columnIndex === 0 || rowIndex === 0) {
            cell.viewportX += MARGIN;
          }

          x += columnWidths[columnIndex];
        });
        row.sizeMeToLowestAndRightmostChildren();
      });

      table.sizeMeToLowestAndRightmostChildren();

      if (isReadAloud()) {
        tableWrapperForReadAloud.viewportY = prompt.viewportH;
        tableWrapperForReadAloud.add(table);
        tableWrapperForReadAloud.layoutChildrenLR();
        tableWrapperForReadAloud.sizeMeToLowestAndRightmostChildren();
        matchTableGrid.add(tableWrapperForReadAloud);
      } else {
        table.viewportY = prompt.viewportH;
        matchTableGrid.add(table);
      }

      matchTableGrid.centerHorizontally();
      matchTableGrid.sizeMeToLowestAndRightmostChildren();

      // *************** PAINT GRID (full layout) *******************

      table.paintPost = function (ctx) {
        ctx.save();
        ctx.beginPath();

        // top border (between prompt and headers)
        ctx.moveTo(0, 0);
        ctx.lineTo(table.viewportW, 0);

        // left border
        ctx.moveTo(0, 0);
        ctx.lineTo(0, table.viewportH);

        // vertical dividers and right border
        var x = 0;

        columnWidths.forEach(columnWidth => {
          x += columnWidth;
          ctx.moveTo(x, 0);
          ctx.lineTo(x, table.viewportH);
        });

        // horizontal dividers bottom border
        var y = 0;

        rowHeights.forEach(rowHeight => {
          y += rowHeight;
          ctx.moveTo(0, y);
          ctx.lineTo(table.viewportW, y);
        });

        ctx.stroke();
        ctx.restore();
      };

      matchTableGrid.getOptionRows = function () {
        return matchTableGrid.getOptionRowsHelper(table);
      };
    }

    // ************** NARROW layout ***************
    else {
      prompt.viewportFill = 'white';
      prompt.viewportW = NARROW_PROBLEM_WIDTH;
      // use entire width of narrow canvas for getter
      matchTableGrid.viewportW = NARROW_PROBLEM_WIDTH;
      matchTableGrid.viewportFill = GETTER_BACKGROUND;

      // First cell of headers from 'full'
      var titleJson;
      // Headers from 'full' columns that represent options
      var optionsJson = [];

      jsonFromServer.headers.forEach((headerJson, headerIndex) => {
        if (headerIndex === 0) {
          titleJson = headerJson;
        } else {
          optionsJson.push(headerJson);
        }
      });

      // Each row from 'full' layout becomes a vertically arranged 'choice zone.'
      // A 'choice zone' has: title, display, and rows of options.
      var zoneY = prompt.viewportH + MARGIN_BETWEEN_CHOICE_ZONES;

      jsonFromServer.rows.forEach((jsonRow, choiceZoneIndex) => {
        // collection of vertically arranged rows for a single display and its options
        var choiceZone = getBasicObject();

        choiceZone.viewportFill = 'white';
        choiceZone.viewportX = MARGIN_BETWEEN_GETTER_AND_CHOICE_ZONE;

        var titleRow = getBasicObject();

        titleRow.viewportW = CHOICE_ZONE_WIDTH;

        var title = titleJson + ' ' + (1 + choiceZoneIndex);
        var titleRowText = getStaticPanelFromText(
          title,
          NARROW_HEADER_FONT,
          buildTTSDTO(title),
          titleRow
        );

        titleRow.add(titleRowText);

        titleRow.layoutChildrenLR();
        titleRow.sizeMeToLowestAndRightmostChildren();
        titleRow.viewportH = titleRow.viewportH + 2 * VERTICAL_ROW_MARGIN;
        titleRow.viewportX = MARGIN;
        titleRow.centerVertically();

        choiceZone.add(titleRow);

        var displayRow = getBasicObject();

        var display = getStaticPanel(
          jsonRow.display,
          0,
          0,
          undefined,
          titleRow
        );

        displayRow.add(display);

        displayRow.viewportY = titleRow.gB();
        displayRow.viewportW = CHOICE_ZONE_WIDTH;
        displayRow.viewportH = display.viewportH + 2 * VERTICAL_ROW_MARGIN;
        displayRow.centerVertically();
        displayRow.centerHorizontally();

        choiceZone.add(displayRow);

        // Bundle options as if in 'full' width to communicate
        // between checkboxes during selection events (mouse/touch on a checkbox).
        var optionGroup = getMatchTableGridOptionGroup();

        var nextY = displayRow.gB();

        jsonRow.boxes.forEach((boxJson, boxIndex) => {
          var optionRow = getBasicObject();

          optionRow.viewportW = CHOICE_ZONE_WIDTH;

          var text = getStaticPanelFromText(
            optionsJson[boxIndex],
            null,
            buildTTSDTO(optionsJson[boxIndex]),
            titleRow
          );

          text.viewportX = MARGIN;

          optionRow.add(text);

          var boxHolder = getMatchTableGridBoxHolder({
            matchTableGridGetter: matchTableGrid,
            optionGroup,
            rowJson: jsonRow,
            boxIndex,
            width: CHECKBOX_HEIGHT,
            height: CHECKBOX_HEIGHT,
          });

          boxHolder.viewportX =
            optionRow.viewportW - boxHolder.viewportW - MARGIN;

          optionRow.add(boxHolder);

          optionRow.viewportH =
            Math.max(text.viewportH, boxHolder.viewportH) +
            2 * VERTICAL_ROW_MARGIN;
          optionRow.centerVertically();
          optionRow.viewportY = nextY;
          nextY += optionRow.viewportH;

          choiceZone.add(optionRow);
        });

        // Match expectations of data structure used for 'full' layout so
        // that update method still works.
        choiceZone.boxes = optionGroup.boxes;

        choiceZone.viewportY = zoneY;
        choiceZone.sizeMeToLowestAndRightmostChildren();
        choiceZone.viewportW = CHOICE_ZONE_WIDTH;
        zoneY += choiceZone.viewportH + MARGIN_BETWEEN_CHOICE_ZONES;

        matchTableGrid.add(choiceZone);

        // *************** Paint CHOICE_ZONE grid (narrow layout) *******************

        choiceZone.paintMe = function (ctx) {
          ctx.save();
          ctx.beginPath();

          ctx.strokeStyle = CHOICE_ZONE_BORDER_COLOR;

          // horizontal divider under each child
          var y = 0;
          var first = true;

          choiceZone.children.forEach(child => {
            y += child.viewportH;

            if (!first) {
              ctx.moveTo(0, y);
              ctx.lineTo(choiceZone.viewportW, y);
            }

            first = false;
          });

          ctx.stroke();
          ctx.restore();
        };
      });

      matchTableGrid.getOptionRows = function () {
        return matchTableGrid.getOptionRowsHelper(matchTableGrid);
      };
    }

    // filter out non option rows (headers, submit button, propmt)
    matchTableGrid.getOptionRowsHelper = function (rowHolder) {
      var ret = [];

      rowHolder.children.forEach(child => {
        if (child.boxes) ret.push(child);
      });

      return ret;
    };

    matchTableGrid.sizeMeToLowestAndRightmostChildren();

    // ************** SUBMIT BUTTON ******************

    var submitButton = getSubmitButton(
      matchTableGrid,
      paintCanvas,
      submitAttempt,
      getThemeColor,
      'matchTableGrid'
    );

    submitButton.viewportX =
      (matchTableGrid.viewportW - submitButton.viewportW) / 2;
    submitButton.viewportY =
      matchTableGrid.viewportH + MARGIN_UNDER_LAST_OPTION_PANEL;

    matchTableGrid.add(submitButton);

    // Recompute getter height now that submit button has been added.
    matchTableGrid.viewportH +=
      submitButton.viewportH + MARGIN_UNDER_LAST_OPTION_PANEL;

    // A small vertical dark gray continuation under the submit button when in narrow
    if (isNarrow()) matchTableGrid.viewportH += MARGIN_BETWEEN_CHOICE_ZONES;

    // ************ UNIQUE MatchTableGrid METHODS ************

    // Helps evaluate whether submitAttempt should send state to server.
    // If invalid, returns string with message to user.
    matchTableGrid.validateAttempt = function () {
      var invalidMessage;

      // each row should have a selected box
      matchTableGrid.getOptionRows().forEach(optionRow => {
        const isSelected = optionRow.boxes.some(box => box.isSelected());

        if (!isSelected) {
          // pull in localized invalid message
          invalidMessage = jsonFromServer.missingChoiceInvalid;
        }
      });

      return invalidMessage;
    };

    // Package current checked/unchecked state of boxes using DTO for MatchTableGrid.
    // Used to send submission to server.
    matchTableGrid.serializeAttempt = function () {
      if (!isTesting()) {
        var errorMessage = matchTableGrid.validateAttempt();

        if (errorMessage) {
          getProblemContext().addStackableDialog({
            msg: errorMessage,
            top: 'Invalid',
          });

          return;
        }
      }

      var ret = {};

      ret.rows = [];
      matchTableGrid.args.rows.forEach(rowJson => {
        ret.rows.push({ boxes: rowJson.boxes });
      });

      return ret;
    };

    // Apply checkbox state sent from server. For example, mark previously
    // submitted options incorrect or correct and disable them (if not testing).
    matchTableGrid.update = function (updatedJson) {
      // each DTO.row has field 'boxes' than can replace local version
      matchTableGrid.args.rows.forEach((jsonRow, rowIndex) => {
        jsonRow.boxes = updatedJson.rows[rowIndex].boxes;
      });

      // each ui box needs to call setSelected and setCorrectlySubmitted
      // based on inbound json box state
      var rowIndex = 0;

      matchTableGrid.getOptionRows().forEach(optionRow => {
        var jsonBoxes = updatedJson.rows[rowIndex].boxes;

        jsonBoxes.forEach((jsonBox, boxIndex) => {
          var uiBox = optionRow.boxes[boxIndex];

          uiBox.setSelected(jsonBox.state === 'checked');
          uiBox.setCorrectlySubmitted(jsonBox.correct);
        });

        rowIndex++;
      });
    };

    // Apply box state originally received from server
    matchTableGrid.update(jsonFromServer);

    // ************ REQUIRED GETTER METHODS *************

    // All getters are expected to support this function
    matchTableGrid.setStatus = function (status) {
      matchTableGrid.st = status;
      // Disable getter if status is correct ('c')
      // Note: 'e' means enabled
      matchTableGrid.e = status !== 'c';
    };

    // All getters are expected to implement this function
    matchTableGrid.loseFocus = function () {};

    // All getters are expected to implement this function
    matchTableGrid.grabFocus = function () {
      if (
        currentAttemptGetter &&
        currentAttemptGetter.gmmName == 'gridGetter'
      ) {
        currentAttemptGetter.g.setSelected(false);
      }

      if (focused) {
        focused.setFocused(false);
      }

      removeKeyboard();
      currentAttemptGetter = matchTableGrid;
    };

    // All getters are expected to support this function
    matchTableGrid.isEnabled = function () {
      return matchTableGrid.e;
    };

    return matchTableGrid;
  };

  var getMatchTableGridOptionGroup = function () {
    var optionGroup = getBasicObject();

    optionGroup.boxes = [];

    optionGroup.deselectAll = function () {
      optionGroup.boxes.forEach(checkbox => {
        checkbox.setSelected(false);
      });
    };

    // disable row if it has been correctly answered (and not testing)
    optionGroup.isEnabled = function () {
      return (
        isTesting() ||
        !optionGroup.boxes.some(box => box.hasBeenCorrectlySubmitted())
      );
    };

    return optionGroup;
  };

  var getMatchTableGridBoxHolder = function ({
    matchTableGridGetter,
    optionGroup,
    rowJson,
    boxIndex,
    width,
    height,
  }) {
    var boxHolder = getBasicObject();
    var checkbox = getMultiSelectCheckbox();

    checkbox.getter = matchTableGridGetter;

    // override setSelected's default behavior (default supports MultiSelect)
    checkbox.setSelected = function (b) {
      // update ui element
      checkbox.selected = b;
      // update state originally received from server
      rowJson.boxes[boxIndex].state = b ? 'checked' : 'unchecked';
    };

    optionGroup.getter = matchTableGridGetter;
    optionGroup.boxes.push(checkbox);

    var mouseDownResponse = function () {
      // if the getter is not enabled, ignore mouse down
      if (!matchTableGridGetter.isEnabled()) return true;
      // if the checkbox is marked correct or wrong from data from server, we disable it
      if (!checkbox.isEnabled()) return true;
      // If the group of options does not permit further user interaction, ignore mouse down.
      // (essentially, if the user has submitted a correct answer and this is not a test)
      if (!optionGroup.isEnabled()) return true;

      changedMaybe(matchTableGridGetter.agId);
      optionGroup.deselectAll();
      checkbox.setSelected(true);
      matchTableGridGetter.grabFocus();

      return true;
    };

    // Entire holder is mouse-sensitive. In 'full', this is size of cell, but in 'Narrow', this is
    // just sized to the checkbox to prevent attempts to vertically scroll from clicking box.
    boxHolder.mouseDownResponse = mouseDownResponse;
    boxHolder.add(checkbox);
    boxHolder.setAllDim(width, height);
    boxHolder.centerHorizontally();
    boxHolder.centerVertically();

    return boxHolder;
  };

  var repositionProblemRows = function () {
    if (!problemPanel || problemPanel.children.length === 0) {
      return;
    }

    var y = 0;
    var w = 0;
    var lastGetter = null;

    for (var r = 0; r < problemPanel.children.length; r++) {
      var h = 0;
      var lineA = false;
      var child = problemPanel.children[r];

      if (child.gmmName === 'lineAfter') {
        h = lineAfterH;
        lineA = true;
      } else if ((keyboardRow && r != keyboardRow) || !keyboardRow) {
        for (var c = 0; c < child.children.length; c++) {
          h = Math.max(h, child.children[c].viewportH);
        }

        // Check if getter and store last instance
        if (child.getter) lastGetter = child;
      } else if (keyboardRow && r == keyboardRow) {
        h = keyboard.viewportH;
      }

      child.viewportH = h;
      child.viewportY = y;

      y += h + rowVertSpacer;
    }

    w = problemPanel.viewportW;

    // Set height, text is middle vertical aligned so add an additional half height for final row if text
    var last = problemPanel.children[problemPanel.children.length - 1];
    var height = y;

    if (isStudent) {
      height += hasText(last) ? last.viewportH / 2 : 0;
      // Check if the panel is vertically shifted
      height += problemPanel.viewportY ?? 0;
    }

    // Add additional margin based on the size of the last attempt getter so that it can be scrolled vertically for centering
    if (lastGetter)
      height += Math.min(EXTRA_PIXELS_UNDER_PROBLEM, lastGetter.viewportH / 2);

    problemPanel.height = height;
    problemPanel.viewportH = height;

    resizeCanvas($canvas.get(0), canvasW, height);
    world.height = height;
    world.viewportH = height;

    resetLeftSideSlideOfProblemPanel();

    for (var r = 0; r < problemPanel.children.length; r++) {
      if (problemPanel.children[r].gmmName === 'lineAfter') {
        problemPanel.children[r].setAllDim(w, lineAfterH);
        problemPanel.children[r].children[0].setAllDim(w, lineAfterH);
      }
    }

    problemPanel.centerHorizontally();

    if (keyboard.getter) {
      var getterRow = problemPanel.children[keyboardRow - 1];
      var keyTar = keyboard.getter.keyboardX;

      if (!keyTar) {
        var getterX = keyboard.getter.parentForKeyboardX
          ? keyboard.getter.parentForKeyboardX.viewportX
          : keyboard.getter.viewportX;

        if (keyboard.getter.parentForKeyboardX) {
          if (
            keyboard.getter.parentForKeyboardX.gmmName === 'answerPlusSupplier'
          ) {
            var children = keyboard.getter.parentForKeyboardX.children;

            for (var z = 0; z < children.length; z++) {
              if (children[z].gmmName === 'normalGetter') {
                getterX += children[z].viewportX;
              }
            }
          }
        }

        var midX =
          getterRow.viewportX + getterX + keyboard.getter.viewportW / 2;
        var useX = midX - keyboardW / 2;

        keyTar = Math.max(0, useX);
        if (keyTar + keyboardW > canvasW) keyTar = canvasW - keyboardW;
      } else {
        keyTar += getterRow.viewportX;
      }

      keyboard.viewportX = keyTar;

      var overRight =
        keyboard.viewportW +
        keyboard.viewportX +
        problemPanel.viewportSlideX -
        (problemPanel.viewportW - 2 * problemPanel.viewportMargin);

      if (overRight > 0) {
        problemPanel.slide(-overRight, 0);
      }

      var overLeft = keyboard.viewportX + problemPanel.viewportSlideX;

      if (overLeft < 0) {
        problemPanel.slide(-overLeft, 0);
      }

      var overTop =
        keyboard.viewportY +
        keyboard.viewportH +
        problemPanel.viewportSlideY -
        (problemPanel.viewportH - 2 * problemPanel.viewportMargin);

      if (overTop > 0) {
        problemPanel.slide(0, -overTop);
      }
    }

    if (!isStudent) {
      problemPanel.viewportX = 0;
      problemPanel.viewportY = 0;

      resizeCanvas($canvas.get(0), w, height);
      world.height = $canvas[0].height - 5;
      world.viewportH = $canvas[0].height - 5;
      world.width = $canvas[0].width;
      world.viewportW = $canvas[0].width;
    }

    paintCanvas();
  };

  var resetLeftSideSlideOfProblemPanel = function () {
    var w = problemPanel.width;

    problemPanel.viewportX = (canvasW - w) / 2;

    if (!isStudent) problemPanel.viewportX = 0;
  };

  var insertKeyboard = function (row, getter, agId) {
    if (keyboardRow) {
      problemPanel.children.splice(keyboardRow, 1);
    }

    keyboard.agId = agId;
    keyboard.submitter.cancelAnimation();
    setKeyboardBottom('numbers');
    problemPanel.children.splice(row + 1, 0, keyboard);
    keyboardRow = row + 1;
    keyboard.getter = getter;

    applySelectedButton(getter);

    repositionProblemRows();
  };

  var removeKeyboard = function () {
    if (!keyboardRow) {
      return;
    }

    problemPanel.children.splice(keyboardRow, 1);

    keyboardRow = undefined;
    keyboard.agId = -1;
    keyboard.getter = undefined;

    repositionProblemRows();
  };

  var getNumberLine = function (x, y, args, nlg) {
    var nl = getBasicObject(x, y);

    nl.nlg = nlg;
    nl.gmmName = 'numberLine';

    if (!args) {
      args = {};
      args.width = 300;
      args.height = 60;
      args.mode = '3';
    }

    args.ppb = args.ppb || 20;

    nl.args = args;

    nl.setAllDim(args.width, args.height);

    nl.paintMe = function (ctx) {
      ctx.save();
      ctx.beginPath();

      ctx.moveTo(0, 15);
      ctx.lineTo(args.width, 15);
      arrow(ctx, 0, 15, args.width, 15, 'small');
      arrow(ctx, args.width, 15, 0, 15, 'small');
      ctx.stroke();
      ctx.beginPath();

      if (nl.args.mode === '1') {
        ctx.moveTo(args.width / 2, 7);
        ctx.lineTo(args.width / 2, 23);
      } else if (nl.args.mode === '2') {
        var off = args.ppb * 3;
        var mid = args.width / 2;

        ctx.moveTo(mid - off, 7);
        ctx.lineTo(mid - off, 23);
        ctx.moveTo(mid + off, 7);
        ctx.lineTo(mid + off, 23);
      } else {
        var mid = args.width / 2;

        ctx.moveTo(mid, 4);
        ctx.lineTo(mid, 26);
        ctx.lineWidth = 3;
        ctx.stroke();
        ctx.lineWidth = 1;
        ctx.beginPath();
        var t = Math.floor((mid - args.ppb) / args.ppb);

        for (var a = 1; a <= t; a++) {
          var x = mid - a * args.ppb;

          ctx.moveTo(x, 9);
          ctx.lineTo(x, 21);
          var x = mid + a * args.ppb;

          ctx.moveTo(x, 9);
          ctx.lineTo(x, 21);
        }
      }

      ctx.stroke();

      ctx.restore();

      if (nl.args.middleStamp) {
        nl.args.middleStamp.paint(ctx);
      }

      if (nl.args.leftStamp) {
        nl.args.leftStamp.paint(ctx);
      }

      if (nl.args.rightStamp) {
        nl.args.rightStamp.paint(ctx);
      }

      ctx.save();

      if (nl.args.mode === 'allCentered' && nl.args.scale) {
        if (!nl.scaleStamp) {
          nl.scaleStamp = getText();
        }

        var mid = args.width / 2;
        var t = Math.floor((mid - args.ppb) / args.ppb);
        var x = mid - t * args.ppb;
        var ss = -t * nl.args.scale;

        ctx.translate(x, 26);

        for (var a = 0; a <= 2 * t; a++) {
          nl.scaleStamp.text = ss + a * nl.args.scale;
          nl.scaleStamp.sizeMe();
          ctx.translate(-nl.scaleStamp.viewportW / 2, 0);

          if (!nl.args.skip || a % 2 === 0) {
            nl.scaleStamp.paint(ctx);
          }

          ctx.translate(nl.scaleStamp.viewportW / 2 + args.ppb, 0);
        }
      }

      ctx.restore();

      if (nl.args.objs) {
        ctx.save();
        ctx.translate(0, 15);

        for (var a = 0; a < nl.args.objs.length; a++) {
          var obj = nl.args.objs[a];
          var col = 'magenta';

          if (obj.t === 'Ray') {
            ctx.beginPath();
            ctx.arc(obj.cx, 0, 5, 0, 2 * Math.PI, false);

            if (obj.fill) {
              ctx.fillStyle = col;
              ctx.fill();
            }

            ctx.lineWidth = 3;
            ctx.strokeStyle = col;
            ctx.stroke();

            if (obj.dir) {
              var x1, x2;

              if (obj.dir === 'l') {
                x1 = obj.cx - 6;
                x2 = 10;
              } else {
                x1 = obj.cx + 6;
                x2 = nl.args.width - 10;
              }

              ctx.lineWidth = 4;
              ctx.beginPath();
              ctx.moveTo(x1, 0);
              ctx.lineTo(x2, 0);
              arrow(ctx, x1, 0, x2, 0, 'smaller');
              ctx.stroke();
            }
          } else if (obj.t === 'Segment') {
            ctx.beginPath();
            ctx.arc(obj.first.cx, 0, 6, 0, 2 * Math.PI, false);

            if (obj.first.fill) {
              ctx.fillStyle = col;
              ctx.fill();
            }

            ctx.lineWidth = 3;
            ctx.strokeStyle = col;
            ctx.stroke();

            if (obj.second) {
              ctx.beginPath();
              ctx.arc(obj.second.cx, 0, 6, 0, 2 * Math.PI, false);

              if (obj.second.fill) {
                ctx.fillStyle = col;
                ctx.fill();
              }

              ctx.lineWidth = 3;
              ctx.strokeStyle = col;
              ctx.stroke();

              var x1 = Math.min(obj.first.cx, obj.second.cx) + 6;
              var x2 = Math.max(obj.first.cx, obj.second.cx) - 6;

              ctx.lineWidth = 4;
              ctx.beginPath();
              ctx.moveTo(x1, 0);
              ctx.lineTo(x2, 0);
              ctx.stroke();
            }
          }
        }

        ctx.restore();
      }
    };

    nl.paintPostClip = function (ctx) {
      paintStatus(nl, ctx, true);
    };

    nl.getRealX = function (x) {
      return (x - nl.args.width / 2) / nl.args.ppb;
    };

    nl.setStatus = function (st) {
      nl.st = st;
    };

    nl.getNearestX = function (x) {
      var hf = nl.args.width / 2;

      if (nl.args.mode === '1') {
        return [hf, false];
      } else if (nl.args.mode === '2') {
        if (x < nl.args.width / 2) {
          return [hf - nl.args.ppb * 3, false];
        } else return [hf + nl.args.ppb * 3, true];
      }

      var fromO = Math.abs(nl.args.width / 2 - x);
      var xtra = fromO % nl.args.ppb;
      var ticks = Math.floor(fromO / nl.args.ppb);

      ticks += xtra < nl.args.ppb / 2 ? 0 : 1;
      var v =
        nl.args.width / 2 +
        (x >= nl.args.width / 2 ? ticks * nl.args.ppb : -ticks * nl.args.ppb);

      return [v, x >= nl.args.width / 2];
    };

    nl.addedGetters = false;

    nl.setGetter = function (pos, xml, row, agId) {
      var side = 56;
      var line = toLine(xml);

      line.lockViewportDim = false;

      var off = nl.args.ppb * 3;
      var made = getNormalGetter({
        lines: [line],
        row: row,
        agId: agId,
        centerMe: true,
      });

      if (pos === 'left') {
        made.viewportX = nl.args.width / 2 - off;
        line.nlZone = 'leftXMLG';
        made.keyboardX = nl.viewportW / 2 - keyboardW / 2;
      } else if (pos === 'right') {
        made.viewportX = nl.args.width / 2 + off;
        line.nlZone = 'rightXMLG';
        made.keyboardX = nl.viewportW / 2 - keyboardW / 2;
      } else {
        made.viewportX = nl.args.width / 2;
        line.nlZone = 'midXMLG';
      }

      made.setAllDim(side, side);
      made.viewportX -= side / 2;
      made.center = true;
      made.repositionLines();
      made.nlg = nlg;
      // default getter "editable" property to true...
      // ...will be set to false after nlg status is set to 'c'
      made.e = true;

      if (!nl.addedGetters) {
        nl.getters = getBasicObject();
        nl.getters.viewportY = nl.args.height;
        nl.getters.gmmName = 'nlGetters';
        nl.add(nl.getters);

        nl.addedGetters = true;
      }

      nl.getters.add(made);
      nl.getters.sizeMeToFitChildren();
      nl.resetHeight();

      return line;
    };

    nl.setStamp = function (pos, xml) {
      var line = toLine(xml);

      line.e = false;
      line.viewportY = 26;
      var third = nl.args.width / 3;
      var half = line.viewportW / 2;

      if (pos === 'left') {
        line.viewportX = third - half;
        nl.args.leftStamp = line;
      } else if (pos === 'right') {
        line.viewportX = 2 * third - half;
        nl.args.rightStamp = line;
      } else {
        line.viewportX = nl.args.width / 2 - half;
        nl.args.middleStamp = line;
      }
    };

    nl.resetHeight = function () {
      var h = 26;
      var stampH = 0;

      if (nl.args.leftStamp) {
        stampH = Math.max(stampH, nl.args.leftStamp.viewportH);
      }

      if (nl.args.rightStamp) {
        stampH = Math.max(stampH, nl.args.rightStamp.viewportH);
      }

      if (nl.args.middleStamp) {
        stampH = Math.max(stampH, nl.args.middleStamp.viewportH);
      }

      h += stampH;
      nl.viewportH = h;
      nl.height = h;

      if (nl.getters) {
        nl.getters.viewportY = h;
        nl.viewportH += nl.getters.viewportH;
        nl.height += nl.getters.viewportH;
      }
    };

    nl.setMode = function (m, row, agId, buildingGetter, over) {
      if (nl.args.mode === m && !over) {
        return;
      }

      if (!buildingGetter) nl.nlg.clear(false);
      nl.args.mode = m;

      if (nl.args.useGetters) {
        if (nl.getters) {
          nl.getters.children = [];
        }

        var xml = '<t></t>';
        var focusMe;

        if (m === '1') {
          if (nl.args.midXMLG) {
            xml = nl.args.midXMLG;
          }

          focusMe = nl.setGetter('middle', xml, nl.nlg.row, nl.nlg.agId);
        } else {
          if (nl.args.rightXMLG) {
            xml = nl.args.rightXMLG;
          }

          var rf = nl.setGetter('right', xml, nl.nlg.row, nl.nlg.agId);

          if (!nl.switcher) {
            var switcher = getBasicObject();

            switcher.setAllDim(50, 40);
            switcher.animates = true;

            switcher.paintMe = function (ctx) {
              roundRect2(
                ctx,
                0,
                0,
                50,
                40,
                6,
                switcher.animated ? getThemeColor() : 'lightgray'
              );
              var w = 40;
              var h = 30;

              ctx.save();
              ctx.translate(5, 5);
              ctx.beginPath();
              ctx.strokeStyle = 'gray';
              ctx.lineWidth = 2;

              var iW = 0.3;
              var iH = 0.3;
              var i = 3;
              var i2 = 4;

              ctx.moveTo(i, 0.5 * h);
              ctx.lineTo(iW * w, h - i2);
              ctx.lineTo(iW * w, (1 - iH) * h);
              ctx.lineTo((1 - iW) * w, (1 - iH) * h);
              ctx.lineTo((1 - iW) * w, h - i2);
              ctx.lineTo(w - i, 0.5 * h);
              ctx.lineTo((1 - iW) * w, i2);
              ctx.lineTo((1 - iW) * w, iH * h);
              ctx.lineTo(iW * w, iH * h);
              ctx.lineTo(iW * w, i2);
              ctx.lineTo(i, 0.5 * h);
              ctx.closePath();

              ctx.stroke();
              ctx.restore();
            };

            switcher.mouseDownResponse = function () {
              switcher.animated = true;
              var temp = nl.args.leftXMLG;

              nl.args.leftXMLG = nl.args.rightXMLG;
              nl.args.rightXMLG = temp;
              var fme = nl.setMode('2', undefined, undefined, true, true);

              if (fme) {
                fme.grabFocus();
              }

              // true --> consume event, no mouse reaction by parents!
              return true;
            };

            switcher.viewportY =
              nl.getters.children[0].viewportH / 2 - switcher.viewportH / 2;
            switcher.viewportX = nl.args.width / 2 - switcher.viewportW / 2;
            nl.switcher = switcher;
          }

          nl.getters.add(nl.switcher);

          xml = '<t></t>';

          if (nl.args.leftXMLG) {
            xml = nl.args.leftXMLG;
          }

          var lf = nl.setGetter('left', xml, nl.nlg.row, nl.nlg.agId);

          focusMe = lf || rf;
        }

        if (nl.nlg && nl.nlg.resetHeight) {
          nl.nlg.resetHeight();
        }

        return focusMe;
      }
    };

    nl.addObj = function (obj) {
      if (!nl.args.objs) {
        nl.args.objs = [];
      }

      if (obj.t === 'Segment' || nl.args.mode !== '2') {
        nl.args.objs = [obj];
      } else {
        if (nl.args.objs.length > 1) {
          nl.args.objs.splice(0, 1);
        }

        nl.args.objs.push(obj);
      }
    };

    if (nl.args.line1) {
      nl.addObj(nl.args.line1);
      nl.args.line1 = undefined;
    }

    if (nl.args.line2) {
      nl.addObj(nl.args.line2);
      nl.args.line2 = undefined;
    }

    if (nl.args.midXMLS) {
      nl.setStamp('middle', nl.args.midXMLS);
    }

    nl.resetHeight();

    return nl;
  };

  var getNumberLineGetter = function (x, y, ag, row) {
    var nlg = getBasicObject(x, y);

    nlg.e = true;

    nlg.gmmName = 'numberLineGetter';
    nlg.agId = ag.agId;
    nlg.t = 'numberLine';
    nlg.row = row;
    nlg.args = ag;

    nlg.getJsonFromServer = function () {
      return nlg.nl.args;
    };

    nlg.insertAttemptIntoJSON = function () {
      var attempt = {};

      if (nlg.nl.args.objs) {
        var objs = [];

        for (var a = 0; a < nlg.nl.args.objs.length; a++) {
          var obj = nlg.nl.args.objs[a];
          var x = { t: obj.t };

          if (obj.t === 'Ray') {
            if (!obj.dir) {
              getProblemContext().addStackableDialog({
                msg:
                  "You're not finished with your ray. Click on either side of your point.",
                top: 'Incomplete ray',
              });

              return 'invalid';
            }

            x.cx = nlg.nl.getRealX(obj.cx);
            x.dir = obj.dir;
            x.mode = obj.fill ? 'rc' : 'ro';
          } else {
            if (!(obj.second && obj.first)) {
              getProblemContext().addStackableDialog({
                msg: "You're not finished with your segment.",
                top: 'Incomplete segment',
              });

              return 'invalid';
              // x.first = {cx:nlg.nl.getRealX(obj.first.cx),fill:obj.first.fill};
            } else {
              var ff = obj.first;
              var ss = obj.second;

              x.first = { cx: nlg.nl.getRealX(ff.cx), fill: ff.fill };
              x.second = { cx: nlg.nl.getRealX(ss.cx), fill: ss.fill };

              if (ff.fill && ss.fill) {
                x.mode = 'bothClosed';
              } else if (!ff.fill && !ss.fill) {
                x.mode = 'bothOpen';
              } else if (ff.fill) {
                var tmp = x.first;

                x.first = x.second;
                x.second = tmp;
              }
            }
          }

          objs.push(x);
        }

        attempt.objs = objs;
      }

      if (nlg.nl.args.mode === '1' || nlg.nl.args.mode === '2') {
        attempt.marks = parseInt(nlg.nl.args.mode);

        if (nlg.nl.getters) {
          if (attempt.marks == 1) {
            attempt.midXMLG = encodeText(nlg.nl.args.midXMLG);
            // xm = encodeText(xm);
          } else {
            if (nlg.nl.args.leftXMLG) {
              attempt.leftXMLG = encodeText(nlg.nl.args.leftXMLG);
            }

            if (nlg.nl.args.rightXMLG) {
              attempt.rightXMLG = encodeText(nlg.nl.args.rightXMLG);
            }
          }
        }
      }

      return attempt;
    };

    nlg.changedNag = function (changed) {
      if (!nlg.nl.getters) return;

      // internal tooling for CanvasCapture ignores response from server to update nag
      if (selfCheckListener) {
        return;
      }

      for (var a = 0; a < nlg.nl.getters.length; a++) {
        var l = nlg.nl.getters[a].lines[0];

        l.clear();
      }

      if (changed.midXMLG) {
        nlg.nl.args.leftXMLG = '<t></t>';
        nlg.nl.args.rightXMLG = '<t></t>';
        var decoded = decodeText(changed.midXMLG);

        if (!decoded) decoded = changed.midXMLG;
        nlg.nl.args.midXMLG = decoded;
        nlg.nl.setMode('1', undefined, undefined, false, true);
      } else {
        nlg.nl.args.midXMLG = '<t></t>';
        var decoded = decodeText(changed.leftXMLG);

        if (!decoded) decoded = changed.leftXMLG;
        nlg.nl.args.leftXMLG = decoded;
        var decoded = decodeText(changed.rightXMLG);

        if (!decoded) decoded = changed.rightXMLG;
        nlg.nl.args.rightXMLG = decoded;
        nlg.nl.setMode('2', undefined, undefined, false, true);
      }
    };

    nlg.grabFocus = function () {
      if (!nlg.e) {
        return;
      }

      if (focused) {
        focused.setFocused(false);
      }

      removeKeyboard();
      currentAttemptGetter = nlg;

      if (lettersButton != null) {
        lettersButton.staysAnimatedColor = true;
      }

      if (
        nlg.nl.getters &&
        nlg.nl.getters.children &&
        nlg.nl.getters.children.length > 0
      ) {
        var ch = nlg.nl.getters.children;

        if (ch.length == 1) {
          ch[0].grabFocus();
        } else {
          ch[2].grabFocus();
        }
      }
    };

    nlg.isLastFocused = function () {
      return nlg.nl.getters.children[0].isFocused();
    };

    nlg.tab = function (dir) {
      var ch = nlg.nl.getters.children;

      if (ch.length == 1) return;

      // Terrible, but it appears these come in reverse order
      // Index 2 is the first child, Index 1 is the arrow image, and Index 0 is the last input field
      ch[2].loseFocus();
      ch[0].grabFocus();
    };

    nlg.setStatus = function (st) {
      nlg.nl.setStatus(st);
      nlg.st = st;
      nlg.e = st !== 'c';

      // also update "editable" status of child getter(s)
      if (nlg.nl.getters) {
        var ch = nlg.nl.getters.children;

        ch[0].e = st !== 'c';

        if (ch.length > 1) {
          ch[2].e = st !== 'c';
        }
      }
    };

    nlg.clear = function (clearBoxes) {
      if (!nlg.e) {
        return;
      }

      if (!clearBoxes) {
        if (nlg.nl.args.objs && nlg.nl.args.objs.length > 0) changedMaybe();
        nlg.nl.args.objs = [];
        nlg.currentObj = undefined;
      }

      if (nlg.nl.getters && clearBoxes) {
        var blank = '<t></t>';

        if (!focused) {
          nlg.nl.args.midXMLG = blank;
          nlg.nl.args.leftXMLG = blank;
          nlg.nl.args.rightXMLG = blank;
          var old = nlg.nl.args.mode;

          nlg.nl.args.mode = undefined;
          var focusMe = nlg.nl.setMode(old);

          if (focusMe) {
            focusMe.grabFocus();
          }
        } else {
          var line = focused.line;
          var wh = line.nlZone;

          if (wh === 'leftXMLG') {
            nlg.nl.args.leftXMLG = blank;
          } else if (wh === 'midXMLG') {
            nlg.nl.args.midXMLG = blank;
          } else if (wh === 'rightXMLG') {
            nlg.nl.args.rightXMLG = blank;
          }

          line.clear();
          line.grabFocus();
        }
      }
    };

    nlg.nextDrawMode = function () {
      var curr = nlg.drawMode;
      var poss;

      if (nlg.nl.args.mode === '1') {
        poss = ['O-->', 'X-->'];
      } else {
        poss = ['O-->', 'X-->', 'O--O', 'X--X', 'O--X', 'X--O'];
      }

      var i = -1;

      for (var a = 0; a < poss.length; a++) {
        if (poss[a] === curr) {
          i = a;
        }
      }

      i++;

      if (i == poss.length) {
        i = 0;
      }

      nlg.setDrawMode(poss[i]);
    };

    var top = getBasicObject();

    var f = function () {
      if (!nlg.e) {
        return false;
      }

      nlg.clear(false);

      return true;
    };
    var del = getClearAll(30, true);

    del.mouseDownResponse = f;

    top.add(del);

    var modeChanger = getBasicObject();

    modeChanger.setAllDim(86, 30);
    modeChanger.animates = true;

    modeChanger.paintMe = function (ctx) {
      ctx.save();
      roundRect2(
        ctx,
        0,
        0,
        modeChanger.viewportW,
        modeChanger.viewportH,
        6,
        'lightgray'
      );

      if (nlg.st !== 'c') {
        ctx.save();
        if (modeChanger.animated)
          roundRect2(ctx, 0, 0, 30, 30, 6, getThemeColor());
        var w = 30;
        var h = 30;

        ctx.beginPath();
        ctx.moveTo(0.3 * w, 0.35 * h);
        ctx.lineTo(0.7 * w, 0.35 * h);
        ctx.lineTo(w / 2, 0.7 * h);
        ctx.closePath();
        ctx.fillStyle = 'gray';
        ctx.fill();

        ctx.restore();
      }

      ctx.translate(28, 6);
      numLineDrawing(ctx, nlg.drawMode);
      ctx.restore();
    };

    var mdr = function () {
      if (nlg.st === 'c') {
        modeChanger.animates = false;

        return false;
      }

      var poss;

      if (nlg.nl.args.mode === '2') {
        poss = ['O-->', 'X-->', 'O--O', 'X--X', 'O--X', 'X--O'];
      } else {
        poss = ['O-->', 'X-->'];
      }

      var options = [];

      for (var zz = 0; zz < poss.length; zz++) {
        if (nlg.drawMode !== poss[zz]) options.push(poss[zz]);
      }

      var listener = function (sel) {
        nlg.setDrawMode(sel);
      };

      let menu = getWordMenu(
        getProblemJSDependencies(),
        options,
        listener,
        numLineDrawing,
        {
          w: 56,
          h: 16,
        }
      );

      floater.setPanel(menu);

      var tl = getFullTopLeftLocation(top);

      menu.viewportY = tl.y + top.viewportH;
      menu.viewportX =
        tl.x +
        modeChanger.viewportX +
        modeChanger.viewportW / 2 -
        menu.viewportW / 2;
      modeChanger.animate(paintCanvas);

      return true;
    };

    modeChanger.mouseDownResponse = mdr;

    top.add(modeChanger);
    nlg.modeChanger = modeChanger;

    var modeButton = getBasicObject();

    modeButton.setAllDim(30, 30);
    modeButton.animates = true;

    modeButton.paintMe = function (ctx) {
      roundRect2(
        ctx,
        0,
        0,
        30,
        30,
        6,
        modeButton.animated ? getThemeColor() : 'lightgray'
      );

      ctx.save();
      ctx.strokeStyle = 'black';
      ctx.lineWidth = 3;
      ctx.beginPath();
      var m = nlg.nl.args.mode;

      if (m === '1') {
        ctx.moveTo(15, 7);
        ctx.lineTo(15, 23);
      } else if (m === '2') {
        ctx.moveTo(10, 7);
        ctx.lineTo(10, 23);
        ctx.moveTo(20, 7);
        ctx.lineTo(20, 23);
      }

      ctx.stroke();
      ctx.restore();
    };

    modeButton.mouseDownResponse = function () {
      if (!nlg.e || nlg.nl.st === 'c') {
        return false;
      }

      var m = nlg.nl.args.mode;
      var focusMe;

      if (m === '1') {
        focusMe = nlg.nl.setMode('2');
      } else if (m === '2') {
        focusMe = nlg.nl.setMode('1');

        if (nlg.drawMode !== 'O-->' && nlg.drawMode != 'X-->') {
          nlg.setDrawMode('O-->');
        }
      }

      focusMe.grabFocus();

      return true;
    };

    if (ag.nl.mode === '1' || ag.nl.mode === '2') {
      top.add(modeButton);
    }

    top.layoutChildrenLR(15);

    top.sizeMeToFitChildren();

    nlg.add(top);

    nlg.setDrawMode = function (drawMode) {
      if (!nlg.e) {
        return;
      }

      if (nlg.drawMode === drawMode) {
        return;
      }

      // nlg.modeChanger.image = drawMode);
      nlg.centerHorizontally();
      nlg.drawMode = drawMode;

      // clear any unfinished work
      if (nlg.currentObj) {
        // nlg.clear(false);
        var ar = nlg.nl.args.objs;

        for (var a = 0; a < ar.length; a++) {
          var o = ar[a];

          if (o === nlg.currentObj) {
            ar.splice(a, 1);
            break;
          }
        }

        nlg.currentObj = null;
      }
    };

    nlg.nl = getNumberLine(0, 0, ag.nl, nlg);
    nlg.nl.nlg = nlg;
    var md = ag.nl.mode;

    ag.nl.mode = undefined;
    nlg.nl.setMode(md, row, nlg.agId, true);

    nlg.add(nlg.nl);
    nlg.nl.viewportY = top.viewportH + 5;

    nlg.nl.mouseDownResponse = function (x, y) {
      if (!nlg.e || nlg.nl.st === 'c') {
        return false;
      }

      if (nlg.nl.getters && y >= nlg.nl.getters.viewportY) {
        // since this only gets called if call to children containing the linegetters has already returned false!
        // want false so that touch users can scroll
        return false;
      }

      nlg.grabFocus();

      changedMaybe();

      if (!nlg.nl.args.objs) {
        nlg.nl.args.objs = [];
      }

      var ni = nlg.nl.getNearestX(x);
      var nx = ni[0];
      var rightMost = ni[1];
      // if mode is 2 and nx is right val rightMost is true

      if (nlg.drawMode === 'O-->' || nlg.drawMode === 'X-->') {
        if (nlg.nl.args.objs) {
          var hasSeg = false;

          for (var i = 0; i < nlg.nl.args.objs.length; i++) {
            if (nlg.nl.args.objs[i].t === 'Segment') {
              hasSeg = true;
            }
          }

          //* *Check: does currentObj get wiped, too?
          if (hasSeg) nlg.clear(false);
        }

        var ar = nlg.nl.args.objs;

        var fill = nlg.drawMode === 'X-->';

        if (!nlg.currentObj) {
          var ar = nlg.nl.args.objs;

          // if there is a ray in ar AND it's fill is
          // same as current fill AND
          // nx is its cx
          // just set its dir and DONE
          if (ar && ar.length > 0) {
            var old = ar[ar.length - 1];

            if (old.cx === nx && old.fill === fill) {
              old.dir = x < old.cx ? 'l' : 'r';

              return true;
            }
          }

          var reuse = false;

          if (ar) {
            // reset existing ray with same starting point
            for (var i = 0; i < ar.length; i++) {
              var r = ar[i];

              if (r.cx === nx) {
                reuse = true;
                nlg.currentObj = r;
                r.fill = fill;
                r.dir = undefined;
                break;
              }
            }
          }

          if (!reuse) {
            nlg.currentObj = {
              t: 'Ray',
              fill: nlg.drawMode === 'X-->',
              cx: nx,
            };
            nlg.nl.addObj(nlg.currentObj);
          }
        } else {
          var fi = nlg.drawMode === 'X-->';

          if (nlg.currentObj.fill === fi) {
            nlg.currentObj.dir = x < nlg.currentObj.cx ? 'l' : 'r';
            nlg.currentObj = undefined;
          } else {
            nlg.currentObj.fill = fi;
            nlg.currentObj.cx = nx;
          }
        }
      } else {
        if (!nlg.currentObj) {
          nlg.clear(false);
          nlg.currentObj = { t: 'Segment' };
          nlg.nl.addObj(nlg.currentObj);
        }

        if (nlg.drawMode === 'O--O' || nlg.drawMode === 'X--X') {
          if (!nlg.currentObj.first) {
            nlg.currentObj.first = {
              cx: nx,
              fill: !(nlg.drawMode === 'O--O'),
            };
          } else if (nlg.currentObj.first.cx != nx) {
            nlg.currentObj.second = {
              cx: nx,
              fill: nlg.drawMode === 'X--X',
            };
            nlg.currentObj = undefined;
          }
        } else {
          var firs = !nlg.currentObj.first;
          var fil =
            (rightMost && nlg.drawMode === 'O--X') ||
            (!rightMost && nlg.drawMode === 'X--O');

          if (firs) {
            nlg.currentObj.first = { cx: nx, fill: fil };
          } else if (nlg.currentObj.first.cx != nx) {
            nlg.currentObj.second = { cx: nx, fill: fil };
            nlg.currentObj = undefined;
          }
        }
      }

      return true;
    };

    nlg.top = top;

    var subH = 0;

    if (nlg.nl.args.mode === '3') {
      var sub = getSubmitButton(
        nlg,
        paintCanvas,
        submitAttempt,
        getThemeColor,
        'numberLine'
      );

      nlg.add(sub);
      subH = sub.viewportH;
      sub.viewportY = nlg.nl.viewportY + nlg.nl.viewportH + 5;
    }

    nlg.resetHeight = function () {
      nlg.setAllDim(
        nlg.nl.viewportW,
        nlg.nl.viewportY + nlg.nl.viewportH + (subH === 0 ? 0 : subH + 5)
      );
    };

    nlg.resetHeight();

    nlg.centerHorizontally();

    nlg.setDrawMode('O-->');

    // Required getter function
    nlg.serializeAttempt = function () {
      var g = nlg.insertAttemptIntoJSON();

      return g !== 'invalid' ? g : undefined;
    };

    return nlg;
  };

  var getGrid = function (args) {
    if (!args) {
      args = {};
      args.width = 240;
      args.height = 240;
      args.pixelsPerBlock = 20;
    } else {
      args.width = args.w;
      args.height = args.h;
      args.pixelsPerBlock = args.p;
    }

    var gridWidth = args.width;
    var gridHeight = args.height;

    // obscure workaround to vertical size of grid based numberline
    if (args && args.o && args.o.u) {
      for (var az = 0; az < args.o.u.length; az++) {
        var obj = args.o.u[az];

        if (obj.t === 'Label') {
          if (obj.line === undefined) {
            obj.line = toLine(obj.xml);
            var hSluff = gridHeight / 2 - 10;
            var lineH = obj.line.viewportH;

            if (lineH > hSluff) {
              var diff = lineH - hSluff;

              gridHeight += diff * 2;
              args.h = gridHeight;
            }
          }
        }
      }
    }

    var grid = getBasicObject();

    grid.clip = true;

    grid.args = args || {};

    grid.viewportMargin = 1;
    grid.viewportW = gridWidth + grid.viewportMargin * 2;
    grid.viewportH = gridHeight + grid.viewportMargin * 2;
    grid.width = gridWidth;
    grid.height = gridHeight;

    grid.gridWidth = gridWidth;
    grid.gridHeight = gridHeight;

    grid.gmmName = 'g';

    grid.viewportMargin = 1;

    grid.setSelected = function (b) {
      grid.selected = b;

      if (grid.getter) {
        grid.getter.disabled = !b;

        if (b) {
          grid.getter.mShower.stamp.fillStyle = 'gray';
        } else {
          grid.getter.mShower.stamp.fillStyle = 'lightgray';
        }
      }

      if (b) {
        grid.viewportMarginColor = getThemeColor();
      } else {
        grid.viewportMarginColor = 'gray';
      }
    };

    grid.setSelected(false);

    grid.viewportSlideX = grid.gridWidth / 2;
    grid.viewportSlideY = grid.gridHeight / 2;

    if (args.eF) {
      grid.viewportSlideX = 45;
      grid.viewportSlideY = args.width - 45;
      grid.hideTails = true;
    } else if (args.sX) {
      grid.viewportSlideX += args.sX;
    }

    args.uPV = args.uPV || 1;
    args.uPH = args.uPH || 1;

    grid.leftMostUserX = function () {
      return grid.args.eF ? 0 : grid.pixelToUserH(-grid.width / 2);
    };

    grid.rightMostUserX = function () {
      if (grid.args.eF) {
        return grid.pixelToUserH(grid.width - grid.viewportSlideX);
      }

      return grid.pixelToUserH(grid.width / 2);
    };

    grid.leftMostPixelX = function () {
      return grid.args.eF ? 0 : -grid.viewportSlideX;
    };

    grid.rightMostPixelX = function () {
      return grid.leftMostPixelX() + grid.width;
    };

    grid.topPixelY = function () {
      return grid.viewportSlideY;
    };

    grid.bottomPixelY = function () {
      return grid.args.eF ? 0 : grid.topPixelY() - grid.height;
    };

    grid.pixelToUserH = function (p) {
      var upHb = grid.args.uPH || 1;

      return processFloat((p / grid.args.pixelsPerBlock) * upHb);
    };

    grid.pixelToUserV = function (p) {
      var upVb = grid.args.uPV || 1;

      return processFloat((p / grid.args.pixelsPerBlock) * upVb);
    };

    grid.userToPixelH = function (u) {
      var upHb = grid.args.uPH || 1;

      return processFloat((u / upHb) * grid.args.pixelsPerBlock);
    };

    grid.userToPixelV = function (u) {
      var upVb = grid.args.uPV || 1;

      return processFloat((u / upVb) * grid.args.pixelsPerBlock);
    };

    grid.getYParabola = function (x, h, k, a) {
      return a * Math.pow(x - h, 2) + k;
    };

    grid.getXMinusParabola = function (y, h, k, a) {
      return h - Math.pow((y - k) / a, 0.5);
    };

    grid.getXPlusParabola = function (y, h, k, a) {
      return h + Math.pow((y - k) / a, 0.5);
    };

    grid.isFractionObj = function (obj) {
      return obj.vl.indexOf('<f>') != -1;
    };

    grid.getFontFromObj = function (obj) {
      return obj.xAxisLabel || obj.yAxisLabel
        ? 'bold ' + (this.isFractionObj(obj) ? '10' : '14') + 'px ' + lineFont
        : null;
    };

    grid.isAboveItemLimit = function (gridObjects) {
      // Bail out early if there isn't even close to limits
      if (gridObjects.length < 4) {
        return false;
      }

      // Max Limits
      var maxLines = 5;
      var maxRays = 5;
      var maxSegments = 5;
      var maxParabolas = 4;
      var maxCombo = 8;
      var maxPoints = grid.args.eF ? 169 : 225;

      // Counters
      var lineCount = 0;
      var rayCount = 0;
      var segmentCount = 0;
      var parabolaCount = 0;
      var pointCount = 0;
      var comboCount = 0;

      var messageReplacement = 'objects';
      var pastLimit = false;

      for (var a = 0; a < gridObjects.length; a++) {
        var gridItem = gridObjects[a];

        // Make sure it is complete
        if (gridItem.second) {
          if (gridItem.t === 'Line') {
            lineCount++;
            comboCount++;

            if (lineCount > maxLines) {
              messageReplacement = 'lines';
              pastLimit = true;
              break;
            }
          } else if (gridItem.t === 'Ray') {
            rayCount++;
            comboCount++;

            if (rayCount > maxRays) {
              messageReplacement = 'rays';
              pastLimit = true;
              break;
            }
          } else if (gridItem.t === 'Segment') {
            segmentCount++;
            comboCount++;

            if (segmentCount > maxSegments) {
              messageReplacement = 'segments';
              pastLimit = true;
              break;
            }
          } else if (gridItem.t === 'Parabola') {
            parabolaCount++;
            comboCount++;

            if (parabolaCount > maxParabolas) {
              messageReplacement = 'parabolas';
              pastLimit = true;
              break;
            }
          }

          if (comboCount > maxCombo) {
            pastLimit = true;
            break;
          }
        } else {
          if (gridItem.t === 'dot') {
            pointCount++;

            if (pointCount > maxPoints) {
              messageReplacement = 'dots';
              pastLimit = true;
              break;
            }
          }
        }
      }

      if (pastLimit) {
        // Remove last entry
        gridObjects.splice(gridObjects.length - 1, 1);
        getProblemContext().addStackableDialog({
          msg:
            'You have reached the limit of ' +
            messageReplacement +
            ' GMM will allow on this grid.',
          top: 'Reached Limit',
        });
      }

      return pastLimit;
    };

    grid.paintObjects = function (ctx, objects, p, left, right, top, bottom) {
      var xAxisLabelHasFractions = false;

      for (var a = 0; a < objects.length; a++) {
        var obj = objects[a];

        if (obj.xAxisLabel && this.isFractionObj(obj)) {
          xAxisLabelHasFractions = true;
          break;
        }
      }

      var shadeRegions;
      var customRegionColors = false;
      var regionColors = [];

      for (var a = 0; a < objects.length; a++) {
        var obj = objects[a];

        // Check for special opacity request
        if (obj.opacity) ctx.globalAlpha = obj.opacity;
        else ctx.globalAlpha = 1;

        if (obj.t === 'dot' && !obj.high) {
          var col =
            gridGuide && gridGuide.on && gridGuide.dot === obj
              ? 'green'
              : obj.color;

          preCircle(ctx, obj, obj.r, col, obj.open, 2);
        } else if (obj.t === 'Parabola') {
          // only dot if user midway
          if (obj.userParabola) {
            // not pre-made, server side
            if (obj.second) {
              obj.h = grid.pixelToUserH(obj.first.cx);
              obj.k = grid.pixelToUserV(obj.first.cy);

              var x = grid.pixelToUserH(obj.second.cx);
              var y = grid.pixelToUserV(obj.second.cy);
              var f = y - obj.k;
              var d = x - obj.h;

              d = d * d;
              obj.a = f / d;

              obj.minX = grid.args.eF ? 0 : grid.leftMostUserX();
              obj.maxX = grid.rightMostUserX();

              // wasteful rebuild of path per paint to account for second point changing
              obj.path = null;
            }
          }

          if (obj.a) {
            ctx.strokeStyle = obj.color || 'black';
            ctx.lineWidth = 3;

            if (!obj.path) {
              var path = [];
              var aa = obj.a;
              var h = obj.h;
              var k = obj.k;
              var inc = grid.pixelToUserH(1) / grid.args.pixelsPerBlock;
              var x;

              obj.minY = grid.args.eF
                ? 0
                : grid.pixelToUserV(grid.bottomPixelY());

              for (x = obj.minX; x <= obj.maxX; x += inc) {
                var y = grid.getYParabola(x, h, k, aa);

                // Check to make sure y is not out of bounds
                if (y >= obj.minY) {
                  path.push([grid.userToPixelH(x), grid.userToPixelV(y)]);
                }
              }

              if (x - inc < obj.maxX) {
                var y = grid.getYParabola(obj.maxX, h, k, aa);

                // Check to make sure y is not out of bounds
                if (y >= obj.minY) {
                  path.push([
                    grid.userToPixelH(obj.maxX),
                    grid.userToPixelV(y),
                  ]);
                }
              }

              obj.path = path;

              if (obj.arrows) {
                var ar = obj.arrows;

                for (var x = 0; x < ar.length; x++) {
                  if (x % 2 == 0) {
                    ar[x] = grid.userToPixelH(ar[x]);
                  } else {
                    ar[x] = grid.userToPixelV(ar[x]);
                  }
                }
              } else {
                // Keep units as user values during calculations
                var leftMostX = grid.args.eF
                  ? 0
                  : grid.pixelToUserH(grid.leftMostPixelX());
                var rightMostX = grid.pixelToUserH(grid.rightMostPixelX());
                var topY = grid.pixelToUserV(grid.topPixelY());
                var bottomY = grid.args.eF
                  ? 0
                  : grid.pixelToUserV(grid.bottomPixelY());

                var y = aa > 0 ? topY : bottomY;

                var leftY = grid.getYParabola(leftMostX, h, k, aa);

                var x = grid.getXMinusParabola(y, h, k, aa);

                if (x > leftMostX) {
                  leftY = y;
                }

                var leftArrowX2 = grid.getXMinusParabola(leftY, h, k, aa);
                var leftArrowX1 = leftArrowX2 + inc;
                var leftArrowY1 = grid.getYParabola(leftArrowX1, h, k, aa);
                var leftArrowY2 = leftY;

                var rightY = grid.getYParabola(rightMostX, h, k, aa);

                x = grid.getXPlusParabola(y, h, k, aa);

                if (x < rightMostX) {
                  rightY = y;
                }

                var rightArrowX2 = grid.getXPlusParabola(rightY, h, k, aa);
                var rightArrowX1 = rightArrowX2 - inc;
                var rightArrowY1 = grid.getYParabola(rightArrowX1, h, k, aa);
                var rightArrowY2 = rightY;

                // Convert units to pixels for display
                var ar1 = [
                  grid.userToPixelH(leftArrowX1),
                  grid.userToPixelV(leftArrowY1),
                  grid.userToPixelH(leftArrowX2),
                  grid.userToPixelV(leftArrowY2),
                ];
                var ar2 = [
                  grid.userToPixelH(rightArrowX1),
                  grid.userToPixelV(rightArrowY1),
                  grid.userToPixelH(rightArrowX2),
                  grid.userToPixelV(rightArrowY2),
                ];

                obj.ar1 = ar1;
                obj.ar2 = ar2;
              }
            }

            ctx.save();

            if (obj.sideways) {
              ctx.rotate(Math.PI / 2);
            }

            ctx.beginPath();
            ctx.moveTo(obj.path[0][0], obj.path[0][1]);

            for (var x = 1; x < obj.path.length; x++) {
              ctx.lineTo(obj.path[x][0], obj.path[x][1]);
            }

            ctx.stroke();
            ctx.restore();

            if (obj.arrows) {
              ctx.beginPath();
              var ar = obj.arrows;

              if (!obj.skipFirstArrow || obj.showArrows)
                arrow(ctx, ar[0], ar[1], ar[2], ar[3]);
              if (!obj.skipSecondArrow || obj.showArrows)
                arrow(ctx, ar[4], ar[5], ar[6], ar[7]);
              ctx.stroke();
            } else if (obj.ar1 || obj.ar2) {
              var ar;

              if ((obj.ar1 && !obj.skipFirstArrow) || obj.showArrows) {
                ar = obj.ar1;
                arrow(ctx, ar[0], ar[1], ar[2], ar[3]);
              }

              if ((obj.ar2 && !obj.skipSecondArrow) || obj.showArrows) {
                ar = obj.ar2;
                arrow(ctx, ar[0], ar[1], ar[2], ar[3]);
              }

              ctx.stroke();
            }
          }

          if (!obj.first.hideDot && !obj.hideFirst) {
            preCircle(ctx, obj.first, 5, ctx.strokeStyle, obj.first.open);
          }

          if (obj.second) {
            if (!obj.hideSecond) {
              preCircle(ctx, obj.second, 5, ctx.strokeStyle, obj.second.open);
            }
          }

          ctx.strokeStyle = 'black';
        } else if (obj.t === 'Exponential') {
          if (!obj.path) {
            var path = [];
            var min = obj.min != null ? obj.min : grid.leftMostUserX();
            var max = obj.max != null ? obj.max : grid.rightMostUserX();
            var inc = grid.pixelToUserH(1) / 20;

            var lastOnScreen;
            var lastXPixel;
            var lastYPixel;

            for (var x = min; x <= max; x += inc) {
              var xPixel = grid.userToPixelH(x);
              var yPixel = grid.userToPixelV(
                obj.a * Math.pow(obj.b, x) + (obj.c != null ? obj.c : 0)
              );

              var onScreen = grid.args.eF
                ? xPixel >= 0 &&
                  xPixel < grid.width &&
                  yPixel >= 0 &&
                  yPixel < grid.height
                : xPixel >= -grid.width / 2 &&
                  xPixel < grid.width / 2 &&
                  yPixel >= -grid.height / 2 &&
                  yPixel < grid.height / 2;

              if (onScreen && !lastOnScreen)
                path.push([lastXPixel, lastYPixel]);

              if (onScreen || lastOnScreen) path.push([xPixel, yPixel]);

              lastOnScreen = onScreen;
              lastXPixel = xPixel;
              lastYPixel = yPixel;
            }

            obj.path = path;
          }

          ctx.strokeStyle = obj.color || 'black';
          ctx.lineWidth = 3;
          ctx.save();

          if (obj.arrowType && obj.arrowType != 'NONE') {
            var minX = 10000;
            var maxX = -10000;

            // Track the extent of visible x values for arrow positioning later
            for (var i = 0; i < obj.path.length; i++) {
              var x = obj.path[i][0];

              if (x < minX) minX = x;
              if (x > maxX) maxX = x;
            }

            var inc = grid.pixelToUserH(1) * 3;
            var leftArrowX2 = grid.pixelToUserH(minX);
            var leftArrowY2 = exponentialY(obj.a, obj.b, leftArrowX2, obj.c);
            var leftArrowX1 = leftArrowX2 + inc;
            var leftArrowY1 = exponentialY(obj.a, obj.b, leftArrowX1, obj.c);

            var rightArrowX2 = grid.pixelToUserH(maxX);
            var rightArrowY2 = exponentialY(obj.a, obj.b, rightArrowX2, obj.c);
            var rightArrowX1 = rightArrowX2 - inc;
            var rightArrowY1 = exponentialY(obj.a, obj.b, rightArrowX1, obj.c);

            if (obj.arrowType == 'LEFT' || obj.arrowType == 'BOTH') {
              ctx.beginPath();
              arrow(
                ctx,
                grid.userToPixelH(leftArrowX1),
                grid.userToPixelV(leftArrowY1),
                grid.userToPixelH(leftArrowX2),
                grid.userToPixelV(leftArrowY2)
              );
              ctx.stroke();
            }

            if (obj.arrowType == 'RIGHT' || obj.arrowType == 'BOTH') {
              ctx.beginPath();
              arrow(
                ctx,
                grid.userToPixelH(rightArrowX1),
                grid.userToPixelV(rightArrowY1),
                grid.userToPixelH(rightArrowX2),
                grid.userToPixelV(rightArrowY2)
              );
              ctx.stroke();
            }
          }

          ctx.beginPath();
          ctx.moveTo(obj.path[0][0], obj.path[0][1]);

          for (var x = 1; x < obj.path.length; x++) {
            ctx.lineTo(obj.path[x][0], obj.path[x][1]);
          }

          ctx.stroke();

          if (obj.leftDotType) {
            var min = obj.min != null ? obj.min : grid.leftMostUserX();
            var inc = grid.pixelToUserH(1);
            var leftDotX = min;
            var leftDotY = exponentialY(obj.a, obj.b, leftDotX, obj.c);

            dot(
              ctx,
              grid.userToPixelH(leftDotX),
              grid.userToPixelV(leftDotY),
              5,
              obj.leftDotType == 'CLOSED'
            );
          }

          if (obj.rightDotType) {
            var max = obj.max != null ? obj.max : grid.rightMostUserX();
            var inc = grid.pixelToUserH(1);
            var rightDotX = max;
            var rightDotY = exponentialY(obj.a, obj.b, rightDotX, obj.c);

            dot(
              ctx,
              grid.userToPixelH(rightDotX),
              grid.userToPixelV(rightDotY),
              5,
              obj.rightDotType == 'CLOSED'
            );
          }

          ctx.restore();
        } else if (obj.t === 'ValueGridObject') {
          ctx.strokeStyle = obj.color || 'black';
          ctx.lineWidth = 3;
          ctx.save();

          if (obj.arrowType && obj.arrowType != 'NONE') {
            var inc = grid.pixelToUserH(1) * 3;
            var leftArrowX1 = obj.leftArrowX1;
            var leftArrowY1 = obj.leftArrowY1;
            var leftArrowX2 = obj.leftArrowX2;
            var leftArrowY2 = obj.leftArrowY2;
            var rightArrowX1 = obj.rightArrowX1;
            var rightArrowY1 = obj.rightArrowY1;
            var rightArrowX2 = obj.rightArrowX2;
            var rightArrowY2 = obj.rightArrowY2;

            if (obj.arrowType == 'LEFT' || obj.arrowType == 'BOTH') {
              ctx.beginPath();
              arrow(
                ctx,
                grid.userToPixelH(leftArrowX1),
                grid.userToPixelV(leftArrowY1),
                grid.userToPixelH(leftArrowX2),
                grid.userToPixelV(leftArrowY2)
              );
              ctx.stroke();
            }

            if (obj.arrowType == 'RIGHT' || obj.arrowType == 'BOTH') {
              ctx.beginPath();
              arrow(
                ctx,
                grid.userToPixelH(rightArrowX1),
                grid.userToPixelV(rightArrowY1),
                grid.userToPixelH(rightArrowX2),
                grid.userToPixelV(rightArrowY2)
              );
              ctx.stroke();
            }
          }

          ctx.strokeStyle = obj.color || 'black';
          ctx.lineWidth = 3;
          ctx.save();

          for (var looper = 0; looper < obj.paths.length; looper++) {
            var path = obj.paths[looper];

            ctx.beginPath();
            ctx.moveTo(path[0][0], path[0][1]);

            for (var x = 1; x < path.length; x++) {
              ctx.lineTo(path[x][0], path[x][1]);
            }

            ctx.stroke();
          }

          ctx.restore();

          if (obj.leftDotType) {
            var min = obj.min != null ? obj.min : grid.leftMostUserX();
            var inc = grid.pixelToUserH(1);
            var leftDotX = obj.leftDotX;
            var leftDotY = obj.leftDotY;

            dot(
              ctx,
              grid.userToPixelH(leftDotX),
              grid.userToPixelV(leftDotY),
              5,
              obj.leftDotType == 'CLOSED'
            );
          }

          if (obj.rightDotType) {
            var max = obj.max != null ? obj.max : grid.rightMostUserX();
            var inc = grid.pixelToUserH(1);
            var rightDotX = obj.rightDotX;
            var rightDotY = obj.rightDotY;

            dot(
              ctx,
              grid.userToPixelH(rightDotX),
              grid.userToPixelV(rightDotY),
              5,
              obj.rightDotType == 'CLOSED'
            );
          }

          ctx.restore();
        } else if (obj.t === 'SquareRoot') {
          if (!obj.path) {
            var path = [];
            var min = grid.leftMostUserX();
            var max = grid.rightMostUserX();
            var inc = grid.pixelToUserH(1);

            var lastOnScreen;
            var lastXPixel;
            var lastYPixel;

            for (var x = min; x <= max; x += inc) {
              var xPixel = grid.userToPixelH(x);
              var yPixel = grid.userToPixelV(
                obj.a * Math.sqrt(x + obj.b) + obj.c
              );

              var onScreen = grid.args.eF
                ? xPixel >= 0 &&
                  xPixel < grid.width &&
                  yPixel >= 0 &&
                  yPixel < grid.height
                : xPixel >= -grid.width / 2 &&
                  xPixel < grid.width / 2 &&
                  yPixel >= -grid.height / 2 &&
                  yPixel < grid.height / 2;

              if (onScreen && !lastOnScreen)
                path.push([lastXPixel, lastYPixel]);

              if (onScreen || lastOnScreen) path.push([xPixel, yPixel]);

              lastOnScreen = onScreen;
              lastXPixel = xPixel;
              lastYPixel = yPixel;
            }

            obj.path = path;
          }

          ctx.strokeStyle = obj.color || 'black';
          ctx.lineWidth = 3;
          ctx.save();

          ctx.beginPath();
          ctx.moveTo(obj.path[0][0], obj.path[0][1]);

          for (var x = 1; x < obj.path.length; x++) {
            ctx.lineTo(obj.path[x][0], obj.path[x][1]);
          }

          ctx.stroke();
          ctx.restore();
        } else if (obj.t === 'Hyperbola') {
          if (!obj.paths) {
            var paths = [];
            var min = grid.leftMostUserX();
            var max = grid.rightMostUserX();
            var inc = grid.pixelToUserH(1);

            var x = min;

            for (var asi = 0; asi <= obj.asym.length; asi++) {
              var as = 0;

              if (asi < obj.asym.length) {
                as = obj.asym[asi];
              } else {
                as = max + 1;
              }

              if (x < as) var path = [];

              while (x < as) {
                var den = 0;

                for (var xx = 0; xx < obj.den.length; xx++) {
                  den += obj.den[xx] * Math.pow(x, xx);
                }

                if (den !== 0) {
                  var num = 0;

                  for (var xx = 0; xx < obj.num.length; xx++) {
                    num += obj.num[xx] * Math.pow(x, xx);
                  }

                  var output = num / den;

                  if (Math.abs(output) < 1000) {
                    path.push([
                      grid.userToPixelH(x),
                      grid.userToPixelV(output),
                    ]);
                  }
                }

                x += inc;
              }

              if (path && path.length > 1) {
                paths.push(path);
              }
            }

            obj.paths = paths;
          }

          ctx.strokeStyle = obj.color || 'black';
          ctx.lineWidth = 3;
          ctx.save();

          for (var looper = 0; looper < obj.paths.length; looper++) {
            var path = obj.paths[looper];

            ctx.beginPath();
            ctx.moveTo(path[0][0], path[0][1]);

            for (var x = 1; x < path.length; x++) {
              ctx.lineTo(path[x][0], path[x][1]);
            }

            ctx.stroke();
          }

          ctx.restore();
        } else if (obj.t === 'Segment' || obj.t === 'Line' || obj.t === 'Ray') {
          var GG = gridGuide && gridGuide.on && gridGuide.dot;
          var skipFirstForGG = GG && !obj.second;
          var skipSecondForGG = GG;

          ctx.strokeStyle = obj.color || 'black';
          ctx.lineWidth = obj.lw || 3;

          var linePoints = [];
          var useInters = [];

          if (obj.second) {
            var x1 = obj.first.cx;
            var y1 = obj.first.cy;
            var x2 = obj.second.cx;
            var y2 = obj.second.cy;
            var lx = grid.args.eF ? 0 : -grid.viewportSlideX;
            var rx = grid.width - grid.viewportSlideX;
            var by = grid.args.eF ? 0 : -(grid.height - grid.viewportSlideY);
            var ty = grid.viewportSlideY;

            useInters = grid.getLinePoints(x1, y1, x2, y2, lx, by, rx, ty);
            linePoints = grid.getLinePoints(x1, y1, x2, y2, lx, by, rx, ty);
          }

          var x1, y1, x2, y2;

          if (obj.t === 'Segment') {
            if (obj.second) {
              if (obj.dotted) {
                dottedLine(
                  ctx,
                  obj.first.cx,
                  obj.first.cy,
                  obj.second.cx,
                  obj.second.cy,
                  obj.color
                );
              } else {
                ctx.beginPath();
                ctx.moveTo(obj.first.cx, obj.first.cy);
                ctx.lineTo(obj.second.cx, obj.second.cy);
                ctx.stroke();
              }

              if (obj.arrows) {
                for (var az = 0; az < obj.arrows.length; az++) {
                  var ar = obj.arrows[az];

                  ctx.beginPath();
                  arrow(ctx, ar[0], ar[1], ar[2], ar[3]);
                  ctx.stroke();
                }
              }

              if (!obj.second.hideDot && !skipSecondForGG) {
                preCircle(
                  ctx,
                  obj.second,
                  5,
                  ctx.strokeStyle,
                  obj.second.open,
                  2
                );
              }

              // for shading
              if (obj.first.cx == obj.second.cx) {
                if (obj.first.cy < obj.second.cy) {
                  x1 = obj.first.cx;
                  y1 = obj.first.cy;
                  x2 = obj.second.cx;
                  y2 = obj.second.cy;
                } else {
                  x1 = obj.second.cx;
                  y1 = obj.second.cy;
                  x2 = obj.first.cx;
                  y2 = obj.first.cy;
                }
              } else {
                x1 = useInters[0].x;
                y1 = useInters[0].y;
                x2 = useInters[1].x;
                y2 = useInters[1].y;
              }
            }

            if (!obj.first.hideDot && !skipFirstForGG) {
              preCircle(ctx, obj.first, 5, ctx.strokeStyle, obj.first.open, 2);
            }
          } else if (obj.t === 'Line') {
            if (!obj.hideFirst) {
              preCircle(ctx, obj.first, 5, ctx.strokeStyle);
            }

            if (obj.second) {
              x1 = linePoints[0].x;
              y1 = linePoints[0].y;
              x2 = linePoints[1].x;
              y2 = linePoints[1].y;

              if (obj.dotted) {
                dottedLine(ctx, x1, y1, x2, y2, obj.color);
              } else {
                ctx.beginPath();
                ctx.moveTo(x1, y1);
                ctx.lineTo(x2, y2);
                ctx.stroke();
              }

              ctx.beginPath();
              arrow(ctx, x1, y1, x2, y2);
              arrow(ctx, x2, y2, x1, y1);
              ctx.stroke();

              if (!obj.hideSecond) {
                preCircle(ctx, obj.second, 5, ctx.strokeStyle);
              }
            }
          } else if (obj.t === 'Ray') {
            if (obj.second) {
              if (!obj.hideSecond) {
                preCircle(ctx, obj.second, 5, ctx.strokeStyle);
              }

              x1 = obj.first.cx;
              y1 = obj.first.cy;
              x2 = undefined;
              y2 = undefined;

              if (obj.second.cx == x1) {
                x2 = obj.second.cx;

                if (obj.second.cy > y1) {
                  y2 = useInters[1].y;
                } else {
                  y2 = useInters[0].y;
                }
              } else if (obj.second.cy == y1) {
                y2 = obj.second.cy;

                if (obj.second.cx > obj.first.cx) {
                  x2 = useInters[1].x;
                } else {
                  x2 = useInters[0].x;
                }
              } else {
                if (obj.second.cx > obj.first.cx) {
                  x2 = useInters[1].x;
                  y2 = useInters[1].y;
                } else {
                  x2 = useInters[0].x;
                  y2 = useInters[0].y;
                }
              }

              if (obj.dotted) {
                dottedLine(ctx, x1, y1, x2, y2, obj.color);
              } else {
                ctx.beginPath();
                ctx.moveTo(x1, y1);
                ctx.lineTo(x2, y2);
                arrow(ctx, x1, y1, x2, y2);
                ctx.stroke();
              }

              ctx.beginPath();
              arrow(ctx, x1, y1, x2, y2);
              ctx.stroke();
            }

            if (!obj.hideFirst) {
              preCircle(ctx, obj.first, 5, ctx.strokeStyle, obj.open);
            }
          }

          if (obj.shade) {
            if (obj.second) {
              if (obj.shadeColor != null) customRegionColors = true;

              var region = [];

              // ensure single quadrant graphs use points on the grid for shading
              if (args.eF) {
                left = 0;
                bottom = 0;
              }

              // precisely vertical
              if (x1 === x2) {
                shadeHelper(region, x1, bottom);

                if (obj.shade === 'L') {
                  shadeHelper(region, left, bottom);
                  shadeHelper(region, left, top);
                  shadeHelper(region, x2, top);
                } else {
                  shadeHelper(region, x2, top);
                  shadeHelper(region, right, top);
                  shadeHelper(region, right, bottom);
                }
              } else if (y1 === y2) {
                // horizontal
                shadeHelper(region, left, y1);

                if (obj.shade === 'G') {
                  shadeHelper(region, left, top);
                  shadeHelper(region, right, top);
                  shadeHelper(region, right, y1);
                } else {
                  shadeHelper(region, right, y1);
                  shadeHelper(region, right, bottom);
                  shadeHelper(region, left, bottom);
                }
              } else if (Math.abs(x1 - x2) <= 3) {
                // technically not vertical, but intended as vertical by user
                var topX = x1;
                var topY = y1;
                var bottomX = x2;
                var bottomY = y2;

                if (y2 > y1) {
                  topX = x2;
                  topY = y2;
                  bottomX = x1;
                  bottomY = y1;
                }

                shadeHelper(region, bottomX, bottomY);
                shadeHelper(region, topX, topY);

                if (obj.shade == 'L') {
                  shadeHelper(region, left, top);
                  shadeHelper(region, left, bottom);
                } else {
                  shadeHelper(region, right, top);
                  shadeHelper(region, right, bottom);
                }
              } else {
                // up/down
                shadeHelper(region, x1, y1);

                // pos slope
                if ((y2 - y1) / (x2 - x1) > 0) {
                  if (obj.shade === 'G') {
                    // bot, left?
                    if (y1 == bottom) {
                      shadeHelper(region, left, bottom);
                    }

                    shadeHelper(region, left, top);

                    // top right inc?
                    if (y2 < top) {
                      shadeHelper(region, right, top);
                    }

                    shadeHelper(region, x2, y2);
                  } else {
                    shadeHelper(region, x2, y2);

                    // top right?
                    if (y2 == top) {
                      shadeHelper(region, right, top);
                    }

                    shadeHelper(region, right, bottom);

                    if (y1 > bottom) {
                      shadeHelper(region, left, bottom);
                    }
                  }
                } else {
                  // Point order must be swapped for rays to ensure clockwise order of polygon point pairs
                  var needsSwap = obj.t === 'Ray' && x1 > x2;
                  var lx = needsSwap ? x2 : x1;
                  var rx = needsSwap ? x1 : x2;
                  var ly = needsSwap ? y2 : y1;
                  var ry = needsSwap ? y1 : y2;

                  region = [];
                  shadeHelper(region, lx, ly);

                  // neg slope
                  if (obj.shade == 'G') {
                    if (ly < top) {
                      shadeHelper(region, left, top);
                    }

                    shadeHelper(region, right, top);

                    // bot right?
                    if (ry == bottom || (needsSwap && ry == 0)) {
                      shadeHelper(region, right, bottom);
                    }

                    shadeHelper(region, rx, ry);
                  } else {
                    shadeHelper(region, rx, ry);

                    if (ry > bottom) {
                      shadeHelper(region, right, bottom);
                    }

                    shadeHelper(region, left, bottom);

                    if (ly == top) {
                      shadeHelper(region, left, top);
                    }
                  }
                }
              }

              if (!shadeRegions) {
                shadeRegions = [];
              }

              shadeRegions.push(region);
              regionColors.push(
                obj.shadeColor != null ? obj.shadeColor : 'gray'
              );
            }
          }
        } else if (obj.t === 'Label') {
          ctx.save();
          ctx.scale(1, -1);

          obj.line.viewportX = grid.userToPixelH(obj.cx);
          obj.line.viewportY = 10;

          if (obj.anchor === 'TOP_CENTER') {
            obj.line.viewportX -= obj.line.viewportW / 2;
          }

          obj.line.paint(ctx);
          ctx.restore();
        } else if (obj.t === 'ValueLabel') {
          ctx.save();
          ctx.scale(1, -1);

          var line = toLine(
            obj.vl,
            null,
            null,
            null,
            null,
            null,
            null,
            this.getFontFromObj(obj)
          );
          var vlWidth = line.viewportW;
          var vlHeight = line.viewportH;
          var margin = 8;

          if (obj.margin) margin = obj.margin;

          line.viewportX = obj.cx;
          line.viewportY =
            obj.xAxisLabel && xAxisLabelHasFractions ? obj.cy - 4 : obj.cy;

          switch (obj.layout) {
            case 'TOP':
              line.viewportX -= vlWidth / 2;
              line.viewportY -= vlHeight + margin;
              break;
            case 'BOTTOM':
              line.viewportX -= vlWidth / 2;
              line.viewportY += margin;
              break;
            case 'LEFT':
              line.viewportX -= vlWidth + margin;
              line.viewportY -= vlHeight / 2;
              break;
            case 'RIGHT':
              line.viewportX += margin;
              line.viewportY -= vlHeight / 2;
              break;
            case 'TOP_LEFT':
              line.viewportX -= vlWidth + margin;
              line.viewportY -= vlHeight + margin;
              break;
            case 'TOP_RIGHT':
              line.viewportX += margin;
              line.viewportY -= vlHeight + margin;
              break;
            case 'BOTTOM_LEFT':
              line.viewportX -= vlWidth + margin;
              line.viewportY += margin;
              break;
            case 'BOTTOM_RIGHT':
              line.viewportX += margin;
              line.viewportY += margin;
              break;
            case 'CENTER':
              line.viewportX -= vlWidth / 2;
              line.viewportY -= vlHeight / 2;
              break;
          }

          if (obj.background) {
            ctx.fillColor = obj.background;
            ctx.fillRect(line.viewportX, line.viewportY, vlWidth, vlHeight);
          }

          line.paint(ctx);
          ctx.restore();
        } else if (obj.t === 'Circle') {
          ctx.save();
          ctx.strokeStyle = obj.color;
          ctx.lineWidth = 3;
          ctx.beginPath();
          ctx.arc(
            grid.userToPixelH(obj.cx),
            grid.userToPixelV(obj.cy),
            grid.userToPixelH(obj.r),
            0,
            2 * Math.PI
          );
          ctx.stroke();

          ctx.restore();
        } else if (obj.t === 'Arc') {
          ctx.save();
          ctx.strokeStyle = obj.color;
          ctx.lineWidth = 3;
          ctx.beginPath();
          ctx.arc(
            grid.userToPixelH(obj.cx),
            grid.userToPixelV(obj.cy),
            grid.userToPixelH(obj.r),
            obj.start,
            obj.extent
          );
          ctx.stroke();

          ctx.restore();
        } else if (obj.t === 'Cubic') {
          if (!obj.path) {
            var path = [];
            var min = grid.leftMostUserX();
            var max = grid.rightMostUserX();
            var inc = grid.pixelToUserH(1);

            var lastOnScreen;
            var lastXPixel;
            var lastYPixel;

            for (var x = min; x <= max; x += inc) {
              var xPixel = grid.userToPixelH(x);
              var yPixel = grid.userToPixelV(obj.a * Math.pow(x, 3) + obj.c);

              var onScreen =
                xPixel >= -grid.width / 2 &&
                xPixel < grid.width / 2 &&
                yPixel >= -grid.height / 2 &&
                yPixel < grid.height / 2;

              if (onScreen && !lastOnScreen)
                path.push([lastXPixel, lastYPixel]);

              if (onScreen || lastOnScreen) path.push([xPixel, yPixel]);

              lastOnScreen = onScreen;
              lastXPixel = xPixel;
              lastYPixel = yPixel;
            }

            obj.path = path;
          }

          ctx.strokeStyle = obj.color || 'black';
          ctx.lineWidth = 3;
          ctx.save();
          ctx.beginPath();
          ctx.moveTo(obj.path[0][0], obj.path[0][1]);

          for (var x = 1; x < obj.path.length; x++) {
            ctx.lineTo(obj.path[x][0], obj.path[x][1]);
          }

          ctx.stroke();
          ctx.restore();
        } else if (obj.t != 'dot') {
          console.error('paintObject not coded for grid object ' + obj.t);
        }
      }

      if (shadeRegions) {
        ctx.save();
        var intersection;

        if (customRegionColors) {
          for (var a = 0; a < shadeRegions.length; a++) {
            // try rebuilding intersection with just x, y
            var i = shadeRegions[a];
            var ni = [];

            for (var b = 0; b < i.length; b++) {
              ni.push({ x: Math.round(i[b].x), y: Math.round(i[b].y) });
            }

            intersection = ni;

            if (grid.args.eF) {
              var gridR = [
                { x: 0, y: 0 },
                { x: 0, y: top },
                { x: right, y: top },
                { x: right, y: 0 },
              ];

              intersection = getIntersectingPolygon(
                ni,
                gridR,
                getProblemContext().errorToServer
              );
            }

            ctx.beginPath();

            for (var b = 0; b < intersection.length; b++) {
              if (b === 0) {
                ctx.moveTo(intersection[b].x, intersection[b].y);
              } else {
                ctx.lineTo(intersection[b].x, intersection[b].y);
              }
            }

            ctx.closePath();
            ctx.fillStyle = regionColors[a];
            ctx.globalAlpha = 0.65;
            var restore = ctx.globalCompositeOperation;

            ctx.globalCompositeOperation = 'multiply';
            ctx.fill();
            ctx.globalAlpha = 1;
            ctx.globalCompositeOperation = restore;
          }
        } else {
          for (var a = 0; a < shadeRegions.length; a++) {
            var region = shadeRegions[a];

            if (intersection === undefined) {
              intersection = region;
            } else {
              intersection = getIntersectingPolygon(
                intersection,
                region,
                getProblemContext().errorToServer
              );
              var i = intersection;
              var ni = [];

              for (var z = 0; z < i.length; z++) {
                ni.push({ x: Math.round(i[z].x), y: Math.round(i[z].y) });
              }

              intersection = ni;
            }
          }

          // try rebuilding intersection with just x, y
          var i = intersection;
          var ni = [];

          for (var a = 0; a < i.length; a++) {
            ni.push({ x: Math.round(i[a].x), y: Math.round(i[a].y) });
          }

          intersection = ni;

          if (grid.args.eF) {
            var gridR = [
              { x: 0, y: 0 },
              { x: 0, y: top },
              { x: right, y: top },
              { x: right, y: 0 },
            ];

            intersection = getIntersectingPolygon(
              ni,
              gridR,
              getProblemContext().errorToServer
            );
          }

          ctx.beginPath();

          for (var b = 0; b < intersection.length; b++) {
            if (b === 0) {
              ctx.moveTo(intersection[b].x, intersection[b].y);
            } else {
              ctx.lineTo(intersection[b].x, intersection[b].y);
            }
          }

          ctx.closePath();
          ctx.fillStyle = 'gray';
          ctx.globalAlpha = 0.4;
          ctx.fill();
          ctx.globalAlpha = 1;
        }

        ctx.restore();
      }

      for (var a = 0; a < objects.length; a++) {
        var obj = objects[a];

        if (obj.t === 'dot' && obj.high) {
          preCircle(ctx, obj, obj.r, obj.color, obj.open);
        }
      }
    };

    grid.getLinePoints = function (x1, y1, x2, y2, minX, minY, maxX, maxY) {
      var linePoints = [];

      if (x2 - x1 === 0) {
        linePoints.push({ x: x1, y: minY });
        linePoints.push({ x: x1, y: maxY });
      } else if (y2 - y1 === 0) {
        linePoints.push({ x: minX, y: y1 });
        linePoints.push({ x: maxX, y: y1 });
      } else {
        var m = (y2 - y1) / (x2 - x1);
        var b = y1 - m * x1;
        var inters = [];

        inters.push({ x: minX, y: m * minX + b });
        inters.push({ x: maxX, y: m * maxX + b });
        inters.push({ x: (maxY - b) / m, y: maxY });
        inters.push({ x: (minY - b) / m, y: minY });

        while (inters.length > 0) {
          var minX = 10000;
          var minI = -1;

          for (var z = 0; z < inters.length; z++) {
            if (inters[z].x < minX) {
              minI = z;
              minX = inters[z].x;
            }
          }

          linePoints.push(inters[minI]);
          inters.splice(minI, 1);
        }

        linePoints.splice(0, 1);
        linePoints.splice(2, 1);
      }

      return linePoints;
    };

    grid.clearCurrentMaybeSave = function () {
      if (!grid.currentObj) return;

      if (!grid.currentObj.second) {
        for (var a = 0; a < grid.args.o.u.length; a++) {
          if (grid.currentObj === grid.args.o.u[a]) {
            grid.args.o.u.splice(a, 1);
            break;
          }
        }
      }

      grid.currentObj = undefined;
    };

    grid.addLiney = function (liney, perm) {
      if (perm) {
        grid.args.o.p.push(liney);
      } else {
        grid.args.o.u.push(liney);
      }
    };

    grid.gridGuideX = function () {
      var user = grid.args.o.u;

      if (gridGuide.currentObj && gridGuide.currentObj.first) {
        if (gridGuide.currentObj.first === gridGuide.dot) {
          // remove currentObj from user
          for (var a = 0; a < user.length; a++) {
            if (gridGuide.currentObj === user[a]) {
              user.splice(a, 1);
              break;
            }
          }

          gridGuide.currentObj = undefined;
          grid.currentObj = undefined;
        } else {
          gridGuide.currentObj.second = undefined;
        }

        gridGuide.dot = undefined;
      } else {
        // just a point
        for (var a = 0; a < user.length; a++) {
          if (gridGuide.dot === user[a]) {
            user.splice(a, 1);
            gridGuide.dot = undefined;
            break;
          }
        }
      }
    };

    grid.gridCoordinatesToPixelsFromTopLeftOfGrid = function (gx, gy) {
      var rx = gx + grid.viewportSlideX;
      var ry = grid.viewportSlideY - gy;

      return { x: rx, y: ry };
    };

    grid.paintMe = function (ctx) {
      var numberLine = grid.args.nl;

      ctx.save();

      if (grid.scaledDown < 1) {
        // Position based of scale ratio
        tx = (grid.width - grid.width * grid.scaledDown) / 2;
        ty = (grid.height - grid.height * grid.scaledDown) / 2;

        if (grid.args.eF) {
          // Readjust slide offsets based on scale ratio
          tx = grid.viewportSlideX - grid.scaledDown * grid.viewportSlideX;
          ty +=
            (grid.viewportSlideY - grid.scaledDown * grid.viewportSlideY) / 2;
        }

        ctx.translate(-tx, -ty);
      }

      ctx.scale(1, -1);

      var units = grid.gridWidth / grid.args.pixelsPerBlock;
      var leftMostXPixels = -grid.viewportSlideX;
      var rightMostXPixels = leftMostXPixels + grid.width;
      var topYPixels = grid.viewportSlideY;
      var bottomYPixels = topYPixels - grid.height;
      var firstX = Math.abs(leftMostXPixels / grid.args.pixelsPerBlock);
      var lastX = Math.abs(rightMostXPixels / grid.args.pixelsPerBlock);

      if (leftMostXPixels < 0) {
        firstX = Math.floor(firstX);
        firstX *= -1;
      } else {
        firstX = Math.ceil(firstX);
      }

      if (rightMostXPixels < 0) {
        lastX = Math.ceil(lastX);
        lastX *= -1;
      } else {
        lastX = Math.floor(lastX);
      }

      var firstY = Math.floor(
        Math.abs(bottomYPixels) / grid.args.pixelsPerBlock
      );

      if (bottomYPixels < 0) {
        firstY *= -1;
      }

      if (grid.hideTails) {
        ctx.save();
        ctx.beginPath();
        ctx.rect(
          -2,
          -2,
          grid.width - grid.viewportSlideX + 2,
          grid.viewportSlideY + 2
        );
        ctx.clip();
      }

      // Draw grid lines
      for (var looper = 0; looper <= units; looper++) {
        var x = firstX + looper;
        var y = firstY + looper;

        // vertical

        ctx.lineWidth = 1;

        if (Math.abs(x) % 5 === 0) {
          ctx.strokeStyle = 'blue';
        } else {
          ctx.strokeStyle = 'gray';
        }

        if (x === 0) {
          ctx.strokeStyle = axisColor;
          ctx.lineWidth = axisWidth;
        }

        ctx.beginPath();
        ctx.moveTo(x * grid.args.pixelsPerBlock, bottomYPixels);
        ctx.lineTo(x * grid.args.pixelsPerBlock, topYPixels);

        if (!numberLine) {
          ctx.stroke();
        } else if (grid.args.sM) {
          var pix = Math.round(grid.userToPixelV(1) / 2 + 3);

          // I could hardly follow this code enough to figure out where this was expected to come from
          // eslint-disable-next-line no-undef
          line(gr, 0, pix, 0, -pix);
        }
        // horizontal

        ctx.lineWidth = 1;

        if (Math.abs(y) % 5 === 0) {
          ctx.strokeStyle = 'blue';
        } else {
          ctx.strokeStyle = 'gray';
        }

        if (y === 0) {
          ctx.strokeStyle = axisColor;
          ctx.lineWidth = axisWidth;
        }

        ctx.beginPath();
        ctx.moveTo(leftMostXPixels, y * grid.args.pixelsPerBlock);
        ctx.lineTo(rightMostXPixels, y * grid.args.pixelsPerBlock);

        if (y === 0 || !numberLine) {
          ctx.stroke();
        }

        if (y === 0 && numberLine) {
          ctx.beginPath();

          // Default for backwards compatibility
          if (!grid.args.arrowType) grid.args.arrowType = 'BOTH';

          if (grid.args.arrowType != 'NONE') {
            switch (grid.args.arrowType) {
              case 'BOTH':
                arrow(ctx, rightMostXPixels, 0, leftMostXPixels, 0, 'small');
                arrow(ctx, leftMostXPixels, 0, rightMostXPixels, 0, 'small');
                break;
              case 'LEFT_OR_TOP':
                arrow(ctx, rightMostXPixels, 0, leftMostXPixels, 0, 'small');
                break;
              case 'RIGHT_OR_BOTTOM':
                arrow(ctx, leftMostXPixels, 0, rightMostXPixels, 0, 'small');
                break;
            }
          }

          ctx.stroke();
        }
      }

      if (grid.hideTails) {
        ctx.restore();
      }

      // highlight light blue outer box
      if (grid.selected) {
        ctx.save();
        ctx.strokeStyle = getThemeColor();
        ctx.lineWidth = 5;
        // ctx.rect(leftMostXPixels, 0, rightMostXPixels, bottomYPixels);
        ctx.beginPath();
        ctx.lineTo(leftMostXPixels, bottomYPixels, leftMostXPixels, topYPixels);
        ctx.lineTo(leftMostXPixels, topYPixels, rightMostXPixels, topYPixels);
        ctx.lineTo(
          rightMostXPixels,
          topYPixels,
          rightMostXPixels,
          bottomYPixels
        );
        ctx.lineTo(
          rightMostXPixels,
          bottomYPixels,
          leftMostXPixels,
          bottomYPixels
        );
        ctx.closePath();
        ctx.stroke();
        ctx.restore();
      }

      // numberLine
      var minBlock = firstX;
      var maxBlock = lastX;

      if (grid.args.arrowType) {
        switch (grid.args.arrowType) {
          case 'BOTH':
            minBlock++;
            maxBlock--;
            break;
          case 'LEFT_OR_TOP':
            minBlock++;
            break;
          case 'RIGHT_OR_BOTTOM':
            maxBlock--;
            break;
          case 'NONE':
            break;
        }
      }

      if (grid.args.sI) {
        var h = 6;
        var incrementHeightMultiplier = 1.75; // same multiplier used in ProblemAuthoring
        var block = minBlock;

        while (block <= maxBlock) {
          var x = block * grid.args.pixelsPerBlock;

          // check if this is a number line with a label at this position
          var markHasLabel = false;

          if (grid.args.o.p != null) {
            for (var a = 0; a < grid.args.o.p.length; a++) {
              var obj = grid.args.o.p[a];

              if (obj.xAxisLabel && Math.abs(obj.cx - x) == 0) {
                markHasLabel = true;
                break;
              }
            }
          }

          ctx.beginPath();
          ctx.moveTo(x, -h * (markHasLabel ? incrementHeightMultiplier : 1));
          ctx.lineTo(x, h * (markHasLabel ? incrementHeightMultiplier : 1));
          ctx.lineWidth = 2;
          ctx.strokeStyle = 'black';
          ctx.stroke();
          block++;
        }
      }

      var maxVertScaleStampW = 0;
      var maxHorzHeight = 0;
      var xAxisLabelHasFractions = false;

      // Find the widest axis label
      if (grid.args.o.p != null) {
        for (var a = 0; a < grid.args.o.p.length; a++) {
          var obj = grid.args.o.p[a];

          if (obj.yAxisLabel) {
            var line = toLine(
              obj.vl,
              null,
              null,
              null,
              null,
              null,
              null,
              this.getFontFromObj(obj)
            );

            maxVertScaleStampW = Math.max(maxVertScaleStampW, line.viewportW);
          } else if (obj.xAxisLabel) {
            if (this.isFractionObj(obj)) xAxisLabelHasFractions = true;
            var line = toLine(
              obj.vl,
              null,
              null,
              null,
              null,
              null,
              null,
              this.getFontFromObj(obj)
            );

            maxHorzHeight = Math.max(maxHorzHeight, line.viewportH);
          }
        }
      }

      // Units
      if (!grid.scaleStamp) {
        grid.scaleStamp = getText();
        grid.scaleStamp.setFont('bold 12px ' + lineFont);
      }

      if (grid.args.xU) {
        grid.scaleStamp.text = grid.args.xU;
        grid.scaleStamp.sizeMe();
        ctx.save();
        grid.scaleStamp.fillStyle = grid.selected ? 'black' : 'gray';
        ctx.scale(1, -1);
        var tx = rightMostXPixels - grid.scaleStamp.width - 5;
        var ty = maxHorzHeight + (xAxisLabelHasFractions ? 5 : 10);

        ctx.translate(tx, ty);
        grid.scaleStamp.paintMe(ctx);
        ctx.restore();
      }

      if (grid.args.yU) {
        grid.scaleStamp.text = grid.args.yU;
        grid.scaleStamp.sizeMe();
        ctx.save();
        grid.scaleStamp.fillStyle = grid.selected ? 'black' : 'gray';
        var tx = Math.max(
          leftMostXPixels,
          -maxVertScaleStampW - grid.scaleStamp.viewportH - 5
        );
        var ty = topYPixels - grid.scaleStamp.viewportW - 3;

        ctx.translate(tx, ty);
        ctx.rotate(Math.PI / 2);
        ctx.scale(1, -1);
        grid.scaleStamp.paintMe(ctx);
        ctx.restore();
      }

      // Objects (lines, dots, ...)

      if (grid.args.o) {
        if (grid.args.o.p) {
          grid.paintObjects(
            ctx,
            grid.args.o.p,
            true,
            leftMostXPixels,
            rightMostXPixels,
            topYPixels,
            bottomYPixels
          );
        }

        if (grid.args.o.u) {
          ctx.save();
          ctx.globalAlpha = 1;
          grid.paintObjects(
            ctx,
            grid.args.o.u,
            false,
            leftMostXPixels,
            rightMostXPixels,
            topYPixels,
            bottomYPixels
          );
          ctx.restore();
        }
      }

      ctx.restore();
    };

    grid.paintPostClip = function (ctx) {
      paintStatus(grid, ctx);
    };

    return grid;
  };

  var shadeHelper = function (region, x, y) {
    // patch
    var len = region.length;

    if (len > 0) {
      var last = region[len - 1];

      if (last.x == x && last.y == y) return;
    }

    region.push({ x: x, y: y });
  };

  var isSlopeNearlyVertical = function (s) {
    // 6 blocks up, 7 pixels over
    return Math.abs(s) >= 120 / 7;
  };

  var isNearlyHorizontal = function (s) {
    // 7 pixels up, 6 blocks over
    return Math.abs(s) <= 7 / 120;
  };

  var modeE = function (i) {
    var r;

    if (i == 'L') r = 'Line';
    if (i == 'Pa') r = 'Parabola';
    if (i == 'Sh') r = 'Shade';
    if (i == 'R') r = 'Ray';
    if (i == 'Se') r = 'Segment';
    if (i == 'Po') r = 'Point';
    if (i == 'H') r = 'Highlight Point';

    return r ? r : i;
  };

  var getGridGetter = function (x, y, ag) {
    var gag = getBasicObject(x, y);

    gag.gmmName = 'gridGetter';
    gag.agId = ag.agId;
    gag.t = 'g';
    gag.fwd = ag.fwd;
    gag.offAxis = ag.offAxis;
    gag.actingAsNumberLineGetter = ag.actingAsNumberLineGetter;

    gag.axisLabels = ag.axisLabels;

    ag.m = modeE(ag.m);

    if (ag.ms) {
      for (var x = 0; x < ag.ms.length; x++) {
        ag.ms[x] = modeE(ag.ms[x]);
      }
    }

    gag.disabled = true;
    gag.enableOnTouch = true;

    gag.enable = function () {
      gag.grabFocus();
    };

    gag.ms = ag.ms;

    if (gag.ms && !ag.m) {
      ag.m = gag.ms[0];
    }

    gag.grabFocus = function () {
      if (focused) {
        focused.setFocused(false);
      }

      removeKeyboard();
      currentAttemptGetter = gag;

      if (lettersButton != null) {
        lettersButton.staysAnimatedColor = true;
      }

      gag.g.setSelected(true);
    };

    gag.loseFocus = function () {
      gag.g.setSelected(false);
    };

    if (ag.m) {
      var top = getBasicObject();

      var modeShower = getBasicObject();
      var sizer = getText(0, 0, 'Highlight Point');

      modeShower.viewportW = sizer.viewportW + 30;
      modeShower.viewportH = sizer.viewportH * 1.4;
      modeShower.stamp = getText();
      modeShower.stamp.fillStyle = 'lightgray';
      modeShower.stamp.xtraForLowHang = 5;
      modeShower.add(modeShower.stamp);

      gag.mShower = modeShower;

      var justOne = !(gag.ms && gag.ms.length > 1);

      var modeChanger;

      if (!justOne) {
        for (var a = gag.ms.length; a > -1; a--) {
          var mo = gag.ms[a];

          if (
            mo !== 'Point' &&
            mo !== 'Line' &&
            mo !== 'Ray' &&
            mo !== 'Segment' &&
            mo !== 'Shade' &&
            mo != 'Parabola' &&
            mo != 'Highlight Point'
          ) {
            gag.ms.splice(a, 1);
          }
        }

        var listener = function (sel) {
          gag.setMode(sel);
        };

        modeChanger = getBasicObject();
        modeChanger.setAllDim(30, 30);
        modeChanger.animates = true;

        modeChanger.paintMe = function (ctx) {
          if (gag.g.selected && gag.st !== 'c') {
            ctx.save();
            roundRect2(
              ctx,
              0,
              0,
              30,
              30,
              6,
              modeChanger.animated ? getThemeColor() : 'lightgray'
            );

            var w = 30;
            var h = 30;

            ctx.beginPath();
            ctx.moveTo(0.3 * w, 0.35 * h);
            ctx.lineTo(0.7 * w, 0.35 * h);
            ctx.lineTo(w / 2, 0.7 * h);
            ctx.closePath();
            ctx.fillStyle = gag.g.selected ? 'gray' : 'lightgray';
            ctx.fill();

            ctx.restore();
          }
        };

        gag.modeChanger = modeChanger;

        var mdr = function () {
          if (gag.st === 'c') {
            modeChanger.animates = false;
            modeShower.animates = false;

            return false;
          }

          if (!gag.g.selected) {
            gag.grabFocus();

            return true;
          }

          var options = [];

          for (var zz = 0; zz < gag.ms.length; zz++) {
            if (gag.m !== gag.ms[zz]) options.push(gag.ms[zz]);
          }

          if (options.length === 0) return false;

          floater.setPanel(
            getWordMenu(getProblemJSDependencies(), options, listener)
          );

          var tl = getFullTopLeftLocation(gag.top);

          floater.setLocation(
            tl.x + gag.mShower.viewportX,
            tl.y + gag.top.viewportH
          );

          modeChanger.animate(paintCanvas);

          return true;
        };

        modeChanger.mouseDownResponse = mdr;
        modeShower.mouseDownResponse = mdr;
        top.mouseDownResponse = mdr;
      }

      modeShower.viewportY += 4;

      if (justOne) {
        top.add(gag.mShower);
      } else {
        var modeBoss = getBasicObject();

        modeBoss.add(modeChanger);
        modeBoss.add(modeShower);
        modeBoss.layoutChildrenLR(8);
        modeBoss.sizeMeToFitChildren();

        modeBoss.paintPre = function (ctx) {
          if (gag.g.selected && gag.st !== 'c')
            roundRect2(
              ctx,
              0,
              0,
              modeBoss.viewportW,
              modeBoss.viewportH,
              6,
              'lightgray'
            );
        };

        top.add(modeBoss);
      }

      var lineMode = getBasicObject();

      lineMode.states = ['solid', 'dotted'];
      lineMode.i = 0;

      var mouseDown = function () {
        if (gag.st === 'c') {
          lineMode.animates = false;

          return false;
        }

        lineMode.i++;

        if (lineMode.i == lineMode.states.length) {
          lineMode.i = 0;
        }

        var img = lineMode.states[lineMode.i];

        lineMode.mode = img;

        if (gag.g.currentObj && gag.g.currentObj.second) {
          gag.g.currentObj.dotted = img === 'dotted' ? true : false;
        } else if (!gag.g.currentObj) {
          if (gag.g.args.o && gag.g.args.o.u) {
            var us = gag.g.args.o.u;

            if (us.length > 0) {
              var ls = us[us.length - 1];

              if (ls.second) ls.dotted = img === 'dotted' ? true : false;
            }
          }
        }

        lineMode.animated = true;

        return true;
      };

      lineMode.setAllDim(30, 30);
      lineMode.animates = true;

      lineMode.paintMe = function (ctx) {
        var sel = gag.g.selected;

        if (sel && gag.st !== 'c') {
          var col = lineMode.animated ? getThemeColor() : 'lightgray';

          roundRect2(ctx, 0, 0, 30, 30, 6, col);
          var col = sel ? '#FF00FF' : 'lightgray';

          if (lineMode.i === 0) {
            line(ctx, 3, 16, 27, 16, 3, col);
          } else {
            line(ctx, 4, 16, 12, 16, 3, col);
            line(ctx, 18, 16, 26, 16, 3, col);
          }
        }
      };

      lineMode.mouseDownResponse = mouseDown;

      top.add(lineMode);
      top.layoutChildrenLR(15);
      top.sizeMeToFitChildren();
      top.viewportH += 5;

      if (!justOne) gag.add(top);
      else gag.justOneM = true;
      gag.lineMode = lineMode;

      gag.top = top;
    }

    gag.setMode = function (chosen) {
      if (gag.m === chosen) {
        return;
      }

      if (
        chosen === 'Point' ||
        chosen === 'Line' ||
        chosen === 'Ray' ||
        chosen === 'Segment' ||
        chosen === 'Parabola'
      ) {
        if (gag.m !== chosen) {
          gag.m = chosen;
          gag.g.clearCurrentMaybeSave();
        }
      }

      gag.m = chosen;
      gag.mShower.stamp.text = chosen;
      gag.mShower.stamp.sizeMe();
      gag.mShower.centerVertically();
      gag.mShower.stamp.viewportY += 2;

      gag.lineMode.visible =
        chosen === 'Line' ||
        chosen === 'Ray' ||
        chosen === 'Segment' ||
        chosen === 'Shade';
    };

    gag.clearShade = function () {
      var was = gag.g.shadePoint;

      gag.g.shadePoint = undefined;
      gag.g.args.shadePoint = undefined;
      var u = gag.g.args.o.u;

      if (u) {
        for (var a = 0; a < u.length; a++) {
          var o = u[a];

          o.shade = undefined;

          if (was && o.t === 'dot') {
            u.splice(a, 1);
            a--;
          }
        }
      }

      // bug patch, don't really like screwing around with this
      // cuz maybe sometime I'll have a permanent shade on an editable grid!
      if (gag.g.args.o.p) {
        for (var a = 0; a < gag.g.args.o.p.length; a++) {
          gag.g.args.o.p[a].shade = undefined;
        }
      }
    };

    gag.clearAllDots = function () {
      var objs = grid.args.o.u;

      for (var a = objs.length - 1; a >= 0; a--) {
        if (objs[a].t === 'dot') {
          objs.splice(a, 1);
        }
      }

      pointCount = 0;
    };

    gag.addHighlightDot = function (x, y) {
      var objs = grid.args.o.u;
      var point = {};

      point.t = 'dot';
      point.cx = x;
      point.cy = y;
      point.r = 7;
      point.color = '#B93B8F';
      point.high = true;
      pointCount++;

      objs.push(point);
    };

    gag.maybeUndoSelectedLineEntryButton = function () {
      if (!gag.selectedButton) return false;
      gag.selectedButton = undefined;
      gag.deselectAllButtons();
      submitAttempt({
        blank: true,
        eventSource: 'gag.maybeUndoSelectedLineEntryButton',
      });

      return true;
    };

    var grid = getGrid(ag.g);

    gag.e = true;

    grid.setShadePoint = function (pt) {
      var hasLineRayOrSegment = false;

      var objs = grid.args.o.u;

      // don't allow shade with > 5 objects, not including any points
      // could probably be 3, but this will potentially allow for wrong attempts
      if (
        objs &&
        objs.length -
          objs.reduce(
            (count, element) => (element.t === 'dot' ? count + 1 : count),
            0
          ) >
          5
      ) {
        getProblemContext().addStackableDialog({
          msg:
            'You cannot use the shade tool with more than five objects on your graph.',
          top: 'Invalid Shade Attempt',
        });

        return 'invalid';
      }

      // check for various object situations where we wouldn't want to allow shading
      if (objs && objs.length > 0) {
        for (var a = 0; a < objs.length; a++) {
          var obj = objs[a];

          // we currently don't support shading with parabolas, so not mentioned here, but we may in the future
          if (obj.t !== 'dot' && obj.t !== 'Parabola' && obj.t !== 'Shade') {
            // for four-quadrant graphs, only support shading with lines
            if (!grid.args.eF && (obj.t === 'Ray' || obj.t === 'Segment')) {
              getProblemContext().addStackableDialog({
                msg:
                  'You cannot use the shade tool with anything other than lines in a four-quadrant graph.',
                top: 'Invalid Shade Attempt',
              });

              return 'invalid';
            }

            // for first quadrant graphs, check that segments and rays have endpoints on the axes
            if (grid.args.eF) {
              var margin = 5;

              // check for valid segments for shading
              if (obj.t === 'Segment') {
                if (
                  !(
                    (Math.abs(obj.first.cx) <= margin &&
                      Math.abs(obj.second.cy) <= margin) ||
                    (Math.abs(obj.first.cy) <= margin &&
                      Math.abs(obj.second.cx) <= margin)
                  ) ||
                  (Math.abs(obj.first.cx) <= margin &&
                    Math.abs(obj.first.cy) <= margin) ||
                  (Math.abs(obj.second.cx) <= margin &&
                    Math.abs(obj.second.cy) <= margin)
                ) {
                  getProblemContext().addStackableDialog({
                    msg:
                      'When shading with segments, each endpoint of any segment should be on a different axis and not at the origin.',
                    top: 'Invalid Shade Attempt',
                  });

                  return 'invalid';
                }
              }

              // check for valid rays for shading
              if (obj.t === 'Ray') {
                if (
                  !(
                    Math.abs(obj.first.cx) <= margin ||
                    Math.abs(obj.first.cy) <= margin
                  )
                ) {
                  getProblemContext().addStackableDialog({
                    msg:
                      'When shading with rays, the endpoint of the each ray should be on one of the axes.',
                    top: 'Invalid Shade Attempt',
                  });

                  return 'invalid';
                }
              }
            }
          }
        }
      }

      if (!objs || objs.length === 0) {
        objs = grid.args.o.p;
      }

      if (gag.fwd) {
        if (objs) {
          var maybes = [];
          var xM = grid.width;
          var yM = grid.height;

          for (var xP = 0; xP <= xM; xP += grid.args.pixelsPerBlock) {
            for (var yP = 0; yP <= yM; yP += grid.args.pixelsPerBlock) {
              maybes.push([xP, yP]);
            }
          }

          for (var a = 0; a < objs.length; a++) {
            var obj = objs[a];

            if (obj.t === 'Line' || obj.t === 'Segment' || obj.t === 'Ray') {
              hasLineRayOrSegment = true;
              gag.clearAllDots();

              if (obj.second) {
                if (obj.first.cx == obj.second.cx) {
                  for (var i = 0; i < maybes.length; i++) {
                    var maybe = maybes[i];

                    if (pt.x <= obj.first.cx) {
                      if (maybe[0] <= obj.first.cx) {
                        gag.addHighlightDot(maybe[0], maybe[1]);
                      }
                    } else if (maybe[0] >= obj.first.cx) {
                      gag.addHighlightDot(maybe[0], maybe[1]);
                    }
                  }
                } else {
                  var m =
                    (obj.first.cy - obj.second.cy) /
                    (obj.first.cx - obj.second.cx);
                  var b = obj.first.cy - m * obj.first.cx;
                  var out = m * pt.x + b;
                  var typer = out >= pt.y ? 'L' : 'G';

                  for (var i = 0; i < maybes.length; i++) {
                    var maybe = maybes[i];

                    out = m * maybe[0] + b;

                    if (typer == 'L' && maybe[1] <= out) {
                      gag.addHighlightDot(maybe[0], maybe[1]);
                    } else if (typer == 'G' && maybe[1] >= out) {
                      gag.addHighlightDot(maybe[0], maybe[1]);
                    }
                  }
                }
              }
            }
          }
        }
      } else {
        if (objs) {
          for (var a = 0; a < objs.length; a++) {
            var obj = objs[a];

            if (obj.t === 'Line' || obj.t === 'Segment' || obj.t === 'Ray') {
              hasLineRayOrSegment = true;

              if (obj.second) {
                if (Math.abs(obj.first.cx - obj.second.cx) <= 3) {
                  obj.shade = pt.x <= obj.first.cx ? 'L' : 'G';
                } else {
                  var m =
                    (obj.first.cy - obj.second.cy) /
                    (obj.first.cx - obj.second.cx);
                  var b = obj.first.cy - m * obj.first.cx;
                  var out = m * pt.x + b;

                  obj.shade = out >= pt.y ? 'L' : 'G';
                }
              }
            }
          }
        }
      }

      if (hasLineRayOrSegment) {
        grid.shadePoint = pt;
        grid.args.shadePoint = pt;
      }
    };

    grid.mouseDownResponse = function (x, y) {
      if (!gag.e) {
        return false;
      }

      if (gag.st === 'c') {
        return false;
      }

      changedMaybe();

      var saveX;
      var saveY;

      var wasF = currentAttemptGetter == gag;

      gag.grabFocus();
      if (!wasF) return true;

      gag.maybeUndoSelectedLineEntryButton();

      y = -y;

      x = Math.round(x * 100) / 100;
      y = Math.round(y * 100) / 100;

      if (gag.actingAsNumberLineGetter) y = 0;

      var dotForGridGuide;
      var currentObjForGridGuide;
      var killGridGuide = false;

      if (!grid.args.o) {
        grid.args.o = {};
      }

      if (!grid.args.o.u) {
        grid.args.o.u = [];
      }

      if (grid.args.stg) {
        saveX = x;
        saveY = y;
        var px = Math.abs(x);
        var xtra = px % grid.args.pixelsPerBlock;

        if (x > 0) {
          x =
            xtra > grid.args.pixelsPerBlock / 2
              ? x + (grid.args.pixelsPerBlock - xtra)
              : x - xtra;
        } else if (x < 0) {
          x =
            xtra > grid.args.pixelsPerBlock / 2
              ? x - (grid.args.pixelsPerBlock - xtra)
              : x + xtra;
        }

        var py = Math.abs(y);

        xtra = py % grid.args.pixelsPerBlock;

        if (y > 0) {
          y =
            xtra > grid.args.pixelsPerBlock / 2
              ? y + (grid.args.pixelsPerBlock - xtra)
              : y - xtra;
        } else if (y < 0) {
          y =
            xtra > grid.args.pixelsPerBlock / 2
              ? y - (grid.args.pixelsPerBlock - xtra)
              : y + xtra;
        }

        if (grid.args.eF) {
          var d = grid.viewportW - grid.viewportSlideX;

          if (x < 0 || y < 0 || x > d || y > d) {
            return false;
          }
        } else {
          var d = grid.viewportW / 2;

          if (x > d || y < -d || x > d || y < -d) {
            return false;
          }
        }
      }

      if (grid.args.sta && gag.m !== 'Shade') {
        if (Math.abs(x) <= grid.args.pixelsPerBlock / 2) {
          x = 0;
        }

        if (Math.abs(y) <= grid.args.pixelsPerBlock / 2) {
          y = 0;
        }
      }

      if (grid.args.eF && (x < 0 || y < 0)) return false;

      var objs = grid.args.o.u;

      if (gag.m === 'Point' || gag.m === 'Highlight Point') {
        var point = {};

        point.t = 'dot';

        if (gag.m === 'Highlight Point') {
          point.cx = x;
          point.cy = y;
          point.r = 7;
          point.color = '#B93B8F';
          point.high = true;
        } else {
          point.cx = x;
          point.cy = y;
          point.r = 5;
          point.color = defaultPointColor;
        }

        var remove = false;

        for (var a = 0; a < objs.length; a++) {
          if (objs[a].t === 'dot') {
            // Clicking on the same point
            if (objs[a].cx == x && objs[a].cy == y) {
              objs.splice(a, 1);
              remove = true;
              break;
            }

            if (!objs[a].high && !gag.multiInput) {
              objs[a].color = 'red';
            }

            pointCount++;
          }
        }

        currentObjForGridGuide = undefined;

        if (!remove) {
          objs.push(point);

          if (!grid.args.stg) {
            dotForGridGuide = point;
          }
        } else {
          dotForGridGuide = undefined;

          if (gridGuide) {
            gridGuide.on = false;
          }
        }
      } else if (gag.m === 'Shade') {
        var useX = saveX ? saveX : x;
        var useY = saveY ? saveY : y;

        grid.setShadePoint({ x: useX, y: useY });
      } else {
        var point = {};

        point.t = 'dot';
        point.cx = x;
        point.cy = y;
        point.r = 5;
        point.color = defaultPointColor;

        if (grid.currentObj && grid.currentObj.second) {
          grid.currentObj = undefined;
        }

        if (!grid.currentObj) {
          grid.currentObj = { t: gag.m, color: defaultPointColor };
          grid.currentObj.first = point;

          grid.addLiney(grid.currentObj);

          if (gag.m === 'Parabola') {
            grid.currentObj.userParabola = true;
          }
        } else {
          if (
            gag.m === 'Ray' &&
            grid.args.eF &&
            isAxial(grid.currentObj.first, true) &&
            !isAxial(grid.currentObj.first, false, true)
          ) {
            point.cx = grid.currentObj.first.cx;
          } else if (gag.m === 'Line' || gag.m === 'Segment') {
            var p1 = grid.currentObj.first;
            var p2 = point;
            var x1 = p1.cx;
            var y1 = p1.cy;
            var x2 = p2.cx;
            var y2 = p2.cy;

            if (x1 !== x2 && y1 !== y2) {
              var sl = (y2 - y1) / (x2 - x1);

              if (isSlopeNearlyVertical(sl)) {
                p2.cx = p1.cx;
                killGridGuide = true;
              } else if (isNearlyHorizontal(sl)) {
                p2.cy = p1.cy;
                killGridGuide = true;
                if (gridGuide) gridGuide.on = false;
              }
            }
          } else if (gag.m === 'Parabola' && grid.currentObj.first) {
            var diffVariance = gridGuide.parabolaVertexThreshold;
            var cxDiff = Math.abs(grid.currentObj.first.cx - point.cx);
            var cyDiff = Math.abs(grid.currentObj.first.cy - point.cy);

            if (
              Math.round(cxDiff, 0) <= diffVariance ||
              Math.round(cyDiff, 0) <= diffVariance
            ) {
              getProblemContext().addStackableDialog({
                msg:
                  'Your vertex cannot have the same x- or y-coordinate as any other point.',
                top: 'Invalid Parabola',
              });

              return true;
            }
          }

          grid.currentObj.second = point;

          if (gag.lineMode.mode === 'dotted') {
            grid.currentObj.dotted = true;
          }
        }

        // Make sure the number of graphed objects does not exceed our allowed maximums
        if (grid.args.o.u && grid.isAboveItemLimit(grid.args.o.u)) {
          // Clear the objects
          grid.currentObj = undefined;
          point = undefined;
        }

        dotForGridGuide = point;
        currentObjForGridGuide = grid.currentObj;
      }

      if (dotForGridGuide) {
        setGridGuideToDot(dotForGridGuide, gag.g);
      }

      if (currentObjForGridGuide) {
        gridGuide.currentObj = currentObjForGridGuide;
      }

      if (killGridGuide) gridGuide.on = false;

      return true;
    };

    gag.setStatus = function (st) {
      ag.s = st;
      ag.st = st;
      gag.g.st = st;
      gag.st = st;
      gag.e = st !== 'c';
    };

    gag.add(grid);
    gag.g = grid;
    grid.getter = gag;

    // for now, ignore clear on bottom
    var submitRow = getBasicObject();

    gag.clearF = function (btn) {
      changedMaybe();
      gag.g.args.o.u = [];

      if (gridGuide) {
        gridGuide.on = false;
        gridGuide.dot = undefined;
        gridGuide.currentObj = undefined;
      }

      gag.g.currentObj = undefined;
      gag.clearShade();

      if (btn) ag.selectedButton = btn;
      else gag.deselectAllButtons();

      return true;
    };

    var clear = getBasicObject();

    clear.setAllDim(40, 40);

    clear.paintMe = function (ctx) {
      ctx.save();

      if ((gag.g.selected && gag.st !== 'c') || clear.animated) {
        roundRect2(
          ctx,
          0,
          0,
          40,
          40,
          6,
          clear.animated ? getThemeColor() : 'lightgray'
        );
      }

      var w = clear.viewportW;
      var h = clear.viewportH;
      var c = !gag.g.selected || gag.st === 'c' ? 'trashlight' : 'trash';
      var i = getImage(c, paintCanvas);

      drawImage(ctx, i, 0.2 * w, 0.15 * h, 0.6 * w, 0.7 * h);

      ctx.restore();
    };

    clear.animates = true;

    clear.mouseDownResponse = function () {
      if (gag.st === 'c') {
        clear.animates = false;

        return false;
      }

      gag.clearF();

      // true --> consume event, no mouse reaction by parents!
      return true;
    };

    submitRow.add(clear);

    if (ag.allowSelect) {
      var gearIcon = getBasicObject();
      var clickGearIcon = function () {
        if (gag.st === 'c') {
          gearIcon.animates = false;

          return false;
        }

        axesModalState().setXScale(gag.g.args.uPH);
        axesModalState().setYScale(gag.g.args.uPV);
        axesModalState().setXLabel(gag.g.args.xU || '');
        axesModalState().setYLabel(gag.g.args.yU || '');
        axesModalState().setShowLabelPickers(!ag.hlcb);
        axesModalState().setLabelChoices(gag.axisLabels);
        setAxesModalGridGetter(gag);
        axesModalState().setVisible(true);

        return true;
      };

      gearIcon.mouseDownResponse = clickGearIcon;
      var img = 'gear';
      var image = getImage(img, paintCanvas);

      gearIcon.image = image;
      gearIcon.pale = getImage('gear2', paintCanvas);
      let w = 40;
      var h = 40;

      gearIcon.setAllDim(w, h);
      gearIcon.animates = true;

      gearIcon.paintMe = function (ctx) {
        ctx.save();
        var w = 40;
        var h = 40;
        var aW = gearIcon.viewportW - gearIcon.viewportMargin * 2;
        var aH = gearIcon.viewportH - gearIcon.viewportMargin * 2;

        if ((gag.g.selected || gearIcon.animated) && gag.st != 'c') {
          roundRect2(
            ctx,
            0,
            0,
            w,
            h,
            6,
            gearIcon.animated ? getThemeColor() : 'lightgray'
          );
        }

        drawImage(
          ctx,
          gag.g.selected ? gearIcon.image : gearIcon.pale,
          aW / 2 - gearIcon.image.width / 2,
          aH / 2 - gearIcon.image.height / 2
        );

        ctx.restore();
      };

      submitRow.add(gearIcon);
    }

    gag.updateFromGridDialog = () => {
      var grid = gag.g;
      var args = grid.args;

      var xScalePrev = args.uPH;
      var yScalePrev = args.uPV;

      var xScale = axesModalState().xScale;
      var yScale = axesModalState().yScale;

      args.uPH = xScale;
      args.uPV = yScale;

      var xLabel = axesModalState().xLabel;

      if (xLabel !== '') args.xU = xLabel;

      var yLabel = axesModalState().yLabel;

      if (yLabel !== '') args.yU = yLabel;

      changedMaybe();

      // Check for rescaling
      if (xScale != xScalePrev || yScale != yScalePrev) {
        var ctx = $canvas[0].getContext('2d');

        for (var a = 0; a < args.o.p.length; a++) {
          var obj = args.o.p[a];

          if (obj.xAxisLabel && xScale != xScalePrev) {
            var value = obj.vl.replace('<t>', '').replace('</t>', '');
            var first = value.charAt(0);
            var firstIsNan = isNaN(first);

            if (firstIsNan) value = value.substring(1);

            value /= xScalePrev;
            value *= xScale;
            obj.vl = '<t>' + (firstIsNan ? first : '') + value + '</t>';
          }

          if (obj.yAxisLabel && yScale != yScalePrev) {
            var value = obj.vl.replace('<t>', '').replace('</t>', '');
            var first = value.charAt(0);
            var firstIsNan = isNaN(first);

            if (firstIsNan) value = value.substring(1);

            // Shift back based on original size
            obj.cx += ctx.measureText(value).width / 2;

            // Rescale
            value /= yScalePrev;
            value *= yScale;

            // Shift forward based on new size
            obj.cx -= ctx.measureText(value).width / 2;
            obj.vl = '<t>' + (firstIsNan ? first : '') + value + '</t>';
          }
        }
      }

      paintCanvas();
    };

    var submitButton = getSubmitButton(
      gag,
      paintCanvas,
      submitAttempt,
      getThemeColor,
      'grid'
    );

    submitRow.add(submitButton);
    submitRow.setAllDim(
      clear.viewportW + 15 + submitButton.viewportW,
      Math.max(clear.viewportH, submitButton.viewportH)
    );
    submitRow.layoutChildrenLR(15);
    submitRow.sizeMeToFitChildren();
    submitRow.centerVertically();

    gag.add(submitRow);

    var bs = buttons[gag.agId + ''];
    var br;

    if (bs) {
      br = getBasicObject();

      for (var a = 0; a < bs.length; a++) {
        (function (z) {
          var key = getAnswerButton(bs[z]);

          br.add(key);
          key.sel = ag.selectedButton === bs[z];
        })(a);
      }

      br.layoutChildrenLR(10);
      br.centerHorizontally();
      br.sizeMeToFitChildren();
      gag.add(br);
    }

    gag.deselectAllButtons = function () {
      ag.selectedButton = undefined;

      if (br) {
        for (var j = 0; j < br.children.length; j++) {
          br.children[j].sel = false;
        }
      }
    };

    var vW = 0;
    var vH = 0;

    if (ag.m && !gag.justOneM) {
      vW += 5 + gag.top.viewportW;
      vH += gag.top.viewportH;
      gag.g.viewportY += vH;
    }

    submitRow.viewportY = grid.viewportY + grid.viewportH + 5;

    vW = Math.max(gag.g.viewportW, vW);
    vH += gag.g.viewportH + 5 + submitRow.viewportH;

    // if there are extra submit buttons, add them above the submit row
    if (br) {
      br.viewportY = submitRow.viewportY + 5;
      submitRow.viewportY = br.viewportY + br.viewportH + 10;
      vH += br.viewportH + 10;
    }

    gag.setAllDim(vW, vH);

    gag.centerHorizontally();
    gag.top.viewportX += 20;

    gag.insertAttemptIntoJSON = function () {
      var attempt;

      if (!grid.args.o.u) {
        attempt = { objects: [] };
      } else {
        attempt = {};

        attempt.objects = [];

        var myDots = [];
        var dotsHigh = false;

        var objs = grid.args.o.u;

        if (objs && objs.length > 0) {
          for (var a = objs.length - 1; a > -1; a--) {
            var obj = objs[a];

            if (obj.t === 'Parabola') {
              obj.path = null;
              obj.ar1 = null;
              obj.ar2 = null;
            }

            if (obj.t === 'dot') {
              myDots.push([obj.cx, obj.cy]);
              if (obj.high) dotsHigh = true;
            } else {
              // Check for no second point or a second point equal to the first
              if (
                !obj.second ||
                (obj.t != 'Line' &&
                  obj.first.cx == obj.second.cx &&
                  obj.first.cy == obj.second.cy)
              ) {
                var na = obj.t.toLowerCase();

                getProblemContext().addStackableDialog({
                  msg: `Hmm, you appear to have left a ${na} incomplete.`,
                  top: `Incomplete ${na}?`,
                });

                return 'invalid';
              } else {
                if (
                  obj.t === 'Ray' ||
                  obj.t === 'Line' ||
                  obj.t === 'Parabola'
                ) {
                  if (obj.t === 'Parabola') {
                    obj.showArrows = true;
                  }

                  obj.hideFirst = false;
                  obj.hideSecond = false;
                }
              }

              attempt.objects.push(obj);
            }
          }
        }

        if (myDots.length > 0) {
          attempt.dots = myDots;
          if (dotsHigh) attempt.dotsHigh = 't';
        }
      }

      var args = gag.g.args;

      attempt.set = {
        xS: args.uPH,
        yS: args.uPV,
        xU: args.xU,
        yU: args.yU,
      };

      if (grid.shadePoint) {
        attempt.sP = grid.shadePoint;
      }

      return attempt;
    };

    if (ag.m) {
      gag.setMode(ag.m);
    }

    gag.suppressPointCountOff = ag.g.sO;

    gag.multiInput = ag.g.mI;

    if (gag.lineMode !== undefined) {
      var us = gag.g.args.o.u;

      if (us && us.length > 0) {
        if (us[us.length - 1].dotted) {
          gag.lineMode.i = 1;
        }
      }
    }

    // Required getter function
    gag.serializeAttempt = function () {
      var g = gag.insertAttemptIntoJSON();

      return g !== 'invalid' ? g : undefined;
    };

    if (gag.g.args && gag.g.args.shadePoint) {
      gag.g.setShadePoint(gag.g.args.shadePoint);
    }

    return gag;
  };

  var isAxial = function (pt, xOnly, yOnly) {
    if (xOnly && yOnly) throw "Can't be both xOnly and yOnly";
    if (!xOnly && !yOnly) return pt.cx === 0 || pt.cy === 0;

    return xOnly ? pt.cy === 0 : pt.cx === 0;
  };

  var distanceC = function (a, b) {
    return distance(a.cx, a.cy, b.cx, b.cy);
  };

  var distance = function (x1, y1, x2, y2) {
    return Math.pow(Math.pow(x1 - x2, 2) + Math.pow(y1 - y2, 2), 0.5);
  };

  var getGridGuide = function () {
    gridGuide = {};
    gridGuide.ignoreNextGridMouseDown = false;
    gridGuide.buttons = [];
    gridGuide.clickSlideDelta = 2;
    gridGuide.parabolaVertexThreshold = 3;
    gridGuide.axisAdjustment = gridGuide.parabolaVertexThreshold * 2;

    gridGuide.checkForSnapToVertHor = function (pre, post, adjusted) {
      var grid = gridGuide.grid;

      if (!grid.currentObj) return;
      if (grid.currentObj === gridGuide.dot) return;

      var x2 = pre.cx;
      var y2 = pre.cy;
      var x3 = post.cx;
      var y3 = post.cy;
      var d = Math.abs(x3);

      // Snaps the dot to x axis
      if (
        d < 10 &&
        ((x2 < 0 && x3 < 0) || (x2 > 0 && x3 > 0)) &&
        Math.abs(x3) < Math.abs(x2)
      ) {
        // Need to check if this is going to make the value invalid for Parabolas
        var newDot = { cx: 0, cy: post.cy };

        if (!gridGuide.invalidDestinationDot(newDot)) {
          post.cx = 0;
        }
      }

      d = Math.abs(y3);

      // Snaps the dot to the y axis
      if (
        d < 10 &&
        ((y2 < 0 && y3 < 0) || (y2 > 0 && y3 > 0)) &&
        Math.abs(y3) < Math.abs(y2)
      ) {
        // Need to check if this is going to make the value invalid for Parabolas
        var newDot = { cx: post.cx, cy: 0 };

        if (!gridGuide.invalidDestinationDot(newDot)) {
          post.cy = 0;
        }
      }

      if (!grid.currentObj.first) return;
      if (grid.currentObj.first === gridGuide.dot) return;
      var first = grid.currentObj.first;

      d = distanceC(first, post);
      if (d < 10) return;

      var x1 = first.cx;
      var y1 = first.cy;

      var was = x1 == x2 || y1 == y2;

      if (was) return;

      var is = x1 == post.cx && y1 == post.cy;

      if (is) return;

      var preS = (y2 - y1) / (x2 - x1);
      var postS = (post.cy - y1) / (post.cx - x1);

      // Skip this check if the dot has already been adjusted
      if (!adjusted && ((preS < 0 && postS > 0) || (preS > 0 && postS < 0))) {
        if (isSlopeNearlyVertical(postS)) post.cx = first.cx;
        else if (isNearlyHorizontal(postS)) post.cy = first.cy;
      }
    };

    var w1, w2, w3;
    var m = 4;

    if (!narrow) {
      w2 = 40;
      w3 = 120;
      w1 = 120;
    } else {
      w2 = 55;
      w3 = 245;
      w1 = 190;
      m = 15;
    }

    var arVLong = 0.45 * w3;
    var arVShort = 0.25 * (2 * w2 + w1);

    var arHLong = 0.33 * (2 * w2 + w1);
    var arHShort = 0.33 * w3;
    var lx = 0;
    var rx = 2 * w2 + w1 - arHLong;
    var ly = w2 + 0.33 * w3;
    var ry = ly;
    var ux = w2 + w1 / 2 - arVShort / 2;
    var dx = ux;
    var uy = w2;
    var dy = w2 + w3 - arVLong;

    var marginP = (1 - 0.66 - 0.25) / 4;

    var lhy = w2 + 0.25 * w3;
    var lhx = 0;
    var horHitW = arHLong + marginP * (2 * w2 + w1);
    var rhx = 2 * w2 + w1 - horHitW;
    var horHitH = 0.5 * w3;

    var uhy = uy;
    var uhx = ux - marginP * (2 * w2 + w1);
    var dhy = w2 + 0.5 * w3;
    var vertHitW = (2 * marginP + 0.25) * (2 * w2 + w1);
    var vertHitH = w3 / 2;

    var adj = 10;

    if (narrow) adj = 40;

    gridGuide.getW = function () {
      return 2 * w2 + w1;
    };

    gridGuide.getH = function () {
      return w2 + w3;
    };

    gridGuide.invalidDestinationDot = function (dot) {
      if (
        gridGuide.currentObj &&
        gridGuide.currentObj.t === 'Parabola' &&
        gridGuide.currentObj.first &&
        gridGuide.currentObj.second &&
        dot
      ) {
        var cxDiff = Math.abs(gridGuide.currentObj.first.cx - dot.cx);
        var cyDiff = Math.abs(gridGuide.currentObj.first.cy - dot.cy);

        return (
          Math.round(cxDiff, 0) <= gridGuide.parabolaVertexThreshold ||
          Math.round(cyDiff, 0) <= gridGuide.parabolaVertexThreshold
        );
      }

      return false;
    };

    gridGuide.triggerTimeoutAndIgnoreGridMouseDown = function () {
      var that = gridGuide;

      setTimeout(function () {
        that.ignoreNextGridMouseDown = false;
      }, 500);
    };

    gridGuide.mouseDownResponse = function (x, y) {
      x -= gridGuide.cx - (w1 + 2 * w2) / 2;
      y -= gridGuide.cy - (w3 + w2) / 2;

      var px = gridGuide.dot.cx;
      var py = gridGuide.dot.cy;

      var pre = { cx: px, cy: py };

      var hit = false;
      var off = false;
      var needsAjusting = false;

      if (x < 0 || x > 2 * w2 + w1 || y < 0 || y > w2 + w3) {
        off = true;
      } else if (x > w2 + w1 - adj && y < w2 + adj) {
        // Trash-can button click
        gridGuide.grid.gridGuideX();
        gridGuide.on = false;

        gridGuide.ignoreNextGridMouseDown = true;
        gridGuide.triggerTimeoutAndIgnoreGridMouseDown();

        hit = true;
      } else if (
        x <= lhx + horHitW &&
        x >= lx &&
        y >= lhy &&
        y <= lhy + horHitH
      ) {
        // Left-arrow button click
        var d = gridGuide.grid.args.stg
          ? gridGuide.grid.args.pixelsPerBlock
          : gridGuide.clickSlideDelta;

        gridGuide.dot.cx -= d;
        // if invalid move the dot one more to the left
        needsAjusting = gridGuide.invalidDestinationDot(gridGuide.dot);

        if (needsAjusting) {
          gridGuide.dot.cx -= gridGuide.grid.args.stg
            ? d
            : d + gridGuide.axisAdjustment;
        }

        hit = true;
        gridGuide.leftA = getThemeColor();
        setTimeout(function () {
          gridGuide.leftA = false;
          paintCanvas();
        }, 400);
      } else if (
        x >= rhx &&
        x <= rhx + horHitW &&
        y >= lhy &&
        y <= lhy + horHitH
      ) {
        // Right-arrow button click
        var d = gridGuide.grid.args.stg
          ? gridGuide.grid.args.pixelsPerBlock
          : gridGuide.clickSlideDelta;

        gridGuide.dot.cx += d;
        // if invalid move the dot one more to the right
        needsAjusting = gridGuide.invalidDestinationDot(gridGuide.dot);

        if (needsAjusting) {
          gridGuide.dot.cx += gridGuide.grid.args.stg
            ? d
            : d + gridGuide.axisAdjustment;
        }

        hit = true;
        gridGuide.rightA = getThemeColor();
        setTimeout(function () {
          gridGuide.rightA = false;
          paintCanvas();
        }, 400);
      } else if (
        y >= uhy - 10 &&
        y <= uhy + vertHitH &&
        x >= uhx &&
        x <= uhx + vertHitW
      ) {
        // Up-arrow button click
        var d = gridGuide.grid.args.stg
          ? gridGuide.grid.args.pixelsPerBlock
          : gridGuide.clickSlideDelta;

        gridGuide.dot.cy += d;
        // if invalid move the dot one more up
        needsAjusting = gridGuide.invalidDestinationDot(gridGuide.dot);

        if (needsAjusting) {
          gridGuide.dot.cy += gridGuide.grid.args.stg
            ? d
            : d + gridGuide.axisAdjustment;
        }

        hit = true;
        gridGuide.upA = getThemeColor();
        setTimeout(function () {
          gridGuide.upA = false;
          paintCanvas();
        }, 400);
      } else if (
        y >= dhy &&
        y <= dhy + vertHitH &&
        x >= uhx &&
        x <= uhx + vertHitW
      ) {
        // Down-arrow button click
        var d = gridGuide.grid.args.stg
          ? gridGuide.grid.args.pixelsPerBlock
          : gridGuide.clickSlideDelta;

        gridGuide.dot.cy -= d;
        // if invalid move the dot one more down
        needsAjusting = gridGuide.invalidDestinationDot(gridGuide.dot);

        if (needsAjusting) {
          gridGuide.dot.cy -= gridGuide.grid.args.stg
            ? d
            : d + gridGuide.axisAdjustment;
        }

        hit = true;
        gridGuide.downA = getThemeColor();
        setTimeout(function () {
          gridGuide.downA = false;
          paintCanvas();
        }, 400);
      }

      if (hit && gridGuide.dot) {
        // Used by left, right, up and down arrow
        gridGuide.checkForSnapToVertHor(pre, gridGuide.dot, needsAjusting);

        var g = gridGuide.grid;
        var mx = gridGuide.dot.cx;
        var my = gridGuide.dot.cy;

        var actingAsNumberLineGetter =
          g.getter && g.getter.actingAsNumberLineGetter;

        if (actingAsNumberLineGetter) {
          // don't allow vertical movement (moving off numberline axis)
          gridGuide.dot.cy = py;

          if (gridGuide.dot.cx < g.leftMostPixelX()) {
            gridGuide.dot.cx = g.leftMostPixelX();
          } else if (gridGuide.dot.cx > g.rightMostPixelX()) {
            gridGuide.dot.cx = g.rightMostPixelX();
          }
        } else if (g.args.eF) {
          // Single Quadrant Graph
          var d = g.viewportW - g.viewportSlideX;

          if (mx < 0 || my < 0 || mx > d || my > d) {
            gridGuide.dot.cx = px;
            gridGuide.dot.cy = py;
          }
        } else {
          var d = g.viewportW / 2;

          if (mx > d || mx < -d || my > d || my < -d) {
            gridGuide.dot.cx = px;
            gridGuide.dot.cy = py;
          }
        }
      }

      if (off || (!hit && x < w2 + adj && y < w2 + adj)) {
        gridGuide.on = false;
        gridGuide.ignoreNextGridMouseDown = true;
        gridGuide.triggerTimeoutAndIgnoreGridMouseDown();

        return !off;
      }

      return true;
    };

    gridGuide.err = 2;

    gridGuide.paintMe = function (ctx) {
      ctx.save();

      ctx.globalAlpha = narrow ? 0.3 : 0.7;

      ctx.translate(
        gridGuide.cx - (w1 + 2 * w2) / 2,
        gridGuide.cy - (w3 + w2) / 2
      );
      ctx.fillStyle = 'lightgray';
      ctx.fillRect(0, 0, 2 * w2 + w1, w2 + w3);

      var iw = w2 - 2 * m + adj;

      drawImage(ctx, getImage('check', paintCanvas), m + 5, m, iw, iw);
      drawImage(
        ctx,
        getImage('trash', paintCanvas),
        w2 + w1 + m - adj + 0.1 * iw,
        m,
        0.8 * iw,
        iw
      );

      ctx.translate(lx, ly);
      arrowLeft(ctx, arHLong, arHShort, 3, 'gray', gridGuide.leftA);
      ctx.translate(-lx, -ly);

      ctx.translate(rx, ry);
      arrowRight(ctx, arHLong, arHShort, 3, 'gray', gridGuide.rightA);
      ctx.translate(-rx, -ry);

      ctx.translate(ux, uy);
      arrowUp(ctx, arVShort, arVLong, 3, 'gray', gridGuide.upA);
      ctx.translate(-ux, -uy);
      ctx.translate(dx, dy);
      arrowDown(ctx, arVShort, arVLong, 3, 'gray', gridGuide.downA);
      ctx.translate(-dx, -dy);

      ctx.restore();

      ctx.save();

      var g = gridGuide.grid;
      var dot = gridGuide.dot;
      var gc = g.gridCoordinatesToPixelsFromTopLeftOfGrid(dot.cx, dot.cy);
      var tl = getFullTopLeftLocation(g);

      circle(ctx, gc.x + tl.x, gc.y + tl.y, 6, 'magenta');

      ctx.restore();
    };

    return gridGuide;
  };

  var setGridGuideToDot = function (dot, grid) {
    if (!gridGuide) {
      gridGuide = getGridGuide();
    }

    var bx, by;
    var w = gridGuide.getW();
    var h = gridGuide.getH();

    if (narrow) {
      bx = 150;
      by = 150;
    } else {
      bx = 300 + w / 2 + (0.5 * (PROBLEM_WIDTH - 300) - w) / 2;
      by = 150;
    }

    var objLoc = getFullTopLeftLocation(grid);

    gridGuide.cx = objLoc.x + bx;
    gridGuide.cy = objLoc.y + by;
    gridGuide.on = true;
    gridGuide.dot = dot;
    gridGuide.grid = grid;
  };

  var getNormalGetter = function ({
    x,
    y,
    jsonFromServer,
    lines,
    row,
    agId,
    centerMe,
  }) {
    var ret = getBasicObject(x, y);

    ret.lines = lines;
    ret.row = row;
    ret.agId = agId;
    ret.centerMe = centerMe;

    var vH;
    var vW;

    if (jsonFromServer) {
      ret.maxLines = jsonFromServer.maxLines;
      ret.labelWarning = jsonFromServer.labelWarning;
      vH = jsonFromServer.vH;
      vW = jsonFromServer.vW;
      ret.args = jsonFromServer;
    }

    var minHeight = ret.maxLines > 1 ? 80 : vH ? Math.min(90, vH) : 50;

    ret.gmmName = 'normalGetter';

    ret.viewportW = vW || 180;
    ret.viewportH = minHeight;

    ret.setAllDim(ret.viewportW, ret.viewportH);
    ret.clip = true;

    for (var a = 0; a < lines.length; a++) {
      lines[a].normalHolder = ret;
      lines[a].agId = agId;
      lines[a].normalGetter = ret;
      lines[a].maxVW = ret.viewportW;
      lines[a].lineNumber = a;
      ret.add(lines[a]);
    }

    // If this 'normal' getter is being co-opted and used as a child
    // of a NumberLineGetter, then it has no json from server, as
    // that data is tracked by the parent NumberLineGetter
    ret.getJsonFromServer = function () {
      return ret.args || {};
    };

    ret.setXML = function (lineNumber, xml) {
      if (lineNumber) {
        ret.args.lines[lineNumber].xml = xml;
      } else {
        ret.args.lines[0].xml = xml;
      }
    };

    ret.swapString = function (old, replacer) {
      for (var a = 0; a < lines.length; a++) {
        lines[a].swapString(old, replacer);
      }
    };

    ret.nextLine = function () {
      var line;

      if (ret.maxLines && ret.lines.length < ret.maxLines) {
        line = getLine(3);
        ret.args.lines.push({ xml: line.toXML() });
        line.normalHolder = ret;
        line.agId = agId;
        line.normalGetter = ret;
        line.maxVW = ret.viewportW;
        line.lineNumber = ret.lines.length;
        line.setLineOfChildren();
        line.lockViewportDim = false;
        ret.lines.push(line);
        ret.add(line);
        line.balanceEmptyTexts();
        line.buildSizeRecursive();

        ret.repositionLines();
        line.grabFocus();
      }
    };

    ret.repositionLines = function () {
      var margin = 5;

      ret.layoutChildrenUD(margin);

      var used = margin;

      for (var a = 0; a < ret.lines.length; a++) {
        used += ret.lines[a].viewportH + margin;
      }

      // Resize height if needed
      if (used > ret.viewportH) ret.viewportH = used;
      else if (used < ret.viewportH && used != minHeight)
        ret.viewportH = Math.max(minHeight, used);

      var xtra = (ret.viewportH - used) / (ret.lines.length + 1);

      for (var a = 0; a < ret.lines.length; a++) {
        ret.lines[a].viewportY += margin + xtra;

        if (ret.centerMe) {
          ret.lines[a].viewportX = Math.max(
            0,
            ret.viewportW / 2 - ret.lines[a].viewportW / 2
          );
        }
      }
    };

    ret.grabFocus = function (dir) {
      lines[0].grabFocus(dir);

      if (ret.e === false) {
        console.error('error in focus #2');
      }
    };

    ret.childGainedFocus = function () {
      currentAttemptGetter = ret;
      insertKeyboard(ret.row, ret, agId);
      repositionProblemRows();

      if (lettersButton != null) {
        lettersButton.staysAnimatedColor =
          currentAttemptGetter.letters === undefined ||
          currentAttemptGetter.letters.length != 1;
      }

      if (ret.e === false) {
        console.error('error in focus 18!');
      }
    };

    ret.loseFocus = function () {
      focused = undefined;
    };

    ret.setStatus = function (st) {
      ret.st = st;
      ret.e = st !== 'c';
    };

    ret.isFocused = function () {
      return focused && focused.line.normalHolder === ret;
    };

    ret.paintMe = function (ctx) {
      ctx.save();

      if (ret.isFocused()) {
        roundRect2(
          ctx,
          1,
          1,
          ret.viewportW - 2,
          ret.viewportH - 2,
          8,
          undefined,
          getThemeColor(),
          3
        );
      } else {
        roundRect2(
          ctx,
          1,
          1,
          ret.viewportW - 2,
          ret.viewportH - 2,
          8,
          undefined,
          unfocusedBoxBorderColor,
          1
        );
      }

      var t = ret.selectedButton;

      if (t) {
        ctx.textBaseLine = 'middle';
        ctx.fillStyle = 'gray';
        ctx.font = '16px ' + lineFont;
        ctx.fillText(t, 30, ret.viewportH / 2 + 4);
      }

      if (ret.labelWarning) {
        ctx.textBaseLine = 'middle';
        ctx.fillStyle = 'gray';
        ctx.font = '12px ' + lineFont;
        var lww = ctx.measureText(ret.labelWarning).width;

        ctx.fillText(ret.labelWarning, ret.viewportW - lww - 20, 13);
      }

      ctx.restore();
    };

    ret.paintPostClip = function (ctx) {
      paintStatus(ret, ctx, true);
    };

    ret.mouseDownResponse = function (x, y) {
      if (ret.e === false || (ret.nlg && ret.nlg.nl.st === 'c')) {
        return false;
      }

      var ul = lines[0];
      var bel = false;
      var abv = false;

      if (lines.length > 1) {
        var f = false;
        var my;

        for (var a = 0; a < lines.length - 1; a++) {
          var l = lines[a];

          my = l.viewportY + l.viewportH;

          if (y < my) {
            abv = a === 0;
            f = true;
            ul = l;
            break;
          }
        }

        if (!f) {
          bel = true;
          ul = lines[lines.length - 1];
        }
      } else {
        if (y < ul.viewportY) abv = true;
        else if (y > ul.viewportY + ul.viewportH) bel = true;
      }

      if (x < ul.viewportX) {
        ul.children[0].grabFocus('right');

        return false;
      }

      x -= ul.viewportX;

      var m = ul.children[0];

      for (var a = 0; a < m.children.length; a++) {
        var c = m.children[a];
        var right = c.viewportX + c.viewportW;

        if (x < right) {
          var left = c.viewportX;
          var mid = (left + right) / 2;
          var dr = x <= mid ? 'right' : 'left';

          if (c.t !== 'fraction') c.grabFocus(dr);
          else if (bel) c.denominator.grabFocus(dr);
          else if (abv) c.numerator.grabFocus(dr);
          else c.grabFocus(dr);

          return true;
        }
      }

      m.children[m.children.length - 1].grabFocus('left');

      return true;
    };

    ret.clear = function (clearButton) {
      if (ret.nlg) {
        ret.nlg.clear(true);

        return;
      }

      // check for request to clear selected button
      if (clearButton && ret.selectedButton) ret.selectedButton = null;

      var replacer = [];

      replacer[0] = ret.args.lines[0];
      ret.args.lines = replacer;

      var temp = ret.lines[0];

      temp.lineNumber = 0;
      var clearedLines = [];

      ret.lines = clearedLines;
      ret.lines.push(temp);
      ret.children = [];
      ret.add(temp);
      temp.balanceEmptyTexts();
      temp.buildSizeRecursive();

      ret.repositionLines();
      //* *plz leave the two grabs, they're both sorta bug patches
      temp.grabFocus();
      temp.clear();
      temp.grabFocus();
    };

    ret.repositionLines();

    // Required getter function
    ret.serializeAttempt = function () {
      // if systems and answer needs at least 2 or 3 statements, just add line
      if (ret.maxLines && !selfCheckListener) {
        if (ret.args.lines && ret.args.lines.length < ret.maxLines) {
          if (focused) {
            focused.nextLine();
            paintCanvas();
            console.log(
              'abandon serialization in favor of calling focused.nextLine()'
            );

            return;
          }
        }
      }

      var attempt = { lines: [] };

      for (var x = 0; x < ret.args.lines.length; x++) {
        var xm = ret.args.lines[x].xml;

        if (
          x === 0 &&
          (xm === '' ||
            xm.indexOf('<t></t>') > -1 ||
            xm.indexOf('<num></num>') > -1 ||
            xm.indexOf('<denom></denom>') > -1 ||
            xm.indexOf('<q></q>') > -1 ||
            // allow blank base so that labeling still works when
            // user makes an edit viewed as deleting base by gmm
            // but viewed as deleting one letter by user
            // xm.indexOf("<b></b>") > -1 ||
            xm.indexOf('<e></e>') > -1 ||
            xm.indexOf('<b></b>') > -1 ||
            xm.indexOf('<r></r>') > -1)
        ) {
          if (!isTesting()) {
            getProblemContext().addStackableDialog({
              msg: 'Your attempt is not valid.',
              top: 'Invalid',
            });

            return;
          }
          // When testing, clicking submit for a blank normal getter when a button is already
          // selected should do nothing. It's not invalid, rather it's irrlevant.
          else if (currentAttemptGetter.selectedButton) return;
        }

        if (
          (!isTesting() && currentAttemptGetter === undefined) ||
          currentAttemptGetter.letters !== undefined
        ) {
          var copy = xm;

          while (copy.indexOf('<t>') > -1) {
            var i1 = copy.indexOf('<t>');
            var i2 = copy.indexOf('</t>');
            var t = copy.substring(i1 + 3, i2);

            copy = copy.substring(i2 + 4);
          }
        }

        xm = baseBugPatch(xm);
        xm = encodeText(xm);
        attempt.lines.push(xm);
      }

      return attempt;
    };

    return ret;
  };

  var getLineHolder = function (x, y, line, w, h) {
    var ret = getBasicObject(x, y);

    ret.line = line;
    ret.viewportW = w || 140;
    ret.viewportH = h || 56;
    line.maxVW = ret.viewportW;
    ret.viewportMargin = 1;
    ret.gmmName = 'lineHolder';

    ret.lineSizeChanged = function () {
      ret.width = ret.line.width;
      ret.height = ret.line.height;
      var dy =
        (ret.viewportH - ret.viewportMargin) / 2 - ret.line.viewportH / 2;

      ret.line.viewportY = dy;

      if (ret.centerText) {
        var dx =
          (ret.viewportW - ret.viewportMargin) / 2 - ret.line.viewportW / 2;

        ret.line.viewportX = dx;
      }
    };

    ret.grabFocus = function (dir) {
      ret.line.grabFocus(dir);

      if (ret.e === false) {
        console.error('focus error 3');
      }
    };

    ret.setEditable = function (b) {
      ret.e = b;
      ret.line.setEditable(b);
    };

    ret.add(line);
    line.lineHolder = ret;

    ret.lineSizeChanged();

    ret.setCenterText = function (b) {
      if (b == ret.centerText) {
        return;
      }

      ret.centerText = b;
      ret.lineSizeChanged();
    };

    ret.paintMe = function (ctx) {
      if (focused && focused.line === ret.line)
        roundRect2(
          ctx,
          0,
          0,
          ret.viewportW - 2,
          ret.viewportH - 2,
          6,
          undefined,
          getThemeColor(),
          6
        );
    };

    return ret;
  };

  var paintStatus = function (obj, ctx, slideLeft) {
    if (!obj.st) {
      return;
    }

    var color;

    if (obj.st === 'wr') {
      color = 'magenta';
    } else if (obj.st === 'c') {
      color = 'green';
    }

    if (isTesting() && obj.st == 'ready') {
      color = 'cyan';
    }

    if (obj.gmmName === 't' || obj.gmmName === 'valueTable') return color;
    var cx = slideLeft
      ? -obj.viewportSlideX +
        obj.viewportW -
        obj.viewportMargin -
        statusDiameter / 2 -
        5
      : -obj.viewportSlideX + obj.viewportW + 10;

    if (color) {
      var cy = -obj.viewportSlideY + obj.viewportH / 2;

      ctx.globalAlpha = 0.5;
      circle(ctx, cx, cy, statusDiameter / 2, color);
      ctx.globalAlpha = 1;
    }
  };

  /**
   * @memberOf gmm
   *
   * args will have two forks:
   *  cols, which has xml from server
   *    (array of arrays of cells:
   *        cell.xml
   *        cell.e
   *    )
   *  uiCols, which (duh) has ui elements
   */
  var getTable = function (x, y, args, readAloudWrapper) {
    var table = getBasicObject(x, y);

    table.gmmName = 't';
    table.args = args;
    var minTableCellWidth = 40;

    // array of arrays
    var uiCols = [];

    for (var a = 0; a < args.cols.length; a++) {
      var col = args.cols[a];
      var uiCol = [];

      for (var r = 0; r < col.length; r++) {
        (function (b) {
          var cell = col[b];
          var uiCell = getBasicObject();
          var line = toLine(cell.xml);

          line.e = cell.e ? true : false;

          if (line.e) {
            line.lockViewportDim = false;
            table.editableLines = table.editableLines || [];
            table.editableLines.push(line);
            line.tableCol = a;
            line.tableRow = r;
            var lineHolder = getLineHolder(0, 0, line, minTableCellWidth, 30);

            lineHolder.setCenterText(true);
            lineHolder.viewportMargin = 0;
            uiCell.add(lineHolder);
            uiCell.lineHolder = lineHolder;
          } else {
            uiCell.fill = 'lightgray';

            // see later loop: re-orienting for readAloud
            if (table.args.alignment === 'vertical') {
              line.ttsTransfer = cell.tts;
            } else if (readAloudWrapper) {
              injectReadAloudButton(
                readAloudWrapper,
                cell.tts,
                { paintCanvas, errorToServer },
                line
              );
            }
          }

          uiCell.viewportMargin = 1;

          if (line.e) {
            uiCell.mouseDownResponse = function () {
              uiCell.line.grabFocus('left');

              return true;
            };
          } else {
            uiCell.add(line);
          }

          uiCell.line = line;
          line.uiCell = uiCell;
          uiCol.push(uiCell);
          table.add(uiCell);
        })(r);
      }

      uiCols.push(uiCol);
      table.uiCols = uiCols;
    }

    // prior loops work vertically through each column,
    // but users expect readAloud to go left to right
    // for vertically aligned tables,
    // so creating a new loop in loop after all the above
    // building just for readAloud
    if (readAloudWrapper && table.args.alignment === 'vertical') {
      for (let row = 0; row < uiCols[0].length; row++) {
        for (let col = 0; col < uiCols.length; col++) {
          const uiCell = uiCols[col][row];
          const line = uiCell.line;

          if (!line.e) {
            injectReadAloudButton(
              readAloudWrapper,
              line.ttsTransfer,
              { paintCanvas },
              line
            );
          }
        }
      }
    }

    table.sizeMe = function () {
      var m = 0;
      var tableH = 0;
      var tableW = 0;

      for (var a = 0; a < table.uiCols.length; a++) {
        var uiCol = table.uiCols[a];
        var maxColW = 0;

        for (var r = 0; r < uiCol.length; r++) {
          var uiCell = uiCol[r];
          var w = uiCell.line.viewportW + 4;

          if (uiCell.lineHolder) {
            w = uiCell.lineHolder.viewportW;
          }

          maxColW = Math.max(w, maxColW);
        }

        for (var r = 0; r < uiCol.length; r++) {
          var uiCell = uiCol[r];

          if (uiCell.lineHolder) {
            uiCell.lineHolder.viewportW = maxColW;
          }
        }

        uiCol.cellW = Math.max(minTableCellWidth, maxColW);
        tableW += uiCol.cellW;
      }

      for (var a = 0; a < table.uiCols[0].length; a++) {
        var h = 30;

        for (var i = 0; i < uiCols.length; i++) {
          var hCheck = table.uiCols[i][a].line.viewportH + 4;

          if (table.uiCols[i][a].lineHolder) {
            hCheck = table.uiCols[i][a].lineHolder.viewportH;
          }

          h = Math.max(h, hCheck);
        }

        tableH += h;

        for (var i = 0; i < uiCols.length; i++) {
          table.uiCols[i][a].viewportH = h;
        }
      }

      for (var a = 0; a < table.uiCols.length; a++) {
        var uiCol = table.uiCols[a];
        var cellX = m;

        if (a > 0) {
          cellX =
            m +
            table.uiCols[a - 1][0].viewportX +
            table.uiCols[a - 1][0].viewportW;
        }

        for (var r = 0; r < uiCol.length; r++) {
          var uiCell = uiCol[r];
          var line = uiCell.line;

          line.viewportX = uiCol.cellW / 2 - line.viewportW / 2;
          line.viewportY = uiCell.viewportH / 2 - line.viewportH / 2;
          uiCell.setAllDim(uiCol.cellW, uiCell.viewportH);
          uiCell.viewportX = cellX;

          if (r > 0) {
            uiCell.viewportY =
              m + uiCol[r - 1].viewportY + uiCol[r - 1].viewportH;
          } else {
            uiCell.viewportY = m;
          }
        }
      }

      // +1 here is like "ummmm" below - it prevents right side of table from being slightly cut off
      table.viewportW = tableW + m * 3 + 1;

      var ummmm = 1;

      table.viewportH = tableH + m * (table.uiCols[0].length + 1) + ummmm;
    };

    table.paintPostClip = function (ctx) {
      var color = paintStatus(table, ctx);

      if (!color) return;

      ctx.save();

      ctx.beginPath();
      ctx.moveTo(0, 0);
      ctx.lineTo(table.viewportW, 0);
      ctx.lineTo(table.viewportW, table.viewportH);
      ctx.lineTo(0, table.viewportH);
      ctx.lineTo(0, 0);

      ctx.strokeStyle = color;
      ctx.lineWidth = 6;
      ctx.globalAlpha = 0.5;
      ctx.stroke();
      ctx.globalAlpha = 1;

      ctx.restore();
    };

    table.sizeMe();

    return table;
  };

  var getValueTable = function (x, y, args, readAloudWrapper) {
    var valueTable = getBasicObject(x, y);

    valueTable.gmmName = 'valueTable';
    valueTable.args = args;
    var minTableCellWidth = 30;
    var minTableCellHeight = 20;
    var widthPadding = 16;
    var heightPadding = 12;

    // Iterate through table and build cells for view
    var uiRows = [];

    for (var r = 0; r < args.rows.length; r++) {
      var row = args.rows[r];
      var uiRow = [];

      for (var c = 0; c < row.length; c++) {
        (function (b) {
          var cell = row[b];
          var uiCell = getBasicObject();
          var line = toLine(cell.xml);

          line.e = cell.e ? true : false;

          // Check if editable
          if (line.e) {
            line.lockViewportDim = false;
            line.isDynamicSize = true;
            valueTable.editableLines = valueTable.editableLines || [];
            valueTable.editableLines.push(line);
            line.tableCol = c;
            line.tableRow = r;
            var lineHolder = getLineHolder(
              0,
              0,
              line,
              minTableCellWidth,
              minTableCellHeight
            );

            lineHolder.setCenterText(true);
            lineHolder.viewportMargin = 0;
            uiCell.add(lineHolder);
            uiCell.lineHolder = lineHolder;

            // Create mouse down event
            uiCell.mouseDownResponse = function () {
              uiCell.line.grabFocus('left');

              return true;
            };
          } else {
            uiCell.fill = 'lightgray';

            if (readAloudWrapper) {
              injectReadAloudButton(
                readAloudWrapper,
                cell.tts,
                { paintCanvas },
                line
              );
            }

            uiCell.add(line);
          }

          uiCell.viewportMargin = 1;
          line.parent = uiCell;
          uiCell.line = line;
          uiCell.parent = valueTable;
          line.uiCell = uiCell;
          uiRow.push(uiCell);
          valueTable.add(uiCell);
        })(c);
      }

      uiRows.push(uiRow);
      valueTable.uiRows = uiRows;
    }

    valueTable.buildSizeRecursive = function () {
      for (var r = 0; r < valueTable.uiRows.length; r++) {
        var uiRow = valueTable.uiRows[r];

        for (var c = 0; c < uiRow.length; c++) {
          var uiCell = uiRow[c];

          if (uiCell.lineHolder != null) {
            uiCell.lineHolder.line.buildSizeRecursive();
          }
        }
      }

      valueTable.childSizeChanged();
    };

    valueTable.childSizeChanged = function () {
      valueTable.sizeMe();
      valueTable.setAllDim(valueTable.viewportW, valueTable.viewportH);
    };

    valueTable.sizeMe = function () {
      var tableH = 0;
      var tableW = 0;
      var maxColW = [];
      var maxRowH = [];

      // Calculate the widest cell per column and tallest cell per row
      for (var r = 0; r < valueTable.uiRows.length; r++) {
        var uiRow = valueTable.uiRows[r];

        for (var c = 0; c < uiRow.length; c++) {
          var uiCell = uiRow[c];
          var w = uiCell.line.viewportW + widthPadding;
          var h = uiCell.line.viewportH + heightPadding;

          maxColW[c] = Math.max(
            minTableCellWidth,
            Math.max(w, maxColW.length > c ? maxColW[c] : 0)
          );
          maxRowH[r] = Math.max(
            minTableCellHeight,
            Math.max(h, maxRowH.length > r ? maxRowH[r] : 0)
          );
        }

        uiRow.cellH = maxRowH[r];
      }

      // Mark all rows for max cell width
      for (var r = 0; r < valueTable.uiRows.length; r++) {
        var uiRow = valueTable.uiRows[r];

        // Iterate and set standardized width / heights
        var rowWidth = 0;

        for (var c = 0; c < uiRow.length; c++) {
          var uiCell = uiRow[c];

          rowWidth += maxColW[c];
          uiCell.viewportW = maxColW[c];
          uiCell.viewportH = maxRowH[r];

          if (uiCell.lineHolder) {
            uiCell.lineHolder.viewportW = maxColW[c];
            uiCell.lineHolder.viewportH = maxRowH[r];
          }
        }

        tableW = Math.max(tableW, rowWidth);
        tableH += uiRow.cellH;
      }

      // Position each table cell
      for (var r = 0; r < valueTable.uiRows.length; r++) {
        var uiRow = valueTable.uiRows[r];
        var cellY = 0;

        if (r > 0)
          cellY =
            valueTable.uiRows[r - 1][0].viewportY +
            valueTable.uiRows[r - 1][0].viewportH;

        for (var c = 0; c < uiRow.length; c++) {
          var cellX = 0;
          var uiCell = uiRow[c];
          var line = uiCell.line;

          line.viewportX = maxColW[c] / 2 - line.viewportW / 2;
          line.viewportY = maxRowH[r] / 2 - line.viewportH / 2;
          uiCell.setAllDim(uiCell.viewportW, uiCell.viewportH);

          if (c > 0) cellX = uiRow[c - 1].viewportX + uiRow[c - 1].viewportW;

          uiCell.viewportX = cellX;
          uiCell.viewportY = cellY;
        }
      }

      valueTable.viewportW = tableW;
      valueTable.viewportH = tableH;
    };

    valueTable.paintPostClip = function (ctx) {
      var color = paintStatus(valueTable, ctx);

      if (!color) return;

      ctx.save();

      ctx.beginPath();
      ctx.moveTo(0, 0);
      ctx.lineTo(valueTable.viewportW, 0);
      ctx.lineTo(valueTable.viewportW, valueTable.viewportH);
      ctx.lineTo(0, valueTable.viewportH);
      ctx.lineTo(0, 0);

      ctx.strokeStyle = color;
      ctx.lineWidth = 6;
      ctx.globalAlpha = 0.5;
      ctx.stroke();
      ctx.globalAlpha = 1;

      ctx.restore();
    };

    valueTable.sizeMe();

    return valueTable;
  };

  var getValueTableGetter = function (x, y, args, row) {
    var tg = getBasicObject(x, y);

    tg.t = 'valueTable';
    tg.gmmName = 'valueTableGetter';
    tg.row = row;
    tg.args = args;

    var valueTable = getValueTable(
      0,
      0,
      args.valueTable,
      isReadAloud() ? tg : undefined
    );

    valueTable.parent = tg;
    tg.valueTable = valueTable;
    tg.add(valueTable);
    tg.layoutChildrenLR();
    tg.sizeMeToLowestAndRightmostChildren();

    // We found cases where tables barely fit in narrow view prior to read
    // aloud, so audio icon added to top left caused table's row to exceed
    // narrow max width. Here, we detect this issue and resolve it by
    // bumping the audio icon to above the table
    if (isReadAloud() && isNarrow() && tg.viewportW > NARROW_PROBLEM_WIDTH) {
      tg.layoutChildrenUD();
      tg.children.forEach(child => {
        child.viewportX = 0;
      });
      tg.sizeMeToLowestAndRightmostChildren();
    }

    for (var a = 0; a < valueTable.editableLines.length; a++) {
      valueTable.editableLines[a].tableGetter = tg;
    }

    tg.e = true;
    tg.agId = args.agId;

    tg.getJsonFromServer = function () {
      return tg.args;
    };

    tg.buildSizeRecursive = function () {
      valueTable.buildSizeRecursive();
      tg.childSizeChanged();
    };

    tg.childSizeChanged = function () {
      tg.layoutChildrenLR();
      tg.sizeMeToLowestAndRightmostChildren();
    };

    tg.grabFocus = function (dir) {
      tg.valueTable.editableLines[0].grabFocus(dir || 'left');
    };

    tg.childGainedFocus = function () {
      insertKeyboard(tg.row, tg, tg.agId);
      repositionProblemRows();
      currentAttemptGetter = tg;

      if (lettersButton != null) {
        lettersButton.staysAnimatedColor = true;
      }

      if (tg.e === false) {
        console.error('error in focus! 28');
      }
    };

    tg.loseFocus = function () {
      focused = undefined;
    };

    tg.setStatus = function (st) {
      args.s = st;
      args.st = st;
      tg.valueTable.st = st;
      tg.st = st;
      tg.e = st !== 'c';

      if (!tg.e) {
        for (var r = 0; r < valueTable.uiRows.length; r++) {
          var uiRow = valueTable.uiRows[r];

          for (var c = 0; c < uiRow.length; c++) {
            var cell = uiRow[c];

            if (cell.lineHolder) {
              cell.lineHolder.setEditable(false);
              cell.mouseDownResponse = undefined;
            }
          }
        }
      }
    };

    tg.isLastFocused = function () {
      var lines = tg.valueTable.editableLines;
      var c = -1,
        r = -1;
      var found = false;

      for (var a = 0; a < lines.length; a++) {
        var line = lines[a];

        if (focused.line === line) {
          c = line.tableCol;
          r = line.tableRow;
          found = true;
          break;
        }
      }

      if (!found) {
        return false;
      }

      var last = lines[lines.length - 1];
      var rowMax = last.tableRow;
      var colMax = last.tableCol;

      return c == colMax && r == rowMax;
    };

    tg.tab = function (dir) {
      if (!dir) {
        dir = 'right';
      }

      var lines = tg.valueTable.editableLines;
      var c = -1,
        r = -1;
      var found = false;

      var rowMax = lines[lines.length - 1].tableRow;
      var colMax = 0;

      for (var a = 0; a < lines.length; a++) {
        var line = lines[a];

        colMax = Math.max(colMax, line.tableCol);

        if (!found && focused.line === line) {
          c = line.tableCol;
          r = line.tableRow;
          found = true;
        }
      }

      if (!found) {
        return;
      }

      var nc = c;
      var nr = r;

      if (dir === 'up') {
        nc++;
        nr--;
      } else if (dir === 'down') {
        nr++;
        nc--;
      }

      // Logically move to the next best cell based on direction
      do {
        var isForward = dir === 'right' || dir === 'down';

        if (isForward) nc++;
        else nc--;

        if (nc > colMax) {
          nc = 0;
          nr++;
        } else if (nc < 0) {
          nc = colMax;
          nr--;
        }

        if (nr > rowMax) {
          nr = 0;
          nc = 0;
        } else if (nr < 0) {
          nr = rowMax;
          nc = colMax;
        }
      } while (lines.length > 0 && !tg.valueTable.uiRows[nr][nc].line.e);

      tg.valueTable.uiRows[nr][nc].line.grabFocus(
        dir === 'left' ? 'right' : 'left'
      );
    };

    // Required getter function
    tg.serializeAttempt = function () {
      var uilines = tg.valueTable.editableLines;
      var lines = [];

      for (var a = 0; a < uilines.length; a++) {
        var line = { c: uilines[a].tableCol, r: uilines[a].tableRow };
        var xm = toXML(uilines[a]);

        line.xml = encodeText(xm);
        lines.push(line);
      }

      return { lines };
    };

    return tg;
  };

  var getMultiSelectGetter = function (x, y, jsonFromServer) {
    var multiSelectGetter = getBasicObject(x, y);

    multiSelectGetter.args = jsonFromServer;
    multiSelectGetter.agId = jsonFromServer.agId;
    multiSelectGetter.t = jsonFromServer.t;
    multiSelectGetter.numberSelectable = jsonFromServer.numberSelectable;
    // gmmName is used for convenience when debugging
    multiSelectGetter.gmmName = 'multiSelectGetter';

    // Server may send a prompt, such as 'Choose TWO of the following:'
    if (jsonFromServer.prompt) {
      var prompt = getStaticPanel({
        xml: '<t>' + jsonFromServer.prompt + '</t>',
        tts: buildTTSDTO(jsonFromServer.prompt),
      });

      // margin below prompt
      prompt.viewportH += 8;
      // prompt gets added to the widgets wrapped by the multiSelectGetter
      multiSelectGetter.add(prompt);
    }

    multiSelectGetter.optionPanels = [];

    multiSelectGetter.getJsonFromServer = function () {
      return multiSelectGetter.args;
    };

    multiSelectGetter.addOptionPanel = function (optionPanel) {
      // optionPanel gets added to the widgets wrapped by the multiSelectGetter
      multiSelectGetter.add(optionPanel);
      // optionPanels can be accessed via this collection
      // (not mixed with other widgets, such as the prompt)
      multiSelectGetter.optionPanels.push(optionPanel);
    };

    // ************ OPTION PANELS *************

    // margin between checkbox and left side of panel showing option/choice
    const MARGIN_CHECKBOX_TO_DISPLAY = 10;
    // margin on right side of option/choice
    const MARGIN_RIGHT_OF_DISPLAY = 5;
    const SEPARATOR_HEIGHT = 1;

    // For each each option,
    // create an 'optionPanel' -- a panel that holds both a checkbox
    // and a display of the option,
    // as well as perhaps a horizontal divider
    jsonFromServer.options.forEach((jsonOption, optionIndex) => {
      // build the panel that will hold both checkbox and display of option
      var optionPanel = getBasicObject();

      // gmmName is used for convenience when debugging
      optionPanel.gmmName = 'optionPanel';
      optionPanel.letter = jsonOption.letter;

      optionPanel.getLetter = function () {
        return optionPanel.letter;
      };

      // ************ CHECKBOX *************

      // build the checkbox object
      var checkbox = getMultiSelectCheckbox(jsonOption.letter);

      checkbox.getter = multiSelectGetter;

      optionPanel.add(checkbox);
      optionPanel.checkbox = checkbox;

      optionPanel.isSelected = function () {
        return checkbox.isSelected();
      };

      // ************ DISPLAY OF OPTION *************

      // build a view of the option's display

      // Use a ReadAloudWrapper for audio icon so that when shrinking is needed,
      // the ReadAloudWrapper will protect the icon from shrinking and only shrink
      // the display of the option/choice.
      var readAloudWrapper =
        isReadAloud() && jsonOption.display.tts
          ? getReadAloudWrapper()
          : undefined;

      var display = getStaticPanel(
        jsonOption.display,
        undefined,
        undefined,
        true,
        readAloudWrapper
      );

      // When getStaticPanel is called with a targetForReadAloud,
      // it does not add the created static panel to the target panel --
      // this is left to the caller if needed. Normally, it is not needed,
      // such as in setProblem when a second horizontally aligned panel in a
      // single row is supplied the first panel as targetForReadAloud.
      // However, here, we want the created static panel to be the second
      // child of the ReadAloudWrapper for control of shrinking.
      if (readAloudWrapper) {
        readAloudWrapper.add(display);
        readAloudWrapper.layoutChildrenLR();
        display = readAloudWrapper;
      }

      display.viewportX = checkbox.viewportW + MARGIN_CHECKBOX_TO_DISPLAY;
      optionPanel.add(display);

      // horizontal pixels available for display:
      // width of canvas
      // take away width of checkbox
      var availableWidth =
        canvasW -
        checkbox.viewportW -
        MARGIN_CHECKBOX_TO_DISPLAY -
        MARGIN_RIGHT_OF_DISPLAY;

      display.setMaxWidth(availableWidth);

      // Base optionPanel's height on heighest child: checkbox or display
      optionPanel.viewportH = Math.max(checkbox.viewportH, display.viewportH);
      // optionPanel's width is the x coordinate of the right side of the display
      optionPanel.viewportW = display.viewportX + display.viewportW;
      // Align children (checkbox and display) so vertical centers are at same horizontal level
      optionPanel.centerVertically();

      var mouseDownResponse = function () {
        // if the getter is not enabled, ignore mouse down
        if (!multiSelectGetter.isEnabled()) return true;
        if (!checkbox.isEnabled()) return true;

        changedMaybe(multiSelectGetter.agId);

        // if this is a 'choose one,' only allow one selection at a time
        if (jsonFromServer.numberSelectable == 1) {
          multiSelectGetter.deselectAll();
          checkbox.setSelected(true);
        }
        // Allow deselection if hasn't been marked as correct choice on prior submit
        else if (checkbox.isSelected()) checkbox.toggleSelected();
        // Not deselecting, and not in 'choose one' mode?
        // Only permit selections up to 'numberSelectable'
        else if (multiSelectGetter.allowAnotherSelection()) {
          checkbox.toggleSelected();
        }

        multiSelectGetter.grabFocus();

        return true;
      };

      // In narrow mode, only the checkbox responds to mouseDown.
      // This is to avoid a student accidentally 'checking' when trying
      // to scroll on phone.
      if (!narrow) optionPanel.mouseDownResponse = mouseDownResponse;
      else {
        checkbox.mouseDownResponse = mouseDownResponse;
      }

      multiSelectGetter.addOptionPanel(optionPanel);

      // ************ HORIZONTAL SEPARATOR *************

      // Add horizontal gray separator segment after each option except the last one
      if (optionIndex < jsonFromServer.options.length - 1) {
        var separator = getBasicObject();

        separator.t = 'separator';

        // margin between bottom of display and horizontal separator
        optionPanel.viewportH += 8;
        // Separator is at bottom of optionPanel
        separator.viewportY = optionPanel.viewportH - 1;
        // '0' width here is irrelevant, as we reset width for all separators later, matching width of widest optionPanel
        separator.setAllDim(0, SEPARATOR_HEIGHT);
        separator.fill = 'lightgray';
        optionPanel.separator = separator;
        optionPanel.add(separator);
      }
    });

    // Loop to find ideal separator width.
    // Separators (light gray segments between optionPanels)
    // should be as wide as widest optionPanel.
    var separatorWidth = 0;
    var optionPanels = multiSelectGetter.optionPanels;

    optionPanels.forEach(optionPanel => {
      separatorWidth = Math.max(separatorWidth, optionPanel.viewportW);
    });

    // Assign ideal separator width to all separators (and optionPanels)
    optionPanels.forEach(optionPanel => {
      optionPanel.viewportW = separatorWidth;

      if (optionPanel.separator) {
        optionPanel.separator.setAllDim(separatorWidth, 1);
      }
    });

    // effectively, adds 7 pixel margin at the top of each optionPanel
    optionPanels.forEach(optionPanel => {
      optionPanel.viewportH += 7;

      // Slide all children of optionPanel down 7 pixels so that the margin
      // created above is at the top of the optionPanel
      optionPanel.children.forEach(child => {
        child.viewportY += 7;
      });
    });

    // Prompt (optional) and optionPanels will be distributed vertically.
    multiSelectGetter.layoutChildrenUD(0);
    // Getter determines its width and height based on space occupied by children
    multiSelectGetter.sizeMeToLowestAndRightmostChildren();

    // ************ SUBMIT BUTTON *************

    var submitButton = getSubmitButton(
      multiSelectGetter,
      paintCanvas,
      submitAttempt,
      getThemeColor,
      'multiSelect'
    );

    multiSelectGetter.add(submitButton);
    submitButton.viewportX =
      multiSelectGetter.viewportW / 2 - submitButton.viewportW / 2;
    const MARGIN_UNDER_LAST_OPTION_PANEL = 15;

    submitButton.viewportY =
      multiSelectGetter.viewportH + MARGIN_UNDER_LAST_OPTION_PANEL;

    // Recompute getter dimensions now that submit button has been added
    multiSelectGetter.sizeMeToLowestAndRightmostChildren();

    // ************ UNIQUE MultiSelectGetter METHODS *************

    // Apply checkbox state sent from server. For example, mark previously
    // submitted options incorrect or correct and disable them (if not testing).
    multiSelectGetter.update = function (updatedJson) {
      var inboundOptions = updatedJson.options;
      var storedJsonOptions = multiSelectGetter.args.options;

      inboundOptions.forEach((inboundOption, x) => {
        var storedOption = storedJsonOptions[x];

        storedOption.state = inboundOption.state;
        storedOption.correct = inboundOption.correct;

        var checkbox = multiSelectGetter.optionPanels[x].checkbox;

        checkbox.setSelected(inboundOption.state === 'checked');
        checkbox.setCorrectlySubmitted(inboundOption.correct);
      });
    };

    // Apply checkbox state originally received from server
    multiSelectGetter.update(jsonFromServer);

    // How many checkboxes are selected?
    // If not testing, includes checkboxes that were correctly submitted and are now disabled
    multiSelectGetter.getSelectedCount = function () {
      var selectedCount = 0;

      multiSelectGetter.optionPanels.forEach(optionPanel => {
        if (optionPanel.isSelected()) {
          selectedCount++;
        }
      });

      return selectedCount;
    };

    multiSelectGetter.hasRequiredSelectionCount = function () {
      return (
        multiSelectGetter.getSelectedCount() ==
        multiSelectGetter.getNumberSelectable()
      );
    };

    multiSelectGetter.allowAnotherSelection = function () {
      return !multiSelectGetter.hasRequiredSelectionCount();
    };

    multiSelectGetter.getNumberSelectable = function () {
      return multiSelectGetter.numberSelectable;
    };

    multiSelectGetter.deselectAll = function () {
      multiSelectGetter.optionPanels.forEach(optionPanel => {
        optionPanel.checkbox.setSelected(false);
      });
    };

    multiSelectGetter.hasSelected = function () {
      return multiSelectGetter.getSelectedCount() > 0;
    };

    // ************ REQUIRED GETTER METHODS *************

    // All getters are expected to support this function
    multiSelectGetter.setStatus = function (status) {
      multiSelectGetter.st = status;
      multiSelectGetter.e = status !== 'c';
    };

    // All getters are expected to support this function
    multiSelectGetter.loseFocus = function () {};

    // All getters are expected to support this function
    multiSelectGetter.grabFocus = function () {
      if (
        currentAttemptGetter &&
        currentAttemptGetter.gmmName == 'gridGetter'
      ) {
        currentAttemptGetter.g.setSelected(false);
      }

      if (focused) {
        focused.setFocused(false);
      }

      removeKeyboard();
      currentAttemptGetter = multiSelectGetter;
    };

    // All getters will be expected to support this function
    multiSelectGetter.isEnabled = function () {
      return status !== 'c';
    };

    // On submit, package ui state into json to send back to server.
    // All getters are expected to support this function.
    multiSelectGetter.serializeAttempt = function () {
      // if not testing, zero choices is invalid
      if (!isTesting()) {
        var errorMessage;

        if (multiSelectGetter.getSelectedCount() === 0) {
          errorMessage = 'You must make a choice.';
        }
        // if not testing, require number of choices to be number of correct options
        else if (!multiSelectGetter.hasRequiredSelectionCount()) {
          errorMessage =
            'You must select ' + multiSelectGetter.getNumberSelectable() + '.';
        }

        if (errorMessage) {
          getProblemContext().addStackableDialog({
            msg: errorMessage,
            top: 'Invalid',
          });

          return;
        }
      }

      var options = [];

      multiSelectGetter.optionPanels.forEach(option => {
        var jsonOption = {};

        jsonOption.state = option.isSelected() ? 'checked' : 'unchecked';
        jsonOption.letter = option.getLetter();
        options.push(jsonOption);
      });

      return { options };
    };

    return multiSelectGetter;
  };

  /**
   * @memberOf gmm
   *
   * Supports getMultiSelectGetter
   */
  var getMultiSelectCheckbox = function (letter) {
    var checkbox = getBasicObject();

    checkbox.setAllDim(25, 25);
    checkbox.viewportX = 1;
    checkbox.letter = letter;

    // ************ CHECKBOX FUNCTIONS **************

    checkbox.getLetter = function () {
      return checkbox.letter;
    };

    checkbox.setSelected = function (b) {
      checkbox.selected = b;

      // TODO: make this unnecessary OR document
      var storedJsonOptions = checkbox.getter.args.options;

      storedJsonOptions.forEach(storedOption => {
        if (storedOption.letter === checkbox.getLetter()) {
          storedOption.state = b ? 'checked' : 'unchecked';
        }
      });
    };

    checkbox.isSelected = function () {
      return checkbox.selected;
    };

    checkbox.toggleSelected = function () {
      checkbox.setSelected(!checkbox.isSelected());
    };

    // undefined means never submitted
    checkbox.setCorrectlySubmitted = function (b) {
      checkbox.correct = b;
    };

    checkbox.hasBeenCorrectlySubmitted = function () {
      return checkbox.correct === true;
    };

    checkbox.hasBeenIncorrectlySubmitted = function () {
      return checkbox.correct === false;
    };

    checkbox.hasNeverBeenSubmitted = function () {
      return checkbox.correct === undefined;
    };

    // allow changes to any non-submitted option (this will be ANY option when not testing)
    checkbox.isEnabled = function () {
      return checkbox.correct === undefined;
    };

    checkbox.paintMe = function (ctx) {
      var fillColor = 'white';

      if (isTesting() && checkbox.isSelected()) {
        fillColor = 'cyan';
      }

      // 'correctBlue' background when not testing and has been submitted and is correct
      else if (!isTesting() && checkbox.hasBeenCorrectlySubmitted()) {
        fillColor = correctBlue;
      }
      // gray background when not testing and has been submitted and is not correct
      // (and getter is not disabled). Later, we'll paint an X over the background.
      else if (
        !isTesting() &&
        checkbox.hasBeenIncorrectlySubmitted() &&
        checkbox.getter.isEnabled()
      ) {
        fillColor = 'gray';
      }

      // fill background
      roundRect2(
        ctx,
        0,
        0,
        checkbox.viewportW,
        checkbox.viewportH,
        6,
        fillColor,
        undefined,
        2
      );

      var borderColor;

      // no border color when checkbox has been submitted and marked right/wrong
      // rather, solid bacground
      if (isTesting() || checkbox.hasNeverBeenSubmitted()) {
        borderColor = checkbox.isSelected() ? 'cyan' : 'lightGray';
      }

      if (borderColor) {
        roundRect2(
          ctx,
          0,
          0,
          checkbox.viewportW,
          checkbox.viewportH,
          6,
          undefined,
          borderColor,
          2
        );
      }

      // paint a check to show this box is selected
      if (checkbox.isSelected()) {
        ctx.save();
        // forecolor white if we've filled background with non-white
        ctx.fillStyle =
          isTesting() || checkbox.hasBeenCorrectlySubmitted()
            ? 'white'
            : 'black';
        ctx.font = 'bold 18px ' + lineFont;

        ctx.textBaseline = 'top';
        ctx.fillText('\u2713', 4, 4);
        ctx.restore();
      }

      // paint an X to show this box was selected and submitted in the past
      // and got marked wrong
      if (!isTesting() && checkbox.hasBeenIncorrectlySubmitted()) {
        ctx.save();

        ctx.lineWidth = 2;
        ctx.strokeStyle = 'magenta';
        ctx.beginPath();
        ctx.moveTo(2, 2);
        ctx.lineTo(checkbox.viewportW - 2, checkbox.viewportH - 2);
        ctx.moveTo(checkbox.viewportW - 2, 2);
        ctx.lineTo(2, checkbox.viewportH - 2);
        ctx.stroke();

        ctx.restore();
      }
    };

    return checkbox;
  };

  /**
   * @memberOf gmm
   *
   * choices: array of panelSuppliers
   *       panelSupplier has whatever it has for normal setProblem
   *         such as t string and lineXml ('normal' line)
   *         or t string and json (grid)
   * choice: letter
   * agId
   * mode: "4", "2by2"
   */
  var getMultiChoiceGetter = function (x, y, args) {
    var multi = getBasicObject(x, y);

    multi.uiChoices = [];
    var letters = 'ABCD';

    multi.checkboxes = [];
    multi.args = args;
    multi.agId = args.agId;
    multi.gmmName = 'multiChoice';
    multi.t = 'm';

    multi.getJsonFromServer = function () {
      return multi.args;
    };

    for (var a = 0; a < args.choices.length; a++) {
      // need closure or byRef uses last value of a for all mouseDowns
      (function (aa) {
        var letter = letters.substring(aa, aa + 1);
        var cb = getCheckbox();

        cb.multi = multi;
        var uiChoice = getBasicObject();

        uiChoice.gmmName = 'choicePanel';
        uiChoice.letter = letter;
        uiChoice.add(cb);
        uiChoice.cb = cb;

        if (args.choice === letter) {
          cb.sel = true;
        }

        var uiH = cb.viewportH;

        // HORIZONTAL PIXELS AVAILABLE:
        // width of canvas (or half width of canvas if mode is 2by2)
        // take away width of checkbox
        // take away 10 for margin between checkbox and left side of panel showing option/choice
        // take away 5 for margin on right side of option/choice
        // take away 1 because a 'precise' measurement ends up shaving the furthest right pixel sometimes
        var maxWidth =
          args.mode === '2by2' && !isNarrow() ? canvasW / 2 : canvasW;
        var maxDisplayWidth = maxWidth - cb.viewportW - 10 - 5 - 1;

        // Use a ReadAloudWrapper for audio icon so that when shrinking is needed,
        // the ReadAloudWrapper will protect the icon from shrinking and only shrink
        // the display of the option/choice.
        var readAloudWrapper =
          isReadAloud() && args.choices[aa].tts
            ? getReadAloudWrapper()
            : undefined;

        var pss = getStaticPanel(
          args.choices[aa],
          undefined,
          undefined,
          true,
          readAloudWrapper
        );

        // When getStaticPanel is called with a targetForReadAloud,
        // it does not add the created static panel to the target panel --
        // this is left to the caller if needed. Normally, it is not needed,
        // such as in setProblem when a second horizontally aligned panel in a
        // single row is supplied the first panel as targetForReadAloud.
        // However, here, we want the created static panel to be the second
        // child of the ReadAloudWrapper for control of shrinking.
        if (readAloudWrapper) {
          readAloudWrapper.add(pss);
          readAloudWrapper.layoutChildrenLR();
          pss = readAloudWrapper;
        }

        pss.setMaxWidth(maxDisplayWidth);

        pss.viewportX = cb.viewportW + 10;
        pss.choicePan = true;

        uiChoice.add(pss);

        uiH = Math.max(uiH, pss.viewportH);
        uiChoice.viewportW = pss.viewportX + pss.viewportW;
        uiChoice.viewportH = uiH;

        uiChoice.centerVertically();

        var mdr = function () {
          if (!multi.e) return true;
          // shouldn't need to check for isTesting, as server shouldn't ship this data when testing!
          if (cb.incorrectChoice) return true;
          changedMaybe(multi.agId);

          if (!cb.sel) {
            for (var z = 0; z < multi.uiChoices.length; z++) {
              multi.uiChoices[z].cb.sel = false;
            }

            cb.toggleSel();
            multi.args.choice = uiChoice.letter;
          }

          multi.grabFocus();

          return true;
        };

        if (!narrow) uiChoice.mouseDownResponse = mdr;
        else {
          cb.mouseDownResponse = mdr;
        }

        multi.uiChoices.push(uiChoice);
        multi.add(uiChoice);

        if (aa < args.choices.length - 1 && (narrow || args.mode === '4')) {
          var sep = getBasicObject();

          uiChoice.viewportH += 8;
          sep.viewportY = uiChoice.viewportY + uiChoice.viewportH - 1;
          sep.setAllDim(uiChoice.viewportW, 1);
          sep.fill = 'lightgray';
          uiChoice.sep = sep;
          uiChoice.add(sep);
        }
      })(a);
    }

    if (args.mode === '4' || narrow) {
      var uic = multi.uiChoices;

      for (var ba = 1; ba < uic.length; ba++) {
        var c = uic[ba];

        c.viewportH += 7;

        for (var bb = 0; bb < c.children.length; bb++) {
          c.children[bb].viewportY += 7;
        }
      }

      var vW = 0;

      for (var a = 0; a < uic.length; a++) {
        vW = Math.max(vW, uic[a].viewportW);
      }

      multi.layoutChildrenUD(0);
      multi.sizeMeToLowestAndRightmostChildren();

      for (var a = 0; a < multi.children.length; a++) {
        var n = multi.children[a];

        if (n.sep) {
          n.viewportW = vW;
          n.sep.setAllDim(vW, 1);
        }
      }
    } else if (args.mode === '2by2') {
      var uic = multi.uiChoices;
      var rx =
        uic.length < 3
          ? uic[0].viewportW
          : Math.max(uic[0].viewportW, uic[2].viewportW);

      rx += 15;
      uic[1].viewportX = rx;

      if (uic.length > 3) {
        uic[3].viewportX = rx;
      }

      var r1h = Math.max(uic[0].viewportH, uic[1].viewportH);

      if (r1h > uic[0].viewportH) {
        uic[0].viewportY += r1h / 2 - uic[0].viewportH / 2;
      }

      if (r1h > uic[1].viewportH) {
        uic[1].viewportY += r1h / 2 - uic[1].viewportH / 2;
      }

      multi.viewportW = uic[1].gR();
      multi.viewportH = r1h;

      if (uic.length > 2) {
        r1h += 10;
        uic[2].viewportY = r1h;

        if (uic.length > 3) {
          uic[3].viewportY = r1h;
          var r2h = Math.max(uic[2].viewportH, uic[3].viewportH);

          if (uic[2].viewportH < r2h) {
            uic[2].viewportY += r2h / 2 - uic[2].viewportH / 2;
          }

          if (uic[3].viewportH < r2h) {
            uic[3].viewportY += r2h / 2 - uic[3].viewportH / 2;
          }
        }

        // if == 3, use vW of first row (already set)
        if (uic.length > 3) {
          multi.viewportW = Math.max(multi.viewportW, uic[3].gR());
        }

        multi.sizeMeToLowestAndRightmostChildren();
      }
    } else {
      console.error('args.mode not supported in getMultiChoiceGetter');
    }

    multi.updateIncorrectChoices = function (incorrectChoiceLetters) {
      multi.args.incorrectChoices = incorrectChoiceLetters;

      if (!incorrectChoiceLetters) return;

      for (var x = 0; x < incorrectChoiceLetters.length; x++) {
        var letter = incorrectChoiceLetters[x];

        if (letter === multi.args.choice) {
          multi.args.choice = null;
        }

        for (var y = 0; y < multi.uiChoices.length; y++) {
          var uiC = multi.uiChoices[y];

          if (uiC.letter == letter) {
            uiC.cb.incorrectChoice = true;
            uiC.cb.sel = false;
          }
        }
      }
    };

    multi.updateCorrectChoice = function (correctChoiceLetter) {
      multi.args.correctChoice = correctChoiceLetter;

      if (!correctChoiceLetter) return;

      for (var y = 0; y < multi.uiChoices.length; y++) {
        var ui = multi.uiChoices[y];

        if (ui.letter == correctChoiceLetter) {
          ui.cb.correctChoice = true;
          multi.args.choice = correctChoiceLetter;
        }
      }
    };

    multi.updateChoiceStatus = function ({ incorrectChoices, correctChoice }) {
      if (incorrectChoices) multi.updateIncorrectChoices(incorrectChoices);
      if (correctChoice) multi.updateCorrectChoice(correctChoice);
    };

    multi.updateChoiceStatus({
      incorrectChoices: multi.args.incorrectChoices,
      correctChoice: multi.args.correctChoice,
    });

    multi.setStatus = function (st) {
      multi.st = st;
      multi.e = st !== 'c';
    };

    multi.loseFocus = function () {};

    multi.grabFocus = function () {
      if (
        currentAttemptGetter &&
        currentAttemptGetter.gmmName == 'gridGetter'
      ) {
        currentAttemptGetter.g.setSelected(false);
      }

      if (focused) {
        focused.setFocused(false);
      }

      removeKeyboard();
      currentAttemptGetter = multi;
    };

    var submitButton = getBasicObject();

    submitButton.animates = true;
    submitButton.setAllDim(40, 40);

    submitButton.mouseDownResponse = function () {
      if (!multi.e) return true;

      if (currentAttemptGetter !== multi) {
        multi.grabFocus();
      }

      submitAttempt({ eventSource: 'multiChoiceGetter' });

      return true;
    };

    submitButton.paintMe = function (ctx) {
      xyz(
        'submit',
        ctx,
        0,
        0,
        paintCanvas,
        submitButton.animated && multi.e,
        getThemeColor(),
        40
      );
    };

    multi.add(submitButton);
    submitButton.viewportX = multi.viewportW / 2 - submitButton.viewportW / 2;
    submitButton.viewportY = multi.viewportH + 15;
    multi.sizeMeToLowestAndRightmostChildren();

    // Required getter function
    multi.serializeAttempt = function () {
      if (!multi.args.choice) {
        getProblemContext().addStackableDialog({
          msg: 'You must make a choice.',
          top: 'Invalid',
        });

        return;
      }

      return { choice: multi.args.choice };
    };

    return multi;
  };

  var getCheckbox = function () {
    var cb = getBasicObject();

    cb.setAllDim(25, 25);
    cb.sel = false;

    cb.toggleSel = function () {
      cb.sel = !cb.sel;
    };

    cb.viewportX = 1;

    cb.paintMe = function (ctx) {
      var noChoiceSelected = cb.multi.args.s === 'u';

      var isWrongInTeacherPreviewPage =
        isTeacherViewingExamProblem && !noChoiceSelected && cb.sel;
      var fillR;

      if (isTesting() && cb.sel) {
        fillR = 'cyan';
      } else if (!isTesting() && cb.correctChoice) {
        fillR = correctBlue;
      } else if (
        !isTesting() &&
        (cb.incorrectChoice || isWrongInTeacherPreviewPage) &&
        cb.multi.e
      ) {
        fillR = 'gray';
      }

      roundRect2(
        ctx,
        0,
        0,
        cb.viewportW,
        cb.viewportH,
        6,
        fillR,
        'lightGray',
        2
      );

      if (
        !isTesting() &&
        !cb.correctChoice &&
        !cb.incorrectChoice &&
        !isTeacherViewingExamProblem &&
        cb.sel
      ) {
        roundRect2(
          ctx,
          0,
          0,
          cb.viewportW,
          cb.viewportH,
          6,
          undefined,
          'cyan',
          2
        );
      }

      if (cb.sel && !(isTeacherViewingExamProblem && !cb.correctChoice)) {
        ctx.save();
        ctx.fillStyle = isTesting() || cb.correctChoice ? 'white' : 'black';
        ctx.font = 'bold 18px ' + lineFont;

        ctx.textBaseline = 'top';
        ctx.fillText('\u2713', 4, 4);
        ctx.restore();
      }

      if (
        !isTesting() &&
        (cb.incorrectChoice || isWrongInTeacherPreviewPage) &&
        cb.multi.e
      ) {
        ctx.save();

        ctx.lineWidth = 2;
        ctx.strokeStyle = 'magenta';
        ctx.beginPath();
        ctx.moveTo(2, 2);
        ctx.lineTo(cb.viewportW - 2, cb.viewportH - 2);
        ctx.moveTo(cb.viewportW - 2, 2);
        ctx.lineTo(2, cb.viewportH - 2);
        ctx.stroke();

        ctx.restore();
      }
    };

    cb.setSelected = function (b) {
      cb.sel = b;
    };

    return cb;
  };

  //* *CAREFUL: if button already selected, don't want to submit
  // blank material in getter
  var submitAllAgs = function (after) {
    var funcs = [];

    for (var a = 0; a < attemptGetters.size; a++) {
      (function (b) {
        if (b === 0) {
          funcs[0] = function () {
            currentAttemptGetter = attemptGetters.get(b);
            if (
              !currentAttemptGetter.selectedButton &&
              currentAttemptGetter.uncertain
            ) {
              submitAttempt({
                after: after,
                autoSubmittedDuringTest: true,
                eventSource: 'submitAllAgs for autoSubmitDuringTest (v1)',
              });
            } else if (after) after();
          };
        } else {
          funcs[b] = function () {
            currentAttemptGetter = attemptGetters.get(b);

            if (
              !currentAttemptGetter.selectedButton &&
              currentAttemptGetter.uncertain
            ) {
              submitAttempt({
                after: funcs[b - 1],
                autoSubmittedDuringTest: true,
                eventSource: 'submitAllAgs for autoSubmitDuringTest (v2)',
              });
            } else {
              funcs[b - 1]();
            }
          };
        }
      })(a);
    }

    funcs[funcs.length - 1]();
  };

  var getAttemptJSON = function (getter, button) {
    var ret = { id: currentProblemId, agId: getter.agId };

    // if currentAttemptGetter is a 'normal getter' used by a number line getter,
    // then this storage logic should acquire the parent number line getter
    var ag = attemptGetters.get(getter.agId);

    if (button) {
      ret.attempt = JSON.stringify({ button: button });

      return ret;
    }

    ret.attempt = ag.serializeAttempt();

    if (ret.attempt) {
      ret.attempt = JSON.stringify(ret.attempt);
    }

    return ret;
  };

  var setSubmitLock = function (b) {
    if (b === submitLock) return;
    submitLock = b;
    getProblemContext().logHistory?.('submitLock changed to: ' + submitLock);
  };

  var submitAttempt = function (submitParams) {
    var button = submitParams?.button || undefined;
    var after = submitParams?.after || undefined;
    var blank = submitParams?.blank || false;
    var autoSubmittedDuringTest =
      submitParams?.autoSubmittedDuringTest || false;
    var eventSource = submitParams?.eventSource || 'unknown';

    cancelReadAloud();

    getProblemContext().logHistory?.(
      'Submit requested, event source: ' + eventSource
    );

    if (!selfCheckListener && submitLock && !blank) {
      getProblemContext().logHistory?.(
        `Abandon submit submitLock: ${submitLock}, blank: ${blank}, selfCheckListener: ${selfCheckListener}`
      );

      return;
    }

    var submitStart = new Date().getTime();

    if (
      !selfCheckListener &&
      lastSubmitTime > 0 &&
      submitStart - lastSubmitTime < 300
    ) {
      getProblemContext().logHistory?.(
        `Abandon submit due to submission ${
          submitStart - lastSubmitTime
        } ms after last submit`
      );

      return;
    }

    setLoading(true);
    setSubmitLock(true);

    // internal tool CanvasCapture selfCheck simulates 'clicking' correct button
    if (selfCheckListener && currentAttemptGetter.selectedButton) {
      button = currentAttemptGetter.selectedButton;
    }

    var submitBundle = {};

    if (!blank) {
      submitBundle = getAttemptJSON(currentAttemptGetter, button);

      if (!submitBundle.attempt) {
        getProblemContext().logHistory?.(
          'Abandon submit due to invalidation during getAttemptJSON'
        );

        setLoading(false);
        setSubmitLock(false);

        return;
      }

      currentAttemptGetter.selectedButton = button ? button : undefined;

      if (currentAttemptGetter.gmmName === 'gridGetter') {
        if (button) currentAttemptGetter.clearF(button);
        else currentAttemptGetter.deselectAllButtons();
      } else {
        if (button) {
          clearAll(true, true, true, null, false);
          currentAttemptGetter.getJsonFromServer().selectedButton = button;
        } else applySelectedButton();
      }
    } else {
      submitBundle = {
        id: currentProblemId,
        agId: currentAttemptGetter.agId,
      };

      submitBundle.attempt = JSON.stringify({ t: currentAttemptGetter.t });
      submitBundle.blank = true;

      if (currentAttemptGetter.gmmName === 'gridGetter') {
        currentAttemptGetter.deselectAllButtons();
      } else {
        currentAttemptGetter.selectedButton = undefined;
        applySelectedButton();
      }
    }

    if (getProblemContext().getMd5) {
      submitBundle.md5 = getProblemContext().getMd5(currentProblemId);
    }

    if (isTesting()) submitBundle.isTest = true;

    // prevent cache
    submitBundle.a = new Date().getTime();

    if (isStudent) {
      submitBundle.ss = getProblemContext().getSS();
      submitBundle.user = getProblemContext().getUsername();
    }

    var guid = getProblemContext().getGuid();

    if (guid) submitBundle.guid = guid;

    var url = getApiUrl() + '/Submit';

    var submitID = submitCount++;

    getProblemContext().logHistory?.(
      `submit #${submitID}
        for restore ${submitBundle.id}
        (md5 ${submitBundle.md5})
        eventSource ${eventSource}`
    );

    var timeSeen = getProblemContext().harvestTimeSeen(false);

    submitBundle.timeSeen = timeSeen ? timeSeen : 0;

    const apiCall = function () {
      let dataForError;

      callSubmit(url, submitBundle)
        .then(data => {
          dataForError = data;
          lastSubmitTime = new Date().getTime();
          var duration = lastSubmitTime - submitStart;

          if (selfCheckListener) {
            selfCheckListener.addResult(currentAttemptGetter.agId, data);
          }

          if (getProblemContext().logHistory) {
            var forceLoginInternal = data.forceLoginInternal
              ? ' (forceLoginInternal)'
              : '';
            var msg = `
            Successful round trip to WF Submit Servlet ${forceLoginInternal} 
            on submit #${submitID} 
            for Restore ${submitBundle.id} 
            (md5 ${submitBundle.md5}) 
            in ${duration} millis
          `;

            getProblemContext().logHistory(msg);
          }

          if (data.forceLoginInternal) {
            throw new Error('forceLoginInternal');
          }

          var probs = getProblemContext().getProblems();
          var ag = currentAttemptGetter;
          var abandoned =
            probs && !isTesting() && probs.get(`${data.id}`) === undefined;

          if (!abandoned && data.changedNag) {
            attemptGetters
              .get(data.changedNag.agId)
              .changedNag(data.changedNag);
          }

          if (!abandoned && data.changedGrid) {
            data.changedGrid.color = 'rgba(255, 200, 0, .55)';
            data.changedGrid.lw = 9;
            attemptGetters
              .get(data.changedGrid.agId)
              .g.addLiney(data.changedGrid, true);
          }

          if ((!abandoned && data.correctChoice) || data.incorrectChoices) {
            attemptGetters.get(ag.agId).updateChoiceStatus({
              incorrectChoices: data.incorrectChoices,
              correctChoice: data.correctChoice,
            });
          }

          if (!abandoned && data.updateGetter) {
            attemptGetters.get(ag.agId).update(data.updateGetter);
          }

          if (!abandoned && data.swapLabel) {
            var lins = attemptGetters.get(data.swapLabel.agId).args.lines;

            for (var xa = 0; xa < lins.length; xa++) {
              var lin = lins[xa];
              var xm = lin.xml;
              var bad = data.swapLabel.badLabel;
              var i = xm.indexOf(bad);

              if (i > -1) {
                var good = data.swapLabel.goodLabel;

                lin.xml =
                  xm.substring(0, i) + good + xm.substring(i + bad.length);
                attemptGetters.get(data.swapLabel.agId).swapString(bad, good);
              }
            }
          }

          if (!isTesting() && !abandoned) {
            if (!data.inv && !data.uF) {
              if (data.aC) {
                attemptGetters.get(ag.agId).setStatus('c');
                if (!data.tC) advanceFocus(ag.agId);
              } else {
                attemptGetters.get(ag.agId).setStatus('wr');
              }
            }
          }

          // student app may send back its 'nextExamProblem' function
          var nextExamProblem = getProblemContext().attemptSubmitted(
            data,
            ag.agId,
            autoSubmittedDuringTest,
            blank
          );

          if (isTesting()) {
            attemptGetters.get(ag.agId).uncertain = blank ? true : false;

            if (data.valids) {
              for (var x = 0; x < data.valids.length; x++) {
                attemptGetters.get(data.valids[x]).setStatus('ready');
              }
            }

            if (data.invalids) {
              for (var x = 0; x < data.invalids.length; x++) {
                attemptGetters.get(data.invalids[x]).setStatus('invalid');
              }
            }

            if (!data.testResult || data.testResult.status !== 'ready') {
              if (!autoSubmittedDuringTest && ag.st === 'ready') {
                advanceFocus(ag.agId);
              }
            }
          }

          if (nextExamProblem) nextExamProblem();

          paintCanvas();
          if (after) after();
        })
        .catch(error => {
          lastSubmitTime = new Date().getTime();
          var duration = lastSubmitTime - submitStart;
          let msg = `
            Error caught when attempting to submit by ${
              isStudent ? 'student' : 'anonymous'
            }. 
            Submit #${submitID} 
            for Restore ${submitBundle.id} 
            (md5 ${submitBundle.md5}) 
            ${duration} millis after submit start.
          `;

          if (error.name === 'AxiosError') {
            const code = error.code || 'No error code';
            const response = error.response
              ? JSON.stringify(error.response, null, 2)
              : 'No response';

            msg += `
              AXIOS ERROR
              Code: ${code}
              Response: ${response}
            `;
          } else {
            msg += `
              GMM LOGIC ERROR
              error.name: ${error.name || 'No error name available'}
              error.message: ${error.message || 'No error message available'}
              error.stack: ${error.stack || 'No stack trace available'}
              forceLoginInternal: ${
                dataForError ? dataForError.forceLoginInternal : 'false'
              }
              outbound submitBundle: ${JSON.stringify(submitBundle, null, 2)}
              inbound data: ${
                dataForError
                  ? JSON.stringify(dataForError, null, 2)
                  : 'No data available'
              }
            `;
          }

          getProblemContext().errorToServer(msg);

          const dialogMsg = isStudent
            ? 'GMM hit an unexpected technical error and needed to reset your screen.'
            : 'GMM hit a connection issue. Please try again in a moment.';

          getProblemContext().addStackableDialog({
            top: 'Connection Trouble',
            msg: dialogMsg,
            reload: true,
            reloadIsInternal: true,
          });
        })
        .finally(() => {
          setLoading(false);
          setSubmitLock(false);
        });
    };

    if (getProblemContext().addQueuedSubmit) {
      getProblemContext().addQueuedSubmit({
        id: submitBundle.id,
        md5: submitBundle.md5,
        submit: apiCall,
      });
    } else {
      apiCall();
    }
  };

  var advanceFocus = function (startAgId, fromTab) {
    if (!attemptGetters || attemptGetters.size === 0) {
      return;
    }

    if (gridGuide && gridGuide.on) {
      gridGuide.on = false;
    }

    var tabbedGetter = currentAttemptGetter?.nlg ?? currentAttemptGetter;

    if (
      fromTab &&
      currentAttemptGetter &&
      ((currentAttemptGetter.tab && currentAttemptGetter.isLastFocused) ||
        (currentAttemptGetter.nlg?.tab &&
          currentAttemptGetter.nlg?.isLastFocused))
    ) {
      var wasAtLast = tabbedGetter.isLastFocused();

      if (!wasAtLast) {
        tabbedGetter.tab();

        return true;
      }
    }

    /**
     * List getters in order in which they appear, top left to bottom right
     *
     * In older client versions, we used getter.agId as both a unique identifier
     * AND visual order indicator. As of late 2022, server no longer guarantees
     * this concurrent usage, but does still deliver getter json in the order
     * in which the getters appear vertically.
     */
    let gettersOrderedTopToBottom = Array.from(attemptGetters.values());

    let start = 0;

    if (startAgId == undefined && currentAttemptGetter) {
      startAgId = currentAttemptGetter.agId;
    }

    if (startAgId !== undefined) {
      for (let x = 0; x < gettersOrderedTopToBottom.length; x++) {
        if (gettersOrderedTopToBottom[x].agId === startAgId) {
          start = x + 1;
          if (start > gettersOrderedTopToBottom.length) start = 0;
          break;
        }
      }
    }

    var oldGetter = currentAttemptGetter;
    var newGetter;

    for (let x = start; x < gettersOrderedTopToBottom.length; x++) {
      if (gettersOrderedTopToBottom[x].st !== 'c') {
        newGetter = gettersOrderedTopToBottom[x];
        break;
      }
    }

    if (!newGetter) {
      for (let x = 0; x < start; x++) {
        if (gettersOrderedTopToBottom[x].st !== 'c') {
          newGetter = gettersOrderedTopToBottom[x];
          break;
        }
      }
    }

    if (newGetter) {
      currentAttemptGetter = newGetter;

      // Notify old getter of focus loss
      oldGetter?.loseFocus?.();

      // Notify new getter of focus gain
      newGetter.grabFocus('left');

      return true;
    }
  };

  var useTouchKeyboard = function () {
    return isTouchDeviceOnly() || getProblemContext()?.isForceTouchKeyboard();
  };

  var buildKeyboard = function () {
    var ret = getBasicObject();

    ret.gmmName = 'keyboard';

    keyboardW = DEFAULT_KEYBOARD_WIDTH;

    var top = getBasicObject();
    var topH = 0;

    ret.setShowBarred = function (b) {
      if (ret.fracButton) ret.fracButton.setShowBarred(b);
      ret.showBarred = b;
    };

    ret.setShowDivision = function (b) {
      ret.showDivision = b ? true : false;
    };

    ret.setShowDollarSymbol = function (b) {
      ret.showDollarSymbol = b ? true : false;
    };

    top.clearLockedAnimated = function () {
      for (var az = 0; az < top.children.length; az++) {
        top.children[az].selected = false;
      }
    };

    var lastB;
    var touch = useTouchKeyboard();
    var scalar = 1;

    if (touch) {
      var range = PROBLEM_WIDTH - NARROW_PROBLEM_WIDTH;
      var percent = (canvasW - NARROW_PROBLEM_WIDTH) / range;
      var maxScalar = isTouchDeviceOnly() ? 1.5 : 1.2;

      keyboardScalar = Math.min(1 + percent, maxScalar);
      keyboardW = DEFAULT_KEYBOARD_WIDTH * keyboardScalar;
      scalar = keyboardScalar;

      var mouseDownResponse = function () {
        setKeyboardBottom('numbers');

        return true;
      };
      var numbers = getBasicObject();

      numbers.selected = false;
      numbers.setAllDim(34 * scalar, 34 * scalar);
      numbers.mouseDownResponse = mouseDownResponse;

      numbers.paintMe = function (ctx) {
        xyz(
          '#',
          ctx,
          0,
          0,
          paintCanvas,
          numbers.selected,
          getThemeColor(),
          30 * scalar,
          false,
          !numbers.selected,
          scalar
        );
      };

      top.add(numbers);
      var topH = numbers.viewportH;

      mouseDownResponse = function () {
        setKeyboardBottom('expressions');

        return true;
      };

      var expressions = getBasicObject();

      expressions.selected = false;
      expressions.setAllDim(38 * scalar, 34 * scalar);
      expressions.mouseDownResponse = mouseDownResponse;

      expressions.paintMe = function (ctx) {
        ctx.save();
        ctx.translate(4, 0);
        xyz(
          'xsquared',
          ctx,
          0,
          0,
          paintCanvas,
          expressions.selected,
          getThemeColor(),
          30 * scalar,
          false,
          !expressions.selected,
          scalar
        );
        ctx.restore();
      };

      expressions.viewportX = numbers.viewportX + numbers.viewportW; // + 8;

      top.add(expressions);
      topH = Math.max(expressions.viewportH, topH);
      expressions.staysAnimatedColor = true;

      mouseDownResponse = function () {
        const problemContext = getProblemContext();

        setKeyboardBottom('alphabet');

        if (problemContext.isUseNativeKeyboard() && focused) {
          const location = getFullTopLeftLocation2(focused);

          problemModalState().setNativeInputText(focused.text);
          problemModalState().setNativeInputCursorOnMount(
            focused.cursorIndentChars
          );
          problemModalState().setNativeInputLocation(location);
          setFocusedNativeInput(focused);
        }

        return true;
      };

      var alpha = getBasicObject();

      alpha.selected = false;
      alpha.setAllDim(38 * scalar, 34 * scalar);
      alpha.mouseDownResponse = mouseDownResponse;

      alpha.paintMe = function (ctx) {
        ctx.save();
        ctx.translate(4, 0);
        xyz(
          'abc',
          ctx,
          0,
          0,
          paintCanvas,
          alpha.selected,
          getThemeColor(),
          30 * scalar,
          false,
          !alpha.selected,
          scalar
        );
        ctx.restore();
      };

      alpha.viewportX = expressions.viewportX + expressions.viewportW; // + 8;

      top.add(alpha);
      topH = Math.max(alpha.viewportH, topH);
      alpha.staysAnimatedColor = true;

      mouseDownResponse = function () {
        pressedKey(' ');

        return true;
      };

      var space = getBasicObject();

      space.setAllDim(46 * scalar, 34 * scalar);

      space.paintMe = function (ctx) {
        ctx.save();
        ctx.translate(4, 0);
        roundRect2(
          ctx,
          0,
          5,
          38 * scalar,
          20 * scalar,
          4,
          space.animated ? getThemeColor() : 'lightgray'
        );

        ctx.translate(0, 5);
        ctx.lineWidth = 2;
        ctx.strokeStyle = 'gray';
        ctx.beginPath();
        var w = 38 * scalar;
        var h = 20 * scalar;
        var wi = 0.15;
        var hi = 0.35;

        ctx.moveTo(wi * w, hi * h);
        ctx.lineTo(wi * w, (1 - hi) * h);
        ctx.lineTo((1 - wi) * w, (1 - hi) * h);
        ctx.lineTo((1 - wi) * w, hi * h);
        ctx.stroke();
        ctx.restore();
      };

      space.animates = true;
      space.mouseDownResponse = mouseDownResponse;
      space.viewportX = alpha.viewportX + alpha.viewportW + 10;
      top.add(space);

      mouseDownResponse = function () {
        if (focused) {
          focused.backspace();
          paintCanvas();

          return true;
        }

        return false;
      };

      var backspace = getBasicObject();

      backspace.setAllDim(40 * scalar, 34 * scalar);

      backspace.paintMe = function (ctx) {
        ctx.save();

        var w = 26 * scalar;
        var h = 24 * scalar;

        ctx.translate(4, 0);
        roundRect2(
          ctx,
          0,
          0,
          32 * scalar,
          30 * scalar,
          8,
          backspace.animated ? getThemeColor() : 'lightgray'
        );

        ctx.translate(2, 4);
        ctx.scale(1, 0.9);

        ctx.lineWidth = 2;
        ctx.strokeStyle = 'gray';
        ctx.beginPath();
        ctx.moveTo(1, 0.5 * h);
        ctx.lineTo(0.5 * w, h - 1);
        ctx.lineTo(0.5 * w, 0.8 * h);
        ctx.lineTo(w - 1, 0.8 * h);
        ctx.lineTo(w - 1, 0.2 * h);
        ctx.lineTo(0.5 * w, 0.2 * h);
        ctx.lineTo(0.5 * w, 1);
        ctx.lineTo(1, 0.5 * h);
        ctx.closePath();
        ctx.stroke();

        ctx.beginPath();
        ctx.moveTo(0.5 * w, 0.3 * h);
        ctx.lineTo(0.85 * w, 0.7 * h);
        ctx.moveTo(0.85 * w, 0.3 * h);
        ctx.lineTo(0.5 * w, 0.7 * h);
        ctx.closePath();

        ctx.stroke();

        ctx.restore();
      };

      backspace.animates = true;
      backspace.mouseDownResponse = mouseDownResponse;
      backspace.viewportX = space.viewportX + space.viewportW;

      top.add(backspace);
      topH = Math.max(backspace.viewportH, topH);

      var clearAll = getClearAll(30 * scalar, true, 4, 4, 4);

      clearAll.viewportX = backspace.viewportX + backspace.viewportW;

      top.add(clearAll);
      topH = Math.max(clearAll.viewportH, topH);

      lastB = clearAll;
    } else {
      var frac = getFracButton();

      if (ret.showBarred) frac.setShowBarred(true);
      ret.fracButton = frac;
      frac.animates = true;
      top.add(frac);
      var pow = getPowButton();

      pow.animates = true;
      top.add(pow);
      var f = getFunctionButton(pow);

      f.animates = true;
      top.add(f);
      var r = getRootButton(pow);

      r.animates = true;
      top.add(r);
      var s = getSubscriptButton(pow);

      s.animates = true;
      top.add(s);

      lastB = pow;
    }

    var submitMouseDownResponse = function () {
      submitAttempt({
        eventSource:
          'mouseDown on keyboard submit button, getter: ' +
          getCurrentAttemptGetterName(),
      });

      return true;
    };

    var submit = getBasicObject();

    submit.selected = false;
    submit.setAllDim(40 * scalar, 40 * scalar);
    submit.mouseDownResponse = submitMouseDownResponse;

    submit.paintMe = function (ctx) {
      xyz(
        'submit',
        ctx,
        0,
        0,
        paintCanvas,
        submit.animated,
        getThemeColor(),
        40 * scalar
      );
    };

    submit.animates = true;

    top.add(submit);
    ret.submitter = submit;

    if (touch) {
      submit.viewportX = lastB.viewportX + lastB.viewportW + (touch ? 16 : 20);
      topH = Math.max(submit.viewportH, topH);
      top.setAllDim(submit.viewportX + submit.viewportW, topH);
    } else {
      top.layoutChildrenLR(8);
      submit.viewportX += 14;
      top.sizeMeToFitChildren();
    }

    var extraSubmitButtons = getBasicObject();

    ret.add(extraSubmitButtons);

    top.centerVertically();
    ret.add(top);

    var bottom = getBasicObject();

    bottom.viewportY = top.viewportH + 5;
    bottom.tucked = lastB.viewportW;

    ret.add(bottom);

    ret.extraSubmitButtons = extraSubmitButtons;
    ret.top = top;
    ret.bottom = bottom;

    keyboard = ret;
    setKeyboardBottom('numbers');
  };

  var getHolder = function (key, btwnH, btwnV, first, last) {
    var holder = getBasicObject();

    key.viewportX = !first ? btwnH / 2 : 0;
    key.viewportY = btwnV / 2;
    holder.setAllDim(
      key.viewportW + (first || last ? btwnH / 2 : btwnH),
      key.viewportH + btwnV
    );
    holder.add(key);

    holder.mouseDownResponse = function () {
      return key.mouseDown(
        holder.viewportW / 2,
        holder.viewportH / 2,
        paintCanvas
      );
    };

    return holder;
  };

  var setKeyboardBottom = function (t) {
    var scalar = 1;

    if (useTouchKeyboard()) {
      scalar = keyboardScalar;
    }

    var side = 28.5 * scalar;
    var gap = 8;
    var btwnH = 6;
    var btwnV = 5;

    var h = 0;
    var x = 0;
    var wAvailable = keyboardW;

    keyboard.bottom.children = [];

    if (useTouchKeyboard()) {
      var cl = 0;

      if (t === 'numbers') {
        cl = 1;
      } else if (t == 'expressions') {
        cl = 2;
      } else if (t == 'alphabet') {
        cl = 3;
      }

      if (cl) {
        keyboard.top.clearLockedAnimated();
        keyboard.top.children[cl - 1].selected = true;
      }

      var rs = getBasicObject();

      if (t === 'numbers') {
        var digits = [];
        var symbols = [];

        for (var a = 0; a < 10; a++) {
          (function (b) {
            var mouseDownResponse = function () {
              pressedKey(b);

              return true;
            };
            var key = getBasicObject();

            key.mouseDownResponse = mouseDownResponse;
            key.setAllDim(side, side);

            key.paintMe = function (ctx) {
              roundRect2(
                ctx,
                0,
                0,
                side,
                side,
                8,
                key.animated ? getThemeColor() : 'lightgray'
              );
              ctx.save();
              ctx.beginPath();

              ctx.fillStyle = 'gray';
              ctx.textBaseline = 'top';
              ctx.font = 22 * scalar + 'px ' + lineFont;
              ctx.fillText(b + '', 8.55 * scalar, 2.85 * scalar);

              ctx.restore();
            };

            key.animates = true;

            var holder = getHolder(key, btwnH, btwnV, b === 0 || b == 5, false);

            digits.push(holder);
          })(a);
        }

        // var chars = '+\u2212*=.' + comma + '<\u2264>\u2265' + percent;
        var chars = '+\u2212*\u00F7' + '=.,$' + '<\u2264>\u2265%:';

        for (var a = 0; a < chars.length; a++) {
          (function (b) {
            var but = getSymbolButton(b, side);

            but.animates = true;
            var l = b === '\u00F7' || b === '$' || b === ':';

            symbols.push(getHolder(but, btwnH, btwnV, false, l));
          })(chars.substring(a, a + 1));
        }

        var r1 = getBasicObject();
        var x = 0;

        for (var a = 0; a < 5; a++) {
          var n = digits[a];

          n.viewportX = x;
          x += n.viewportW;
          r1.add(n);
        }

        for (var a = 0; a < 4; a++) {
          var n = symbols[a];

          n.viewportX = x;
          x += n.viewportW;
          r1.add(n);
        }

        r1.sizeMeToFitChildren();

        var r2 = getBasicObject();

        x = 0;

        for (var a = 5; a < 10; a++) {
          var n = digits[a];

          n.viewportX = x;
          x += n.viewportW;
          r2.add(n);
        }

        for (var a = 4; a < 8; a++) {
          var n = symbols[a];

          n.viewportX = x;
          x += n.viewportW;
          r2.add(n);
        }

        r2.sizeMeToFitChildren();
        r2.viewportY = r1.viewportH;

        var r3 = getBasicObject();

        var letBox = getBasicObject();
        var wi = side * 3 + btwnH * 2.5;

        letBox.setAllDim(wi, side + 2 * btwnV);
        var letters =
          currentAttemptGetter && currentAttemptGetter.letters
            ? currentAttemptGetter.letters
            : 'xyz';

        var star =
          letters.length == 1
            ? side + gap / 2
            : letters.length == 2
            ? 0.5 * side
            : 0;

        for (var a = 0; a < letters.length; a++) {
          (function (b) {
            var mouseDownResponse = function () {
              pressedKey(b);

              return true;
            };
            var key = getBasicObject();

            key.mouseDownResponse = mouseDownResponse;
            key.setAllDim(side, side);

            key.paintMe = function (ctx) {
              roundRect2(
                ctx,
                0,
                0,
                side,
                side,
                8,
                key.animated ? getThemeColor() : '#97CC6B'
              );
              ctx.save();
              ctx.beginPath();

              ctx.fillStyle = 'white';
              ctx.textBaseline = 'top';
              ctx.font = 22 * scalar + 'px ' + lineFont;

              var wh = ctx.measureText(b).width;

              ctx.fillText(b + '', (side - wh) / 2, 3);

              ctx.restore();
            };

            key.animates = true;
            var first =
              letters.length == 3 &&
              (!letBox.children || !letBox.children.length);
            var ho = getHolder(key, btwnH, btwnV, first);

            ho.viewportX = star;
            star += gap + side - (first ? gap / 2 : 0);
            letBox.add(ho);
          })(letters.substring(a, a + 1));
        }

        r3.add(letBox);

        x = letBox.viewportW;

        for (var a = 8; a < 14; a++) {
          var n = symbols[a];

          n.viewportX = x;
          x += n.viewportW;
          r3.add(n);
        }

        r3.sizeMeToFitChildren();
        r3.viewportY = r2.viewportY + r2.viewportH;

        rs.add(r1);
        rs.add(r2);
        rs.add(r3);
      } else if (t === 'expressions') {
        var x = 0;
        var r1 = getBasicObject();
        var frac = getFracButton();

        keyboard.fracButton = frac;
        frac.setShowBarred(keyboard.showBarred);

        r1.add(frac);
        x = frac.viewportW;
        var h = frac.viewportH;

        var pow = getPowButton();

        r1.add(pow);
        pow.viewportX = x + 6 * scalar;
        x += 6 * scalar + pow.viewportW;
        h = Math.max(h, pow.viewportH);

        var quantity = getQuantityButton(pow);

        quantity.viewportX = x + 6 * scalar;
        x = quantity.viewportX + quantity.viewportW;
        h = Math.max(h, quantity.viewportH);

        r1.add(quantity);

        var absoluteValue = getAbsoluteValueButton(pow);

        absoluteValue.viewportX = x + 6 * scalar;
        x = absoluteValue.viewportX + absoluteValue.viewportW;
        h = Math.max(h, absoluteValue.viewportH);

        r1.add(absoluteValue);

        var root = getRootButton(pow);

        root.viewportX = x + 6 * scalar;
        x = root.viewportX + root.viewportW;
        h = Math.max(h, root.viewportH);

        r1.add(root);

        // Build subscript button
        var subscriptButton = getSubscriptButton();

        subscriptButton.viewportX = x + 6 * scalar;
        x = subscriptButton.viewportX + subscriptButton.viewportW;
        h = Math.max(h, subscriptButton.viewportH);
        r1.add(subscriptButton);

        // Build function button
        var functionButton = getFunctionButton(pow);

        functionButton.viewportX = x + 6 * scalar;
        x = functionButton.viewportX + functionButton.viewportW;
        h = Math.max(h, functionButton.viewportH);
        r1.add(functionButton);

        r1.sizeMeToFitChildren();
        r1.centerVertically();
        rs.add(r1);

        var r2 = getBasicObject();

        var Q = 6 * scalar;
        var H = 12 * scalar;
        var M = 5 * scalar;
        var X = 2 * scalar;
        var F = 10 * scalar;

        var mouseDownResponse = function () {
          moveCursor('up');

          return true;
        };
        var up = getBasicObject();

        up.setAllDim(24 * scalar + 2 * X, 22 * scalar + H / 2 + M);

        up.paintMe = function (ctx) {
          ctx.save();
          ctx.translate(X, M);
          arrowUp(
            ctx,
            24 * scalar,
            22 * scalar,
            2,
            'gray',
            up.animated ? getThemeColor() : undefined
          );
          ctx.restore();
        };

        up.animates = true;
        up.mouseDownResponse = mouseDownResponse;

        mouseDownResponse = function () {
          moveCursor('down');

          return true;
        };

        var down = getBasicObject();

        down.setAllDim(24 * scalar + 2 * X, 22 * scalar + H / 2 + M);

        down.paintMe = function (ctx) {
          ctx.save();
          ctx.translate(X, H / 2);
          arrowDown(
            ctx,
            24 * scalar,
            22 * scalar,
            2,
            'gray',
            down.animated ? getThemeColor() : undefined
          );
          ctx.restore();
        };

        down.animates = true;
        down.mouseDownResponse = mouseDownResponse;

        mouseDownResponse = function () {
          moveCursor('left');

          return true;
        };

        var left = getBasicObject();

        left.setAllDim(F + 22 * scalar + Q, 22 * 2 * scalar + H + 2 * M);

        left.paintMe = function (ctx) {
          ctx.save();
          ctx.translate(F, left.viewportH / 2 - (24 / 2) * scalar);
          arrowLeft(
            ctx,
            22 * scalar,
            24 * scalar,
            2,
            'gray',
            left.animated ? getThemeColor() : undefined
          );
          ctx.restore();
        };

        left.animates = true;
        left.mouseDownResponse = mouseDownResponse;

        mouseDownResponse = function () {
          moveCursor('right');

          return true;
        };

        var right = getBasicObject();

        right.setAllDim(Q + 22 * scalar + F, 22 * 2 * scalar + H + 2 * M);

        right.paintMe = function (ctx) {
          ctx.save();
          ctx.translate(Q, right.viewportH / 2 - (24 / 2) * scalar);
          arrowRight(
            ctx,
            22 * scalar,
            24 * scalar,
            2,
            'gray',
            right.animated ? getThemeColor() : undefined
          );
          ctx.restore();
        };

        right.animates = true;
        right.mouseDownResponse = mouseDownResponse;

        up.viewportX = left.viewportW;
        down.viewportX = left.viewportW;
        down.viewportY = up.viewportH;
        right.viewportX = up.viewportX + up.viewportW;

        r2.add(left);
        r2.add(right);
        r2.add(up);
        r2.add(down);
        r2.sizeMeToFitChildren();
        r2.viewportY = r1.viewportH;

        rs.add(r2);
        rs.centerHorizontally();
      } else if (t === 'alphabet') {
        var letters = [];

        side = 30 * scalar;
        gap = 5 * scalar;

        var chars = 'QWERTYUIOASDFGHJKLZXCVBNMP';

        for (var a = 0; a < 26; a++) {
          (function (b) {
            var mouseDownResponse = function () {
              pressedKey(b.toLowerCase());

              return true;
            };
            var key = getBasicObject();

            key.mouseDownResponse = mouseDownResponse;
            key.setAllDim(side, side);

            key.paintMe = function (ctx) {
              roundRect2(
                ctx,
                0,
                0,
                side,
                side,
                8,
                key.animated ? getThemeColor() : 'lightgray'
              );
              ctx.save();
              ctx.beginPath();

              ctx.fillStyle = 'gray';
              ctx.textBaseline = 'top';
              ctx.font = 22 * scalar + 'px ' + lineFont;

              var wh = ctx.measureText(b).width;

              ctx.fillText(b + '', (side - wh) / 2, 3 * scalar);

              ctx.restore();
            };

            key.animates = true;

            var first = b === 'Q' || b === 'A' || b === 'Z';
            var last = b === 'O' || b === 'L' || b === 'P';

            letters.push(getHolder(key, gap, gap, first, last));
          })(chars.substring(a, a + 1));
        }

        var r1 = getBasicObject();
        var x = 0;

        for (var a = 0; a < 9; a++) {
          var n = letters[a];

          n.viewportX = x;
          x += n.viewportW;
          r1.add(n);
        }

        r1.sizeMeToFitChildren();

        var r2 = getBasicObject();

        x = 0;

        for (var a = 9; a < 18; a++) {
          var n = letters[a];

          n.viewportX = x;
          x += n.viewportW;
          r2.add(n);
        }

        r2.sizeMeToFitChildren();
        r2.viewportY = r1.viewportH;

        var r3 = getBasicObject();

        x = 0;

        for (var a = 18; a < 26; a++) {
          var n = letters[a];

          n.viewportX = x;
          x += n.viewportW;
          r3.add(n);
        }

        r3.sizeMeToFitChildren();
        r3.viewportY = r2.viewportY + r2.viewportH; // + 5;

        rs.add(r1);
        rs.add(r2);
        rs.add(r3);
      }

      rs.sizeMeToFitChildren();
      keyboard.bottom.add(rs);
    } else {
      // rebuild every time because of later code to add extra buttons
      var r1 = getBasicObject();

      var c = getClearAll(keyboard.bottom.tucked, true);

      clearAllB = c;
      c.animates = true;
      r1.add(c);

      var q = getQuantityButton(c);

      q.animates = true;
      r1.add(q);

      var a = getAbsoluteValueButton(c);

      a.animates = true;
      r1.add(a);

      // <=, >=, pi
      var letters = '\u2264\u2265\u03C0';

      for (var a = 0; a < letters.length; a++) {
        (function (b) {
          var key = getSymbolButton(b, keyboard.bottom.tucked, true);

          key.animates = true;
          r1.add(key);
        })(letters.substring(a, a + 1));
      }

      r1.layoutChildrenLR(8);
      r1.sizeMeToFitChildren();

      keyboard.bottom.add(r1);

      keyboard.bottom.sizeMeToFitChildren();
    }

    keyboard.bottom.sizeMeToFitChildren();

    keyboard.extraSubmitButtons.children = [];

    if (currentAttemptGetter) {
      var bs = buttons[keyboard.agId + ''];

      if (bs) {
        buttonKeys = [];

        var row2 = getBasicObject();

        for (var a = 0; a < bs.length; a++) {
          (function (z) {
            var key = getAnswerButton(bs[z]);

            row2.add(key);
            buttonKeys.push(key);
          })(a);
        }

        row2.layoutChildrenLR(10);
        row2.sizeMeToFitChildren();
        keyboard.extraSubmitButtons.add(row2);
        keyboard.extraSubmitButtons.sizeMeToFitChildren();
        keyboard.extraSubmitButtons.centerHorizontally();
        keyboard.extraSubmitButtons.viewportH = row2.viewportH + 5;
      } else {
        keyboard.extraSubmitButtons.viewportH = 0;
      }
    }

    keyboard.top.viewportY = keyboard.extraSubmitButtons.viewportH;
    keyboard.bottom.viewportY =
      keyboard.top.viewportY + keyboard.top.viewportH + 5;

    keyboard.setAllDim(
      keyboardW,
      keyboard.bottom.viewportY + keyboard.bottom.viewportH
    );
    keyboard.centerHorizontally();

    repositionProblemRows();
  };

  var getAnswerButton = function (text) {
    var key = getBasicObject();

    key.text = text;

    var mouseDownResponse = function () {
      updateAnswerButtonHighlighting(key);

      submitAttempt({ button: text, eventSource: 'text button for ' + text });

      return true;
    };
    var a = getText(5, 4, text);

    a.setFont('bold 14px ' + lineFont);
    var w = a.viewportW;
    var h = a.viewportH;

    key.setAllDim(w + 16, h + 13);
    key.mouseDownResponse = mouseDownResponse;
    key.animates = true;

    key.paintMe = function (ctx) {
      // draw a rectangle in the background to serve as a border to
      // help distinguish the button from the rest of the keyboard
      roundRect2(ctx, -2, -2, w + 20, h + 14, 10, getThemeColor());
      // draw a rectangle on top of the background to serve as the button
      roundRect2(
        ctx,
        0,
        0,
        w + 16,
        h + 10,
        8,
        key.sel ? getThemeColor() : 'lightgray'
      );
      ctx.save();

      ctx.fillStyle = key.sel ? 'white' : 'gray';
      ctx.baseline = 'top';
      ctx.font = a.font;
      ctx.fillText(text, 8, 17);

      ctx.restore();
    };

    return key;
  };

  // clear highlighting of all answer buttons
  // then highlight the one that was pressed (if any)
  var updateAnswerButtonHighlighting = function (key) {
    if (currentAttemptGetter.gmmName === 'gridGetter') {
      currentAttemptGetter.deselectAllButtons();
    } else {
      currentAttemptGetter.getJsonFromServer().selectedButton = undefined;
    }

    for (var a = 0; a < buttonKeys.length; a++) {
      buttonKeys[a].sel = false;
    }

    if (key) {
      key.sel = true;

      if (currentAttemptGetter.gmmName === 'gridGetter') {
        currentAttemptGetter.selectedButton = key;
      } else {
        currentAttemptGetter.getJsonFromServer().selectedButton = key;
      }
    }
  };

  var getSymbolButton = function (b, s) {
    s = !s ? 25 : s;
    var key = getBasicObject();
    var mouseDownResponse = function () {
      pressedKey(b);

      return true;
    };

    key.mouseDownResponse = mouseDownResponse;
    key.setAllDim(s, s);
    key.gmmName = 'symbol button ' + b;

    key.paintMe = function (ctx) {
      roundRect2(
        ctx,
        0,
        0,
        s,
        s,
        8,
        key.animated ? getThemeColor() : 'lightgray'
      );
      var y = 3;

      if (b == '.' || b == ',') y = -3;
      if (b == '*') y = 6;
      ctx.save();
      ctx.beginPath();

      ctx.fillStyle = 'gray';
      ctx.textBaseline = 'top';
      ctx.font = s - 7 + 'px ' + lineFont;
      var wh = ctx.measureText(b).width;

      ctx.fillText(b, (s - wh) / 2, y);

      ctx.restore();
    };

    return key;
  };

  var getRootButton = function (pow) {
    var mouseDownResponse = function () {
      if (!focused) {
        return false;
      }

      var created = focused.parent.insertRequested('root', focused);

      if (created) {
        created.grabFocus();
        setKeyboardBottom('numbers');
      }

      return true;
    };
    var rootLine = toLine(
      '<r><rad><t>X</t></rad><root><t>n</t></root></r>',
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      16 * keyboardScalar + 'px ' + lineFont
    );

    rootLine.setEditable(false);
    rootLine.setFillStyleRecursive('gray');
    var root = getBasicObject();

    root.paintParentFirst = true;
    root.add(rootLine);
    root.mouseDownResponse = mouseDownResponse;
    root.setAllDim(rootLine.viewportW + root.viewportMargin + 8, pow.viewportH);
    root.centerVertically();
    root.centerHorizontally();
    root.children[0].viewportX -= 1;

    root.paintMe = function (ctx) {
      roundRect2(
        ctx,
        0,
        0,
        root.viewportW,
        root.viewportH,
        8,
        root.animated ? getThemeColor() : 'lightgray'
      );
    };

    return root;
  };

  var clearAll = function (
    preserveBottom,
    skipChangedMaybe,
    skipAnim,
    clearButton,
    updateAnswerButtons
  ) {
    if (focused) {
      if (!skipChangedMaybe) changedMaybe();

      var line = focused.line;

      if (line.normalGetter) {
        line.normalGetter.clear(clearButton);
      } else {
        focused.clearMyLine();
        line.grabFocus();
      }

      storeCurrentLineXML();

      if (updateAnswerButtons) updateAnswerButtonHighlighting(null);

      if (!preserveBottom) setKeyboardBottom('numbers');
      else if (!skipAnim && clearAllB) {
        clearAllB.animate(paintCanvas);
        paintCanvas();
      }
    }
  };

  var getClearAll = function (b, preserveBottom, xtraL, xtraB, xtraR) {
    xtraL = xtraL || 0;
    xtraB = xtraB || 0;
    xtraR = xtraR || 0;
    var rectBack = b ? true : false;
    var ret = getBasicObject();
    var mouseDownResponse = function () {
      clearAll(preserveBottom, null, null, true, true);

      return true;
    };

    ret.gmmName = 'clearAll';
    var s = b ? b : 22;

    ret.setAllDim(s + xtraL + xtraR, s + xtraB);

    ret.paintMe = function (ctx) {
      ctx.save();

      ctx.translate(xtraL, 0);

      if (rectBack) {
        roundRect2(
          ctx,
          0,
          0,
          b,
          b,
          6,
          ret.animated ? getThemeColor() : 'lightgray'
        );
      }

      var m = b ? 6 : 1;

      var i = getImage('trash', paintCanvas);

      drawImage(ctx, i, 0.2 * b, 0.15 * b, 0.6 * b, 0.7 * b);

      ctx.restore();
    };

    ret.animates = true;
    ret.mouseDownResponse = mouseDownResponse;

    return ret;
  };

  var getQuantityButton = function (pow) {
    var mouseDownResponse = function () {
      if (!focused) {
        return false;
      }

      var created = focused.parent.insertRequested('quantity', focused);

      if (created) {
        created.grabFocus();
        setKeyboardBottom('numbers');
      }

      return true;
    };

    var qLine = toLine(
      '<q><t>X</t></q>',
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      16 * keyboardScalar + 'px ' + lineFont
    );

    qLine.setEditable(false);
    qLine.setFillStyleRecursive('gray');
    var quantity = getBasicObject();

    quantity.paintParentFirst = true;
    quantity.add(qLine);
    quantity.mouseDownResponse = mouseDownResponse;
    quantity.setAllDim(
      qLine.viewportW + quantity.viewportMargin + 6,
      pow.viewportH
    );
    quantity.centerVertically();
    quantity.centerHorizontally();

    quantity.paintMe = function (ctx) {
      roundRect2(
        ctx,
        0,
        0,
        quantity.viewportW,
        quantity.viewportH,
        8,
        quantity.animated ? getThemeColor() : 'lightgray'
      );
    };

    return quantity;
  };

  var getAbsoluteValueButton = function (pow) {
    var mouseDownResponse = function () {
      if (!focused) {
        return false;
      }

      var created = focused.parent.insertRequested('absoluteValue', focused);

      if (created) {
        created.grabFocus();
        setKeyboardBottom('numbers');
      }

      return true;
    };

    var aLine = toLine(
      '<a><t>X</t></a>',
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      16 * keyboardScalar + 'px ' + lineFont
    );

    aLine.setEditable(false);
    aLine.setFillStyleRecursive('gray');
    var absoluteValue = getBasicObject();

    absoluteValue.paintParentFirst = true;
    absoluteValue.add(aLine);
    absoluteValue.mouseDownResponse = mouseDownResponse;
    absoluteValue.setAllDim(
      aLine.viewportW + absoluteValue.viewportMargin + 6,
      pow.viewportH
    );
    absoluteValue.centerVertically();
    absoluteValue.centerHorizontally();

    absoluteValue.paintMe = function (ctx) {
      roundRect2(
        ctx,
        0,
        0,
        absoluteValue.viewportW,
        absoluteValue.viewportH,
        8,
        absoluteValue.animated ? getThemeColor() : 'lightgray'
      );
    };

    return absoluteValue;
  };

  var getPowButton = function () {
    var mouseDownResponse = function () {
      if (!focused) {
        return false;
      }

      var created = focused.parent.insertRequested('power', focused);

      // paintCanvas();
      if (created) {
        created.unsureWhichToFocus();
        setKeyboardBottom('numbers');
      }

      return true;
    };

    var pLine = toLine(
      '<p><b><t>X</t></b><e><t>2</t></e></p>',
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      16 * keyboardScalar + 'px ' + lineFont
    );

    pLine.setEditable(false);
    pLine.setFillStyleRecursive('gray');
    var pow = getBasicObject();

    pow.paintParentFirst = true;
    pow.add(pLine);
    pow.mouseDownResponse = mouseDownResponse;
    pow.setAllDim(
      pLine.viewportW + pow.viewportMargin + 10,
      pLine.viewportH + pow.viewportMargin + 10
    );
    pow.centerVertically();
    pow.centerHorizontally();

    pow.paintMe = function (ctx) {
      roundRect2(
        ctx,
        0,
        0,
        pow.viewportW,
        pow.viewportH,
        8,
        pow.animated ? getThemeColor() : 'lightgray'
      );
    };

    return pow;
  };

  var getFunctionButton = function (ref) {
    var mouseDownResponse = function (x, y) {
      if (!focused) {
        return false;
      }

      var options = [
        getLogarithmBaseTenMenuItem(),
        getLogarithmMenuItem(),
        getFunctionMenuItem('ln', 'X'),
        getFunctionMenuItem('sin', theta),
        getFunctionMenuItem('cos', theta),
        getFunctionMenuItem('tan', theta),
        getFunctionMenuItem('cot', theta),
        getFunctionMenuItem('sec', theta),
        getFunctionMenuItem('csc', theta),
        getFunctionMenuItem('sin' + inverse, theta),
        getFunctionMenuItem('cos' + inverse, theta),
        getFunctionMenuItem('tan' + inverse, theta),
        getFunctionMenuItem('cot' + inverse, theta),
        getFunctionMenuItem('sec' + inverse, theta),
        getFunctionMenuItem('csc' + inverse, theta),
      ];

      var tl = getFullTopLeftLocation(keyboard.getter);
      var tlw = keyboard.getter.viewportW;
      var tlh = keyboard.getter.viewportH;

      var menu = getMultiMenu(options, 3);

      menu.viewportX = tl.x + tlw / 2 - menu.width / 2;
      menu.viewportY = tl.y + tlh;

      floater.setPanel(menu);

      return true;
    };

    var pLine = toLine(
      '<t>\u0192(x)</t>',
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      16 * keyboardScalar + 'px ' + lineFont
    );

    pLine.setEditable(false);
    pLine.setFillStyleRecursive('gray');
    var sub = getBasicObject();

    sub.paintParentFirst = true;
    sub.add(pLine);
    sub.mouseDownResponse = mouseDownResponse;
    sub.setAllDim(pLine.viewportW + sub.viewportMargin + 10, ref.viewportH);
    sub.centerVertically();
    sub.centerHorizontally();

    sub.paintMe = function (ctx) {
      roundRect2(
        ctx,
        0,
        0,
        sub.viewportW,
        sub.viewportH,
        8,
        sub.animated ? getThemeColor() : 'lightgray'
      );
    };

    return sub;
  };

  var getSubscriptButton = function (ref) {
    var mouseDownResponse = function () {
      if (!focused) {
        return false;
      }

      var created = focused.parent.insertRequested('subscript', focused);

      if (created) {
        created.unsureWhichToFocus();
        setKeyboardBottom('numbers');
      }

      return true;
    };

    var pLine = toLine(
      '<s><b><t>X</t></b><sub><t>n</t></sub></s>',
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      16 * keyboardScalar + 'px ' + lineFont
    );

    pLine.setEditable(false);
    pLine.setFillStyleRecursive('gray');
    var sub = getBasicObject();

    sub.paintParentFirst = true;
    sub.add(pLine);
    sub.mouseDownResponse = mouseDownResponse;
    sub.setAllDim(
      pLine.viewportW + sub.viewportMargin + 10,
      pLine.viewportH + sub.viewportMargin + 10
    );
    sub.centerVertically();
    sub.centerHorizontally();

    sub.paintMe = function (ctx) {
      roundRect2(
        ctx,
        0,
        0,
        sub.viewportW,
        sub.viewportH,
        8,
        sub.animated ? getThemeColor() : 'lightgray'
      );
    };

    return sub;
  };

  var getLogarithmMenuItem = function () {
    var mouseDownResponse = function () {
      if (!focused) {
        return false;
      }

      var created = focused.parent.insertRequested('function', focused);

      if (created) {
        created.setTypeAndBase('log', '', true);
        created.unsureWhichToFocus();
        setKeyboardBottom('numbers');
      }

      return true;
    };

    var pLine = toLine(
      '<tf><ft><t>log</t></ft><fb><t>n</t></fb><v><t>X</t></v></tf>',
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      16 * keyboardScalar + 'px ' + lineFont
    );

    pLine.setEditable(false);
    pLine.setFillStyleRecursive('white');
    pLine.mouseDownResponse = mouseDownResponse;

    return pLine;
  };

  var getLogarithmBaseTenMenuItem = function () {
    var mouseDownResponse = function () {
      if (!focused) {
        return false;
      }

      var created = focused.parent.insertRequested('function', focused);

      if (created) {
        created.setTypeAndBase('log', '10', false);
        created.unsureWhichToFocus();
        setKeyboardBottom('numbers');
      }

      return true;
    };

    var pLine = toLine(
      '<tf><ft><t>log</t></ft><fb><t>10</t></fb><v><t>X</t></v></tf>',
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      16 * keyboardScalar + 'px ' + lineFont
    );

    pLine.setEditable(false);
    pLine.setFillStyleRecursive('white');
    pLine.mouseDownResponse = mouseDownResponse;

    return pLine;
  };

  var getFunctionMenuItem = function (type, variable) {
    var mouseDownResponse = function () {
      if (!focused) {
        return false;
      }

      var created = focused.parent.insertRequested('function', focused);

      if (created) {
        created.setTypeAndBase(type, '*', false);
        created.unsureWhichToFocus();
        setKeyboardBottom('numbers');
      }

      return true;
    };

    var pLine = toLine(
      '<tf><ft><t>' +
        type +
        '</t></ft><fb><t>*</t></fb><v><t>' +
        variable +
        '</t></v></tf>',
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      16 * keyboardScalar + 'px ' + lineFont
    );

    pLine.setEditable(false);
    pLine.setFillStyleRecursive('white');
    pLine.mouseDownResponse = mouseDownResponse;

    return pLine;
  };

  var scaleFraction = function (fraction) {
    if (!fraction) return;

    const fractionScale = 0.7;
    const parentScale = 0.9;

    // Check if fraction is an exponent or subscript
    if (
      fraction.parent &&
      (fraction.parent.gmmName === 'exp multi' ||
        fraction.parent.gmmName === 'sub multi')
    ) {
      // adjust the font scaling
      var fractionParent = fraction.parent;

      if (fractionParent.children && fractionParent.children.length > 0) {
        scaleFont(fraction, fractionParent.children[0].font, fractionScale);
        scaleFont(fractionParent, fractionParent.children[0].font, parentScale);
      }
    }
  };

  var getFracButton = function () {
    var frac = getBasicObject();

    frac.paintParentFirst = true;

    frac.setShowBarred = function (b) {
      frac.children = [];
      var mouseDownResponse;

      var fLine = toLine(
        '<f><num><t>Y</t></num><denom><t>X</t></denom></f>',
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        16 * keyboardScalar + 'px ' + lineFont
      );

      fLine.setEditable(false);
      fLine.paintParentFirst = true;
      fLine.setFillStyleRecursive('gray');

      if (!b) {
        mouseDownResponse = function () {
          if (!focused) {
            return false;
          }

          var created = focused.parent.insertRequested('fraction', focused);

          scaleFraction(created);

          if (created) {
            created.grabFocus();
            setKeyboardBottom('numbers');
          }

          return true;
        };

        frac.add(fLine);
      } else {
        mouseDownResponse = function () {
          if (!focused) {
            return false;
          }

          var created = focused.parent.insertRequested('barred', focused);

          if (created) {
            created.grabFocus();
            setKeyboardBottom('numbers');
          }

          return true;
        };

        var bLine = toLine(
          '<b><t>X</t></b>',
          undefined,
          undefined,
          undefined,
          undefined,
          undefined,
          undefined,
          '16px ' + lineFont
        );

        bLine.setEditable(false);
        bLine.paintParentFirst = true;
        bLine.setFillStyleRecursive('gray');
        frac.add(bLine);
      }

      frac.mouseDownResponse = mouseDownResponse;
      frac.setAllDim(
        fLine.viewportW + frac.viewportMargin + 10,
        fLine.viewportH + frac.viewportMargin + 10
      );
      frac.centerVertically();
      frac.centerHorizontally();
    };

    frac.paintMe = function (ctx) {
      roundRect2(
        ctx,
        0,
        0,
        frac.viewportW,
        frac.viewportH,
        8,
        frac.animated ? getThemeColor() : 'lightgray'
      );
    };

    frac.setShowBarred(false);

    return frac;
  };

  var getMultipleLines = function (string, maxW, ctx) {
    var ret = [];

    var words = [];

    while (string.length > 0) {
      var x = 0;
      var found = false;
      var end = false;

      while (!found && !end) {
        var s = string.substring(x, x + 1);

        if (s == ' ') {
          found = true;
        }

        x++;

        if (x == string.length) {
          end = true;
        }
      }

      var word = '';

      if (!end) {
        word = string.substring(0, x - 1);
        string = string.substring(x);
      } else {
        word = string;
        string = '';
      }

      words.push(word);
    }

    var line = words[0];
    var lastW = ctx.measureText(line).width;

    for (var x = 1; x < words.length; x++) {
      var maybe = line + ' ' + words[x];
      var metrics = ctx.measureText(maybe);

      if (metrics.width > maxW) {
        ret.push([line, lastW]);
        line = words[x];
        lastW = 0;
      } else {
        line = maybe;
        lastW = metrics.width;
      }
    }

    ret.push([line, lastW]);

    return ret;
  };

  /** NOT carefully considering escapes, acutal use of <, > in strings */
  var findClose = function (tag, start, val) {
    var starter = '<' + tag + '>';
    var closer = '</' + tag + '>';
    var ret = -1;
    var xtra = 0;
    var done = false;

    while (!done) {
      var nextOpen = val.indexOf(starter, start);
      var nextClose = val.indexOf(closer, start);

      if (nextClose < 0) {
        console.error("next close doesn't exist?");
      }

      if (nextOpen == -1) {
        if (xtra === 0) {
          return nextClose;
        } else {
          xtra--;
          start = nextClose + closer.length;
        }
      } else if (nextOpen < nextClose) {
        xtra++;
        start = nextOpen + starter.length;
      } else {
        if (xtra > 0) {
          xtra--;
          start = nextClose + closer.length;
        } else {
          return nextClose;
        }
      }
    }

    return ret;
  };

  var repositionProblemPanel = function () {
    var pw = world.viewportW - 10 - world.viewportMargin * 2;
    var ph = world.viewportH - world.viewportMargin * 2;

    problemPanel.setAllDim(pw, ph);
    problemPanel.viewportY = 10;
    problemPanel.viewportX = 5;
  };

  var resizeCanvas = function (can, w, h) {
    // we send in computed heights that are non-integral
    // we speculate that this causes attempts at 'sub-pixel'
    // precision that can result in slight textual blurring
    if (Math.floor(w) !== w) {
      w = 1 + Math.floor(w);
    }

    if (Math.floor(h) !== h) {
      h = 1 + Math.floor(h);
    }

    applyPixelRatioToCanvas(can, w, h);
  };

  var isTesting = function () {
    return getProblemContext().isTesting();
  };

  var getCurrentAttemptGetterName = function () {
    return currentAttemptGetter ? currentAttemptGetter.gmmName : 'unknown';
  };

  // This method is a hack to ignore all input after mobius totallyCorrect.
  // On totallyCorrect, mobius sample problem stays on screen
  // and mouse clicks and key presses are still captured.
  // This triggers an 'extra' submit, which is then probably marked wrong
  // because WF saved and sent a new problem -- it checks the second
  // submission against that new (unseen) problem. It can also
  // cause a lot of weird additional input to appear on the problem.
  var ignoreTeacherAfterTotallyCorrect = function () {
    return !isStudent && currentAttemptGetter && !currentAttemptGetter.e;
  };

  var keyDown = function (evt, skipRepaint) {
    if (ignoreTeacherAfterTotallyCorrect()) return;

    // Ignore key presses if the student app has gone dormant
    // due to inactivity
    if (!getProblemContext().activity()) {
      evt.preventDefault();

      return false;
    } else if (evt.key === 'Backspace') {
      if (focused) {
        focused.backspace();
      }
    } else if (evt.key === 'Delete') {
      if (focused) {
        focused.deletePressed();
      }
    } else if (evt.key === 'Tab') {
      advanceFocus(undefined, true);
    } else if (evt.key === 'Enter' && !evt.ctrlKey) {
      submitAttempt({
        eventSource:
          'pressed Enter to submit, getter: ' + getCurrentAttemptGetterName(),
      });
    } else if (
      ['ArrowLeft', 'ArrowUp', 'ArrowRight', 'ArrowDown'].includes(evt.key)
    ) {
      if (evt.key === 'ArrowUp') {
        moveCursor('up');
      } else if (evt.key === 'ArrowDown') {
        moveCursor('down');
      } else if (evt.key === 'ArrowRight') {
        moveCursor('right');
      } else if (evt.key === 'ArrowLeft') {
        moveCursor('left');
      }

      // don't want page to scroll in addition to moving cursor in getter
      evt.stopPropagation();
      evt.preventDefault();
    } else if (evt.key === ')') {
      moveCursor('right');
    } else if (evt.metaKey) {
      return;
    }
    // insert or 'normal' key
    else {
      if (!focused) {
        return;
      }
      // insert
      else if (
        evt.ctrlKey ||
        evt.key === '(' ||
        evt.key === '|' ||
        evt.key === '^'
      ) {
        let created;

        switch (evt.key) {
          case 'f':
          case 'F':
            created = focused.parent.insertRequested('fraction', focused);
            scaleFraction(created);
            break;
          case '(':
            created = focused.parent.insertRequested('quantity', focused);
            break;
          case '|':
            created = focused.parent.insertRequested('absoluteValue', focused);
            break;
          case 'r':
          case 'R':
            created = focused.parent.insertRequested('root', focused);
            break;
          case '^':
          case 'p':
          case 'P':
            created = focused.parent.insertRequested('power', focused);
            break;
          case 'b':
          case 'B':
            created = focused.parent.insertRequested('barred', focused);
            break;
          case ',':
          case '<':
            created = focused.insertString('\u2264'); // less or equal
            storeCurrentLineXML();
            break;
          case '.':
          case '>':
            created = focused.insertString('\u2265'); // greater or equal
            storeCurrentLineXML();
            break;
          case 'Enter':
            focused.nextLine();
            storeCurrentLineXML();
            break;
          default:
            break;
        }

        if (created) {
          if (created.t !== 'power') {
            created.grabFocus();
          } else {
            created.unsureWhichToFocus();
          }

          storeCurrentLineXML();

          evt.preventDefault();
          evt.stopPropagation();
        }
      }
      // 'normal' key
      else {
        // ignore non character keys, such as just pressing 'shift'
        if (evt.key.length !== 1) {
          return;
        }

        pressedKey(evt.key);
      }
    }

    if (!skipRepaint) paintCanvas();
  };

  // width is optional
  var resetSizeAndLayout = function (width) {
    // close nativeInputBox on resize, as otherwise its location
    // is no longer valid
    problemModalState().setNativeInputLocation(undefined);

    canvasW = width || NARROW_PROBLEM_WIDTH;

    if (canvasW < PROBLEM_WIDTH) {
      screenMaxTextW = Math.min(canvasW - 30, NARROW_PROBLEM_WIDTH);
    } else {
      screenMaxTextW = MAX_MULTI_LINE_WIDTH;
    }

    world.viewportW = canvasW;

    if (!$canvas) return;

    // includes building keyboard
    rebuildCurrentProblem();
  };

  function exponentialY(a, b, x, c) {
    return a * Math.pow(b, x) + (c != null ? c : 0);
  }

  var setImageLoadedListener = function (iLL) {
    imageLoadedListener = iLL;
  };

  var setSelfCheckListener = function (asl) {
    selfCheckListener = asl;
  };

  var isReadAloud = function () {
    return getProblemContext().isPermitReadAloud();
  };

  var setPermitReadAloud = function (b) {
    problemModalState().setPermitReadAloud(b);
    if (!$canvas) return;
    rebuildCurrentProblem();
  };

  var rebuildCurrentProblem = function () {
    buildKeyboard();
    if (builtCurrentFrom) setProblem(builtCurrentFrom, currentProblemId);
  };

  var touchStart = function (evt) {
    /*
     * Ideally, we could directly access coordinates of touch on the canvas.
     * This is possible with MouseEvent.offsetX, for example. However,
     * TouchEvent does not have any similar normalized field, so we have to work
     * with two values. First, `pageX`, which gives the x coordinate on the viewport,
     * including horizontal scroll offset. Second, the target's (target = canvas)
     * offset from the left.
     */
    let x = evt.changedTouches[0].pageX - $(evt.target).offset().left;
    let y = evt.changedTouches[0].pageY - $(evt.target).offset().top;

    if (dragController) {
      dragController.mostRecentMouseDown = { x: x, y: y };
      dragController.sourceCanvas = evt.target;
    }

    const touchStarted = world.touchStart(x, y);

    if (touchStarted) {
      paintCanvas();
    }

    return touchStarted;
  };

  var touchMove = function (evt) {
    let x = evt.changedTouches[0].pageX - $(evt.target).offset().left;
    let y = evt.changedTouches[0].pageY - $(evt.target).offset().top;

    canvasMouseMoveHelper(x, y);
  };

  var touchEnd = function (evt) {
    let x = evt.changedTouches[0].pageX - $(evt.target).offset().left;
    let y = evt.changedTouches[0].pageY - $(evt.target).offset().top;

    canvasMouseUpHelper(x, y);
  };

  var stopDragging = function () {
    if (dragController) dragController.setDragging(false);
  };

  // width is optional, defaults to NARROW_PROBLEM_WIDTH
  var setCanvas = function (canvas, width) {
    if (!canvas) throw 'undefined canvas sent to problemCanvasOwner.setCanvas';

    $canvas = $(canvas);

    if (!world) {
      world = getBasicObject();
      world.gmmName = 'world';

      world.fill = isStudent ? STUDENT_PROBLEM_BACKCOLOR : 'white';

      problemPanel = getBasicObject();
      problemPanel.gmmName = 'problemPanel';

      world.add(problemPanel);
    }

    resetSizeAndLayout(width || NARROW_PROBLEM_WIDTH);

    initSpeech(paintCanvas);
  };

  var onlyHasNormalGetters = function () {
    return onlyNormalGetters;
  };

  return {
    setCanvas,
    setProblem,
    setToBlank,
    repaint: paintCanvas,
    rebuildCurrentProblem,
    resetSizeAndLayout,
    keyDown,
    onlyHasNormalGetters,
    submitAllAgs,
    setImageLoadedListener,
    setSelfCheckListener,
    setBase64EncodedImgs,
    setPermitReadAloud,
    setSubmitLock,
    mouseDown: canvasMouseDown,
    mouseMove: canvasMouseMove,
    mouseUp: canvasMouseUp,
    touchStart,
    touchMove,
    touchEnd,
    stopDragging,
    isDragging,
  };
}
